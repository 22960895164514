import React from 'react';
import { Container } from 'react-bootstrap';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_CARTAO } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import ModalCartao from '../../Components/ControleFinanceiro/ModalCartao';
import CartaoList from '../../Components/ControleFinanceiro/CartaoList';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Cartao = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_CARTAO(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  return (
    <>
      <Head
        metaDescription="Cartões de Crédito | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Cartões de Crédito | Guardar Dinheiro PRO"
        h1="Cartões de Crédito | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />
      <BreadcumbH1
        paginaAtual=":: MEUS CARTÕES DE CRÉDITO"
        texto=":: MEUS CARTÕES DE CRÉDITO"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MEUS CARTÕES DE CRÉDITO</h1>
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {data ? (
          <CartaoList
            data={data.filter((registro) => registro.fg_status !== 'A')}
          />
        ) : (
          <Loading />
        )}

        {show && <ModalCartao onClose={() => setShow(false)} show={show} />}
      </Container>
    </>
  );
};

export default Cartao;
