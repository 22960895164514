import React from 'react';
import isMobile from '../Helper/Mobile';
import { Bar } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import styleSelect from '../../Pages/ControleFinanceiro/css/Select.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';
import Label from '../Forms/Label';

const BoxCartaoCreditoAnual = ({ data, contasOuCartoes, fgContaOuCartao }) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const [cartao, setCartao] = React.useState(null);
  const [conta, setConta] = React.useState(null);

  function getDadosMesPorCartao(dadosAnual) {
    let retorno = [];
    let mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    for (let i = 0; i < mes.length; i++) {
      let dataset = dadosAnual.filter(
        (dados) =>
          dados.data_vencimento.substring(
            5,
            dados.data_vencimento.length - 3,
          ) === mes[i],
      );

      let total = 0;
      if (dataset.length > 0) {
        dataset.forEach((registro) => {
          total = total + parseFloat(registro.valor);
        });
        retorno.push(total.toFixed(2));
      } else {
        retorno.push(0);
      }
    }

    // console.log(retorno);
    return retorno;
  }

  function getGastoMensalPorCartao() {
    let dadosAnual = [];

    if (cartao) {
      dadosAnual = data.filter(
        (dados) =>
          dados.forma === 'Cartão de Crédito' &&
          dados.cartao === cartao &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    } else {
      if ((cartao === '') | !cartao) {
        dadosAnual = data.filter(
          (dados) =>
            dados.forma === 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 6,
            ) === window.sessionStorage.getItem('gd_cf_ano'),
        );

        // console.log(dadosAnual);
      }
    }

    let dataSetCartoes = getDadosMesPorCartao(dadosAnual);

    let labels = [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez',
    ];

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            {':: despesas mês a mês por cartão de crédito'}
          </h2>
        </div>
        <br />
        <div>
          <div style={{ textAlign: 'left', margin: '5px' }}>
            <Label label={'Selecione um Cartão'} />

            <select
              // className={style.dropdownList}
              id="seletorOrdenacao"
              onChange={(e) => {
                setCartao(e.target.value);
              }}
              // value={categoria}
              className={styleSelect.select}
            >
              <option
                // className={style.dropdownList}
                key=""
                value=""
              >
                Selecione
              </option>
              {contasOuCartoes.map((dado) => (
                <option
                  // className={style.dropdownList}
                  key={dado}
                  value={dado}
                >
                  {dado}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Bar
          data={{
            labels: labels,
            datasets: [
              {
                label: cartao ? cartao : 'Cartão',
                data: dataSetCartoes,
                backgroundColor: '#B22222',

                barPercentage: 1,
              },
            ],
          }}
          height={!mobile ? 100 : 200}
        ></Bar>
        <div style={{ textAlign: 'left' }}>
          <button
            className={stylesButton.button}
            onClick={() => navigate('/plano-pro/faturas')}
          >
            Ver Faturas
          </button>
        </div>
      </>
    );
  }

  function getGastoMensalPorConta() {
    let dadosAnual = [];

    if (conta) {
      dadosAnual = data.filter(
        (dados) =>
          dados.conta === conta &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    let dataSetCartoes = getDadosMesPorCartao(dadosAnual);

    let labels = [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez',
    ];

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            {':: despesas mês a mês por conta'}
          </h2>
        </div>
        <br />
        <div>
          <div style={{ textAlign: 'left', margin: '5px' }}>
            <Label label={'Selecione uma Conta'} />

            <select
              // className={style.dropdownList}
              id="seletorOrdenacao"
              onChange={(e) => {
                setConta(e.target.value);
              }}
              // value={categoria}
              className={styleSelect.select}
            >
              <option
                // className={style.dropdownList}
                key=""
                value=""
              >
                Selecione
              </option>
              {contasOuCartoes.map((dado) => (
                <option
                  // className={style.dropdownList}
                  key={dado}
                  value={dado}
                >
                  {dado}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Bar
          data={{
            labels: labels,
            datasets: [
              {
                label: 'Conta',
                data: dataSetCartoes,
                backgroundColor: '#B22222',

                barPercentage: 1,
              },
            ],
          }}
          height={!mobile ? 100 : 200}
        ></Bar>
      </>
    );
  }

  return (
    <>
      {fgContaOuCartao === 'Cartao' && getGastoMensalPorCartao()}
      {fgContaOuCartao === 'Conta' && getGastoMensalPorConta()}
    </>
  );
};
export default BoxCartaoCreditoAnual;
