import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import Error from '../Helper/Error';

function ModalConta(props) {
  const inputElement = React.useRef();
  const [idConta, setIdConta] = React.useState(null);
  const [nomeConta, setNomeConta] = React.useState(null);
  const [instituicao, setInstituicao] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    // console.log(props);
    if (props.idConta) {
      setIdConta(props.idConta);
    }
    if (props.nomeConta) {
      setNomeConta(props.nomeConta);
    }
    if (props.instituicao) {
      setInstituicao(props.instituicao);
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(http_method, id_conta, nome_conta, instituicao) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id_conta) {
      id_conta = '1';
    }

    const urlPostPortfolio = `https://m2fbwt18e0.execute-api.sa-east-1.amazonaws.com/producao/contas?user_id=${user_id}&id=${id_conta}&nome_conta=${encodeURIComponent(
      nome_conta,
    )}&instituicao=${instituicao}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addConta(event) {
    event.preventDefault();
    setLoading(true);

    if (isValidForm()) {
      let acao = 'INSERT';
      // console.log(props.acao);
      if (props.acao === 'UPDATE') {
        acao = props.acao;
      }

      const selectInstituicao = document.getElementById('select-banco');

      postItem(acao, idConta, nomeConta, selectInstituicao.value);
    }
    // console.log(idConta, nomeConta);
  }

  function isValidForm() {
    let retorno = true;
    if (nomeConta) {
      if (nomeConta.length < 1) {
        document.getElementById('v-conta').innerHTML =
          'Nome da Conta obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-conta').innerHTML =
        'Nome da Conta obrigatório';
      retorno = false;
    }

    const selectInstituicao = document.getElementById('select-banco');
    if (selectInstituicao) {
      if (selectInstituicao.value.length < 1) {
        document.getElementById('v-banco').innerHTML =
          'Instituição é obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-banco').innerHTML =
        'Instituição é obrigatório';
      retorno = false;
    }
    if (!retorno) {
      setLoading(false);
    }

    // retorno = false;
    return retorno;
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="lg"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>{'Contas'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-nome-conta'}
              label={'Nome Conta'}
              type="text"
              value={nomeConta}
              ref={inputElement}
              onChange={({ target }) => {
                setNomeConta(target.value);
                document.getElementById('v-conta').innerHTML = '';
              }}
            ></Input>
            <p id="v-conta" className={stylesTextos.textoErros}></p>
          </div>
          <div className={stylesModal.itemContainer}>
            <Select
              tipo="banco"
              label="Instituição"
              name="select-banco"
              valor={props.instituicao}
            />
            <p id="v-banco" className={stylesTextos.textoErros}></p>
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button className={stylesButton.button} onClick={(e) => addConta(e)}>
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConta;
