import React from 'react';
import isMobile from '../Helper/Mobile';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesCartao from '../../Pages/ControleFinanceiro/css/CartaoCredito.module.css';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import { AWS_GET_USER_LIMITE_CARTAO } from '../Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../Helper/Loading';
import { formataMoedaCF } from '../Helper/DateUtilities';
import { Alert, Table } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ModalCadastroMovimento from './ModalCadastroMovimento';

const BoxLimiteCartao = ({ isInicio, hide }) => {
  const mobile = isMobile();
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);

  const [idMovimentoDetalhe, setIdMovimentoDetalhe] = React.useState(null);
  const [dataMovimento, setDataMovimento] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [idCartao, setIdCartao] = React.useState(null);
  const [cartao, setCartao] = React.useState(null);

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_LIMITE_CARTAO(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  function getLimite(id_cartao, limite) {
    var limiteDisponivel = parseFloat(limite);
    var dadoCartao = [];
    if (data) {
      var dadoCartao = data.filter(
        (dados) => dados.id_cartao === id_cartao && dados.id_situacao === '0',
      );

      if (dadoCartao.length > 0) {
        dadoCartao.forEach((dado) => {
          limiteDisponivel = limiteDisponivel - parseFloat(dado.valor);
        });
      }
    }

    return limiteDisponivel;
  }

  function getSaldoMensal() {
    if (loading) return <Loading />;
    if (data) {
      let dadosFaturaMes = data.filter(
        (dados) =>
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
      return (
        <>
          <style type="text/css">
            {`
          .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
           }

           .carousel-control-next-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
          }
           `}
          </style>
          <Table responsive bordered hover size="sm" className={styles.tabela}>
            <thead>
              <tr>
                <th>Cartão</th>
                <th>Valor Fatura</th>
                <th>Vencimento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dadosFaturaMes.map((cartao) => {
                return (
                  <tr key={cartao.cartao}>
                    <td>
                      <div style={{ display: 'block' }}>
                        <div>
                          <span className={styles.spanDescricao}>
                            {cartao.cartao}
                          </span>
                        </div>
                        <div>
                          <span className={styles.spanDetalhes}>
                            {'Limite Utilizado:' +
                              formataMoedaCF(
                                parseFloat(cartao.limite) -
                                  parseFloat(
                                    getLimite(cartao.id_cartao, cartao.limite),
                                  ),
                                hide,
                              )}
                          </span>
                        </div>
                        <div>
                          <span className={styles.spanDetalhes}>
                            {'Limite Disponível:' +
                              formataMoedaCF(
                                getLimite(cartao.id_cartao, cartao.limite),

                                hide,
                              )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{formataMoedaCF(cartao.valor, hide)}</td>
                    <td>
                      {cartao.data_vencimento.split('-')[2] +
                        '/' +
                        cartao.data_vencimento.split('-')[1]}
                    </td>
                    <td>
                      {' '}
                      <div>
                        {cartao.id_situacao === '0' ? (
                          <button
                            className={stylesCartao.buttonPagarFatura}
                            onClick={(e) => {
                              setIdMovimentoDetalhe(cartao.id_mov_detalhe);
                              setDataMovimento(cartao.data_vencimento);
                              setDescricao(
                                cartao.cartao + ' - ' + cartao.desrcicao,
                              );
                              setValor(cartao.valor);
                              setIdCartao(cartao.id_cartao);
                              setCartao(cartao.cartao);
                              setShow(true);
                            }}
                          >
                            Pagar Fatura
                          </button>
                        ) : (
                          <button
                            className={stylesCartao.buttonPagarFaturaDisabled}
                            disabled
                          >
                            Pagar Fatura
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {show && (
            <ModalCadastroMovimento
              onClose={() => setShow(false)}
              show={show}
              idMovimentoDetalhe={idMovimentoDetalhe}
              dataMovimento={dataMovimento}
              valor={parseFloat(valor).toFixed(2)}
              descricao={descricao}
              cartao={idCartao}
              acao={'PAGAR'}
              // isFatura={true}
              idMovimento={idMovimentoDetalhe}
              idCartao={idCartao}
              situacao={'0'}
              totalParcelas={'NA'}
              tipoLancamento={'Despesa'}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  }

  function getLimiteInicio() {
    if (loading) return <Loading />;
    if (data) {
      let dadosFaturaMes = data.filter(
        (dados) =>
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );

      // console.log(dadosFaturaMes);
      return (
        <>
          {dadosFaturaMes.map((cartao) => {
            var limiteUtilizado =
              parseFloat(cartao.limite) -
              parseFloat(getLimite(cartao.id_cartao, cartao.limite));

            var limiteDisponivel = parseFloat(
              getLimite(cartao.id_cartao, cartao.limite),
            );

            var porcentagemLimite =
              (limiteUtilizado / parseFloat(cartao.limite)) * 100;

            // console.log(porcentagemLimite);
            if (porcentagemLimite > 70) {
              return (
                <Alert
                  variant="danger"
                  style={{ paddingTop: '1px', paddingBottom: '1px' }}
                >
                  <p className={stylesTipografia.pAlertasAnimate}>
                    {'o Limite do cartão ' +
                      cartao.cartao +
                      ' está quase sendo atingido. Limite disponível: ' +
                      formataMoedaCF(limiteDisponivel, hide)}
                  </p>
                </Alert>
              );
            }
          })}
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      {isInicio === false && getSaldoMensal()}
      {isInicio === true && getLimiteInicio()}
    </>
  );
};
export default BoxLimiteCartao;
