import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { formataMoedaCF } from '../Helper/DateUtilities';
import { useNavigate } from 'react-router-dom';

const BoxPendentes = ({ data, tipo, flagAnoOuMes, hide }) => {
  const navigate = useNavigate();
  function getDespesasPendentes() {
    let valor = 0;
    let dataPendentes;
    if (flagAnoOuMes === 'mes') {
      dataPendentes = data.filter(
        (dados) =>
          dados.tipo_registro === '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dataPendentes = data.filter(
        (dados) =>
          dados.tipo_registro === '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    let valorDespesasPendentes = 0;
    let valorReceitasPendentes = 0;
    let valorDespesasVencidas = 0;
    let valorReceitasVencidas = 0;
    let qtdeDespesasPendentes = 0;
    let qtdeDespesasPendentesVencidas = 0;
    var dataHoje = new Date().toISOString().slice(0, 10);
    dataPendentes.forEach((registro) => {
      if (registro.id_situacao === '0' && registro.tipo === 'Despesa') {
        valorDespesasPendentes =
          valorDespesasPendentes + parseFloat(registro.valor);

        qtdeDespesasPendentes = qtdeDespesasPendentes + 1;

        if (
          parseFloat(registro.data_vencimento.replaceAll('-', '')) <
          parseFloat(dataHoje.replaceAll('-', ''))
        ) {
          valorDespesasVencidas =
            valorDespesasVencidas + parseFloat(registro.valor);
          qtdeDespesasPendentesVencidas = qtdeDespesasPendentesVencidas + 1;
        }
      } else if (registro.id_situacao === '0' && registro.tipo === 'Receita') {
        valorReceitasPendentes =
          valorReceitasPendentes + parseFloat(registro.valor);

        if (
          parseFloat(registro.data_vencimento.replaceAll('-', '')) <
          parseFloat(dataHoje.replaceAll('-', ''))
        ) {
          valorReceitasVencidas =
            valorReceitasVencidas + parseFloat(registro.valor);
        }
      }
    });

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>:: notificações e alertas</h2>

          <br />
          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Despesas Pendentes:{' '}
              <b>{qtdeDespesasPendentes.toString()}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Despesas Pendentes Não Vencidas:
              <b>
                {Math.abs(
                  qtdeDespesasPendentesVencidas - qtdeDespesasPendentes,
                )}
              </b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Despesas Pendentes Vencidas:
              <b> {qtdeDespesasPendentesVencidas}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Despesas Pendentes:
              <b>{formataMoedaCF(valorDespesasPendentes, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Despesas Pendentes Não Vencidas:
              <b>
                {formataMoedaCF(
                  Math.abs(valorDespesasVencidas - valorDespesasPendentes),
                  hide,
                )}
              </b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Despesas Pendentes Vencidas:
              <b>{formataMoedaCF(valorDespesasVencidas, hide)}</b>
            </p>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          {flagAnoOuMes === 'mes' ? (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos')}
            >
              Ver Extrato
            </button>
          ) : (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos-ano')}
            >
              Ver Extrato Anual
            </button>
          )}
        </div>
      </>
    );
  }

  function getReceitasPendentes() {
    let valor = 0;
    let dataPendentes;
    if (flagAnoOuMes === 'mes') {
      dataPendentes = data.filter(
        (dados) =>
          dados.tipo_registro === '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dataPendentes = data.filter(
        (dados) =>
          dados.tipo_registro === '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    let valorDespesasPendentes = 0;
    let valorReceitasPendentes = 0;
    let valorDespesasVencidas = 0;
    let valorReceitasVencidas = 0;
    let qtdeDespesasPendentes = 0;
    let qtdeDespesasPendentesVencidas = 0;
    var dataHoje = new Date().toISOString().slice(0, 10);
    dataPendentes.forEach((registro) => {
      if (registro.id_situacao === '0' && registro.tipo === 'Receita') {
        valorDespesasPendentes =
          valorDespesasPendentes + parseFloat(registro.valor);

        qtdeDespesasPendentes = qtdeDespesasPendentes + 1;

        if (
          parseFloat(registro.data_vencimento.replaceAll('-', '')) <
          parseFloat(dataHoje.replaceAll('-', ''))
        ) {
          valorDespesasVencidas =
            valorDespesasVencidas + parseFloat(registro.valor);
          qtdeDespesasPendentesVencidas = qtdeDespesasPendentesVencidas + 1;
        }
      }
    });

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>:: notificações e alertas</h2>

          <br />
          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Receitas Pendentes:
              <b>{qtdeDespesasPendentes.toString()}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Receitas Pendentes Não Vencidas:{' '}
              <b>
                {Math.abs(
                  qtdeDespesasPendentesVencidas - qtdeDespesasPendentes,
                )}
              </b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Qtde. Receitas Pendentes Vencidas:{' '}
              <b>{qtdeDespesasPendentesVencidas}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Receitas Pendentes:{' '}
              <b>{formataMoedaCF(valorDespesasPendentes, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Receitas Pendentes Não Vencidas:{' '}
              <b>
                {formataMoedaCF(
                  Math.abs(valorDespesasVencidas - valorDespesasPendentes),
                  hide,
                )}
              </b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28A745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Valor Despesas Pendentes Vencidas:
              <b>{formataMoedaCF(valorDespesasVencidas, hide)}</b>
            </p>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          {flagAnoOuMes === 'mes' ? (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos')}
            >
              Ver Extrato
            </button>
          ) : (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos-ano')}
            >
              Ver Extrato Anual
            </button>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {tipo === 'despesa' && getDespesasPendentes()}
      {tipo === 'receita' && getReceitasPendentes()}
    </>
  );
};

export default BoxPendentes;
