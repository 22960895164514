import React from 'react';
import isMobile from '../Helper/Mobile';
import { Line } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';

const BoxDividas = ({ data, nomeDivida }) => {
  const mobile = isMobile();

  function retornaEvolucaoMesTotal(labels, dadosDividas) {
    var retorno = [];
    var dividas = [];
    // console.log(data.filter((items) => items.registro === '1'));
    data
      .filter((items) => items.registro === '1')
      .forEach((dado) => {
        if (dividas.indexOf(dado.descricao) === -1) {
          dividas.push(dado.descricao);
        }
      });

    // console.log(dividas);

    for (let i = 0; i < labels.length; i++) {
      var valor = 0;
      var dadosDividasMes = dadosDividas.filter(
        (dados) => dados.data_abatimento.substring(0, 7) === labels[i],
      );

      dividas.forEach((divida) => {
        dadosDividasMes.forEach((registro) => {
          valor = parseFloat(registro.valor_atual);
        });
      });

      retorno.push(valor.toFixed(2));
    }
    // console.log(labels);
    // console.log(retorno);

    return retorno;
  }

  function retornaEvolucaoMes(labels, dadosDividas) {
    var retorno = [];

    for (let i = 0; i < labels.length; i++) {
      var valor = 0;
      var dadosDividasMes = dadosDividas.filter(
        (dados) => dados.data_abatimento.substring(0, 7) === labels[i],
      );

      // console.log(dadosDividasMes);

      dadosDividasMes.forEach((registro) => {
        valor = parseFloat(registro.valor_atual);
      });

      retorno.push(valor.toFixed(2));
    }

    return retorno;
  }

  function getSaldoMensal() {
    let dadosDividas = data.filter((dados) => dados.registro === '1');

    dadosDividas = dadosDividas.sort(
      (a, b) =>
        parseFloat(a.data_abatimento.toString().replaceAll('-', '')) -
        parseFloat(b.data_abatimento.toString().replaceAll('-', '')),
    );

    var labels = [];
    var anoMes = '';
    dadosDividas.forEach((registro) => {
      anoMes = registro.data_abatimento.substring(0, 7);
      if (labels.indexOf(anoMes) === -1) {
        labels.push(anoMes);
      }
    });

    // console.log(labels);

    var dataset = [];

    dataset.push({
      label: nomeDivida ? nomeDivida : 'Evolução das Dívidas',
      data: nomeDivida
        ? retornaEvolucaoMes(labels, dadosDividas)
        : retornaEvolucaoMesTotal(labels, dadosDividas),
      borderColor: '#B22222',
      borderSkipped: false,
      fill: false,
    });

    return (
      <>
        {labels.length > 0 && (
          <div className={stylesDiv.divSombra}>
            <div className={stylesDiv.divCardName}>
              {!nomeDivida && (
                <h2 className={stylesTipografia.h2}>
                  :: evolução de todas as dívidas
                </h2>
              )}
              <span className={stylesTipografia.span}>
                {nomeDivida ? 'Evolução da Dívida -> ' + nomeDivida : null}
              </span>
              <Line
                data={{
                  labels: labels,
                  datasets: dataset,
                }}
                height={!mobile ? 100 : 200}
              ></Line>
            </div>
          </div>
        )}
      </>
    );
  }

  return <>{getSaldoMensal()}</>;
};
export default BoxDividas;
