import React from 'react';
import { Container } from 'react-bootstrap';
import AdsenseFixedMobileHeader from '../Adsense/AdsenseFixedMobileHeader';

const AdsFixedMobileHeader = (props) => {
  const [content1Visible, setContent1Visible] = React.useState(true);
  React.useEffect(() => {
    var path =
      '/22523725657/guardardinheiro.com.br/Guardardinheiro_Mobile_Header';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
      [320, 50],
      [320, 100],
    ];
    var id = 'Mobile_Header';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize(
          [0, 0],
          ['fluid', [250, 250], [300, 250], [336, 280], [320, 50], [320, 100]],
        )
        .build();

      if (props.interests && props.interests.length > 0) {
        googletag
          .defineSlot(path, size, id)
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      } else {
        googletag
          .defineSlot(path, size, id)
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      }

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContent1Visibility = () => {
      const content1Element = document.getElementById('Mobile_Header');
      if (content1Element) {
        setContent1Visible(
          window.getComputedStyle(content1Element).display !== 'none',
        );
      }
    };

    checkContent1Visibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContent1Visibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <div id="Mobile_Header" style={{ textAlign: 'center' }}></div>
      {!content1Visible && (
        <div>
          <AdsenseFixedMobileHeader />
        </div>
      )}
    </Container>
  );
};
export default AdsFixedMobileHeader;
