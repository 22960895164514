import React from 'react';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoedaCF } from '../Helper/DateUtilities';
import { useNavigate } from 'react-router-dom';
import isMobile from '../Helper/Mobile';
import { Table } from 'react-bootstrap';

const BoxFatura = ({ data, flagAnoOuMes, hide }) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  function getSaldoPorFatura() {
    let dadosFatura;
    if (flagAnoOuMes === 'mes') {
      dadosFatura = data.filter(
        (dados) =>
          dados.forma === 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dadosFatura = data.filter(
        (dados) =>
          dados.forma === 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    let total = 0;
    dadosFatura.forEach((registro) => {
      total = total + parseFloat(registro.valor);
    });

    var labels = [];
    dadosFatura.forEach((registro) => {
      if (labels.indexOf(registro.cartao) === -1) {
        labels.push(registro.cartao);
      }
    });

    var valores = [];
    for (var i = 0; i < labels.length; i++) {
      var valor = 0;
      dadosFatura.forEach((registro) => {
        if (registro.cartao === labels[i]) {
          valor = valor + parseFloat(registro.valor);
        }
      });
      valores.push(valor);
    }

    var cartoes = [];
    for (var i = 0; i < labels.length; i++) {
      cartoes.push({ cartao: labels[i], valor: valores[i] });
    }

    dadosFatura.sort((a, b) => b.nome_cartao - a.nome_cartao);
    // console.log(dadosFatura);

    return (
      <>
        <Table
          responsive
          // striped
          // bordered
          hover
          size="sm"
          className={stylesTabela.tabela}
        >
          <thead>
            <tr key={'header'}>
              <th>Cartão</th>
              <th>Fatura</th>
            </tr>
            {/* <th>Limite Disponível</th> */}
          </thead>
          <tbody>
            {cartoes.map((registro) => (
              <tr id={registro.id} key={registro.cartao}>
                <td>
                  <span className={stylesTipografia.span}>
                    {registro.cartao}
                  </span>
                </td>

                <td>
                  <span className={stylesTipografia.valorNegativo}>
                    {formataMoedaCF(registro.valor, hide)}
                  </span>
                </td>

                {/* <td>
                  {parseFloat(registro.limite) - parseFloat(registro.valor) >
                  0 ? (
                    <span className={stylesTipografia.valorPositivo}>
                      {formataMoeda(
                        parseFloat(registro.limite) -
                          parseFloat(registro.valor),
                      )}
                    </span>
                  ) : (
                    <span className={stylesTipografia.valorNegativo}>
                      {formataMoeda(
                        parseFloat(registro.limite) -
                          parseFloat(registro.valor),
                      )}
                    </span>
                  )}
                </td> */}
              </tr>
            ))}
            <tr key={'total'}>
              <td>
                <span className={stylesTipografia.valorNegativo}>
                  {'Total'}
                </span>
              </td>
              <td>
                <span className={stylesTipografia.valorNegativo}>
                  {formataMoedaCF(total, hide)}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
        <button
          className={stylesButton.button}
          onClick={() => navigate('/plano-pro/faturas')}
        >
          Ver Faturas
        </button>
      </>
    );
  }

  return <>{getSaldoPorFatura()}</>;
};

export default BoxFatura;
