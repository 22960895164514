import React from 'react';
import { Container } from 'react-bootstrap';
import Head from '../../Components/Tags/Head';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Paragrafo from '../../Components/Tags/Paragrafo';
import stylesLista from '../../Components/Tags/Lista.module.css';
import isMobile from '../../Components/Helper/Mobile';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import AdsSticky from '../../Components/AdsUtil/AdsSticky';

const DicaFinanceira = () => {
  const mobile = isMobile();
  function returnLinkDica(numero) {
    return (
      <a
        href={
          '/dica-financeira-do-dia/dia/' +
          new Date(new Date().setDate(new Date().getDate() - numero))
            .toISOString()
            .slice(0, 10)
        }
      >
        {'Dica do dia -> ' +
          new Date(new Date().setDate(new Date().getDate() - numero))
            .toISOString()
            .slice(0, 10)}
      </a>
    );
  }
  return (
    <>
      <Head
        metaDescription={'Dica Financeira do dia'}
        title={'Dica Financeira do dia'}
        h1={'Dica Financeira do dia'}
        canonical="https://www.guardardinheiro.com.br/dica-financeira-do-dia"
        nivel="2"
        id_wp_post={1700}
      />
      <BreadcumbH1
        paginaAtual={'Dica Financeira do Dia'}
        texto={'Dica Financeira do Dia'}
      />

      <Container>
        <AdsSticky ads={'content1'} pixels={130} />
        <h1 className={stylesTipografia.h1}>Dica Financeira do Dia</h1>

        <Paragrafo
          texto={
            'Descubra segredos para o sucesso financeiro com nossas dicas valiosas de finanças do dia. Aprenda a economizar, investir e gerenciar suas finanças pessoais de forma inteligente.'
          }
        />
        <div>
          <ul className={stylesLista.checkList}>
            {Array.apply(null, { length: 10 }).map((e, i) => (
              <>
                <li key={i}>{returnLinkDica(i)}</li>
                {/* {i === 1 && <AdsContent1 />} */}
              </>
            ))}
          </ul>
        </div>
        <hr />
      </Container>
    </>
  );
};

export default DicaFinanceira;
