import React from 'react';
import { formataMoedaCF, formataPorcentagem } from '../Helper/DateUtilities';
import ProgressBar from 'react-bootstrap/ProgressBar';
import isMobile from '../Helper/Mobile';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import { Alert } from 'react-bootstrap';

const BoxBalancoMedio = ({ data, hide }) => {
  const mobile = isMobile();

  function getSaldoPorBalanco() {
    let dadosBalanco;

    dadosBalanco = data.filter(
      (dados) =>
        // dados.tipo_registro === '1' &&
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 6) ===
          window.sessionStorage.getItem('gd_cf_ano'),
    );

    let valorDespesa = 0;
    let arrayDespesa = [];

    let valorReceita = 0;
    let arrayReceita = [];

    let valorInvestimento = 0;
    let arrayInvestimento = [];

    let valorEssencial = 0;
    let valorNaoEssencial = 0;

    var dataHoje = new Date().toISOString().slice(0, 10);
    dataHoje = parseFloat(
      dataHoje.substring(0, dataHoje.length - 2).replaceAll('-', ''),
    );

    dadosBalanco.forEach((registro) => {
      if (registro.tipo === 'Despesa') {
        if (
          parseFloat(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          ) <= dataHoje
        ) {
          valorDespesa = valorDespesa + parseFloat(registro.valor);
          if (registro.essencial === 'S') {
            valorEssencial = valorEssencial + parseFloat(registro.valor);
          } else {
            valorNaoEssencial = valorNaoEssencial + parseFloat(registro.valor);
          }

          arrayDespesa.push(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          );
        }
      } else if (registro.tipo === 'Receita') {
        if (
          parseFloat(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          ) <= dataHoje
        ) {
          valorReceita = valorReceita + parseFloat(registro.valor);

          arrayReceita.push(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          );
        }
      } else if (registro.tipo === 'Investimentos') {
        if (
          parseFloat(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          ) <= dataHoje
        ) {
          valorInvestimento = valorInvestimento + parseFloat(registro.valor);

          arrayInvestimento.push(
            registro.data_vencimento
              .substring(0, registro.data_vencimento.length - 2)
              .replaceAll('-', ''),
          );
        }
      }
    });
    arrayDespesa = [...new Set(arrayDespesa)];
    arrayReceita = [...new Set(arrayReceita)];
    arrayInvestimento = [...new Set(arrayInvestimento)];

    let mediaDespesa = 0;
    let mediaReceita = 0;
    let mediaInvestimento = 0;
    let reservaEmergencia = 0;
    let mediaEssencial = 0;
    let mediaNaoEssencial = 0;

    if (arrayDespesa.length > 0) {
      mediaDespesa = valorDespesa / arrayDespesa.length;
      mediaEssencial = valorEssencial / arrayDespesa.length;
      mediaNaoEssencial = valorNaoEssencial / arrayDespesa.length;
      reservaEmergencia = mediaDespesa * 6;
    }
    if (arrayReceita.length > 0) {
      mediaReceita = valorReceita / arrayReceita.length;
    }
    if (arrayInvestimento.length > 0) {
      mediaInvestimento = valorInvestimento / arrayInvestimento.length;
    }

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>:: média de saldos</h2>

          <br />
          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Média de Despesas: <b>{formataMoedaCF(mediaDespesa, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Média de Despesas Essenciais:{' '}
              <b>{formataMoedaCF(mediaEssencial, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#B22222',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Média de Despesas Não Essenciais:{' '}
              <b>{formataMoedaCF(mediaNaoEssencial, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#28a745',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Média de Receitas: <b>{formataMoedaCF(mediaReceita, hide)}</b>
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              // width: '30%',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '30px',
                marginRight: '5px',
                backgroundColor: '#008B8B',
              }}
            ></div>

            <p className={stylesTipografia.pAlertas}>
              Média de Investimentos:
              <b>{formataMoedaCF(mediaInvestimento, hide)}</b>
            </p>
          </div>

          <br />
          {!mobile && <br />}
          {/* {!mobile && <br />} */}
          <Alert
            variant="info"
            style={{
              textAlign: 'left',
              marginTop: '5px',
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            <p
              className={stylesTipografia.p}
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: '12px',
              }}
            >
              A Reserva de Emergência deve ser 6 vezes a sua média de despesas.
              De acordo com sua média de despesas atual, sua{' '}
              {'Reserva de Emergência deve ser de ' +
                formataMoedaCF(reservaEmergencia, hide) +
                '.'}
            </p>
          </Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        {getSaldoPorBalanco()}
      </div>
    </>
  );
};

export default BoxBalancoMedio;
