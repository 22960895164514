import React from 'react';
import BreadcumbH1 from '../Components/Tags/BreadcumbH1';
import H2 from '../Components/Tags/H2';
import OndeInvestir from './../Pages/OndeInvestir/OndeInvestir';
import Head from '../Components/Tags/Head';
import AdsContent1 from '../Components/AdsJoinAds/AdsContent1';
import stylesTipografia from './_css/Tipografia.module.css';

const NotFoundPage = () => {
  return (
    <>
      <Head
        title={'Página Não Encontrada'}
        metaDescription={'Página Não Encontrada'}
        canonical={'https://www.guardardinheiro.com.br/not-found'}
        h1={'Página Não Encontrada'}
        nivel="2"
        id_wp_post={1111}
      />
      <BreadcumbH1
        texto="Página Não Encontrada"
        paginaAtual="Página Não Encontrada"
      />

      <AdsContent1 />
      <h1 className={stylesTipografia.h1}>Página Não Encontrada</h1>

      <H2 texto="Acesse as páginas abaixo e confira onde seu dinheiro vai render mais em 2025." />

      <OndeInvestir />
    </>
  );
};

export default NotFoundPage;
