import React from 'react';
import { DualRing } from 'react-awesome-spinners';
import styles from './Loading.module.css';

const Loading = (props) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <div className={styles.div_center}>
      {loading && <DualRing size={64} color={'#1A7058'} sizeUnit={'px'} />}{' '}
      {props.texto ? (
        <h2 style={{ color: '#3F3F44' }}>{props.texto}</h2>
      ) : (
        <h2 style={{ color: '#3F3F44' }}>Carregando...</h2>
      )}
    </div>
  );
};

export default Loading;
