import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_META } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import LookNumbers from '../../Components/Helper/LookNumbers';
import { NumberContext } from '../../Contexts/NumberContext';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import MetasList from '../../Components/ControleFinanceiro/MetasList';
import ModalMeta from '../../Components/ControleFinanceiro/ModalMeta';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Metas = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);
  const { hide } = React.useContext(NumberContext);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_META(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  function retornaListaMetas() {
    if (data) {
      return <MetasList data={data} hide={hide} />;
    } else {
      return <Loading />;
    }
  }

  return (
    <>
      <Head
        metaDescription="Projetos e Metas | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Projetos e Metas | Guardar Dinheiro PRO"
        h1="Projetos e Metas | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />
      <BreadcumbH1
        paginaAtual=":: MEUS PROJETOS E METAS"
        texto=":: MEUS PROJETOS E METAS"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MEUS PROJETOS E METAS</h1>
        <LookNumbers />

        <br />
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {retornaListaMetas()}

        <hr />

        {show && (
          <ModalMeta
            onClose={() => setShow(false)}
            show={show}
            acao={'INSERT'}
          />
        )}
      </Container>
    </>
  );
};

export default Metas;
