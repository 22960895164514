import React from 'react';
// import styles from './Select.module.css';
import styles from '../../Pages/ControleFinanceiro/css/Select.module.css';
import styleInput from '../StylesGlobal/Input.module.css';
import 'react-phone-number-input/style.css';
import Label from './Label';
import jsonPagamentos from './formaPagamento.json';
import jsonSituacao from './situacaoPagamento.json';
import jsonBandeirasCartao from './bandeirasCartao.json';
import jsonBancos from './bancos.json';
import useFetch from '../../Hooks/useFetch';

import {
  AWS_GET_USER_CATEGORIAS,
  AWS_GET_USER_PAGAMENTOS,
  AWS_GET_USER_META,
} from '../Integracoes/Api';

const Select = ({
  tipo,
  label,
  name,
  is_disabled,
  error,
  valor,
  tipoLancamento,
  acao,
}) => {
  const { data, loading, erro, request } = useFetch();
  const [item, setItem] = React.useState();

  React.useEffect(() => {
    if (name === 'select-categoria') {
      // console.log('entrou');
      const { url, options } = AWS_GET_USER_CATEGORIAS(
        window.localStorage.getItem('gd_uid'),
      );
      // console.log(window.localStorage.getItem('gd_uid'));
      request(url, options);
      if (valor) {
        setItem(valor);
      }
    }

    if (
      name === 'select-conta' ||
      name === 'select-conta-origem' ||
      name === 'select-conta-destino'
    ) {
      // console.log('entrou');
      const { url, options } = AWS_GET_USER_PAGAMENTOS(
        window.localStorage.getItem('gd_uid'),
      );
      // console.log(window.localStorage.getItem('gd_uid'));
      request(url, options);
      if (valor) {
        // console.log(valor);
        setItem(valor);
      }
    }

    if (name === 'select-meta') {
      // console.log('entrou');
      const { url, options } = AWS_GET_USER_META(
        window.localStorage.getItem('gd_uid'),
      );
      // console.log(window.localStorage.getItem('gd_uid'));
      request(url, options);
      if (valor) {
        // console.log(valor);
        setItem(valor);
      }
    }

    if (name === 'select-pagamento') {
      if (valor) {
        let element = document.getElementById(name);
        element.value = valor;
      }
    }

    if (name === 'select-situacao') {
      if (valor) {
        // console.log(valor);
        let element = document.getElementById(name);
        // console.log(element);
        element.value = valor;
      }
    }

    if (name === 'select-bandeira') {
      if (valor) {
        // console.log(valor);
        let element = document.getElementById(name);
        // console.log(element);
        element.value = valor;
      }
    }
    if (name === 'select-banco') {
      if (valor) {
        // console.log(valor);
        let element = document.getElementById(name);
        // console.log(element);
        element.value = valor;
      }
    }
  }, []);

  function retornaSelect() {
    // console.log(tipoLancamento);
    if (data && tipo === 'categoria') {
      // console.log(is_disabled);
      if (tipoLancamento === 'todos') {
        return retornaSelectCategorias();
      } else {
        return retornaSelectCategoriaEspecifica();
      }
    } else if (tipo === 'pagamento') {
      return retornaSelectFormaPagamento();
    } else if (tipo === 'situacao') {
      return retornaSelectSituacao();
    } else if (data && tipo === 'conta') {
      return retornaSelectContas();
    } else if (data && tipo === 'conta-transf') {
      return retornaSelectContasTransf();
    } else if (tipo === 'bandeira') {
      return retornaSelectBandeiras();
    } else if (tipo === 'banco') {
      return retornaSelectBanco();
    } else if (data && tipo === 'meta') {
      return retornaSelectMetas();
    }
  }

  function retornaSelectFormaPagamento() {
    return (
      <select
        className={styles.select}
        id={name}
        onChange={(e) => setItem(e.target.value)}
        value={item}
      >
        <option key="Selecione" value="Selecione">
          Selecione
        </option>
        {jsonPagamentos.map((item) => (
          <option key={item.id} value={item.id}>
            {item.forma_pagamento}
          </option>
        ))}
      </select>
    );
  }

  function retornaSelectSituacao() {
    return (
      <select
        className={styles.select}
        id={name}
        onChange={(e) => setItem(e.target.value)}
        value={item}
      >
        <option key="Selecione" value="Selecione">
          Selecione
        </option>
        {jsonSituacao.map((item) => (
          <option key={item.id} value={item.id}>
            {item.situacao}
          </option>
        ))}
      </select>
    );
  }

  function retornaSelectCategoriaEspecifica() {
    return (
      <select
        className={styles.select}
        id={name}
        onChange={(e) => setItem(e.target.value)}
        value={item}
        disabled={is_disabled}
      >
        <option key="Selecione" value="Selecione">
          Selecione
        </option>
        {tipoLancamento === 'Investimentos' &&
          data
            .filter((registro) => registro.fg_tipo_categoria === 'I')
            .map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome_categoria}
              </option>
            ))}

        {tipoLancamento === 'Despesa' &&
          data
            .filter(
              (registro) =>
                registro.fg_tipo_categoria === 'D' &&
                registro.nome_categoria !== 'Geral' &&
                registro.nome_categoria !== 'Gastos Essenciais' &&
                registro.nome_categoria !== 'Gastos Não Essenciais' &&
                registro.nome_categoria !== 'Guardar Dinheiro - Seu Futuro',
            )
            .map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome_categoria}
              </option>
            ))}

        {tipoLancamento === 'Receita' &&
          data
            .filter((registro) => registro.fg_tipo_categoria === 'R')
            .map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome_categoria}
              </option>
            ))}
      </select>
    );
  }

  function retornaSelectMetas() {
    return (
      <select
        className={styles.select}
        id={name}
        onChange={(e) => setItem(e.target.value)}
        value={item}
        disabled={is_disabled}
      >
        <option key="Selecione" value="Selecione">
          Selecione
        </option>

        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.descricao}
          </option>
        ))}
      </select>
    );
  }

  function retornaSelectCategorias() {
    return (
      <select
        className={styles.select}
        id={name}
        onChange={(e) => setItem(e.target.value)}
        value={item}
        disabled={is_disabled}
      >
        <option key="Selecione" value="Selecione">
          Selecione
        </option>
        <option key="Selecione" value="Selecione" disabled={true}>
          Investimentos
        </option>
        {data
          .filter((registro) => registro.fg_tipo_categoria === 'I')
          .map((item) => (
            <option key={item.id} value={item.id}>
              {item.nome_categoria}
            </option>
          ))}

        <option key="Selecione" value="Selecione" disabled={true}>
          Despesa
        </option>
        {data
          .filter(
            (registro) =>
              registro.fg_tipo_categoria === 'D' &&
              registro.nome_categoria !== 'Geral' &&
              registro.nome_categoria !== 'Gastos Essenciais' &&
              registro.nome_categoria !== 'Gastos Não Essenciais' &&
              registro.nome_categoria !== 'Guardar Dinheiro - Seu Futuro',
          )
          .map((item) => (
            <option key={item.id} value={item.id}>
              {item.nome_categoria}
            </option>
          ))}

        <option key="Selecione" value="Selecione" disabled={true}>
          Receita
        </option>
        {data
          .filter((registro) => registro.fg_tipo_categoria === 'R')
          .map((item) => (
            <option key={item.id} value={item.id}>
              {item.nome_categoria}
            </option>
          ))}
      </select>
    );
  }

  function removePagamento() {
    const selectConta = document.getElementById('select-conta');
    let tipoPagamento =
      selectConta.value === 'Selecione' ? '' : selectConta.value;
    let conta = '0';
    let cartao = '0';

    if (tipoPagamento.split('|')[0] === 'pgto-1') {
      cartao = tipoPagamento.split('|')[1];
    } else {
      conta = tipoPagamento.split('|')[1];
    }

    const divPagamento = document.getElementById('div-switch-pago');
    if (divPagamento) {
      if (conta === '0') {
        divPagamento.style.visibility = 'hidden';
      } else {
        divPagamento.style.visibility = 'visible';
      }
    }
  }

  function retornaSelectContasTransf() {
    if (loading) {
      return (
        <select
          className={styles.select}
          id={name}
          onChange={(e) => setItem(e.target.value)}
          value={item}
        >
          <option key="Carregando" value="Carregando">
            Selecione
          </option>
        </select>
      );
    } else {
      return (
        <select
          className={styles.select}
          id={name}
          onChange={(e) => {
            setItem(e.target.value);
          }}
          value={item}
        >
          <option key="Selecione" value="Selecione">
            Selecione
          </option>

          {data
            .filter((items) => items.tipo_pagamento === '0')
            .map((item) => (
              <option
                key={item.id}
                value={item.id}
                style={{
                  textAlign: 'center',
                  margin: '10px',
                }}
              >
                {item.nome_conta}
              </option>
            ))}
        </select>
      );
    }
  }

  function retornaSelectContas() {
    if (loading) {
      return (
        <select
          className={styles.select}
          id={name}
          onChange={(e) => setItem(e.target.value)}
          value={item}
        >
          <option key="Carregando" value="Carregando">
            Selecione
          </option>
        </select>
      );
    } else {
      return (
        <select
          className={styles.select}
          id={name}
          onChange={(e) => {
            setItem(e.target.value);
            removePagamento();
          }}
          value={item}
        >
          <option key="Selecione" value="Selecione">
            Selecione
          </option>
          {acao !== 'PAGAR' && (
            <option key="co" value="co" disabled style={{ fontWeight: 'bold' }}>
              {'Conta'}
            </option>
          )}
          {data
            .filter(
              (items) =>
                items.tipo_pagamento === '0' && items.fg_status !== 'A',
            )
            .map((item) => (
              <option
                key={'pgto-' + item.tipo_pagamento + '|' + item.id}
                value={'pgto-' + item.tipo_pagamento + '|' + item.id}
                style={{
                  textAlign: 'center',
                  margin: '10px',
                }}
              >
                {item.nome_conta}
              </option>
            ))}

          {acao !== 'PAGAR' && (
            <option
              id={'cc'}
              key="cc"
              value="cc"
              disabled
              style={{ fontWeight: 'bold' }}
            >
              {'Cartão de Crédito'}
            </option>
          )}
          {acao !== 'PAGAR' &&
            data
              .filter(
                (items) =>
                  items.tipo_pagamento === '1' && items.fg_status !== 'A',
              )
              .map((item) => (
                <option
                  key={'pgto-' + item.tipo_pagamento + '|' + item.id}
                  value={'pgto-' + item.tipo_pagamento + '|' + item.id}
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                  }}
                >
                  {item.nome_cartao}
                </option>
              ))}
        </select>
      );
    }
  }

  function retornaSelectBandeiras() {
    return (
      <>
        <input
          type="search"
          id="select-bandeira"
          list="bandeiras"
          value={item}
          className={styleInput.inputDataList}
        ></input>

        <datalist id="bandeiras">
          {jsonBandeirasCartao.map((item) => (
            <option key={item.bandeira} value={item.bandeira}></option>
          ))}
        </datalist>
      </>
    );
  }

  function retornaSelectBanco() {
    return (
      <>
        <input
          type="search"
          id="select-banco"
          list="bancos"
          value={item}
          className={styleInput.inputDataList}
          // class="col-sm-6 custom-select custom-select-sm"
        ></input>

        <datalist id="bancos">
          {jsonBancos.map((item) => (
            <option key={item.nome_banco} value={item.nome_banco}></option>
          ))}
        </datalist>
      </>
    );
  }

  if (loading) {
    return (
      <div>
        <Label name={name} label={label} />
        <select className={styles.select}>
          <option key="Carregando" value="Carregando">
            Carregando...
          </option>
        </select>
      </div>
    );
  } else {
    return (
      <div>
        <Label name={name} label={label} />
        {retornaSelect()}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  }
};

export default Select;
