import React from 'react';

export const NumberContext = React.createContext();

export const NumberStorage = ({ children }) => {
  const [hide, setHide] = React.useState(false);

  function showNumber() {
    setHide((hide) => !hide);
  }

  return (
    <NumberContext.Provider value={{ hide, showNumber }}>
      {children}
    </NumberContext.Provider>
  );
};
