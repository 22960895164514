import React from 'react';
// import { Helmet } from 'react-helmet';
import styles from './PopUpMaquinasVendidas.module.css';
import Toast from 'react-bootstrap/Toast';
import fgMinizinhaNFC2 from '../../Assets/maquina/lp/band/minizinha-nfc2.webp';
import fgMinizinhaChip3 from '../../Assets/maquina/lp/band/minizinha-chip3.webp';
import fgModerninhaPro2 from '../../Assets/maquina/lp/band/moderninha-pro2.webp';
import fgModerninhaPlus2 from '../../Assets/maquina/lp/band/moderninha-plus-2.webp';
import fgModerninhaSmart2 from '../../Assets/maquina/lp/band/moderninha-smart2.webp';
import { Container, Image } from 'react-bootstrap';

const PopUpMaquinasVendidas = () => {
  const [show, setShow] = React.useState(false);
  const [seconds, setSeconds] = React.useState(0);
  const [numeroRandomicoMaquina, setNumeroRandomicoMaquina] = React.useState(
    Math.floor(Math.random() * 5),
  );
  // const [city, setCity] = React.useState(window.localStorage.getItem('gd_geo'));

  // const toggleShow = () => setShow(!show);

  // React.useEffect(() => {
  //   setTimeout(10000);

  //   const items = [10000];
  //   setNumeroRandomicoMaquina(Math.floor(Math.random() * 4));

  //   // const items = [3000];
  //   const interval = setInterval(() => {
  //     setSeconds((seconds) => seconds + 1);
  //   }, items[Math.floor(Math.random() * items.length)]);
  //   // getNumeroCompradores(numeroRandomicoMaquina);
  //   return () => {
  //     clearInterval(interval);

  //     toggleShow();
  //   };
  // });

  // const toggleShow = () => setShow(!show);

  function getImagemMaquinaRandomica(numero) {
    // console.log(numero);
    if (numero === 0) {
      return fgMinizinhaNFC2;
    } else if (numero === 1) {
      return fgMinizinhaChip3;
    } else if (numero === 2) {
      return fgModerninhaPro2;
    } else if (numero === 3) {
      return fgModerninhaPlus2;
    } else if (numero === 4) {
      return fgModerninhaSmart2;
    }
  }

  function getNomeMaquinaRandomica(numero) {
    if (numero === 0) {
      return 'Minizinha NFC 2';
    } else if (numero === 1) {
      return 'Minizinha Chip 3';
    } else if (numero === 2) {
      return 'Moderninha Pro 2';
    } else if (numero === 3) {
      return 'Moderninha Plus 2';
    } else if (numero === 4) {
      return 'Moderninha Smart 2';
    }
  }

  function retornaCompradoresCidade() {
    let mensagem = '';
    let compradores = [
      '7',
      '9',
      '11',
      '12',
      '13',
      '17',
      '24',
      '27',
      '29',
      '33',
      '41',
      // '45',
      // '50',
      '52',
      // '61',
      '63',
      // '71',
    ];
    // 17
    let nomes = [
      'Douglas',
      'Gracy',
      'Luan',
      'Rosângela',
      'Celso',
      'Ferreira',
      'Mariana',
      'Silvana',
      'Talita',
      'Rafael',
      'Rosa',
      'Clara',
      'Carlos',
      'Carol',
      'Mateus',
      'Nunes',
      'Léia',
      'Isaura',
      'Josias',
      'Nelson',
      'Jonas',
      'Maria',
      'José',
      'Bianca',
      'Evelyn',
      'Taís',
      'Ana Júlia',
      'Mércia',
    ];
    // 28

    let tipo = ['1', '2', '3'];

    let tipoEscolhido = tipo[Math.floor(Math.random() * 2)];

    if (tipoEscolhido === '1') {
      return (
        <p>
          <strong>
            {compradores[
              Math.floor(Math.random() * compradores.length)
            ].toString() + ' pessoas compraram'}
          </strong>
          <i>{' nos últimos 15 minutos.'}</i>
        </p>
      );
    } else {
      return (
        <p>
          <strong>
            {nomes[Math.floor(Math.random() * nomes.length)].toString() +
              ' acabou de comprar. '}
          </strong>{' '}
          <i>Restam poucas unidades promocionais!</i>
        </p>
      );
    }

    return mensagem;
  }

  function returnPopup() {
    return (
      <div>
        {/* <Toast show={show} onClose={toggleShow} className={styles.popup}> */}
        <Toast className={styles.popup}>
          <Toast.Header>
            <Image
              src={getImagemMaquinaRandomica(numeroRandomicoMaquina)}
              alt={getNomeMaquinaRandomica(numeroRandomicoMaquina)}
              fluid
              className={styles.img}
            />

            <div style={{ display: 'block' }}>
              <div>
                <strong className="me-auto">
                  {getNomeMaquinaRandomica(numeroRandomicoMaquina)}
                </strong>
              </div>
              <div>
                <p>{retornaCompradoresCidade()}</p>
              </div>
              {/* <p>{seconds}</p> */}
            </div>
          </Toast.Header>
          {/* <Toast.Body></Toast.Body> */}
        </Toast>
      </div>
    );
  }

  return <div className="App">{returnPopup()}</div>;
};

export default PopUpMaquinasVendidas;
