import React from 'react';

const AdsenseResponsivo0 = (props) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: '20px',
          // marginBottom: '20px',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',

            // border: '1px solid #00bfff',
          }}
          data-ad-client="ca-pub-4615548473778420"
          data-ad-slot="1709666591"
          data-ad-format="auto"
          data-full-width-responsive="true"
          data-ad-keywords="investimentos, finanças pessoais"
        ></ins>
      </div>
    </>
  );
};
export default AdsenseResponsivo0;
