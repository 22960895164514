import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import useForm from '../../Hooks/useForm';
import stylesLogin from './LoginForm.module.css';
import stylesButton from '../../Pages/_css/Button.module.css';
// import fgLogo from '../../Assets/logo-site-guardar-dinheiro.png';
import fgLogo from '../../Assets/logo1.png';
import Error from '../../Components/Helper/Error';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';

const LoginForm = () => {
  const [loading, setLoading] = React.useState(false);
  const email = useForm();
  const senha = useForm();
  const { userLogin, error, login } = React.useContext(UserContext);
  const [erroNegocio, setErroNegocio] = React.useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (email.value === '' || senha.value === '') {
      setErroNegocio('Favor preencher email e senha para acessar');
    } else {
      if (email.validate() && senha.validate()) {
        setErroNegocio(null);

        userLogin(email.value, senha.value);
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>Login - Guardar Dinheiro</title>
      </Helmet>
      <div>
        <br />
        <br />
        <br />

        <div className={stylesLogin.containerCenter}>
          <div className={stylesLogin.login}>
            <form className={stylesLogin.form} onSubmit={handleSubmit}>
              <h1>Acesse sua conta</h1>
              <input
                className={stylesLogin.loginInput}
                type="text"
                placeholder="email@email.com"
                name="email"
                {...email}
              />
              <br />
              <input
                className={stylesLogin.loginInput}
                type="password"
                placeholder="*******"
                name="senha"
                {...senha}
              />
              <br />
              {loading ? (
                <button className={stylesButton.button} disabled>
                  Acessando...
                </button>
              ) : (
                <button className={stylesButton.button}>Fazer Login</button>
              )}
              <br />
              {login === true && (
                <Alert variant="success">
                  Login efetuado com sucesso. Redirecionando...
                </Alert>
              )}
              <Error error={erroNegocio} />
              <Error error={error} />
            </form>
            <br />
            <Link to="/autenticacao/criar" style={{ color: 'black' }}>
              Crie uma conta
            </Link>

            <Link to="/autenticacao/perdeu" style={{ color: 'black' }}>
              Esqueceu a Senha? Clique aqui
            </Link>
          </div>
          <hr /> <br />
        </div>
      </div>
    </>
  );
};

export default LoginForm;
