import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './Wallet.module.css';
import Input from '../Forms/Input';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';
import InputPortfolio2 from '../Forms/InputPortfolio2';
import stylesButton from '../../Pages/_css/Button.module.css';

function ModalCadastroAtivo(props) {
  const inputElement = React.useRef();
  const [precoMedio, setPrecoMedio] = React.useState(null);
  const [qtde, setQtde] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    // console.log(props);
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(http_method, tipo, chave, qtde, preco_medio, user_id) {
    const urlPostPortfolio = `https://oe8p7mxbw5.execute-api.sa-east-1.amazonaws.com/producao/user-wallet?user_id=${user_id}&tipo=${tipo}&chave=${chave}&qtde=${qtde}&preco_medio=${preco_medio}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);
          if (http_method === 'POST') {
            window.location.reload(false);
          }
          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let cadastroCompleto = true;
    setErro(null);
    const inputPortfolio = document.getElementById('inputPortfolioCadastro');

    if (inputPortfolio.value === '') {
      setErro('Selecione um ativo para cadastro');
      cadastroCompleto = false;
    }

    if (!qtde) {
      setErro('Informe a quantidade para continuar o cadastro');
      cadastroCompleto = false;
    }

    if (!precoMedio) {
      setErro('Informe o preço médio para continuar o cadastro');
      cadastroCompleto = false;
    }

    if (cadastroCompleto) {
      const ticker = inputPortfolio.value.split('  -  ')[2];
      let tipoAtivo = props.tipo.toUpperCase();
      if (props.tipo === 'acoes') {
        tipoAtivo = 'ACAO';
      }

      postItem(
        'POST',
        tipoAtivo,
        ticker,
        qtde,
        precoMedio
          .replace('.', '')
          .replace(',', '.')
          .replace('R$', '')
          .replace(' ', ''),
        window.localStorage.getItem('gd_uid'),
      );
    }
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Modal show={props.show} onHide={props.onClose} style={{ top: '20%' }}>
      <Modal.Header>
        {/* <Modal.Title> */}
        {/* {'Pesquise pelo ativo para adicionar na sua carteira'} */}
        {/* </Modal.Title> */}
      </Modal.Header>

      <Modal.Body>
        <div className={styles.containerModal}>
          <div className={styles.itemContainer}>
            <div className={styles.itemContainer}>
              <InputPortfolio2
                funcionalidade={props.tipo}
                id="inputPortfolioCadastro"
              />
            </div>
          </div>
          <br />
          <div className={styles.itemContainer}>
            <InputMask
              name={'input-preco-medio'}
              label="Preço Médio"
              placeholder="R$"
              value={precoMedio}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) =>
                setPrecoMedio(target.value.replace('R$', ''))
              }
            ></InputMask>
            <a
              href="/calculadoras/preco-medio"
              style={
                {
                  // textDecoration: 'none',
                  // fontWeight: 'bold',
                  // fontSize: '12px',
                }
              }
            >
              Como calcular o preço médio?
            </a>
          </div>
          <div className={styles.itemContainer}>
            <Input
              name={'input-qtde'}
              label={'Quantidade'}
              type="number"
              placeholder="0"
              value={qtde}
              ref={inputElement}
              onChange={({ target }) => setQtde(target.value)}
            ></Input>
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button className={stylesButton.button} onClick={props.onClose}>
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button className={stylesButton.button} onClick={handleSubmit}>
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCadastroAtivo;
