import React from 'react';
import isMobile from '../Helper/Mobile';
import { Line } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { LinearScale, CategoryScale, Chart } from 'chart.js';
// Chart.register(CategoryScale, LinearScale);

const BoxEvolucaoPatrimonio = ({ data, nomePatrimonio }) => {
  const mobile = isMobile();

  function retornaEvolucaoMes(labels, dadosPatrimonio) {
    var retorno = [];
    var teste = [];
    dadosPatrimonio = dadosPatrimonio.sort(
      (a, b) =>
        parseFloat(b.atualizacao.toString().replaceAll('-', '')) -
        parseFloat(a.atualizacao.toString().replaceAll('-', '')),
    );
    // console.log(dadosPatrimonio);

    dadosPatrimonio.forEach((registro) => {
      var isInserido =
        registro.descricao + '|' + registro.atualizacao.substring(0, 7);

      if (!teste.toString().includes(isInserido)) {
        teste.push(
          registro.descricao +
            '|' +
            registro.atualizacao.substring(0, 7) +
            '|' +
            registro.valor_atual,
        );
      }
    });

    //cria json com somente os ultimas atualzicoes
    let jsonString = '[';
    for (let i = 0; i < teste.length; i++) {
      jsonString +=
        '{' +
        '"descricao" : "' +
        teste[i].split('|')[0] +
        '",' +
        '"atualizacao" : "' +
        teste[i].split('|')[1] +
        '",' +
        '"valor_atual" : "' +
        teste[i].split('|')[2] +
        '"},';
    }

    jsonString = jsonString.substring(0, jsonString.length - 1);
    jsonString = jsonString + ']';

    if (jsonString.length > 10) {
      var jsonFinal = JSON.parse(jsonString);

      jsonFinal = jsonFinal.sort(
        (a, b) =>
          parseFloat(a.atualizacao.toString().replaceAll('-', '')) -
          parseFloat(b.atualizacao.toString().replaceAll('-', '')),
      );

      var as = jsonFinal
        .filter(
          (dados) =>
            dados.descricao === 'Clear Investimentos' &&
            parseFloat(dados.atualizacao.replace('-', '')) <
              parseFloat('2023-02'.toString().replace('-', '')),
          // 202302,
        )
        .sort(
          (a, b) =>
            parseFloat(b.atualizacao.toString().replaceAll('-', '')) -
            parseFloat(a.atualizacao.toString().replaceAll('-', '')),
        );
      // console.log(as);

      //cria json somente com a descricoes existentes
      var labelsPatrimonio = [];
      jsonFinal.forEach((registro) => {
        if (labelsPatrimonio.indexOf(registro.descricao) === -1) {
          labelsPatrimonio.push(registro.descricao);
        }
      });

      //cria json somente com a descricoes existentes, colocando o anomes para todos, mesmo que nao tenha registro
      var labelsPatrimonio2 = [];
      for (let i = 0; i < labels.length; i++) {
        for (let x = 0; x < labelsPatrimonio.length; x++) {
          labelsPatrimonio2.push({
            descricao: labelsPatrimonio[x],
            atualizacao: labels[i],
          });
        }
      }

      //cria json final com todos os patrimonios e com todos os anos existentes
      var jsonFinal3 = [];
      labelsPatrimonio2.forEach((item) => {
        var obj = jsonFinal.filter(
          (dados) =>
            dados.atualizacao === item.atualizacao &&
            dados.descricao === item.descricao,
        );
        if (obj.length === 0) {
          var obj2 = jsonFinal
            .filter(
              (dados) =>
                parseFloat(dados.atualizacao.replace('-', '')) <
                  parseFloat(item.atualizacao.replace('-', '')) &&
                dados.descricao === item.descricao,
            )
            .sort(
              (a, b) =>
                parseFloat(b.atualizacao.toString().replaceAll('-', '')) -
                parseFloat(a.atualizacao.toString().replaceAll('-', '')),
            );
          // console.log(item.descricao, item.atualizacao, obj2);

          if (obj2.length === 0) {
            jsonFinal3.push({
              descricao: item.descricao,
              atualizacao: item.atualizacao,
              valor_atual: '0',
            });
          } else {
            jsonFinal3.push({
              descricao: item.descricao,
              atualizacao: item.atualizacao,
              valor_atual: obj2[0].valor_atual,
            });
          }
        } else {
          jsonFinal3.push({
            descricao: item.descricao,
            atualizacao: item.atualizacao,
            valor_atual: obj[0].valor_atual,
          });
        }
      });

      // console.log(jsonFinal3);
      // console.log(labelsPatrimonio2);
      //cria dataset do grafico
      for (let i = 0; i < labels.length; i++) {
        var valor = 0;
        var jsonMes = jsonFinal3.filter(
          (dados) => dados.atualizacao === labels[i],
        );

        jsonMes.forEach((registro) => {
          // console.log(parseFloat(registro.valor_atual), registro.atualizacao);
          valor = valor + parseFloat(registro.valor_atual);
        });

        // if (retorno.length >= 1) {
        //   valor = valor - retorno[retorno.length - 1];
        // }
        retorno.push(valor.toFixed(2));
      }
      // console.log(retorno);
      return retorno;
    } else {
      return null;
    }
  }

  function getSaldoMensal() {
    let dadosPatrimonio = data.filter((dados) => dados.registro === '1');

    dadosPatrimonio = dadosPatrimonio.sort(
      (a, b) =>
        parseFloat(a.atualizacao.toString().replaceAll('-', '')) -
        parseFloat(b.atualizacao.toString().replaceAll('-', '')),
    );

    var labels = [];
    var anoMes = '';
    dadosPatrimonio.forEach((registro) => {
      anoMes = registro.atualizacao.substring(0, 7);
      if (labels.indexOf(anoMes) === -1) {
        labels.push(anoMes);
      }
    });

    // console.log(labels);

    var dataset = [];

    var datasetJson = retornaEvolucaoMes(labels, dadosPatrimonio);

    if (datasetJson) {
      dataset.push({
        label: nomePatrimonio ? nomePatrimonio : 'Evolução do Patrimônio',
        data: datasetJson,
        borderColor: '#28a745',
        borderSkipped: false,
        fill: false,
      });

      return (
        <>
          {labels.length > 0 && (
            <div className={stylesDiv.divSombra}>
              <div className={stylesDiv.divCardName}>
                {!nomePatrimonio && (
                  <h2 className={stylesTipografia.h2}>
                    :: evolução de todos os patrimônios
                  </h2>
                )}
                <span className={stylesTipografia.span}>
                  {nomePatrimonio
                    ? 'Evolução do Patrimônio -> ' + nomePatrimonio
                    : null}
                </span>
                <Line
                  data={{
                    labels: labels,
                    datasets: dataset,
                  }}
                  height={!mobile ? 100 : 200}
                ></Line>
              </div>
            </div>
          )}
        </>
      );
    } else return null;
  }

  return <>{getSaldoMensal()}</>;
};
export default BoxEvolucaoPatrimonio;
