import React from 'react';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import { formataData, formataMoedaCF } from '../Helper/DateUtilities';
import ModalCadastroCategoria from './ModalCadastroCategoria';
import ModalDividas from './ModalDividas';
import BoxDividas from './BoxDividas';

const DividasAbatimentoList = ({ data, hide }) => {
  const [show, setShow] = React.useState(false);
  const [idDivida, setIdDivida] = React.useState(null);
  const [idAbatimento, setIdAbatimento] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [dataInicio, setDataInicio] = React.useState(null);
  const [dataAbatimento, setDataAbatimento] = React.useState(null);
  const [valorInicial, setValorInicial] = React.useState(null);
  const [valorAtual, setValorAtual] = React.useState(null);
  const [acao, setAcao] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'Atualização', accessor: 'data_abatimento' },
      { Header: 'Valor Atual', accessor: 'valor_atual' },
      { Header: 'id', accessor: 'id' },
      { Header: 'id_divida', accessor: 'id_divida' },
      { Header: 'descricao', accessor: 'descricao' },
      { Header: 'valor_inicial', accessor: 'valor_inicial' },
      { Header: 'data_inicial', accessor: 'data_inicial' },
      { Header: 'registro', accessor: 'registro' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: [
      'id',
      'id_divida',
      'descricao',
      'valor_inicial',
      'data_inicial',
      'registro',
    ],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    // console.log(data);
    event.preventDefault();
    setShow(true);
  };

  function editMovimento(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_divida') {
        setIdDivida(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdAbatimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'descricao') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'data_inicial') {
        setDataInicio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'valor_inicial') {
        setValorInicial(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor Atual') {
        setValorAtual(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Atualização') {
        setDataAbatimento(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removeDivida(event, row) {
    event.preventDefault();
    let id_divida = '';
    let id_abatimento = '';
    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_divida') {
        id_divida = row.allCells[i].value;
      }
      if (row.allCells[i].column.Header === 'id') {
        id_abatimento = row.allCells[i].value;
      }
    }
    // console.log(id_divida, id_abatimento);
    deleteItem(id_divida, id_abatimento);
  }

  function novoAbatimento(event, row) {
    event.preventDefault();
    // console.log(rows);
    var row = rows[0];

    setAcao('PAGAR');

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_divida') {
        setIdDivida(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'descricao') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'data_inicial') {
        setDataInicio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'valor_inicial') {
        setValorInicial(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function deleteItem(id_divida, id_abatimento) {
    const urlPostPortfolio = `https://6rfu0efxfg.execute-api.sa-east-1.amazonaws.com/producao/dividas?id=${id_divida}&id_abatimento=${id_abatimento}&metodo=DELETE-filho`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function retornaColunasBody(item) {
    let valor = item.value;
    if (item.column.Header === 'Valor Atual') {
      valor = formataMoedaCF(item.value, hide);
    }

    if (item.column.Header === 'Atualização') {
      valor = formataData(item.value);
    }

    return <td>{valor}</td>;
  }

  return (
    <>
      <div>
        <Table
          responsive
          bordered
          hover
          size="sm"
          className={stylesTabela.tabela}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th>{column.render('Header')}</th>
                ))}
                <th>{'Editar / Excluir'}</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  id={row.cells[0].value}
                  key={row.cells[0].value}
                >
                  {row.cells.map((cell, index) => {
                    return retornaColunasBody(row.cells[index]);
                  })}
                  <td>
                    <div className={stylesTabela.divBtnEdit}>
                      <OverlayTrigger overlay={<Tooltip>Alterar</Tooltip>}>
                        <button
                          className={stylesTabela.btnEdit}
                          onClick={(e) => {
                            setAcao('UPDATE-filho');
                            editMovimento(e, row);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger overlay={<Tooltip>Excluir</Tooltip>}>
                        <button
                          className={stylesTabela.btnEdit}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                'Tem certeza que deseja EXCLUIR a atualização do dia ' +
                                  row.cells[0].value +
                                  ' no valor de ' +
                                  formataMoedaCF(row.cells[1].value, false) +
                                  '?',
                              )
                            ) {
                              removeDivida(e, row);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <button
            className={stylesButton.button}
            onClick={(e) => {
              novoAbatimento(e, rows);
            }}
          >
            Nova Atualização
          </button>
        </div>
        <br />
        <div>
          <BoxDividas data={data} nomeDivida={data[0]['descricao']} />
        </div>
      </div>
      {show && (
        <ModalDividas
          onClose={() => setShow(false)}
          show={show}
          acao={acao}
          idDivida={idDivida}
          idAbatimento={idAbatimento}
          descricao={descricao}
          dataInicio={dataInicio}
          valorInicial={valorInicial}
          dataAbatimento={acao === 'UPDATE-filho' ? dataAbatimento : null}
          valorAtual={acao === 'UPDATE-filho' ? valorAtual : null}
        />
      )}
    </>
  );
};

export default DividasAbatimentoList;
