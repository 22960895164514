import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import Error from '../Helper/Error';
import InputMask from '../Forms/InputMask';
import { formataMoeda } from '../Helper/DateUtilities';

function ModalTransf(props) {
  const inputElement = React.useRef();
  const [idTransf, setIdTransf] = React.useState(null);
  const [dataTransf, setDataTransf] = React.useState(null);
  const [idContaOrigem, setIdContaOrigem] = React.useState(null);
  const [idContaDestino, setIdContaDestino] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    console.log(props);
    if (props.idTransf) {
      setIdTransf(props.idTransf);
    }
    if (props.dataTransf) {
      setDataTransf(props.dataTransf);
    }
    if (props.idContaOrigem) {
      setIdContaDestino(props.idContaOrigem);
    }
    if (props.idContaDestino) {
      setIdContaDestino(props.idContaDestino);
    }
    if (props.valor) {
      setValor(formataMoeda(props.valor));
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(
    http_method,
    id_transf,
    data_transf,
    id_conta_origem,
    id_conta_destino,
    valor,
  ) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id_transf) {
      id_transf = '1';
    }

    const urlPostPortfolio = `https://33sa1tj8j4.execute-api.sa-east-1.amazonaws.com/producao/user-transf?user_id=${user_id}&id=${id_transf}&id_conta_origem=${id_conta_origem}&id_conta_destino=${id_conta_destino}&valor=${valor}&data_transf=${data_transf}&metodo=${http_method}`;
    console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addTransf(event) {
    event.preventDefault();
    setLoading(true);

    if (isValidForm()) {
      let acao = 'INSERT';
      // console.log(props.acao);
      if (props.acao === 'UPDATE') {
        acao = props.acao;
      }

      const selectContaOrigem = document.getElementById('select-conta-origem');
      const selectContaDestino = document.getElementById(
        'select-conta-destino',
      );

      postItem(
        acao,
        idTransf,
        dataTransf,
        selectContaOrigem.value,
        selectContaDestino.value,
        valor
          ? valor.replace('R$', '').replaceAll('.', '').replace(',', '.')
          : valor,
      );
    }
    // console.log(idConta, nomeConta);
  }

  function isValidForm() {
    let retorno = true;
    if (dataTransf) {
      if (dataTransf.length < 1) {
        document.getElementById('v-data').innerHTML = 'Data é obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-data').innerHTML = 'Data é obrigatório';
      retorno = false;
    }

    if (valor) {
      if (valor.length < 1) {
        document.getElementById('v-valor').innerHTML = 'Valor é obrigatório';
        retorno = false;
      } else {
        if (parseFloat(valor.replace(',', '.')) <= 0) {
          document.getElementById('v-valor').innerHTML =
            'Valor deve ser maior que Zero';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-valor').innerHTML = 'Valor é obrigatório';
      retorno = false;
    }

    const selectContaOrigem = document.getElementById('select-conta-origem');
    if (selectContaOrigem.value === 'Selecione') {
      document.getElementById('v-conta-origem').innerHTML =
        'Conta Origem é obrigatório';
      retorno = false;
    }

    const selectContaDestino = document.getElementById('select-conta-destino');
    if (selectContaDestino.value === 'Selecione') {
      document.getElementById('v-conta-destino').innerHTML =
        'Conta Destino é obrigatório';
      retorno = false;
    }

    if (retorno) {
      if (selectContaDestino.value === selectContaOrigem.value) {
        document.getElementById('v-conta-destino').innerHTML =
          'Conta Destino não pode ser igual a Conta Origem';
        retorno = false;
      }
    }

    if (!retorno) {
      setLoading(false);
    }

    // retorno = false;
    return retorno;
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="lg"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {'Transferências'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-data-transf'}
              label={'Dia'}
              type="date"
              placeholder="dd-mm-yyyy"
              value={dataTransf}
              ref={inputElement}
              onChange={({ target }) => {
                setDataTransf(target.value);
                document.getElementById('v-data').innerHTML = '';
              }}
            ></Input>
            <p id="v-data" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer} id="div-conta-origem">
            <Select
              name={'select-conta-origem'}
              label="Conta Origem"
              tipo="conta-transf"
              valor={props.idContaOrigem}
              acao={props.acao}
            />
            <p id="v-conta-origem" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer} id="div-conta-destino">
            <Select
              name={'select-conta-destino'}
              label="Conta Destino"
              tipo="conta-transf"
              valor={props.idContaDestino}
              acao={props.acao}
            />
            <p id="v-conta-destino" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <InputMask
              name={'input-valor-total'}
              label="Valor"
              placeholder="R$"
              value={valor}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) => {
                setValor(target.value.replace('R$', '').replace('.', ''));
                document.getElementById('v-valor').innerHTML = '';
              }}
            ></InputMask>
            <p id="v-valor" className={stylesTextos.textoErros}></p>
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button className={stylesButton.button} onClick={(e) => addTransf(e)}>
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalTransf;
