import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_PATRIMONIO } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import PatrimonioList from '../../Components/ControleFinanceiro/PatrimonioList';
import ModalPatrimonio from '../../Components/ControleFinanceiro/ModalPatrimonio';
import BoxEvolucaoPatrimonio from '../../Components/ControleFinanceiro/BoxEvolucaoPatrimonio';
import BoxPatrimonio from '../../Components/ControleFinanceiro/BoxPatrimonio';
import BoxSaldoPatrimonio from '../../Components/ControleFinanceiro/BoxSaldoPatrimonio';
import LookNumbers from '../../Components/Helper/LookNumbers';
import { NumberContext } from '../../Contexts/NumberContext';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Patrimonio = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);
  const { hide } = React.useContext(NumberContext);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_PATRIMONIO(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  function retornaPatrimonioLista() {
    if (data) {
      let dados = data
        .sort((a, b) => a.descricao - b.descricao)
        .filter((dados) => dados.registro === '0');

      let dadosAtualizacao = data
        .sort((a, b) => a.data_atualizacao - b.data_atualizacao)
        .filter((dados) => dados.registro === '1');

      return (
        <PatrimonioList
          data={dados}
          dadosAtualizacao={dadosAtualizacao}
          hide={hide}
        />
      );
    } else {
      return <Loading />;
    }
  }

  return (
    <>
      <Head
        metaDescription="Patrimônio Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Patrimônio | Guardar Dinheiro PRO"
        h1="Patrimônio | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />

      <BreadcumbH1
        paginaAtual=":: MEU PATRIMÔNIO"
        texto=":: MEU PATRIMÔNIO"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MEU PATRIMÔNIO</h1>
        <LookNumbers />

        {data && <BoxSaldoPatrimonio data={data} hide={hide} />}
        <br />
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {retornaPatrimonioLista()}

        <br />
        {/* <hr /> */}

        {data && <BoxEvolucaoPatrimonio data={data} />}
        <br />
        {data && <BoxPatrimonio data={data} />}

        {show && (
          <ModalPatrimonio
            onClose={() => setShow(false)}
            show={show}
            acao={'INSERT'}
          />
        )}
        <hr />
      </Container>
    </>
  );
};

export default Patrimonio;
