import React from 'react';

const AdsFixedMobile = (props) => {
  React.useEffect(() => {
    var path =
      '/22523725657/guardardinheiro.com.br/Guardardinheiro_Fixed_Mobile';
    var size = [
      [320, 50],
      [300, 50],
      [320, 100],
    ];
    var id = 'Fixed_slot';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [320, 50], [300, 50], [320, 100]])
        .build();

      googletag
        .defineSlot(path, size, id)
        .defineSizeMapping(mapping)
        .setCollapseEmptyDiv(true)
        .setTargeting('hora_do_dia', new Date().getHours().toString())
        .addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          id="Fixed_slot"
          style={{
            textAlign: 'center',
            marginTop: '5px',
            // display: 'inline-block',
            width: '320px',
            height: '100px',
            // border: '1px solid #00bfff',
            marginBottom: '5px',
          }}
        ></div>
      </div>
      {/* <hr /> */}
    </>
  );
};
export default AdsFixedMobile;
