import React from 'react';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styleTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';
import ModalConta from './ModalConta';

const ContasList = ({ data }) => {
  const [show, setShow] = React.useState(false);
  const [idConta, setIdConta] = React.useState(null);
  const [nomeConta, setNomeConta] = React.useState(null);
  const [instituicao, setInstituicao] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'id', accessor: 'id' },
      { Header: 'Nome da Conta', accessor: 'nome_conta' },
      { Header: 'Instituição', accessor: 'instituicao' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: ['id'],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  function editMovimento(event, row) {
    event.preventDefault();
    console.log(row.allCells);
    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        setIdConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Nome da Conta') {
        setNomeConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Instituição') {
        setInstituicao(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removeItem(event, row) {
    event.preventDefault();
    // console.log(row.cells[0].value);

    for (var i = 0; i < row.cells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        deleteItem(row.allCells[i].value);
      }
    }
  }

  function deleteItem(id) {
    const urlPostPortfolio = `https://m2fbwt18e0.execute-api.sa-east-1.amazonaws.com/producao/contas?id=${id}&metodo=DELETE`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <div>
        <div className="search-container">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder={'🔎 Pesquisar...'}
          />
        </div>
        <Table
          responsive
          bordered
          hover
          size="sm"
          className={styleTabela.tabela}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? '▾'
                          : '▴'
                        : '▴▾'}
                    </span>
                  </th>
                ))}
                <th>{'Editar / Excluir'}</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} id={row.cells[0].value}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                  <td>
                    <div className={styleTabela.divBtnEdit}>
                      <OverlayTrigger overlay={<Tooltip>Alterar</Tooltip>}>
                        <button
                          className={styleTabela.btnEdit}
                          onClick={(e) => {
                            editMovimento(e, row);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger overlay={<Tooltip>Excluir</Tooltip>}>
                        <button
                          className={styleTabela.btnEdit}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                'Esta Conta não será mais apresentada para cadastrar Receitas, Despesas e Investimentos. Tem certeza que deseja EXCLUIR a conta ' +
                                  row.cells[0].value +
                                  '?',
                              )
                            ) {
                              removeItem(e, row);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {show && (
        <ModalConta
          onClose={() => setShow(false)}
          show={show}
          idConta={idConta}
          nomeConta={nomeConta}
          instituicao={instituicao}
          acao={'UPDATE'}
        />
      )}
    </>
  );
};

export default ContasList;
