import { useState, useEffect } from 'react';

const useConfiguracoes = () => {
  const [configuracoes, setConfiguracoes] = useState(null);
  const [loadingConf, setLoadingConf] = useState(true);
  const [erro, setErro] = useState(null);

  useEffect(() => {
    const fetchConfiguracoes = async () => {
      try {
        const resposta = await fetch(
          'https://conf-guardar-dinheiro.vercel.app/api/configuration',
        );
        if (!resposta.ok) {
          throw new Error('Erro ao buscar as configurações');
        }
        const dados = await resposta.json();
        setConfiguracoes(dados);
      } catch (erro) {
        setErro(erro.message);
      } finally {
        setLoadingConf(false);
      }
    };

    fetchConfiguracoes();
  }, []);

  return { configuracoes, loadingConf, erro };
};

export default useConfiguracoes;
