import React from 'react';
// import Input from './Forms/Input';
import styles from './GlobalFilter.module.css';

const GlobalFilter = ({ filter, setFilter, placeholder }) => {
  return (
    <span>
      {/* Search:{' '} */}
      <input
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={placeholder}
        className={styles.globalFilter}
      ></input>
    </span>
  );
};

export default GlobalFilter;
