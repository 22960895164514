import React from 'react';
import { Container } from 'react-bootstrap';
import CleverAds from '../AdsAposta/CleverAds';
// import AdsNobeta2 from '../AdsNobeta/AdsNobeta2';
import isMobile from '../Helper/Mobile';
import AdsenseResponsivo2 from '../Adsense/AdsenseResponsivo2';
import AdsHelper from './AdsHelper';
import stylesDiv from '../../Pages/_css/Div.module.css';

const AdsAmpContent2 = (props) => {
  const [contentVisible, setContentVisible] = React.useState(true);
  const mobile = isMobile();

  React.useEffect(() => {
    var path =
      '/22523725657/guardardinheiro.com.br/Guardardinheiro_AMP_Content2';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
    ];
    var id = 'Content2';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250], [336, 280]])
        .build();

      if (props.interests && props.interests.length > 0) {
        googletag
          .defineSlot(path, size, id)
          .setTargeting('test', 'lazyload')
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .setTargeting('interesses', props.interests)
          .setTargeting('palavrasChave', props.keywords)
          .setTargeting('hora_do_dia', new Date().getHours().toString())
          .addService(googletag.pubads());
      } else {
        googletag
          .defineSlot(path, size, id)
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      }

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      // googletag.pubads().setTargeting('keywords', keywords);
      googletag.display(id);
    });

    const checkContentVisibility = () => {
      const contentElement = document.getElementById('Content2');
      if (contentElement) {
        setContentVisible(
          window.getComputedStyle(contentElement).display !== 'none',
        );
      }
    };

    checkContentVisibility();
    const intervalId = setInterval(checkContentVisibility, 2800);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Container>
        <div
          id="Content2"
          style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}
          className={stylesDiv.publicidade}
        >
          <span>Publicidade</span>
        </div>
        {!contentVisible && (
          <div>{mobile ? <CleverAds /> : <AdsenseResponsivo2 />}</div>
        )}
      </Container>
      {/* <AdsHelper segundos={500} name="ViewTaxAmpContent2" /> */}
    </>
  );
};

export default AdsAmpContent2;
