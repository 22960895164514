import { useParams } from 'react-router-dom';
import React from 'react';
import Head from '../../Components/Tags/Head';
import H2 from '../../Components/Tags/H2';
import styles from '../../Pages/Home/Home2.module.css';
import stylesButton from '../../Pages/_css/Button.module.css';
import Paragrafo from '../../Components/Tags/Paragrafo';
import stylesTable from '../../Pages/_css/Tabela.module.css';
import stylePost from '../../Components/Tags/Post.module.css';
import { Spinner, Container, Table } from 'react-bootstrap';
import style from './RendaFixa.module.css';
import Input from '../../Components/Forms/Input';
import isMobile from '../../Components/Helper/Mobile';
import { Image } from 'react-bootstrap';
import fgPagBank from '../../Assets/pagbank.png';
import fgTesouro from '../../Assets/tesouro.png';
import fgPicPay from '../../Assets/picpay.png';
import fgBancoNext from '../../Assets/banconext.webp';
import fgNuBank from '../../Assets/nubank.png';
import fgNeon from '../../Assets/neon.png';
import fgPoupanca from '../../Assets/poupanca.png';
import fgMercadoPago from '../../Assets/mercadopago.webp';
import fgInter from '../../Assets/inter.png';
import fg99 from '../../Assets/99pay.png';
import fgWill from '../../Assets/will-bank.png';
import fgBv from '../../Assets/banco_bv.webp';
import fgIti from '../../Assets/iti.png';
import fgFgts from '../../Assets/fgts.png';
import fgCDB from '../../Assets/cdb_.png';
import fgCDI from '../../Assets/cdb.png';
import fgC6 from '../../Assets/c6.png';
import fgSofisa from '../../Assets/sofisa.png';
import InputMask from '../../Components/Forms/InputMask';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import SideBar from '../../Components/Categorias/SideBar';
import AdsAmpContent2 from '../../Components/AdsJoinAds/AdsAmpContent2';
import AdsContent3 from '../../Components/AdsJoinAds/AdsContent3';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import CleverAdsDesktop from '../../Components/AdsAposta/CleverAdsDesktop';
import AdsFixedMobile from '../../Components/AdsJoinAds/AdsFixedMobile';
import CookieManager from '../../Components/Helper/CookieManager';
import AdsSticky from '../../Components/AdsUtil/AdsSticky';
import { formataMoeda } from '../../Components/Helper/DateUtilities';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import AdsContent2 from '../../Components/AdsJoinAds/AdsContent2';
import useConfiguracoes from '../../Hooks/useConfiguracoes';

const QuantoRendeMil = () => {
  const params = useParams();
  const { configuracoes, loadingConf, erro } = useConfiguracoes();
  const [inputPeriodo, setInputPeriodo] = React.useState(12);
  const [inputValor, setInputValor] = React.useState(params.valor * 1000);
  const [inputValorMensal, setInputValorMensal] = React.useState(null);
  const inputElement = React.useRef();
  const mobile = isMobile();
  const [produto, setProduto] = React.useState(null);
  const [tempo, setTempo] = React.useState(0);
  const [investimento, setInvestimento] = React.useState(0);
  const [retornoBruto, setRetornoBruto] = React.useState(0);
  const [retornoLiquido, setRetornoLiquido] = React.useState(0);
  const [imposto, setImposto] = React.useState(0);
  const loading = false;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  React.useEffect(() => {
    // retornaResultados(params.valor, 12);

    // window.scroll(0, 0);
    getNomeProduto();
    if (params.valor > 0) {
      calculaJurosCompostos(params.valor, 12, 0);
    }
  }, []);

  function getTitle() {
    let titulo = '';

    if (params.valor > 0) {
      if (params.valor <= 999) {
        titulo = 'Simulador de Quanto Rende ' + params.valor + ' Mil Reais ';
      } else {
        titulo =
          'Simulador de Quanto Rende ' +
          params.valor / 1000 +
          ' Milhões de Reais ';
      }
    } else {
      titulo = 'Simulador de Quanto Rende Seu Dinheiro ';
    }

    if (params.produto === 'poupanca') {
      titulo = titulo + 'na Poupança';
    } else if (params.produto === 'mercadopago') {
      titulo = titulo + 'no Mercado Pago';
    } else if (params.produto === 'nubank') {
      titulo = titulo + 'no Nubank';
    } else if (params.produto === 'caixinha-nubank') {
      titulo = titulo + 'na Caixinha Nubank';
    } else if (params.produto === 'picpay') {
      titulo = titulo + 'no PicPay';
    } else if (params.produto === 'pagbank') {
      titulo = titulo + 'no PagBank';
    } else if (params.produto === 'next') {
      titulo = titulo + 'no Banco Next';
    } else if (params.produto === 'inter') {
      titulo = titulo + 'no Banco Inter';
    } else if (params.produto === '99pay') {
      titulo = titulo + 'na 99Pay';
    } else if (params.produto === 'neon') {
      titulo = titulo + 'no Banco Neon';
    } else if (params.produto === 'iti') {
      titulo = titulo + 'no Iti';
    } else if (params.produto === 'will') {
      titulo = titulo + 'no Will Bank';
    } else if (params.produto === 'bv') {
      titulo = titulo + 'no Banco BV';
    } else if (params.produto === 'c6') {
      titulo = titulo + 'no C6 Bank';
    } else if (params.produto === 'sofisa') {
      titulo = titulo + 'no Banco Sofisa';
    } else if (params.produto === 'tesouro') {
      titulo = titulo + 'no Tesouro Selic';
    } else if (params.produto === 'selic') {
      titulo = titulo + 'na Selic';
    } else if (params.produto === 'renda-fixa') {
      titulo = titulo + 'em Renda Fixa';
    } else if (params.produto === 'cdb') {
      titulo = titulo + 'em CDB';
    } else if (params.produto === 'banco-digital') {
      titulo = titulo + 'em Banco Digital';
    } else {
      titulo = titulo + 'na Poupança';
    }

    return titulo;
  }

  function retornaShemaOrg() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/mil/' +
      params.produto +
      '/simulador';

    return (
      <script type="application/ld+json">
        {`{
            "@context":"https://schema.org",
            "@type":"WebPage",
            "@id": "${endereco}#webpage",
            "url":"${endereco}",
            "name":"${getTitle()}",
            "isPartOf":
              {
                "@id":"https://www.guardardinheiro.com.br/#website"
                },
            "description":"${getTitle()}",
            "inLanguage":"pt-BR"
          }`}
      </script>
    );
  }

  function retornaSchemaBreadcrumbList() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/mil/' +
      params.produto +
      '/simulador';

    return (
      <script type="application/ld+json">
        {`{
          "@context":"https://schema.org",
          "@type":"BreadcrumbList",
          "itemListElement":
            [
              {
                "@type":"ListItem",
                "position":1,
                "name":"Guardar Dinheiro",
                "item":"https://www.guardardinheiro.com.br"
              },
              {
                "@type":"ListItem",
                "position":2,
                "name":"${getTitle()}",
                "item":"${endereco}"
              }              
            ]
        }`}
      </script>
    );
  }

  function getNomeProduto() {
    if (params.produto === 'poupanca') {
      setProduto('Poupança');
    } else if (params.produto === 'mercadopago') {
      setProduto('Mercado Pago');
    } else if (params.produto === 'nubank') {
      setProduto('Nubank');
    } else if (params.produto === 'caixinha-nubank') {
      setProduto('Caixinha Nubank');
    } else if (params.produto === 'picpay') {
      setProduto('PicPay');
    } else if (params.produto === 'pagbank') {
      setProduto('PagBank');
    } else if (params.produto === 'tesouro') {
      setProduto('Tesouro Selic');
    } else if (params.produto === 'selic') {
      setProduto('Selic');
    } else if (params.produto === 'next') {
      setProduto('Banco Next');
    } else if (params.produto === 'inter') {
      setProduto('Banco Inter');
    } else if (params.produto === '99pay') {
      setProduto('99Pay');
    } else if (params.produto === 'neon') {
      setProduto('Neon');
    } else if (params.produto === 'iti') {
      setProduto('Iti');
    } else if (params.produto === 'will') {
      setProduto('Will Bank');
    } else if (params.produto === 'bv') {
      setProduto('Banco BV');
    } else if (params.produto === 'fgts') {
      setProduto('FGTS');
    } else if (params.produto === 'cdb') {
      setProduto('CDB');
    } else if (params.produto === 'cdi') {
      setProduto('CDI');
    } else if (params.produto === 'c6') {
      setProduto('C6 Bank');
    } else if (params.produto === 'sofisa') {
      setProduto('Banco Sofisa');
    } else {
      setProduto('Poupança');
    }
  }

  function calculaJurosCompostos(capitalInicial, tempoMeses, valorMensal) {
    if (configuracoes) {
      if (capitalInicial) {
        if (capitalInicial.toString().includes('R$')) {
          capitalInicial = capitalInicial
            .toString()
            .replace('R$', '')
            .replaceAll('.', '')
            .replace(',00', '');

          capitalInicial = parseFloat(capitalInicial);
        } else {
          capitalInicial = parseFloat(capitalInicial) * 1000;
        }
      }

      if (valorMensal) {
        valorMensal = valorMensal
          .replace('R$', '')
          .replaceAll('.', '')
          .replace(',00', '');
      } else {
        valorMensal = 0;
      }

      if (!valorMensal) {
        valorMensal = 0;
      }

      console.log(capitalInicial);

      const valorSelic = parseFloat(configuracoes.valor_selic);

      const ValorTesouro2027 = valorSelic + 0.1587;
      const valorCDI = parseFloat(configuracoes.valor_cdi);
      let porcentagemCDIProduto = 100;
      let taxaMensal = 0;
      let tempoFinal = 1;
      let montante = 0;
      let valorMensalAcumulado = parseFloat(valorMensal * tempoMeses);
      let valorTotalInvestido =
        parseFloat(capitalInicial) + parseFloat(valorMensalAcumulado);

      if (params.produto === 'poupanca') {
        if (valorSelic > 8.5) {
          taxaMensal = 0.5;
        } else {
          taxaMensal = (valorSelic * 70) / 100 / 12;
        }
      } else if (params.produto === 'mercadopago') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto.includes('nubank')) {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'picpay') {
        porcentagemCDIProduto = 102;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'pagbank') {
        porcentagemCDIProduto = 130;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'next') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'inter') {
        porcentagemCDIProduto = 98;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === '99pay') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'iti') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'will') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'bv') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'neon') {
        porcentagemCDIProduto = 95;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'c6') {
        porcentagemCDIProduto = 104;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'sofisa') {
        porcentagemCDIProduto = 110;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'tesouro') {
        taxaMensal = ValorTesouro2027 / 12;
      } else if (params.produto === 'selic') {
        taxaMensal = ValorTesouro2027 / 12;
      } else if (params.produto === 'fgts') {
        taxaMensal = 3 / 12;
      } else if (params.produto === 'cdb') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else if (params.produto === 'cdi') {
        porcentagemCDIProduto = 100;
        taxaMensal = (valorCDI * porcentagemCDIProduto) / 100 / 12;
      } else {
        taxaMensal = (valorSelic * 70) / 100 / 12;
      }
      taxaMensal = taxaMensal / 100;

      while (tempoFinal <= tempoMeses) {
        montante = parseFloat(capitalInicial) * (1 + parseFloat(taxaMensal));
        capitalInicial = parseFloat(montante) + parseFloat(valorMensal);
        tempoFinal += 1;
        // console.log(montante);
      }
      montante = parseFloat(montante) + parseFloat(valorMensal);
      // console.log(capitalInicial);
      // console.log(montante);

      let produtoBruto = montante;
      let produtoIr = calcula_ir(valorTotalInvestido, produtoBruto, tempoMeses);
      let produtoLiquido = produtoBruto - produtoIr;

      setInvestimento(valorTotalInvestido);
      setTempo(tempoMeses);
      setRetornoBruto(produtoBruto);
      setRetornoLiquido(produtoLiquido);
      setImposto(produtoIr);
    }
  }

  function calcula_ir(valorInvestido, valorBruto, qtdeMeses) {
    let rendimento = valorBruto - valorInvestido;
    let ir = 0;

    if (qtdeMeses <= 6) {
      ir = (rendimento * 22.5) / 100;
    } else if (qtdeMeses > 6 && qtdeMeses <= 12) {
      ir = (rendimento * 20) / 100;
    } else if (qtdeMeses > 12 && qtdeMeses < 24) {
      ir = (rendimento * 17.5) / 100;
    } else if (qtdeMeses >= 24) {
      ir = (rendimento * 15) / 100;
    }

    if (params.produto === 'fgts') {
      ir = 0;
    }
    return ir;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    calculaJurosCompostos(inputValor, inputPeriodo, inputValorMensal);
    // smoothScroll(document.getElementById('btn_2_' + params.produto}))
    // window.scrollTo(300, 500);

    if (mobile) {
      window.scrollTo(window.scrollX, window.scrollY + 300);
    } else {
      window.scrollTo(window.scrollX, window.scrollY + 200);
    }
  }

  function retornaBotaoProduto() {
    if (
      params.produto === 'picpay' ||
      params.produto === 'mercadopago' ||
      params.produto === 'pagbank' ||
      params.produto === 'next' ||
      params.produto === 'poupanca' ||
      params.produto === 'tesouro' ||
      params.produto === 'selic' ||
      params.produto === 'inter' ||
      params.produto === '99pay' ||
      params.produto === 'neon' ||
      params.produto === 'iti' ||
      params.produto === 'will' ||
      params.produto === 'bv' ||
      params.produto === 'fgts' ||
      params.produto === 'c6' ||
      params.produto === 'sofisa' ||
      params.produto === 'nubank' ||
      params.produto === 'caixinha-nubank'
    ) {
      return (
        <div className={style.divBotao}>
          <div className={style.divBotao2}>
            <br />
            <a
              href={getUrl()}
              id={'btn_' + params.produto}
              className={stylesButton.a}
            >
              {retornaTextoBotao()}
            </a>

            {isBanco() && (
              <>
                <br />
                <a
                  href={'/calculadora-renda-fixa'}
                  id={'btn_2_' + params.produto}
                  className={stylesButton.a}
                >
                  {'COMPARE O RENDIMENTO DE ' +
                    params.produto +
                    ' COM OUTRAS CONTAS'}
                </a>
              </>
            )}
            <br />
          </div>
        </div>
      );
    } else return null;
  }

  function retornaTextoBotao() {
    if (params.produto === 'picpay') {
      return 'Confira Aqui Contas que Rendem Mais que o PicPay';
      // return 'Abrir Conta PicPay';
    } else if (params.produto === 'mercadopago') {
      return 'Confira Aqui Contas que Rendem Mais que o MercadoPago';
      // return 'Abrir Conta MercadoPago';
    } else if (params.produto === 'pagbank') {
      return 'Abrir Conta no PagBank';
    } else if (params.produto === 'next') {
      return 'Confira Aqui Contas que Rendem Mais que o Banco Next';
      // return 'Abrir Conta no Banco Next';
    } else if (params.produto === 'poupanca') {
      return 'Compare o rendimento da Poupança com outros investimentos seguros';
    } else if (params.produto === 'tesouro') {
      // return 'Abrir Conta no Pagbank';
      return 'Compare o rendimento do Tesouro com outros investimentos seguros';
    } else if (params.produto === 'selic') {
      return 'Confira Aqui Contas que Rendem Mais que a Selic';
    } else if (params.produto === 'nubank') {
      return 'Confira Aqui Contas que Rendem Mais que o Nubank';
      // return 'Abrir Conta no Nubank';
    } else if (params.produto === 'caixinha-nubank') {
      return 'Confira Aqui Contas que Rendem Mais que a Caxinha Nubank';
      // return 'Abrir Conta no Nubank';
    } else if (params.produto === 'inter') {
      return 'Confira Aqui Contas que Rendem Mais que o Banco Inter';
      // return 'Abrir Conta no Nubank';
    } else if (params.produto === '99pay') {
      return 'Confira Aqui Contas que Rendem Mais que o 99Pay';
    } else if (params.produto === 'neon') {
      return 'Confira Aqui Contas que Rendem Mais que o Banco Neon';
    } else if (params.produto === 'iti') {
      return 'Confira Aqui Contas que Rendem Mais que o Iti';
    } else if (params.produto === 'will') {
      return 'Confira Aqui Contas que Rendem Mais que o Will Bank';
    } else if (params.produto === 'bv') {
      return 'Confira Aqui Contas que Rendem Mais que o Banco BV';
    } else if (params.produto === 'fgts') {
      return 'Confira Aqui Contas que Rendem Mais que o FGTS';
    } else if (params.produto === 'c6') {
      return 'Confira Aqui Contas que Rendem Mais que o C6 Bank';
    } else if (params.produto === 'sofisa') {
      return 'Confira Aqui Contas que Rendem Mais que o Banco Sofisa';
    }
  }

  function getUrl() {
    var url = '';
    // if (
    //   params.produto === 'pagbank' ||
    //   params.produto === 'btn_tesouro' ||
    //   params.produto === 'btn_poupanca'
    // ) {
    if (params.produto === 'pagbank') {
      url = 'https://bit.ly/calculadora-pagbank';
    } else {
      url = '/calculadora-renda-fixa';
    }

    return url;
  }

  function retornaLogoInstituicao(instituicao) {
    if (instituicao === 'pagbank') {
      return (
        <div>
          <Image src={fgPagBank} alt="PagBank" fluid />
        </div>
      );
    } else if (instituicao === 'tesouro') {
      return (
        <div>
          <Image src={fgTesouro} alt="Tesouro Selic" fluid />
        </div>
      );
    } else if (instituicao === 'selic') {
      return (
        <div>
          <Image src={fgTesouro} alt="Selic" fluid />
        </div>
      );
    } else if (instituicao === 'picpay') {
      return (
        <div>
          <Image src={fgPicPay} alt="PicPay" fluid />
        </div>
      );
    } else if (instituicao.includes('nubank')) {
      return (
        <div>
          <Image src={fgNuBank} alt="Nubank" fluid />
        </div>
      );
    } else if (instituicao === 'poupanca') {
      return (
        <div>
          <Image src={fgPoupanca} alt="Poupança" fluid />
        </div>
      );
    } else if (instituicao === 'next') {
      return (
        <div>
          {!mobile ? (
            <Image src={fgBancoNext} alt="Banco Next" fluid />
          ) : (
            <Image src={fgBancoNext} alt="Banco Next" fluid />
          )}
        </div>
      );
    } else if (instituicao === 'mercadopago') {
      return (
        <div>
          <Image src={fgMercadoPago} alt="Mercado Pago" fluid />
        </div>
      );
    } else if (instituicao === 'inter') {
      return (
        <div>
          <Image src={fgInter} alt="Banco Inter" fluid />
        </div>
      );
    } else if (instituicao === '99pay') {
      return (
        <div>
          <Image src={fg99} alt="Banco Inter" fluid />
        </div>
      );
    } else if (instituicao === 'neon') {
      return (
        <div>
          <Image src={fgNeon} alt="Banco Neon" fluid />
        </div>
      );
    } else if (instituicao === 'iti') {
      return (
        <div>
          <Image src={fgIti} alt="Iti" fluid />
        </div>
      );
    } else if (instituicao === 'will') {
      return (
        <div>
          <Image src={fgWill} alt="Will Bank" fluid />
        </div>
      );
    } else if (instituicao === 'bv') {
      return (
        <div>
          <Image src={fgBv} alt="Banco BV" fluid />
        </div>
      );
    } else if (instituicao === 'c6') {
      return (
        <div>
          <Image src={fgC6} alt="C6 Bank" fluid />
        </div>
      );
    } else if (instituicao === 'sofisa') {
      return (
        <div>
          <Image src={fgSofisa} alt="Banco Sofisa" fluid />
        </div>
      );
    } else if (instituicao === 'fgts') {
      return (
        <div>
          <Image src={fgFgts} alt="FGTS" fluid />
        </div>
      );
    } else if (instituicao === 'cdb') {
      return (
        <div>
          <Image src={fgCDB} alt="CDB" fluid />
        </div>
      );
    } else if (instituicao === 'cdi') {
      return (
        <div>
          <Image src={fgCDI} alt="CDI" fluid />
        </div>
      );
    }
  }

  function retornaTabela() {
    return (
      <div className={style.containerMaster}>
        <H2 texto={'Resultado Calculadora ' + produto} />
        <div className={style.container}>
          <div className={style.item}>
            <div className={style.itemTitulo}>
              <div className={style.detalheTitulo}>
                {retornaLogoInstituicao(params.produto)}
              </div>
              {/* <table className={stylesTable.tabela}> */}
              <Table hover className={stylesTable.tabela}>
                <thead
                  colSpan={2}
                  style={{ background: '#cddce6', border: '1px solid #ccc' }}
                >
                  <td style={{ fontWeight: 'bold' }} colSpan={2}>
                    {'Calculadora ' + produto}
                  </td>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>INVESTIMENTO INICIAL</td>
                    <td style={{ fontWeight: 'bold' }}>{inputValor}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}> INVESTIMENTO MENSAL</td>
                    <td style={{ fontWeight: 'bold' }}>{inputValorMensal}</td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: 'bold' }}>
                      INVESTIMENTO TOTAL EM{' '}
                      {tempo > 1 ? tempo + ' MESES ' : tempo + ' MÊS '}
                    </td>

                    <td style={{ fontWeight: 'bold' }}>
                      {formatter.format(investimento)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}> VALOR BRUTO</td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: 'var(--valorpositivo)',
                      }}
                    >
                      {formatter.format(retornoBruto)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      VALOR LÍQUIDO
                    </td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: 'var(--valorpositivo)',
                      }}
                    >
                      {formatter.format(retornoLiquido)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>IMPOSTOS</td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: 'var(--valornegativo)',
                      }}
                    >
                      {formatter.format(imposto)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {retornaBotaoProduto()}
          </div>
        </div>
        <AdsContent2 />
        <CleverAdsDesktop />
      </div>
    );
  }

  function isBanco() {
    var listaBancos = [
      'pagbank',
      'picpay',
      'iti',
      'nubank',
      'mercadopago',
      'neon',
      'will',
      'inter',
      'next',
      'bv',
      'sofisa',
      'caixinha-nubank',
    ];

    if (listaBancos.includes(params.produto)) {
      return true;
    } else {
      return false;
    }
  }

  if (loadingConf) {
    return <div>Carregando configurações...</div>;
  }

  if (erro) {
    return <div>Erro: {erro}</div>;
  }

  return (
    <div className="App">
      <Head
        title={getTitle()}
        metaDescription={getTitle()}
        h1={getTitle()}
        canonical={
          'https://www.guardardinheiro.com.br/quanto-rende/' +
          params.valor +
          '/mil/' +
          params.produto +
          '/simulador'
        }
        nivel="2"
        id_wp_post={2201}
      />

      <BreadcumbH1 paginaAtual={getTitle()} texto={getTitle()} />
      <CookieManager category={'Investimentos em Renda Fixa'} />
      <Container>
        <AdsSticky
          ads={'content1'}
          pixels={mobile ? 380 : 300}
          interests={[
            'investimentos em renda fixa',
            'contas digitais',
            'bancos online',
            'investimentos seguros',
            'renda passiva',
          ]}
          keywords={[
            'simulador de rendimento ' + params.produto,
            'quanto rende ' + params.valor + ' mil reais em ' + params.produto,
            'investimento em renda fixa',
            'taxa de juros selic',
            'calculadora de investimentos',
          ]}
        />

        <div className={stylePost.container}>
          <div className={stylePost.itemPost}>
            <h1 className={stylesTipografia.h1}>{getTitle()}</h1>
            {params.valor <= 999 ? (
              <Paragrafo
                texto={
                  'Simulador de Rendimento de ' +
                  params.valor +
                  ' Mil Reais em ' +
                  produto +
                  ' para Guardar Dinheiro com a Selic a   ' +
                  configuracoes.valor_selic.toString().replace('.', ',') +
                  '% ao ano.'
                }
              />
            ) : (
              <Paragrafo
                texto={
                  'Simulador de Rendimento de ' +
                  params.valor / 1000 +
                  ' Milhões de Reais em ' +
                  produto +
                  ' para Guardar Dinheiro com a Selic a ' +
                  configuracoes.valor_selic.toString().replace('.', ',') +
                  '% ao ano.'
                }
              />
            )}
            <H2 texto={'Como funciona a Calculadora ' + produto} />
            <Paragrafo
              texto={
                'A Calculadora ' +
                produto +
                ' faz uma simulação de forma simples de quanto ' +
                formataMoeda(params.valor * 1000) +
                ' vai render no ' +
                produto +
                ' no período informado. '
              }
            />
            <p>
              Essa funcionalidade ajuda muitos investidores a entender qual será
              a expectativa de retorno no futuro apenas informando o
              investimento inicial e qual irá investir ao longo dos meses.
            </p>

            <AdsContent1 />

            <form className={style.form} onSubmit={handleSubmit}>
              <H2 texto={'Calculadora ' + produto} />
              <div className={style.containerCalc}>
                <div>
                  <InputMask
                    label="Investimento Inicial"
                    placeholder="R$0,00"
                    value={inputValor}
                    ref={inputElement}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$'}
                    suffix={',00'}
                    onChange={({ target }) => setInputValor(target.value)}
                  ></InputMask>
                </div>

                <div>
                  <InputMask
                    label="Investimento Mensal"
                    placeholder="R$0,00"
                    value={inputValorMensal}
                    ref={inputElement}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$'}
                    suffix={',00'}
                    onChange={({ target }) => setInputValorMensal(target.value)}
                  ></InputMask>
                </div>
                <div>
                  <Input
                    label="Quantos meses deixaria o dinheiro investido?"
                    type="number"
                    placeholder="0"
                    value={inputPeriodo}
                    ref={inputElement}
                    onChange={({ target }) => setInputPeriodo(target.value)}
                  ></Input>
                </div>
              </div>

              <div style={{ width: '40%', marginBottom: '15px' }}>
                <a
                  className={stylesButton.a}
                  href={'/calculadoras/' + params.produto + '/resumido'}
                >
                  CALCULAR{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-calculator"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </a>
              </div>
            </form>
            {mobile && <AdsFixedMobile />}
            {retornaTabela()}

            <div className={styles.telegram}>
              <div className={styles.itemTelegram}>
                <p className={styles.pTelegram}>
                  ACESSE NOSSA LISTA VIP NO TELEGRAM OU WHATSAPP E RECEBA
                  INFORMAÇÕES DE ONDE SEU DINHEIRO PODE RENDER MAIS. NÃO PERCA!
                </p>
              </div>

              <div className={styles.itemTelegram2}>
                <div>
                  <a
                    className={stylesButton.a}
                    href="https://t.me/+6JN2oQW-M5BlMTlh"
                    // href="https://t.me/+I-RJXHMAcJQwOGFh"
                    target="_blank"
                  >
                    LISTA VIP TELEGRAM
                  </a>
                </div>
                <div>
                  <a
                    className={stylesButton.a}
                    href="https://chat.whatsapp.com/DRyHnTTyFkbK4k07COrYXH"
                    target="_blank"
                  >
                    LISTA VIP WHATSAPP
                  </a>
                </div>
              </div>
            </div>

            <br />
          </div>
          <SideBar />
        </div>
      </Container>
    </div>
  );
};

export default QuantoRendeMil;
