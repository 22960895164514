import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoeda } from '../Helper/DateUtilities';

import isMobile from '../Helper/Mobile';
import BoxBalanco from './BoxBalanco';
import BoxBalancoAnualChart from './BoxBalancoAnualChart';
import BoxEvolucaoSaldos from './BoxEvolucaoSaldos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAccessibleIcon } from '@fortawesome/fontawesome-free-brands';
import {
  faBuilding,
  faCoins,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

const BoxSaldoPatrimonio = ({ data, hide }) => {
  const mobile = isMobile();

  function getSaldos() {
    let dadosPatrimonio = data.filter((dados) => dados.registro === '0');

    var valor = 0;
    var passivoTotal = 0;
    var ativoTotal = 0;
    dadosPatrimonio.forEach((registro) => {
      if (registro.tipo === 'Ativos Financeiros') {
        passivoTotal = passivoTotal + parseFloat(registro.valor_atual);
      } else if (registro.tipo === 'Ativos Imobilizados') {
        ativoTotal = ativoTotal + parseFloat(registro.valor_atual);
      }
      valor = valor + parseFloat(registro.valor_atual);
    });

    return [
      formataMoeda(valor.toString()),
      formataMoeda(passivoTotal.toString()),
      formataMoeda(ativoTotal.toString()),
    ];
  }

  return (
    <>
      <div className={stylesDiv.divSombra}>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}></h2>
        </div>
        <div className={stylesDiv.divFlex100Infos}>
          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#28a745' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {hide ? '-' : getSaldos()[0]}
              </span>
            </div>
            <span className={stylesTipografia.spanSaldos}>
              <FontAwesomeIcon icon={faDollarSign} />
              <FontAwesomeIcon icon={faBuilding} /> {' Patrimônio Total'}
            </span>
          </div>

          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#B8860B' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {hide ? '-' : getSaldos()[1]}
              </span>
            </div>
            <span className={stylesTipografia.spanSaldos}>
              <FontAwesomeIcon icon={faDollarSign} /> {' Ativos Financeiros'}
            </span>
          </div>

          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#2F4F4F' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {hide ? '-' : getSaldos()[2]}
              </span>
            </div>
            <span className={stylesTipografia.spanSaldos}>
              <FontAwesomeIcon icon={faBuilding} /> {' Ativos Imobilizados'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxSaldoPatrimonio;
