import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_CONTAS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import ContasList from '../../Components/ControleFinanceiro/ContasList';
import ModalConta from '../../Components/ControleFinanceiro/ModalConta';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Contas = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_CONTAS(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  return (
    <>
      <Head
        metaDescription="Contas Correntes | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Contas Correntes | Guardar Dinheiro PRO"
        h1="Contas Correntes | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />
      <BreadcumbH1
        paginaAtual=":: MINHAS CONTAS"
        texto=":: MINHAS CONTAS"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MINHAS CONTAS</h1>
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {data ? (
          <ContasList
            data={data.filter((registro) => registro.fg_status !== 'A')}
          />
        ) : (
          <Loading />
        )}

        {show && <ModalConta onClose={() => setShow(false)} show={show} />}
      </Container>
    </>
  );
};

export default Contas;
