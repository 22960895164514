import React from 'react';
import isMobile from '../Helper/Mobile';
import { Line } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import { Table } from 'react-bootstrap';
import { formataMoedaCF } from '../Helper/DateUtilities';

const BoxEvolucaoSaldos = ({ data, hide }) => {
  const mobile = isMobile();

  function getDadosMesPorTipo(dadosAnual, tipo) {
    let retorno = [];
    let mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    // let mes = ['05', '06'];

    for (let i = 0; i < mes.length; i++) {
      let dataset = dadosAnual.filter(
        (dados) =>
          dados.tipo === tipo &&
          dados.data_vencimento.substring(
            5,
            dados.data_vencimento.length - 3,
          ) === mes[i],
      );

      let total = 0;
      if (dataset.length > 0) {
        dataset.forEach((registro) => {
          total = total + parseFloat(registro.valor);
        });
        retorno.push(total.toFixed(2));
      } else {
        retorno.push(0);
      }
    }

    // console.log(retorno);
    return retorno;
  }

  function retornaEvolucaoMes(dataSet) {
    var retorno = [];
    var valor = 0;
    for (let i = 0; i < dataSet.length; i++) {
      valor = valor + parseFloat(dataSet[i]);
      retorno.push(valor.toFixed(2));
    }
    return retorno;
  }

  function getSaldoMensal() {
    let dadosAnual = data.filter(
      (dados) =>
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 6) ===
          window.sessionStorage.getItem('gd_cf_ano'),
    );

    let dataSetDespesas = getDadosMesPorTipo(dadosAnual, 'Despesa');
    let dataSetReceitas = getDadosMesPorTipo(dadosAnual, 'Receita');
    let dataSetInvestimentos = getDadosMesPorTipo(dadosAnual, 'Investimentos');

    var dataset = [];

    dataset.push({
      label: 'Despesa',
      data: retornaEvolucaoMes(dataSetDespesas),
      borderColor: '#B22222',
      borderSkipped: false,
      fill: false,
    });

    dataset.push({
      label: 'Receita',
      data: retornaEvolucaoMes(dataSetReceitas),
      borderColor: '#28a745',
      borderSkipped: false,
      fill: false,
    });

    dataset.push({
      label: 'Investimentos',
      data: retornaEvolucaoMes(dataSetInvestimentos),
      borderColor: '#008B8B',
      borderSkipped: false,
      fill: false,
    });

    let labels = [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez',
    ];

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>{':: evolução dos saldos'}</h2>
          <Line
            data={{
              labels: labels,
              datasets: dataset,
            }}
            height={!mobile ? 100 : 200}
          ></Line>
          <Table
            responsive
            bordered
            hover
            size="sm"
            className={stylesTabela.tabela}
          >
            <thead>
              <tr>
                <th></th>
                <th>jan</th>
                <th>fev</th>
                <th>mar</th>
                <th>abr</th>
                <th>mai</th>
                <th>jun</th>
                <th>jul</th>
                <th>ago</th>
                <th>set</th>
                <th>out</th>
                <th>nov</th>
                <th>dez</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    style={{
                      width: '10px',
                      height: '30px',
                      marginRight: '5px',
                      backgroundColor: '#B22222',
                    }}
                  ></div>
                </td>
                {retornaEvolucaoMes(dataSetDespesas).map((despesa) => (
                  <td>
                    <span className={stylesTipografia.spanDespesa}>
                      {formataMoedaCF(despesa, hide)}
                    </span>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      width: '10px',
                      height: '30px',
                      marginRight: '5px',
                      backgroundColor: '#28a745',
                    }}
                  ></div>
                </td>
                {retornaEvolucaoMes(dataSetReceitas).map((receita) => (
                  <td>
                    <span className={stylesTipografia.spanReceita}>
                      {formataMoedaCF(receita, hide)}
                    </span>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      width: '10px',
                      height: '30px',
                      marginRight: '5px',
                      backgroundColor: '#008B8B',
                    }}
                  ></div>
                </td>
                {retornaEvolucaoMes(dataSetInvestimentos).map((inv) => (
                  <td>
                    <span className={stylesTipografia.spanInvestimento}>
                      {formataMoedaCF(inv, hide)}
                    </span>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return <>{getSaldoMensal()}</>;
};
export default BoxEvolucaoSaldos;
