import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { AWS_GET_USER_ORCAMENTOS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import OrcamentoList from '../../Components/ControleFinanceiro/OrcamentoList';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import stylesDiv from './css/Div.module.css';
import stylesImage from './css/Images.module.css';
import InputMonth from '../../Components/Forms/InputMonth';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Orcamento = () => {
  const { data, loading, error, request } = useFetch();

  const [ano, setAno] = React.useState(new Date().getFullYear().toString());
  const [mes, setMes] = React.useState(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );
  const [dataMovimento, setDataMovimento] = React.useState(ano + '-' + mes);

  React.useEffect(() => {
    teste();
  }, []);

  function setMesAno(valor) {
    // console.log(valor);
    setDataMovimento(valor);
    window.sessionStorage.setItem('gd_cf_dt', valor);
  }

  function aumentaDiminuiMesAno(event) {
    event.preventDefault();
    let mes = 0;
    let ano = 0;
    // console.log(event.target.id);
    if (event.target.id.includes('diminui')) {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) - 1;
      // console.log(mes);
      if (mes === 0) {
        mes = 12;
        ano = parseInt(ano) - 1;
      }
      // setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
      setMesAno(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    } else {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) + 1;
      // console.log(mes);
      if (mes === 13) {
        mes = 1;
        ano = parseInt(ano) + 1;
      }
      // setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
      setMesAno(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    }
    window.sessionStorage.setItem(
      'gd_cf_dt',
      ano.toString() + '-' + mes.toString().padStart(2, '0'),
    );
    teste();
    // console.log(dataMovimento);
  }

  function teste() {
    if (window.sessionStorage.getItem('gd_cf_dt') === null) {
      window.sessionStorage.setItem('gd_cf_dt', dataMovimento);
    } else {
      setMesAno(window.sessionStorage.getItem('gd_cf_dt'));
    }

    const { url, options } = AWS_GET_USER_ORCAMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_dt'),
    );

    request(url, options);
  }

  function getTotalDivisaoReceita() {
    var total = 0;
    var divisao = data
      .filter(
        (dados) =>
          dados.nome_categoria === 'Gastos Essenciais' ||
          dados.nome_categoria === 'Gastos Não Essenciais' ||
          dados.nome_categoria === 'Guardar Dinheiro - Seu Futuro',
      )
      .forEach((registro) => {
        total = total + parseFloat(registro.valor);
      });

    return total;
  }

  function postItem() {
    let user_id = window.localStorage.getItem('gd_uid');

    const urlPostPortfolio = `https://gnz8sbpu9e.execute-api.sa-east-1.amazonaws.com/producao/orcamento/?user_id=${user_id}&anomes=${window.sessionStorage.getItem(
      'gd_cf_dt',
    )}&metodo=${'COPIAR'}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(true);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        <Head
          metaDescription="Orçamento | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
          title="Orçamento | Guardar Dinheiro PRO"
          h1="Orçamento | Guardar Dinheiro PRO"
          canonical={window.location.href}
          nivel="1"
          id_wp_post={9000}
        />

        <BreadcumbH1
          paginaAtual=":: ORÇAMENTO - PLANEJAMENTO DE GASTOS"
          texto=":: ORÇAMENTO - PLANEJAMENTO DE GASTOS"
          isPro={true}
        />
        <Container>
          <AdsContent1 />
          <h1 className={stylesTipografia.h1}>
            :: ORÇAMENTO - PLANEJAMENTO DE GASTOS
          </h1>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            {data &&
              parseFloat(data[data.length - 1].total_orcamento) === 0 && (
                <button
                  className={stylesButton.button}
                  onClick={() => postItem()}
                >
                  Copiar do Mês Anterior
                </button>
              )}
          </div>

          <div>
            <hr />
            <div style={{ textAlign: 'left' }}>
              <div className={stylesDiv.divFlex50}>
                <div className={stylesDiv.child}>
                  <span
                    id="span-diminui-mes"
                    onClick={(e) => aumentaDiminuiMesAno(e)}
                  >
                    <svg
                      id="svg-diminui-mes"
                      xmlns="http://www.w3.org/2000/svg"
                      className={stylesImage.svgAcessoRapido}
                      fill="currentColor"
                      // className="bi bi-caret-left-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="path-diminui-mes"
                        d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                      />
                    </svg>
                  </span>
                </div>
                <div className={stylesDiv.child}>
                  <InputMonth
                    type="month"
                    // label={'Lançamento'}
                    value={dataMovimento}
                    onChange={({ target }) => {
                      setMesAno(target.value);
                    }}
                  />
                </div>
                <div className={stylesDiv.child} id="div-aumenta-mes">
                  <span
                    id="span-diminui-mes"
                    onClick={(e) => aumentaDiminuiMesAno(e)}
                  >
                    <svg
                      id="svg-aumenta-mes"
                      xmlns="http://www.w3.org/2000/svg"
                      className={stylesImage.svgAcessoRapido}
                      fill="currentColor"
                      // class="bi bi-caret-right-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="path-aumenta-mes"
                        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <br />

          <h2 className={stylesTipografia.h2}>
            :: planejamento de utilização da receita
          </h2>
          <OrcamentoList
            data={data.filter(
              (dados) =>
                dados.nome_categoria === 'Gastos Essenciais' ||
                dados.nome_categoria === 'Gastos Não Essenciais' ||
                dados.nome_categoria === 'Guardar Dinheiro - Seu Futuro',
            )}
            isGeral={false}
            isEssencial={true}
            valorDivisao={getTotalDivisaoReceita()}
          />

          <br />
          <h2 className={stylesTipografia.h2}>
            :: planejamento de gastos geral
          </h2>

          <OrcamentoList
            data={data.filter((dados) => dados.nome_categoria === 'Geral')}
            isGeral={true}
            isEssencial={false}
          />

          <br />
          <h2 className={stylesTipografia.h2}>
            :: planejamento de gastos por categoria
          </h2>
          <OrcamentoList
            data={data
              .filter(
                (dados) =>
                  dados.nome_categoria !== 'Geral' &&
                  dados.nome_categoria !== 'Gastos Essenciais' &&
                  dados.nome_categoria !== 'Gastos Não Essenciais' &&
                  dados.nome_categoria !== 'Guardar Dinheiro - Seu Futuro',
              )
              .sort((a, b) => b.valor - a.valor)}
            isGeral={false}
            isEssencial={false}
          />
        </Container>
      </>
    );
  } else return null;
};

export default Orcamento;
