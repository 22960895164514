import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';
import { formataMoedaCF, formataPorcentagem } from '../Helper/DateUtilities';
import isMobile from '../Helper/Mobile';
import { Table } from 'react-bootstrap';
import { AWS_GET_USER_ORCAMENTOS } from '../Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../Helper/Loading';
import { Doughnut } from 'react-chartjs-2';

const BoxOrcamento = ({ dataMov, box, hide }) => {
  const { data, loading, error, request } = useFetch();
  const navigate = useNavigate();
  const mobile = isMobile();

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_ORCAMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_dt'),
    );

    request(url, options);
  }, [request]);

  function getOrcamentoGeral(valorTotal) {
    let valorOrcamentogeral = data.filter(
      (dados) => dados.nome_categoria.toLowerCase() === 'geral',
    )[0].valor;

    if (parseFloat(valorOrcamentogeral) > 0) {
      return (
        <div className={stylesDiv.childLimite}>
          <div style={{ display: 'block' }}>
            <div>
              <span className={stylesTipografia.spanCategoria}>geral</span>
            </div>

            <div className={stylesDiv.childLimiteCenter}>
              <span className={stylesTipografia.spanCategoria}>
                {formataPorcentagem(
                  (parseFloat(valorTotal) / parseFloat(valorOrcamentogeral)) *
                    100,
                )}
              </span>
            </div>

            <div>
              <Doughnut
                data={getDadosGrafico(
                  'geral',
                  (parseFloat(valorTotal) / parseFloat(valorOrcamentogeral)) *
                    100,
                )}
                options={{
                  cutoutPercentage: 70,
                  responsive: true,
                  maintainAspectRatio: false,
                  title: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                }}
                // height={!mobile ? 100 : 200}
                height={150}
              />
            </div>
          </div>
        </div>
      );
    } else return null;
  }

  function getOrcamento() {
    let dadosOrcamento = dataMov.filter(
      (dados) =>
        dados.tipo === 'Despesa' &&
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 3) ===
          window.sessionStorage.getItem('gd_cf_dt'),
    );

    let total = 0;
    var labels = [];
    var valores = [];

    dadosOrcamento = dadosOrcamento.sort((a, b) => a.categoria - b.categoria);

    dadosOrcamento.forEach((registro) => {
      total = total + parseFloat(registro.valor);
    });

    if (total > 0) {
      // console.log(dadosOrcamento);
      dadosOrcamento.forEach((registro) => {
        if (labels.indexOf(registro.categoria.toLowerCase()) === -1) {
          labels.push(registro.categoria.toLowerCase());

          let dadosCategoriaEspecifica = dadosOrcamento.filter(
            (categoriaEspecifica) =>
              categoriaEspecifica.categoria.includes(registro.categoria),
          );

          var saldoCategoria = 0;

          dadosCategoriaEspecifica.forEach((registro) => {
            saldoCategoria = saldoCategoria + parseFloat(registro.valor);
          });

          valores.push(`${saldoCategoria.toFixed(2)} `);
        }
      });
    }

    // console.log(labels);
    // console.log(valores);

    let jsonString = '[';
    let valorOrcamento = 0;
    for (let i = 0; i < labels.length; i++) {
      valorOrcamento = parseFloat(
        data.filter(
          (dados) => dados.nome_categoria.toLowerCase() === labels[i],
        )[0].valor,
      );

      if (valorOrcamento > 0) {
        jsonString +=
          '{' +
          '"categoria" : "' +
          labels[i] +
          '",' +
          '"utilizado" : "' +
          valores[i] +
          '",' +
          '"orcamento" : "' +
          valorOrcamento.toString() +
          '"},';
      }
    }

    jsonString = jsonString.substring(0, jsonString.length - 1);
    jsonString = jsonString + ']';
    // console.log(jsonString);

    if (jsonString.length >= 5) {
      var jsonFinal = JSON.parse(jsonString);
      jsonFinal.sort((a, b) => b.categoria - a.categoria);
      // console.log(jsonFinal);
    }

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            :: limite de gastos por categoria
          </h2>
        </div>

        <br />
        <div className={stylesDiv.divFlexGap10}>
          {getOrcamentoGeral(total)}
          {jsonString.length > 5 && (
            <>
              {jsonFinal.map((registro) => (
                <div className={stylesDiv.childLimite} key={registro.categoria}>
                  <div style={{ display: 'block' }}>
                    <div>
                      <span className={stylesTipografia.spanCategoria}>
                        {registro.categoria}
                      </span>
                    </div>

                    <div className={stylesDiv.childLimiteCenter}>
                      <span className={stylesTipografia.spanCategoria}>
                        {formataPorcentagem(
                          (parseFloat(registro.utilizado) /
                            parseFloat(registro.orcamento)) *
                            100,
                        )}
                      </span>
                    </div>

                    <div>
                      <Doughnut
                        data={getDadosGrafico(
                          registro.categoria,
                          (parseFloat(registro.utilizado) /
                            parseFloat(registro.orcamento)) *
                            100,
                        )}
                        options={{
                          cutoutPercentage: 70,
                          responsive: true,
                          maintainAspectRatio: false,
                          title: {
                            display: false,
                          },
                          legend: {
                            display: false,
                          },
                        }}
                        height={150}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    );
  }

  function getDadosGrafico(nomeCategoria, valor) {
    var cor = '';

    if (valor < 50) {
      cor = '#28a745';
    } else if (valor >= 50 && valor <= 74) {
      cor = '#ffe69c';
    } else {
      cor = '#B22222';
    }

    var cores = [cor, 'transparent'];

    var labels = ['utilizado', 'disponível'];
    var valor2 = 0;
    if (valor > 100) {
      valor = 100;
    } else {
      valor2 = 100 - valor;
    }

    var valores = [valor.toFixed(2).toString(), valor2.toFixed(2).toString()];

    // console.log(labels);
    // console.log(valores);
    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Patrimônio',
          data: valores,
          options: {},
          backgroundColor: cores,
          borderWidth: '5',
        },
      ],
    };

    return dadosGrafico;
  }

  function getTotalReceitas() {
    var dadosReceita = dataMov.filter((dados) => dados.tipo === 'Receita');

    let total = 0;

    dadosReceita.forEach((registro) => {
      total = total + parseFloat(registro.valor);
    });
    return total;
  }

  function getDespesasPorDivisao() {
    var dadosEssencial = dataMov.filter(
      (dados) =>
        (dados.tipo === 'Despesa' || dados.tipo === 'Investimentos') &&
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 3) ===
          window.sessionStorage.getItem('gd_cf_dt'),
    );

    let total = 0;
    let totalEssencial = 0;
    let totalNaoEssencial = 0;
    let totalInvestimentos = 0;

    // console.log(dadosEssencial);
    dadosEssencial.forEach((registro) => {
      if (registro.tipo === 'Despesa') {
        if (registro.essencial === 'S') {
          totalEssencial = totalEssencial + parseFloat(registro.valor);
        } else {
          totalNaoEssencial = totalNaoEssencial + parseFloat(registro.valor);
        }
      } else {
        totalInvestimentos = totalInvestimentos + parseFloat(registro.valor);
      }
    });
    return [totalEssencial, totalNaoEssencial, totalInvestimentos];
  }

  function getDivisaoResultadoGeral() {
    if (data) {
      let dadosGeral = dataMov.filter(
        (dados) =>
          dados.tipo === 'Despesa' &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );

      let total = 0;

      dadosGeral.forEach((registro) => {
        total = total + parseFloat(registro.valor);
      });

      let valorOrcamentoGeral = data.filter(
        (dados) => dados.nome_categoria.toLowerCase() === 'geral',
      )[0].valor;

      return (
        <tr>
          <td>
            <span className={stylesTabela.spanDescricao}>Geral</span>
          </td>

          <td>{formataMoedaCF(parseFloat(valorOrcamentoGeral), hide)}</td>
          <td>{formataMoedaCF(total, hide)}</td>
          <td>
            {parseFloat(valorOrcamentoGeral) - total >= 0 ? (
              <span className={stylesTipografia.valorPositivo}>
                {formataMoedaCF(parseFloat(valorOrcamentoGeral) - total, hide)}
              </span>
            ) : (
              <span className={stylesTipografia.valorNegativo}>
                {formataMoedaCF(parseFloat(valorOrcamentoGeral) - total, hide)}
              </span>
            )}
          </td>
        </tr>
      );
    } else return null;
  }

  function getDivisaoResultadoSemGeral() {
    if (data) {
      // console.log(data);
      let arrayCategorias = data.filter(
        (dados) =>
          dados.nome_categoria.toLowerCase() !== 'gastos essenciais' &&
          dados.nome_categoria.toLowerCase() !== 'gastos não essenciais' &&
          dados.nome_categoria.toLowerCase() !==
            'guardar dinheiro - seu futuro' &&
          dados.nome_categoria.toLowerCase() !== 'geral' &&
          parseFloat(dados.valor) > 0,
      );

      arrayCategorias = arrayCategorias.sort(
        (a, b) => a.nome_categoria - b.nome_categoria,
      );

      // console.log(arrayCategorias.sort((a, b) => a.valor - b.valor));

      var arrayValores = [];
      arrayCategorias.forEach((item) => {
        var valor = 0;
        dataMov
          .filter((mov) => mov.categoria === item.nome_categoria)
          .forEach((categoria) => {
            valor = valor + parseFloat(categoria.valor);
          });
        arrayValores.push(valor);
      });

      return (
        <>
          <Table
            responsive
            bordered
            hover
            size="sm"
            className={stylesTabela.tabela}
          >
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Limite</th>
                <th>Utilizado</th>
                <th>Disponível</th>
              </tr>
            </thead>
            <tbody>
              {getDivisaoResultadoGeral()}
              {arrayCategorias.map((item, idx) => (
                <tr>
                  <td>
                    <span className={stylesTabela.spanDescricao}>
                      {item.nome_categoria}
                    </span>
                  </td>
                  <td>{formataMoedaCF(item.valor, hide)}</td>
                  <td>{formataMoedaCF(arrayValores[idx], hide)}</td>
                  <td>
                    {item.valor - arrayValores[idx] >= 0 ? (
                      <span className={stylesTipografia.valorPositivo}>
                        {formataMoedaCF(item.valor - arrayValores[idx], hide)}
                      </span>
                    ) : (
                      <span className={stylesTipografia.valorNegativo}>
                        {formataMoedaCF(item.valor - arrayValores[idx], hide)}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ textAlign: 'left' }}>
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/orcamento')}
            >
              Ver Limites
            </button>
          </div>
        </>
      );
    } else return null;
  }

  function getCorBarra(valor, isInvestimento) {
    var cor = '';

    if (isInvestimento) {
      if (valor < 50) {
        cor = '#B22222';
      } else if (valor >= 50 && valor <= 74) {
        cor = '#ffe69c';
      } else {
        cor = '#28a745';
      }
    } else {
      if (valor < 50) {
        cor = '#28a745';
      } else if (valor >= 50 && valor <= 74) {
        cor = '#ffe69c';
      } else {
        cor = '#B22222';
      }
    }
    return cor;
  }

  function getDivisaoResultado2() {
    if (data) {
      var totalReceitas = getTotalReceitas();
      var despesasPorDivisao = getDespesasPorDivisao();

      var totalEssencial = despesasPorDivisao[0];
      var totalNaoEssencial = despesasPorDivisao[1];
      var totalFuturo = despesasPorDivisao[2];

      let percPlanejadoEssencial = data.filter(
        (dados) => dados.nome_categoria.toLowerCase() === 'gastos essenciais',
      )[0].valor;

      let percPlanejadoNaoEssencial = data.filter(
        (dados) =>
          dados.nome_categoria.toLowerCase() === 'gastos não essenciais',
      )[0].valor;

      let percPlanejadoFuturo = data.filter(
        (dados) =>
          dados.nome_categoria.toLowerCase() ===
          'guardar dinheiro - seu futuro',
      )[0].valor;

      var orcamentoConfigurado = true;

      if (
        parseFloat(percPlanejadoEssencial) +
          parseFloat(percPlanejadoNaoEssencial) +
          parseFloat(percPlanejadoFuturo) ===
        0
      ) {
        orcamentoConfigurado = false;
      }

      let valorPlanejadoEssencial =
        (totalReceitas * percPlanejadoEssencial) / 100;

      let percUtilizadoEssencial =
        (totalEssencial / valorPlanejadoEssencial) * 100;
      let valorUtilizadoEssencial = totalEssencial;

      let valorPlanejadoNaoEssencial =
        (totalReceitas * percPlanejadoNaoEssencial) / 100;

      let percUtilizadoNaoEssencial =
        (totalNaoEssencial / valorPlanejadoNaoEssencial) * 100;
      let valorUtilizadoNaoEssencial = totalNaoEssencial;

      let valorPlanejadoFuturo = (totalReceitas * percPlanejadoFuturo) / 100;

      let percUtilizadoFuturo = (totalFuturo / valorPlanejadoFuturo) * 100;
      let valorUtilizadoFufuro = totalFuturo;

      var corEssencial = getCorBarra(percUtilizadoEssencial, false);
      var corNaoEssencial = getCorBarra(percUtilizadoNaoEssencial, false);
      var corFuturo = getCorBarra(percUtilizadoFuturo, true);

      // console.log(percUtilizadoNaoEssencial);

      if (percUtilizadoEssencial > 100) {
        percUtilizadoEssencial = 100;
      }
      if (percUtilizadoNaoEssencial > 100) {
        percUtilizadoNaoEssencial = 100;
      }
      if (percUtilizadoFuturo > 100) {
        percUtilizadoFuturo = 100;
      }

      var estourouLimiteEssencial = false;
      var estourouLimiteNEssencial = false;

      if (valorPlanejadoEssencial < valorUtilizadoEssencial) {
        estourouLimiteEssencial = true;
      }

      if (valorPlanejadoNaoEssencial < valorUtilizadoNaoEssencial) {
        estourouLimiteNEssencial = true;
      }

      percUtilizadoEssencial = percUtilizadoEssencial.toString() + '%';
      percUtilizadoNaoEssencial = percUtilizadoNaoEssencial.toString() + '%';
      percUtilizadoFuturo = percUtilizadoFuturo.toString() + '%';

      var temDinheiroSobrando =
        totalReceitas - (totalEssencial + totalNaoEssencial);

      return (
        <>
          <div className={stylesDiv.divCardName}>
            <h2 className={stylesTipografia.h2}>
              {':: orçamento - limite de gastos'}
            </h2>
          </div>

          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.span}>Gastos Essenciais</span>
          </div>
          <div className={stylesDiv.divProgressBar}>
            <div
              className={stylesDiv.divPercProgressBar}
              style={{
                width: percUtilizadoEssencial,
                backgroundColor: corEssencial,
              }}
            ></div>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {'Limite Definido: (' +
                formataPorcentagem(percPlanejadoEssencial) +
                ' da receita) ' +
                formataMoedaCF(valorPlanejadoEssencial, hide)}
            </span>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {totalReceitas > 0
                ? 'Limite Utilizado: (' +
                  formataPorcentagem(
                    (valorUtilizadoEssencial / totalReceitas) * 100,
                  ) +
                  ' da receita) ' +
                  formataMoedaCF(valorUtilizadoEssencial, hide)
                : 'Utilizado: ' + formataMoedaCF(valorUtilizadoEssencial, hide)}
            </span>
            {orcamentoConfigurado === true &&
            totalReceitas > 0 &&
            estourouLimiteEssencial === true ? (
              <p className={stylesTipografia.pAlertasAnimate}>
                No momento você estourou o limite de gastos essenciais
              </p>
            ) : null}
          </div>

          <hr style={{ marginTop: '-2px', marginBottom: '0px' }} />

          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.span}>Gastos Não Essenciais</span>
          </div>
          <div className={stylesDiv.divProgressBar}>
            <div
              className={stylesDiv.divPercProgressBar}
              style={{
                width: percUtilizadoNaoEssencial,
                backgroundColor: corNaoEssencial,
              }}
            ></div>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {'Limite Definido: (' +
                formataPorcentagem(percPlanejadoNaoEssencial) +
                ' da receita) ' +
                formataMoedaCF(valorPlanejadoNaoEssencial, hide)}
            </span>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {totalReceitas > 0
                ? 'Limite Utilizado: (' +
                  formataPorcentagem(
                    (valorUtilizadoNaoEssencial / totalReceitas) * 100,
                  ) +
                  ' da receita) ' +
                  formataMoedaCF(valorUtilizadoNaoEssencial, hide)
                : 'Utilizado: ' +
                  formataMoedaCF(valorUtilizadoNaoEssencial, hide)}
            </span>
            {orcamentoConfigurado === true &&
            totalReceitas > 0 &&
            estourouLimiteNEssencial === true ? (
              <p className={stylesTipografia.pAlertasAnimate}>
                No momento você estourou o limite de gastos não essenciais
              </p>
            ) : null}
          </div>

          <hr style={{ marginTop: '-2px', marginBottom: '0px' }} />

          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.span}>Guardar Dinheiro</span>
          </div>
          <div className={stylesDiv.divProgressBar}>
            <div
              className={stylesDiv.divPercProgressBar}
              style={{
                width: percUtilizadoFuturo,
                backgroundColor: corFuturo,
              }}
            ></div>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {'Definido: (' +
                formataPorcentagem(percPlanejadoFuturo) +
                ' da receita) ' +
                formataMoedaCF(valorPlanejadoFuturo, hide)}
            </span>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span className={stylesTipografia.spanCinza}>
              {totalReceitas > 0
                ? 'Investido: (' +
                  formataPorcentagem(
                    (valorUtilizadoFufuro / totalReceitas) * 100,
                  ) +
                  ' da receita) ' +
                  formataMoedaCF(valorUtilizadoFufuro, hide)
                : 'Investido: ' + formataMoedaCF(valorUtilizadoFufuro)}
            </span>

            {temDinheiroSobrando > 0 && (
              <p className={stylesTipografia.pAlertasAnimateBom}>
                Tem dinheiro Sobrando este mês. Vamos Investir?
              </p>
            )}
            {orcamentoConfigurado === false && (
              <p className={stylesTipografia.pAlertasAnimate}>
                Orçamento de gastos não configurado para o mês atual
              </p>
            )}
          </div>

          <div style={{ textAlign: 'left' }}>
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/orcamento')}
            >
              Ver Orçamento
            </button>
          </div>
        </>
      );
    } else return null;
  }
  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        {box === 'orcamento' && getDivisaoResultado2()}
        {box === 'limite' && (
          <>
            {getOrcamento()}
            {getDivisaoResultadoSemGeral()}
          </>
        )}
      </>
    );
  } else return null;
};

export default BoxOrcamento;
