// CookieManager.js
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

// Componente para gerenciar cookies
const CookieManager = ({ category }) => {
  useEffect(() => {
    // Define o cookie com a preferência de categoria
    if (category) {
      Cookies.set('user-preference', category, { expires: 7 });
    }
  }, [category]);

  // Recupera a preferência do usuário do cookie
  // const userPreference = Cookies.get('user-preference');

  // return (
  //   <div>
  //     {/* Exibindo a preferência do usuário (para fins de debug) */}
  //     <p>Preferência de Categoria: {userPreference || 'Não definida'}</p>
  //   </div>
  // );
  return null;
};

export default CookieManager;
