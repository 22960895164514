import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';
import Switch from '../Forms/Switch';
import { formataMoeda } from '../Helper/DateUtilities';

function ModalExcluiMov(props) {
  const inputElement = React.useRef();

  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);
  const [acao, setAcao] = React.useState(null);

  React.useEffect(() => {}, []);
  console.log(props);
  if (!props.show) {
    return null;
  }

  function deleteItem(http_method) {
    let user_id = window.localStorage.getItem('gd_uid');

    const url = `https://hul9706vv3.execute-api.sa-east-1.amazonaws.com/producao/user-movimentos?id=${props.idMovimento}&id_detalhe=${props.idMovimentoDetalhe}&user_id=${user_id}&fg_forma=${props.formaPagamento}&metodo=${http_method}`;
    // console.log(url);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(url, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function deleteMov(event) {
    event.preventDefault();
    let podeDeletar = true;
    setLoading(true);

    let http_method = '';
    if (props.formaPagamento === '1') {
      http_method = 'DELETE';
    } else {
      const radio1 = document.querySelector('input[id="DELETE-UNICO"]:checked');
      const radio2 = document.querySelector(
        'input[id="DELETE-NAO-PAGAS"]:checked',
      );
      const radio3 = document.querySelector('input[id="DELETE-TODAS"]:checked');

      if (radio1) {
        http_method = radio1.id;
      } else if (radio2) {
        http_method = radio2.id;
      } else if (radio3) {
        http_method = radio3.id;
      }

      if (http_method.length < 2) {
        document.getElementById('v-delete').innerHTML =
          'Escolha o tipo de exclusão';
        podeDeletar = false;
      }
    }
    // console.log(http_method);

    if (podeDeletar) {
      deleteItem(http_method);
    } else {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      className={stylesModal.modal}
      scrollable={true}
      size="xl"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          Tem Certeza que deseja Excluir?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="main-movimento">
          <div className={stylesModal.itemContainerDescricao}>
            <Input
              name={'input-descricao'}
              label={'Descrição'}
              type="text"
              value={props.descricao}
              disable={true}
            ></Input>
          </div>

          <div className={stylesModal.containerModal}>
            <div className={stylesModal.itemContainer}>
              <Select
                name={'select-categoria'}
                label="Categoria"
                tipo="categoria"
                valor={props.categoria}
                is_disabled={true}
                tipoLancamento={props.tipoLancamento}
              />
            </div>
            <div className={stylesModal.itemContainer}>
              <Input
                name={'input-data-venc'}
                label={'Data.Vencimento'}
                type="date"
                value={props.dataMovimento}
                disable={true}
              ></Input>
            </div>
            <div className={stylesModal.itemContainer}>
              <InputMask
                name={'input-valor-total'}
                label="Valor"
                placeholder="R$"
                value={formataMoeda(props.valor)}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'R$'}
                disable={true}
              ></InputMask>
            </div>
            {props.idMovimento ? null : (
              <div className={stylesModal.itemContainer}>
                <Switch id="switch-parcelado" label={'Parcelado'} />

                <Switch id="switch-recorrente" label={'Recorrente'} />
              </div>
            )}
            {props.idMovimento && props.totalParcelas !== 'NA' ? (
              <div className={stylesModal.itemContainer}>
                <Input
                  name={'input-parcelas'}
                  label={'Parcela'}
                  type="text"
                  value={props.totalParcelas}
                  disable={true}
                ></Input>
              </div>
            ) : null}

            {props.idMovimento ? null : (
              <div className={stylesModal.itemContainer}>
                <Input
                  name={'input-total-parcelas'}
                  label={'Qtde.Parcelas'}
                  type="number"
                  placeholder="Exemplo 12 X"
                  value={props.totalParcelas}
                ></Input>
              </div>
            )}
          </div>

          {props.formaPagamento === '1' ? null : (
            <Form>
              <div>
                <Form.Check
                  label="Excluir apenas esta parcela, e não realizar nenhuma alteração nas demais."
                  name="group1"
                  type={'radio'}
                  id={'DELETE-UNICO'}
                />
                <Form.Check
                  label="Excluir esta parcela, e todas as próximas parcelas ainda não pagas."
                  name="group1"
                  type={'radio'}
                  id={'DELETE-NAO-PAGAS'}
                />
                <Form.Check
                  label="Excluir todas as parcelas pagas e não pagas."
                  name="group1"
                  type={'radio'}
                  id={'DELETE-TODAS'}
                />
                <p id="v-delete" className={stylesTextos.textoErros}></p>
              </div>
            </Form>
          )}

          {erro && <Error error={erro} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={stylesButton.buttonCancelar} onClick={props.onClose}>
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Excluindo...
          </button>
        ) : (
          <button className={stylesButton.button} onClick={(e) => deleteMov(e)}>
            Excluir
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalExcluiMov;
