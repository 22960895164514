import React from 'react';

const AdsenseFixedDesktop = () => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
          // marginBottom: '5px',
          // border: '1px solid #00bfff',
          // height: '300px',
          // width: '728px',
          // height: '90px',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'inline-block',
            width: '728px',
            height: '90px',
            // border: '1px solid #00bfff',
            marginBottom: '0.5rem',
          }}
          data-ad-client="ca-pub-4615548473778420"
          data-ad-slot="9368664766"

          // data-ad-format="auto"
          // data-full-width-responsive="true"
        ></ins>
      </div>
    </>
  );
};
export default AdsenseFixedDesktop;
