import React from 'react';
import isMobile from '../Helper/Mobile';
import { Bar } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';

const BoxPorDia = ({ data, flagAnoOuMes }) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  function getGastosPorDia(dadosAnual) {
    let retorno = [];
    let mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    let dia = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '27',
      '28',
      '29',
      '30',
      '31',
    ];

    for (let i = 0; i < dia.length; i++) {
      let dataset = dadosAnual.filter(
        (dados) =>
          dados.data_vencimento.substring(8, dados.data_vencimento.length) ===
          dia[i],
      );

      let total = 0;
      if (dataset.length > 0) {
        dataset.forEach((registro) => {
          total = total + parseFloat(registro.valor);
        });
        retorno.push(total.toFixed(2));
      } else {
        retorno.push(0);
      }
    }

    // console.log(retorno);
    return retorno;
  }

  function retorna() {
    let dadosAnual = [];

    if (flagAnoOuMes === 'ano') {
      dadosAnual = data.filter(
        (dados) =>
          dados.tipo === 'Despesa' &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    } else {
      dadosAnual = data.filter(
        (dados) =>
          dados.tipo === 'Despesa' &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    }

    let dataSetGastosDia = getGastosPorDia(dadosAnual);

    let labels = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '27',
      '28',
      '29',
      '30',
      '31',
    ];

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>:: gastos por dia</h2>
        </div>

        <Bar
          data={{
            labels: labels,
            datasets: [
              {
                label: 'Gastos Por dia',
                data: dataSetGastosDia,
                backgroundColor: '#B22222',
                barPercentage: 1,
              },
            ],
          }}
          height={!mobile ? 100 : 200}
        ></Bar>

        <div style={{ textAlign: 'left' }}>
          {flagAnoOuMes === 'mes' ? (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos')}
            >
              Ver Extrato
            </button>
          ) : (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos-ano')}
            >
              Ver Extrato Anual
            </button>
          )}
        </div>
      </>
    );
  }

  return <>{retorna()}</>;
};
export default BoxPorDia;
