import React, { useEffect } from 'react';

const CleverAdsDesktop = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-cfasync', 'false');
    script.type = 'text/javascript';
    script.id = 'clever-core';
    script.src = `${process.env.PUBLIC_URL}/ScriptAposta.js`;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        class="clever-core-ads"
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      ></div>
    </>
  );
};

export default CleverAdsDesktop;
