import React from 'react';
import { Helmet } from 'react-helmet';
import { setUtmSession } from '../Helper/UtmHelper';
// import isMobile from '../Helper/Mobile';

const Head = (props) => {
  const [mostraAnuncio, setMostraAnuncio] = React.useState(true);
  // const mobile = isMobile();

  React.useEffect(() => {
    setUtmSession();
    // console.log(props);
    if (typeof window.useShortlink === 'function') {
      window.useShortlink(
        props.canonical +
          '/?p=' +
          (props.id_wp_post ? props.id_wp_post : '999'),
      );
    }

    // Limpar o shortlink ao desmontar o componente

    blurMe();
    if (window.location.href.includes('privacidade')) {
      setMostraAnuncio(false);
    } else if (window.location.href.includes('sobre')) {
      setMostraAnuncio(false);
    } else if (window.location.href.includes('termos-de-uso')) {
      setMostraAnuncio(false);
    } else if (window.location.href.includes('autenticacao')) {
      setMostraAnuncio(false);
      // } else if (window.location.href.includes('favoritos')) {
      //   setMostraAnuncio(false);
    } else if (window.location.href.includes('users')) {
      setMostraAnuncio(false);
    } else if (window.location.href.includes('promocao-de-maquininhas')) {
      setMostraAnuncio(false);
    } else if (window.location.href.includes('/lp/guardar')) {
      setMostraAnuncio(false);
    }

    return () => {
      try {
        document.head.removeChild(props.id_wp_post);
      } catch (err) {}
    };
    // getClarity();
  }, []);

  // function getOutPush() {
  //   if (!window.location.href.includes('promocao-de-maquininhas')) {
  //     return (
  //       <script type="text/javascript">
  //         {`
  //       window.pushMST_config = { "vapidPK": "BP4mPRDWgc7WmloYbPkq0hUELbG79OQgVXi6Hhq-dHXBYSDGm-7rtY9f6OHwzSOVxqg18tQAgw90Vhd42wQKa60", "enableOverlay": true, "swPath": "/sw.js", "i18n": {} };

  //       var pushmasterTag = document.createElement('script');
  //       pushmasterTag.src = "https://cdn.pushmaster-cdn.xyz/scripts/publishers/634ea33765cb21000970034d/SDK.js";
  //       pushmasterTag.setAttribute('defer', '');

  //       var firstScriptTag = document.getElementsByTagName('script')[0];
  //       firstScriptTag.parentNode.insertBefore(pushmasterTag, firstScriptTag);
  //   `}
  //       </script>
  //     );
  //   }
  // }

  // function getNobeta() {
  //   var min = Math.ceil(1);
  //   var max = Math.floor(6);
  //   var numero = Math.floor(Math.random() * (max - min)) + min;
  //   // console.log(numero);
  //   // só mostra nobeta se o numero aleatorio entre 1-3 ser 2, senao fica mostrando toda hora
  //   if (numero === 2) {
  //     return (
  //       <script src="https://api.nobeta.com.br/nobetaads&id=guardardinheiro.inter"></script>
  //     );
  //   }
  // }

  function getAposta() {
    return (
      <script
        data-cfasync="false"
        type="text/javascript"
        id="clever-core"
        src={process.env.PUBLIC_URL + '/ScriptAposta.js'}
      ></script>
    );
  }

  function getSchemaWebPage() {
    if (props.nivel === '1') {
      return (
        <script type="application/ld+json">
          {`{
            "@context":"https://schema.org",
            "@type":"WebPage","@id":"${props.canonical}#webpage",
            "url":"${props.canonical}",
            "name":"${props.title}",            
            "description":"${props.metaDescription}",
            "thumbnailUrl":"https://i0.wp.com/blog.guardardinheiro.com.br/wp-content/uploads/2022/12/logo-redes-sociais.webp?fit=1024%2C720&amp;ssl=1",
            "inLanguage":"pt-BR"
          }`}
        </script>
      );
    } else {
      return (
        <script type="application/ld+json">
          {`{
              "@context":"https://schema.org",
              "@type":"WebPage","@id":"${props.canonical}#webpage",
              "url":"${props.canonical}",
              "name":"${props.title}",
              "isPartOf":{"@id":"https://www.guardardinheiro.com.br/#website"},
              "description":"${props.metaDescription}",
              "thumbnailUrl":"https://i0.wp.com/blog.guardardinheiro.com.br/wp-content/uploads/2022/12/logo-redes-sociais.webp?fit=1024%2C720&amp;ssl=1",
              "inLanguage":"pt-BR"
            }`}
        </script>
      );
    }
  }

  function getSchemaBreadcumbList() {
    if (props.nivel === '1') {
      return (
        <script type="application/ld+json">
          {` 
          {
            "@context":"https://schema.org",
            "@type":"BreadcrumbList",
            "itemListElement":
              [
                {
                  "@type":"ListItem",
                  "position":1,
                  "name":"Guardar Dinheiro",
                  "item":"https://www.guardardinheiro.com.br"
                }
              ]
          }
      `}
        </script>
      );
    } else if (props.nivel === '2') {
      return (
        <script type="application/ld+json">
          {` 
          {
            "@context":"https://schema.org",
            "@type":"BreadcrumbList",
            "itemListElement":
              [
                {
                  "@type":"ListItem",
                  "position":1,
                  "name":"Guardar Dinheiro",
                  "item":"https://www.guardardinheiro.com.br"
                },
                {
                  "@type":"ListItem",
                  "position":2,
                  "name":"${props.title}",
                  "item":"${props.canonical}"
                }              
              ]
          }`}
        </script>
      );
    } else if (props.nivel === '3') {
      return (
        <script type="application/ld+json">
          {` 
          {
            "@context":"https://schema.org",
            "@type":"BreadcrumbList",
            "itemListElement":
              [
                {
                  "@type":"ListItem",
                  "position":1,
                  "name":"Guardar Dinheiro",
                  "item":"https://www.guardardinheiro.com.br"
                },
                {
                  "@type":"ListItem",
                  "position":2,
                  "name":"${props.titleNivel2}",
                  "item":"https://www.guardardinheiro.com.br${props.rotaNivel2}"
                },
                {
                  "@type":"ListItem",
                  "position":3,
                  "name":"${props.title}",
                  "item":"${props.canonical}"
                }              
              ]
          }`}
        </script>
      );
    }
  }

  function blurMe() {
    let seconds = 3000;
    document.body.style.overflow = 'hidden';
    if (window.location.href.includes('utm_medium')) {
      document.body.style.filter = 'blur(5px)';
    }

    setTimeout(function () {
      if (window.location.href.includes('utm_medium')) {
        document.body.style.filter = 'none';
        seconds = 3700;
      }

      document.body.style.overflow = 'auto';
    }, seconds);
  }

  // function getClarity() {
  //   var isExecutaClarity = false;
  //   var d = new Date();
  //   var hora = d.getHours();

  //   if (hora > 8 && hora < 21) {
  //     if (window.location.href.includes('quanto-rende')) {
  //       isExecutaClarity = true;
  //     } else if (window.location.href.includes('calculadora')) {
  //       isExecutaClarity = true;
  //     }
  //   }

  //   if (isExecutaClarity) {
  //     executaClarity();
  //   }
  // }

  // function executaClarity() {
  //   return (
  //     <script type="text/javascript">
  //       {`
  //           (function (c, l, a, r, i, t, y) {
  //             c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
  //             t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
  //             y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  //           })(window, document, "clarity", "script", "i4gwuzgspl");
  //       `}
  //     </script>
  //   );
  // }

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta itemProp="name" content={props.title}></meta>
      <meta name="description" content={props.metaDescription} />
      <meta name="keywords" content={props.h1} />
      <link rel="canonical" href={props.canonical}></link>
      <link
        rel="shortlink"
        href={
          props.canonical +
          '/?p=' +
          (props.id_wp_post ? props.id_wp_post : '999')
        }
      ></link>
      {/* <meta property="og:title" content={props.title} /> */}

      <script type="application/ld+json">
        {`{
            "@context":"https://schema.org",
            "@type":"Organization",
            "url":"https://www.guardardinheiro.com.br",
            "name":"Guardar Dinheiro",
            "logo": "https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/logo3.png",
            "description": "Guardar Dinheiro é um portal gratuito que oferece informações do mercado financeiro que ajuda os investidores a escolher o melhor investimento do momento.",
            "sameAs" : [
              "https://www.facebook.com/siteguardardinhero",
              "https://twitter.com/guardardinheir1",
              "https://www.linkedin.com/in/guardar-dinheiro-267073230/",
              "https://br.pinterest.com/guardardinheiro/",
              "https://www.instagram.com/guardardinheiro/",
              "https://www.youtube.com/channel/CYhHakmdv-lGolh3zahffCQ",    
              "https://medium.com/@guardardinheiro"
            ]            
          }`}
      </script>

      {getSchemaWebPage()}

      {getSchemaBreadcumbList()}

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-2K23FV25Q6"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-2K23FV25Q6');
      `}
      </script>

      {/* {getAposta()} */}

      {/* {props.tagOneSignal && (
        <script>
          {`                                                    
            OneSignal.sendTag("${props.oneSignalTag}","1");              
        `}
        </script>
      )} */}

      {/* {getOutPush()} */}

      {/* {getMapaCalor()} */}
    </Helmet>
  );
};

export default Head;
