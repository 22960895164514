import React from 'react';
import Head from '../../Components/Tags/Head';
import stylePost from '../../Components/Tags/Post.module.css';
import stylesTipoGrafia from '../_css/Tipografia.module.css';
import stylesButton from '../_css/Button.module.css';
import styles from './PlannerTreinamento.module.css';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import SideBar from '../../Components/Categorias/SideBar';
import Container from 'react-bootstrap/Container';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';

const PlannerCategorias = () => {
  function getPlaySvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-play-btn-fill"
        viewBox="0 0 16 16"
      >
        <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2m6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
      </svg>
    );
  }
  return (
    <>
      <Head
        title={'Treinamento Guardar Dinheiro - Planner 360º'}
        metaDescription={'Treinamento Guardar Dinheiro - Planner 360º'}
        h1={'Treinamento Guardar Dinheiro - Planner 360º'}
        canonical={window.location.href}
        nivel="2"
        id_wp_post={9028}
      />

      <BreadcumbH1
        paginaAtual={'Aula 3 - Cadastro de Categorias'}
        texto={'Aula 3 - Cadastro de Categorias'}
        paginaAnterior={'Treinamento Guardar Dinheiro - Planner 360º'}
        rotaPaginaAnterior={'/planner/treinamento'}
      />

      <Container>
        <div className={stylePost.container}>
          <div className={stylePost.itemPost}>
            <h1 className={stylesTipografia.h1}>
              Aula 3 - Cadastro de Categorias
            </h1>

            <div
              // id="video-youtube"
              className="video"
              style={{
                position: 'relative',
                paddingBottom: '56.25%' /* 16:9 */,
                paddingTop: 25,
                height: 0,
                margin: '25px',
              }}
            >
              <iframe
                title="youTubeVideoPro"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                // src={`https://www.youtube.com/embed/JQ79DkvVL0c`}
                // src={`https://www.youtube.com/embed/JQ79DkvVL0c?autoplay=1`}
                src="https://www.youtube.com/embed/Hct53njp_9k"
              />
            </div>
            <div>
              <span className={styles.spanAula}>{'Aula Anterior >>'}</span>
              <div className={styles.divFlex}>
                <div className={styles.divChildFlexPlay}>
                  <a
                    href="/planner/aula-cadastro-contas"
                    className={stylesButton.play}
                  >
                    {getPlaySvg()}
                  </a>
                </div>
                <div className={styles.divChildFlexPlay}>
                  <span className={styles.spanAula}>
                    Aula 2 - Cadastro de Contas
                  </span>
                </div>
              </div>
            </div>
            <div>
              <span className={styles.spanAula}>{'Próxima Aula >>'}</span>
              <div className={styles.divFlex}>
                <div className={styles.divChildFlexPlay}>
                  <a
                    href="/planner/aula-orcamento"
                    className={stylesButton.play}
                  >
                    {getPlaySvg()}
                  </a>
                </div>
                <div className={styles.divChildFlexPlay}>
                  <span className={styles.spanAula}>
                    Aula 4 - Configurando Orçamento
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <p style={{ fontWeight: 'bold' }}>Em caso de Dúvidas</p>
            <a
              className={stylesButton.a}
              href="https://api.whatsapp.com/send?phone=5511913569157&text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20dúvidas%20sobre%20o%20Guardar%20Dinheiro-Planner-360%3F"
            >
              Fale com Nosso Suporte{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg>
            </a>
          </div>

          <SideBar />
        </div>
      </Container>
    </>
  );
};

export default PlannerCategorias;
