import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';
import style from '../../Pages/ControleFinanceiro/css/Select.module.css';
import Label from '../Forms/Label';
import ColorPicker from '../Forms/ColorPicker';
import Switch from '../Forms/Switch';

function ModalCadastroCategoria(props) {
  const inputElement = React.useRef();
  const [idCategoria, setIdCategoria] = React.useState(null);
  const [nomeCategoria, setNomeCategoria] = React.useState(null);
  const [limite, setLimite] = React.useState(0);
  const [cor, setCor] = React.useState(null);
  const [flagTipoCategoria, setFlagTipoCategoria] = React.useState(null);
  const [essencial, setEssencial] = React.useState('N');
  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);
  const [switchStateEssencial, setSwitchStateEssencial] = React.useState(false);

  React.useEffect(() => {
    // console.log(props);
    if (props.idCategoria) {
      setIdCategoria(props.idCategoria);
    }
    if (props.nomeCategoria) {
      setNomeCategoria(props.nomeCategoria);
    }
    if (props.essencial) {
      setEssencial(props.essencial);

      if (props.essencial === 'S') {
        // console.log(document.getElementById('switch-essencial'));
        document.getElementById('switch-essencial').click();
      }
    }
    if (props.limite) {
      setLimite(props.limite);
    }
    if (props.flagTipoCategoria) {
      setFlagTipoCategoria(props.flagTipoCategoria);
    } else {
      setFlagTipoCategoria('Selecione');
    }
    if (props.cor) {
      setCor('#' + props.cor);
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(
    id_categoria,
    http_method,
    nome_categoria,
    flag_tipo_categoria,
    cor,
    despesaEssencial,
    limite,
  ) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id_categoria) {
      id_categoria = '1';
    }

    const urlPostPortfolio = `https://rgmmkoiye2.execute-api.sa-east-1.amazonaws.com/producao/categorias?user_id=${user_id}&id=${id_categoria}&nome_categoria=${encodeURIComponent(
      nome_categoria,
    )}&limite=${limite}&fg_tipo_categoria=${flag_tipo_categoria}&essencial=${despesaEssencial}&cor=${cor.replace(
      '#',
      '',
    )}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          setLoading(false);
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addCategoria(event) {
    event.preventDefault();
    if (isValidForm()) {
      setLoading(true);

      const selectFlagTipoCategoria = document.getElementById(
        'select-flag-tipo-categoria',
      );

      const colorPicker = document.getElementById('categoria-color-picker');

      setFlagTipoCategoria(selectFlagTipoCategoria);
      setCor(colorPicker.value);

      let http_method = 'INSERT';
      if (props.acao) {
        http_method = 'UPDATE';
      }

      postItem(
        idCategoria,
        http_method,
        nomeCategoria,
        selectFlagTipoCategoria.value,
        colorPicker.value,
        essencial,
        limite,
      );
    }
  }

  function retornaTipoCategoria() {
    return (
      <select
        className={style.select}
        onChange={(e) => {
          setFlagTipoCategoria(e.target.value);
          document.getElementById('v-tipo-categoria').innerHTML = '';
          if (e.target.value !== 'D') {
            document.getElementById('div-switch-essencial').style.visibility =
              'hidden';
            setEssencial('N');
          } else {
            document.getElementById('div-switch-essencial').style.visibility =
              'visible';
          }
        }}
        value={props.flagTipoCategoria}
        id={'select-flag-tipo-categoria'}
      >
        <option key="Selecione:" value="Selecione">
          Selecione
        </option>
        <option key="D" value="D">
          Despesa
        </option>
        <option key="D" value="R">
          Receita
        </option>
        <option key="I" value="I">
          Investimentos
        </option>
      </select>
    );
  }

  function isValidForm() {
    let retorno = true;
    if (nomeCategoria) {
      if (nomeCategoria.length < 1) {
        document.getElementById('v-nome-categoria').innerHTML =
          'Nome da Categoria obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-nome-categoria').innerHTML =
        'Nome da Categoria obrigatório';
      retorno = false;
    }

    if (flagTipoCategoria) {
      if (flagTipoCategoria === 'Selecione') {
        document.getElementById('v-tipo-categoria').innerHTML =
          'Tipo da Categoria obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-tipo-categoria').innerHTML =
        'Tipo da Categoria obrigatório';
      retorno = false;
    }

    const colorPicker = document.getElementById('categoria-color-picker');
    // console.log(colorPicker.value);
    if (colorPicker.value.includes('000000')) {
      document.getElementById('v-cor-categoria').innerHTML =
        'Selecione uma cor';
      retorno = false;
    }

    if (!retorno) {
      setLoading(false);
    }

    return retorno;
  }

  const handleChangeEssencial = (e) => {
    setSwitchStateEssencial(!switchStateEssencial);
    const switchEssencial = document.getElementById('switch-essencial');

    if (!switchEssencial.hasAttribute('checked')) {
      setEssencial('S');
      // console.log('S');
    } else {
      setEssencial('N');
      // console.log('N');
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="lg"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {'Categorias'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-nome-categoria'}
              label={'Nome Categoria'}
              type="text"
              value={nomeCategoria}
              ref={inputElement}
              onChange={({ target }) => {
                setNomeCategoria(target.value);
                document.getElementById('v-nome-categoria').innerHTML = '';
              }}
            ></Input>
            <p id="v-nome-categoria" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Label name={'Tipo'} label={'Tipo'} />
            {retornaTipoCategoria()}
            <p id="v-tipo-categoria" className={stylesTextos.textoErros}></p>
          </div>
          <div className={stylesModal.itemContainer}>
            <Label name={'Cor'} label={'Cor'} />
            <ColorPicker id="categoria-color-picker" valor={'#' + props.cor} />
            <p id="v-cor-categoria" className={stylesTextos.textoErros}></p>
          </div>
        </div>
        <div id="div-switch-essencial" className={stylesModal.itemContainer}>
          <Switch
            id="switch-essencial"
            label={'Despesa Essencial'}
            onChange={handleChangeEssencial}
            switchState={switchStateEssencial}
            type="switch"
          />
        </div>

        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button
            className={stylesButton.button}
            onClick={(e) => addCategoria(e)}
          >
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCadastroCategoria;
