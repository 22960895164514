import React from 'react';
import { Container } from 'react-bootstrap';
import Input from '../Forms/Input';
import stylesButton from '../../Pages/_css/Button.module.css';

const FormularioLead = (props) => {
  const [inputEmail, setInputEmail] = React.useState('');
  const [erro, setErro] = React.useState(null);
  const inputElement = React.useRef();
  React.useEffect(() => {});

  function addLead() {
    const url = `https://r2em3jxwl8.execute-api.sa-east-1.amazonaws.com/producao/lead?email=${inputEmail}&nome=${'-'}&tel=${'-'}&origem=${
      props.origem
    }`;

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(url, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          console.log(response.json);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function getUrl() {
    let url = '#';
    console.log(inputEmail);

    if (inputEmail.includes('@')) {
      if (inputEmail.includes('.com')) {
        url = '/calculadoras/liberdade-financeira';
      }
    }

    return url;
  }

  return (
    <>
      <Container>
        <div style={{ padding: '20px' }}>
          <Input
            id="input-email-lead"
            label="E-mail?"
            type="text"
            placeholder="Seu melhor e-mail"
            value={inputEmail}
            ref={inputElement}
            onChange={({ target }) => {
              setInputEmail(target.value);
              setErro('');
            }}
          ></Input>

          <p style={{ color: 'red', fontWeight: 'bold' }}>{erro}</p>
          <a
            href={getUrl()}
            className={stylesButton.a}
            onClick={() => {
              if (inputEmail.includes('@')) {
                if (inputEmail.includes('.com')) {
                  addLead();
                } else {
                  setErro(
                    'Formato do e-mail incorreto. Verifique o e-mail digitado!!!',
                  );
                }
              } else {
                setErro(
                  'Formato do e-mail incorreto. Verifique o e-mail digitado!!!',
                );
              }
            }}
          >
            {props.textBotaoLead}
          </a>
        </div>
      </Container>
    </>
  );
};

export default FormularioLead;
