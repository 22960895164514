exports.getMesAtual = (retorno_atual) => {
  const hoje = new Date();
  retorno_atual = retorno_atual.replace('.0', '');
  let dia;

  let mes = retorno_atual.slice(-2);
  // let ano = String(hoje.getFullYear()).slice(-2);
  // let anoAnterior = String(hoje.getFullYear() - 1).slice(-2);

  let ano = retorno_atual.slice(2, 4);
  let anoAnterior = String(parseInt(ano) - 1);

  // console.log(retorno_atual);
  // console.log(mes);
  // console.log(ano);
  // console.log(anoAnterior);
  let lista = [];
  let jan = 'Jan/';
  let fev = 'Fev/';
  let mar = 'Mar/';
  let abr = 'Abr/';
  let mai = 'Mai/';
  let jun = 'Jun/';
  let jul = 'Jul/';
  let ago = 'Ago/';
  let set = 'Set/';
  let out = 'Out/';
  let nov = 'Nov/';
  let dez = 'Dez/';

  jan += ano;
  fev += ano;
  mar += ano;
  abr += ano;
  mai += ano;
  jun += ano;
  jul += ano;
  ago += ano;
  set += ano;
  out += ano;
  nov += ano;
  dez += ano;

  if (mes === '01') {
    fev = fev.replace(ano, anoAnterior);
    mar = mar.replace(ano, anoAnterior);
    abr = abr.replace(ano, anoAnterior);
    mai = mai.replace(ano, anoAnterior);
    jun = jun.replace(ano, anoAnterior);
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez, jan];
  } else if (mes === '02') {
    mar = mar.replace(ano, anoAnterior);
    abr = abr.replace(ano, anoAnterior);
    mai = mai.replace(ano, anoAnterior);
    jun = jun.replace(ano, anoAnterior);
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [mar, abr, mai, jun, jul, ago, set, out, nov, dez, jan, fev];
  } else if (mes === '03') {
    abr = abr.replace(ano, anoAnterior);
    mai = mai.replace(ano, anoAnterior);
    jun = jun.replace(ano, anoAnterior);
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [abr, mai, jun, jul, ago, set, out, nov, dez, jan, fev, mar];
  } else if (mes === '04') {
    mai = mai.replace(ano, anoAnterior);
    jun = jun.replace(ano, anoAnterior);
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [mai, jun, jul, ago, set, out, nov, dez, jan, fev, mar, abr];
  } else if (mes === '05') {
    jun = jun.replace(ano, anoAnterior);
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [jun, jul, ago, set, out, nov, dez, jan, fev, mar, abr, mai];
  } else if (mes === '06') {
    jul = jul.replace(ano, anoAnterior);
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [jul, ago, set, out, nov, dez, jan, fev, mar, abr, mai, jun];
  } else if (mes === '07') {
    ago = ago.replace(ano, anoAnterior);
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [ago, set, out, nov, dez, jan, fev, mar, abr, mai, jun, jul];
  } else if (mes === '08') {
    set = set.replace(ano, anoAnterior);
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [set, out, nov, dez, jan, fev, mar, abr, mai, jun, jul, ago];
  } else if (mes === '09') {
    out = out.replace(ano, anoAnterior);
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [out, nov, dez, jan, fev, mar, abr, mai, jun, jul, ago, set];
  } else if (mes === '10') {
    nov = nov.replace(ano, anoAnterior);
    dez = dez.replace(ano, anoAnterior);
    lista = [nov, dez, jan, fev, mar, abr, mai, jun, jul, ago, set, out];
  } else if (mes === '11') {
    dez = dez.replace(ano, anoAnterior);
    lista = [dez, jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov];
  } else if (mes === '12') {
    lista = [jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez];
  }
  // console.log(lista);
  return lista;
};

exports.formataData = () => {
  // var data = new Date();
  var d = new Date();
  var anoC = d.getFullYear();
  var mesC = d.getMonth() - 4;

  var data = new Date(anoC, mesC, 1);
  // var data = new Date(anoC, mesC + 1, 0);

  var diaS = data.getDay();
  var diaM = data.getDate();
  var mes = data.getMonth();
  var ano = data.getFullYear();

  switch (
    diaS //converte o numero em nome do dia
  ) {
    case 0:
      diaS = 'Domingo';
      break;
    case 1:
      diaS = 'Segunda-feira';
      break;
    case 2:
      diaS = 'Terça-feira';
      break;
    case 3:
      diaS = 'Quarta-feira';
      break;
    case 4:
      diaS = 'Quinta-feira';
      break;
    case 5:
      diaS = 'Sexta-feira';
      break;
    case 6:
      diaS = 'Sabado';
      break;
  }

  if (diaM.toString().length === 1) diaM = '0' + diaM;
  mes = mes + 1;

  var mydate = new Date(ano + '-' + mes + '-' + diaM);

  return diaS + ', ' + diaM + '/' + mes + '/' + ano;
};

exports.formataCnpj = (v) => {
  v = v.replace(/^(\d{2})(\d)/, '$1.$2');

  //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

  //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');

  //Coloca um hífen depois do bloco de quatro dígitos
  v = v.replace(/(\d{4})(\d)/, '$1-$2');
  return v;
};

exports.formataPorcentagem = (valor) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return formatter.format(valor).replace('R$', '') + '%';
};

exports.formataData = (valor) => {
  let dia = valor.substring(8, valor.length);
  let mes = valor.substring(5, valor.length - 3);
  let ano = valor.substring(0, valor.length - 6);
  valor = dia + '/' + mes + '/' + ano;
  return valor;
};

exports.formataMoeda = (valor) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return formatter.format(valor);
};

exports.formataMoedaCF = (valor, hide) => {
  if (hide) {
    return '-';
  } else {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    return formatter.format(valor);
  }
};

exports.formataRetorno = (valor) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return formatter.format(valor).replace('R$', '');
};

exports.formataRisco = (risco) => {
  risco = risco.replace('.0', '');
  if (risco === '1') {
    return 'Baixo';
  } else if (risco === '2') {
    return 'Médio Baixo';
  } else if (risco === '3') {
    return 'Médio';
  } else if (risco === '4') {
    return 'Médio Alto';
  } else if (risco === '5') {
    return 'Alto';
  } else {
    return 'Não Classificado';
  }
};

exports.formataNomeFundo = (stringCorrigidaAlteracao) => {
  let stringCorrigida = stringCorrigidaAlteracao.toString().replace('.0', '');
  return stringCorrigida
    .replace('Multimercado', 'MM')
    .replace('Crédito Privado', 'CP')
    .replace('Longo Prazo', 'LP')
    .replace('Renda Fixa', 'RF')
    .replace('Fundo de ', '')
    .toUpperCase();
};

exports.formataClasse = (stringCorrigidaAlteracao) => {
  let stringCorrigida = stringCorrigidaAlteracao.toString().replace('.0', '');
  return stringCorrigida
    .replace('Fundo', ' ')
    .replace('de', '')
    .replace('0', 'Não Classificado')
    .toUpperCase();
};

exports.formataCorretora = (stringCorrigidaAlteracao) => {
  let stringCorrigida = stringCorrigidaAlteracao.toString().replace('.0', '');
  return stringCorrigida
    .replace('ITAU', 'Itaú')
    .replace('Bradesco', 'Bradesco')
    .replace('Santander', 'Santander')
    .replace('BTG', 'BTG Pactual')
    .replace('Caixa', 'Caixa Econômica')
    .replace('XP', 'XP Investimentos')
    .replace('Orama', 'Órama')
    .toUpperCase();
};

exports.formataListaOrdenacao = (stringCorrigidaAlteracao) => {
  let stringCorrigida = stringCorrigidaAlteracao.toString().replace('.0', '');
  return stringCorrigida
    .replace('menor_aplicacao_inicial', 'Aplicação Inicial')
    .replace('maior_retorno_mes', 'Maior Retorno no Mês')
    .replace('menor_retorno_mes', 'Menor Retorno no Mês')
    .replace('maior_retorno_ano', 'Maior Retorno no Ano')
    .replace('menor_retorno_ano', 'Menor Retorno no Ano')
    .replace('maior_retorno_12mes', 'Maior Retorno em 12 Meses')
    .replace('menor_retorno_12mes', 'Menor Retorno em 12 Meses')
    .replace('maior_cotistas', 'Maior Entrada de Investidores no Mês')
    .replace('menor_cotistas', 'Maior Saída de Investidores no Mês')
    .replace('maior_preco', 'Maior Preço')
    .replace('menor_preco', 'Menor Preço')
    .replace('maior_dividendo', 'Maior Dividendo')
    .replace('menor_dividendo', 'Menor Dividendo')
    .toUpperCase();
};

exports.setRegistroMovimento = (item) => {
  let registroMovimento = '',
    tipoRegistroMovimento = '';

  let arrayDespesa = [
    'agua',
    'assinatura',
    'beleza',
    'besteira',
    'carro',
    'casa',
    'celular',
    'combustivel',
    'condominio',
    'educacao',
    'eletrodomestico',
    'energia',
    'farmacia',
    'ferias',
    'impostos',
    'internet',
    'lazer',
    'presente',
    'saude',
    'telefone',
    'transporte',
    'vestuario',
    'viagem',
    'aluguel',
    'financiamento',
    'emprestimo',
  ];

  let arrayReceita = [
    'aluguel-r',
    'salario',
    '13salario',
    'vale-refeicao',
    'vale-alimentacao',
    'comissao',
    'plr',
    'bonus',
    'trabalho_temp',
  ];

  let arrayDespesaFixa = [
    'agua',
    'animal',
    'assinatura',
    'cartao-credito',
    'celular',
    'condominio',
    'educacao',
    'energia',
    'farmacia',
    'ferias',
    'impostos',
    'internet',
    'telefone',
    'aluguel',
    'financiamento',
  ];

  let arrayDespesaVariavel = [
    'casa',
    'carro',
    'combustivel',
    'eletrodomestico',
    'beleza',
    'besteira',
    'farmacia',
    'ferias',
    'impostos',
    'lazer',
    'presente',
    'saude',
  ];

  let arrayReceitaFixa = [
    'aluguel-r',
    'salario',
    '13salario',
    'vale-refeicao',
    'vale-alimentacao',
  ];

  let arrayReceitaVariavel = ['comissao', 'plr', 'bonus', 'trabalho_temp'];

  if (arrayDespesa.includes(item)) {
    registroMovimento = 'D';
  } else if (arrayReceita.includes(item)) {
    registroMovimento = 'R';
  } else if (item === 'investimento') {
    registroMovimento = 'I';
    tipoRegistroMovimento = 'I';
  }

  if (arrayDespesaFixa.includes(item)) {
    tipoRegistroMovimento = 'DF';
  } else if (arrayDespesaVariavel.includes(item)) {
    tipoRegistroMovimento = 'DV';
  } else if (arrayReceitaFixa.includes(item)) {
    tipoRegistroMovimento = 'RF';
  } else if (arrayReceitaVariavel.includes(item)) {
    tipoRegistroMovimento = 'RV';
  }

  return { registroMovimento, tipoRegistroMovimento };
};

exports.deParaRegistrosMovimento = (item) => {
  let retorno = '';
  switch (item) {
    case 'investimento':
      retorno = 'Investimento';
      break;
    case 'agua':
      retorno = 'Agua';
      break;
    case 'assinatura':
      retorno = 'Assinatura';
      break;
    case 'beleza':
      retorno = 'Beleza';
      break;
    case 'besteira':
      retorno = 'Besteira';
      break;
    case 'carro':
      retorno = 'Carro';
      break;
    case 'casa':
      retorno = 'Casa';
      break;
    case 'celular':
      retorno = 'Celular';
      break;
    case 'combustivel':
      retorno = 'Combustível';
      break;
    case 'condominio':
      retorno = 'Condomínio';
      break;
    case 'educacao':
      retorno = 'Educação';
      break;
    case 'eletrodomestico':
      retorno = 'Eletrodoméstico';
      break;
    case 'energia':
      retorno = 'Energia Elétrica';
      break;
    case 'farmacia':
      retorno = 'Farmácia';
      break;
    case 'ferias':
      retorno = 'Férias';
      break;
    case 'impostos':
      retorno = 'Impostos';
      break;
    case 'internet':
      retorno = 'Internet';
      break;
    case 'lazer':
      retorno = 'Lazer';
      break;
    case 'presente':
      retorno = 'Presente';
      break;
    case 'saude':
      retorno = 'Saúde';
      break;
    case 'telefone':
      retorno = 'Telefone';
      break;
    case 'transporte':
      retorno = 'Transporte';
      break;
    case 'vestuario':
      retorno = 'Vestuario';
      break;
    case 'viagem':
      retorno = 'Viagem';
      break;
    case 'aluguel':
      retorno = 'Aluguel';
      break;
    case 'financiamento':
      retorno = 'Financiamento';
      break;
    case 'emprestimo':
      retorno = 'Empréstimo';
      break;
    case 'aluguel-r':
      retorno = 'Aluguel';
      break;
    case 'salario':
      retorno = 'Salário';
      break;
    case '13salario':
      retorno = 'Salário 13º';
      break;
    case 'vale-refeicao':
      retorno = 'Vale-Refeição';
      break;
    case 'vale-alimentacao':
      retorno = 'Vale-Alimentação';
      break;
    case 'comissao':
      retorno = 'Comissão';
      break;
    case 'plr':
      retorno = 'PLR';
      break;
    case 'bonus':
      retorno = 'Bonus';
      break;
    case 'trabalho_temp':
      retorno = 'Trabalho Temporário';
      break;
    default:
      retorno = 'Despesa';
  }
  return retorno;
};

exports.deParaRegistro = (item) => {
  let retorno = '';
  switch (item) {
    case 'D':
      retorno = 'Despesa';
      break;
    case 'R':
      retorno = 'Receita';
      break;
    case 'I':
      retorno = 'Investimento';
      break;
    default:
      retorno = 'Despesa';
  }

  return retorno;
};
exports.deParaTipoRegistro = (item) => {
  let retorno = '';
  switch (item) {
    case 'DF':
      retorno = 'Despesa Fixa';
      break;
    case 'DV':
      retorno = 'Despesa Variável';
      break;
    case 'RF':
      retorno = 'Receita Fixa';
      break;
    case 'RV':
      retorno = 'Receita Variável';
      break;
    case 'I':
      retorno = 'Investimento';
      break;
    default:
      retorno = 'Despesa';
  }

  return retorno;
};

exports.deParaMes = (mes) => {
  let retorno = '';
  mes = parseInt(mes);
  switch (mes) {
    case 1:
      retorno = 'Janeiro';
      break;
    case 2:
      retorno = 'Fevereiro';
      break;
    case 3:
      retorno = 'Março';
      break;
    case 4:
      retorno = 'Abril';
      break;
    case 5:
      retorno = 'Maio';
      break;
    case 6:
      retorno = 'Junho';
      break;
    case 7:
      retorno = 'Julho';
      break;
    case 8:
      retorno = 'Agosto';
      break;
    case 9:
      retorno = 'Setembro';
      break;
    case 10:
      retorno = 'Outubro';
      break;
    case 11:
      retorno = 'Novembro';
      break;
    case 12:
      retorno = 'Dezembro';
      break;
    default:
      retorno = 'Junho';
  }

  return retorno;
};

exports.reduceMonthName = (monthName) => {
  var monthReduced = monthName.toString().slice(0, 3);
  var year = monthName.split('/')[1];

  return monthReduced + '/' + year;
};

exports.naoUsaDynamoRV = () => {
  var d = new Date();
  var hora = d.getHours();
  return true;
  if (hora === 17 || hora === 18 || hora === 19) {
    // console.log('nao usa');
    return true;
  } else {
    // console.log('usa');
    return false;
  }
};

exports.mostraBanner = () => {
  return false;
  const urls = [
    'comparador',
    'fundo',
    'fii',
    'acoes',
    'etf',
    'bdr',
    'cripto',
    'fechamento',
  ];

  if (
    window.location.href.includes(urls[0]) ||
    window.location.href.includes(urls[1]) ||
    window.location.href.includes(urls[2]) ||
    window.location.href.includes(urls[3]) ||
    window.location.href.includes(urls[4]) ||
    window.location.href.includes(urls[5]) ||
    window.location.href.includes(urls[6]) ||
    window.location.href.includes(urls[7])
  ) {
    return false;
  } else {
    return true;
  }
};
