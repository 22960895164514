import React from 'react';
import stylesLista from '../../Components/Tags/Lista.module.css';
import { Alert } from 'react-bootstrap';

const PrecisaVer = (props) => {
  const [link1, setLink1] = React.useState(null);
  const [link2, setLink2] = React.useState(null);
  const [link3, setLink3] = React.useState(null);
  const [link4, setLink4] = React.useState(null);

  const [texto1, setTexto1] = React.useState(null);
  const [texto2, setTexto2] = React.useState(null);
  const [texto3, setTexto3] = React.useState(null);
  const [texto4, setTexto4] = React.useState(null);

  React.useEffect(() => {
    if (
      props.pagina === 'emprestimo' ||
      props.pagina === 'cdi' ||
      props.pagina === 'cartao'
    ) {
      setLink1('/como-juntar/5000');
      setLink2('/como-organizar-salario/0/reais');
      setLink3('/calculadora-renda-fixa');
      setLink4('/melhores-fundos-imobiliarios');
      setTexto1('Confira Como Juntar R$5000,00.');
      setTexto2('Como Organizar Seu Salário Corretamente.');
      setTexto3('Confira os Melhores Investimentos de Renda Fixa Atualmente.');
      setTexto4('Confira os Fundos Imobiliários que mais renderam este mês.');
    } else if (props.pagina === 'salario') {
      setLink1('/como-juntar/5000');
      setLink2('/calculadora-renda-fixa');
      setLink3('/melhores-fundos-imobiliarios');
      setTexto1('Confira Como Juntar R$5000,00.');
      setTexto2('Confira os Melhores Investimentos de Renda Fixa Atualmente.');
      setTexto3('Confira os Fundos Imobiliários que mais renderam este mês.');
    } else if (props.pagina === 'dica') {
      setLink1('/melhores-fundos-imobiliarios');
      setLink2('/melhores-acoes');
      setLink3('/melhores-etf');
      setLink4('/melhores-fundos-de-investimento');
      setTexto1('Confira os Fundos Imobiliários que mais renderam este mês.');
      setTexto2('Confira as Ações que mais renderam este mês.');
      setTexto3('Confira os ETFs que mais renderam este mês.');
      setTexto4(
        'Confira os Fundos de Investimentos que mais renderam este mês.',
      );
    }
  }, []);

  return (
    <Alert variant="warning">
      <div className="related-content" style={{ margin: '20px' }}>
        <div
          className="related-title"
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
          </svg>{' '}
          Você precisa ver
        </div>
        <ul className={stylesLista.checkList}>
          {link1 && (
            <li>
              <a
                style={{
                  color: '#1A7058',
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                }}
                href={link1}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'precisa_ver');
                  }
                }}
              >
                {texto1}
              </a>
            </li>
          )}
          {link2 && (
            <li>
              <a
                style={{
                  color: '#1A7058',
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                }}
                href={link2}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'precisa_ver');
                  }
                }}
              >
                {texto2}
              </a>
            </li>
          )}
          {link3 && (
            <li>
              <a
                style={{
                  color: '#1A7058',
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                }}
                href={link3}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'precisa_ver');
                  }
                }}
              >
                {texto3}
              </a>
            </li>
          )}
          {link4 && (
            <li>
              <a
                style={{
                  color: '#1A7058',
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                }}
                href={link4}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'precisa_ver');
                  }
                }}
              >
                {texto4}
              </a>
            </li>
          )}
        </ul>
      </div>
    </Alert>
  );
};

export default PrecisaVer;
