import React from 'react';
import thumbplanner from '../../Assets/cfi/planner/thumbnail-planner-360-300x250.webp';
import { Image } from 'react-bootstrap';

const AdsenseContent3 = (props) => {
  const defaultKeywords =
    'investimentos para iniciantes, planejamento financeiro';

  const keywords =
    props.keywords && props.keywords.length > 0
      ? props.keywords[0] + ', ' + props.keywords[1]
      : defaultKeywords;

  const [isUnfilled, setIsUnfilled] = React.useState(false);

  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    // Verifica o status do anúncio depois de carregado
    const checkAdStatus = () => {
      const adElement = document.querySelector('ins.adsbygoogle');
      if (
        adElement &&
        adElement.getAttribute('data-ad-status') === 'unfilled'
      ) {
        setIsUnfilled(true);
      } else {
        setIsUnfilled(false);
      }
    };

    // Aguarda o anúncio carregar
    setTimeout(checkAdStatus, 5000); // Espera 1 segundo para verificar
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'inline-block',
            width: '300px',
            height: '250px',
            // border: '1px solid #00bfff',
          }}
          data-ad-client="ca-pub-4615548473778420"
          data-ad-slot="8420941451"
        ></ins>
      </div>
      {isUnfilled && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '300px',
              height: '250px',
              // border: '1px solid black',
            }}
          >
            <React.Suspense fallback={<div>...</div>}>
              <a href="/lp/guardar-dinheiro-planner-360">
                <Image
                  src={thumbplanner}
                  style={{ cursor: 'pointer' }}
                  width={'100%'}
                  alt="guardar dinheiro planner"
                />
              </a>
            </React.Suspense>
          </div>
        </div>
      )}
    </>
  );
};
export default AdsenseContent3;
