import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import Error from '../Helper/Error';
import InputMask from '../Forms/InputMask';
import { formataMoeda } from '../Helper/DateUtilities';

function ModalOrcamento(props) {
  const inputElement = React.useRef();
  const [idCategoria, setIdCategoria] = React.useState(null);
  const [nomeCategoria, setNomeCategoria] = React.useState(null);
  const [anomes, setAnomes] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    // console.log(props);
    if (props.idCategoria) {
      setIdCategoria(props.idCategoria);
    }
    if (props.nomeCategoria) {
      setNomeCategoria(props.nomeCategoria);
    }
    if (props.anomes) {
      setAnomes(props.anomes);
    }
    if (props.valor) {
      setValor(formataMoeda(props.valor));
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(http_method, id_categoria, ano_mes, valor) {
    let user_id = window.localStorage.getItem('gd_uid');

    const urlPostPortfolio = `https://gnz8sbpu9e.execute-api.sa-east-1.amazonaws.com/producao/orcamento/?user_id=${user_id}&id_categoria=${id_categoria}&anomes=${ano_mes}&valor=${valor}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addOrcamento(event) {
    event.preventDefault();
    setLoading(true);

    postItem(
      'INSERT',
      props.idCategoria,
      props.anomes,
      valor.replace('R$', '').replaceAll('.', '').replace(',', '.'),
    );
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="lg"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {'Orçamento ' + props.anomes}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-nome-categoria'}
              label={'Categoria'}
              type="text"
              value={nomeCategoria}
              ref={inputElement}
              disable={true}
            ></Input>
          </div>

          <div className={stylesModal.itemContainer}>
            {props.isEssencial ? (
              <Input
                name={'input-valor'}
                label="% Divisão"
                type="number"
                value={valor}
                ref={inputElement}
                disable={false}
                onChange={({ target }) => {
                  setValor(target.value);
                }}
              ></Input>
            ) : (
              <InputMask
                name={'input-valor'}
                label="Valor Orçamento"
                placeholder="R$"
                value={valor}
                ref={inputElement}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'R$'}
                onChange={({ target }) => {
                  setValor(target.value.replace('R$', '').replace('.', ''));
                }}
              ></InputMask>
            )}
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button
            className={stylesButton.button}
            onClick={(e) => addOrcamento(e)}
          >
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOrcamento;
