import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_DIVIDAS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import DividasList from '../../Components/ControleFinanceiro/DividasList';
import ModalDividas from '../../Components/ControleFinanceiro/ModalDividas';
import BoxDividas from '../../Components/ControleFinanceiro/BoxDividas';
import LookNumbers from '../../Components/Helper/LookNumbers';
import { NumberContext } from '../../Contexts/NumberContext';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import BoxSaldoDividas from '../../Components/ControleFinanceiro/BoxSaldoDividas';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Dividas = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);
  const { hide } = React.useContext(NumberContext);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_DIVIDAS(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  function retornaDividasLista() {
    if (data) {
      let dados = data
        .sort((a, b) => a.descricao - b.descricao)
        .filter((dados) => dados.registro === '0');

      let dadosAbatimento = data
        .sort((a, b) => a.data_abatimento - b.data_abatimento)
        .filter((dados) => dados.registro === '1');

      return (
        <DividasList
          data={dados}
          dataAbatimento={dadosAbatimento}
          hide={hide}
        />
      );
    } else {
      return <Loading />;
    }
  }

  return (
    <>
      <Head
        metaDescription="Dívidas | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Dívidas | Guardar Dinheiro PRO"
        h1="Dívidas | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />
      <BreadcumbH1
        paginaAtual=":: CONTROLE DE DÍVIDAS"
        texto=":: CONTROLE DE DÍVIDAS"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: CONTROLE DE DÍVIDAS</h1>
        <LookNumbers />

        {data && <BoxSaldoDividas data={data} hide={hide} />}
        <br />
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {retornaDividasLista()}

        <br />
        <hr />

        {/* {data && <BoxDividas data={data} hide={hide} />} */}

        {show && (
          <ModalDividas
            onClose={() => setShow(false)}
            show={show}
            acao={'INSERT'}
          />
        )}
      </Container>
    </>
  );
};

export default Dividas;
