import React from 'react';
import styles from './InputPortfolio.module.css';
import jsonPortfolio from './portfolio.json';

const InputPortfolio2 = (props) => {
  const [text, setText] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [data, setData] = React.useState('');

  React.useEffect(() => {
    if (props.funcionalidade === 'favoritos') {
      setData(jsonPortfolio);
    } else if (props.funcionalidade === 'etf') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'ETF'));
    } else if (props.funcionalidade === 'fundos') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'FUNDOS'));
    } else if (props.funcionalidade === 'fii') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'FII'));
    } else if (props.funcionalidade === 'cripto') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'CRIPTO'));
    } else if (props.funcionalidade === 'bdr') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'BDR'));
    } else if (props.funcionalidade === 'acoes') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'ACAO'));
    } else if (props.funcionalidade === 'stocks') {
      setData(jsonPortfolio.filter((registro) => registro.tipo === 'STOCKS'));
    }

    // console.log(data);
  }, []);

  function onSuggestHandler(text) {
    setText(
      text.tipo.toUpperCase() +
        '  -  ' +
        text.nome.toUpperCase() +
        '  -  ' +
        text.chave.toUpperCase(),
    );

    setSuggestions([]);
    produtoSelecionado();
  }

  function onChangeHandler(text) {
    var qtdeCaracteres = 3;
    if (props.funcionalidade === 'stocks') {
      qtdeCaracteres = 4;
    } else if (
      props.funcionalidade === 'favoritos' ||
      props.funcionalidade === 'fundos'
    ) {
      qtdeCaracteres = 5;
    }
    let matches = [];
    if (text.length >= qtdeCaracteres) {
      matches = data.filter((registro) => {
        var ticker = '';
        var nome = '';
        ticker = registro.chave;
        nome = registro.nome;
        const regex = new RegExp(`${text}`, 'gi');

        return (nome + '_' + ticker).match(regex);
      });
    }
    // console.log('matches', matches);
    setSuggestions(matches);
    setText(text);
  }

  function retornaListaBonita(segguestion) {
    if (props.isSearchHeader) {
      return retornaListaBonitaFavoritosLink(segguestion);
    } else if (props.isSimuladorDividendos) {
      return retornaListaBonitaFavoritosLinkDividendos(segguestion);
    } else {
      return retornaListaBonitaFavoritos(segguestion);
    }
  }

  function retornaListaBonitaFavoritos(suggestion) {
    if (suggestion.tipo === 'FUNDOS' || suggestion.tipo === 'CRIPTO') {
      return (
        <div style={{ margin: '1px' }}>
          <span className={styles.spanTipo}>{suggestion.tipo}</span>
          <div style={{ display: 'grid', marginLeft: '10px' }}>
            <span className={styles.spanNome}>
              {suggestion.nome.toUpperCase()}
            </span>
            <span className={styles.spanDetalhe}>
              {suggestion.chave.toUpperCase()}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ margin: '1px' }}>
            <span className={styles.spanTipo}>{suggestion.tipo}</span>
            <div style={{ display: 'grid', marginLeft: '10px' }}>
              <span className={styles.spanNome}>
                {suggestion.chave.toUpperCase()}
              </span>
              <span className={styles.spanDetalhe}>
                {suggestion.nome.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  function eventoPesquisaSite(tipo) {
    window.gtag('event', 'pesquisa_no_site');
    window.gtag('event', 'pesquisa_no_site_' + tipo.toLowerCase());
  }

  function retornaListaBonitaFavoritosLink(suggestion) {
    if (suggestion.tipo === 'FUNDOS') {
      let cnpjFormatado = suggestion.chave
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '');
      let nomeFormatado = suggestion.nome.replaceAll(' ', '-');
      return (
        <a
          href={'/fundo/' + cnpjFormatado + '/' + nomeFormatado.toLowerCase()}
          onClick={eventoPesquisaSite(suggestion.tipo)}
        >
          <div style={{ margin: '1px' }}>
            <span className={styles.spanTipo}>{suggestion.tipo}</span>
            <div style={{ display: 'grid', marginLeft: '10px' }}>
              <span className={styles.spanNome}>
                {suggestion.nome.toUpperCase()}
              </span>
              <span className={styles.spanDetalhe}>
                {'CNPJ: ' + suggestion.chave.toUpperCase()}
              </span>
            </div>
          </div>
        </a>
      );
    } else {
      let url = '';
      if (suggestion.tipo === 'ACAO') {
        url = '/acoes/' + suggestion.chave.toLowerCase();
      } else if (suggestion.tipo === 'FII') {
        url = '/fii/' + suggestion.chave.toLowerCase();
      } else if (suggestion.tipo === 'ETF') {
        url = '/etf/' + suggestion.chave.toLowerCase();
      } else if (suggestion.tipo === 'CRIPTO') {
        url = '/cripto/' + suggestion.chave.toLowerCase();
      } else if (suggestion.tipo === 'BDR') {
        url = '/bdr/' + suggestion.chave.toLowerCase();
      } else if (suggestion.tipo === 'STOCKS') {
        url = '/stocks/' + suggestion.chave.toLowerCase();
      }
      return (
        <a href={url} onClick={eventoPesquisaSite(suggestion.tipo)}>
          <div style={{ margin: '1px' }}>
            <span className={styles.spanTipo}>{suggestion.tipo}</span>
            <div style={{ display: 'grid', marginLeft: '10px' }}>
              <span className={styles.spanNome}>
                {suggestion.chave.toUpperCase()}
              </span>
              <span className={styles.spanDetalhe}>
                {suggestion.nome.toUpperCase()}
              </span>
            </div>
          </div>
        </a>
      );
    }
  }

  function retornaListaBonitaFavoritosLinkDividendos(suggestion) {
    let url = '';
    if (suggestion.tipo === 'ACAO') {
      url =
        '/acoes/' + suggestion.chave.toLowerCase() + '/simulacao/dividendos';
    } else if (suggestion.tipo === 'FII') {
      url = '/fii/' + suggestion.chave.toLowerCase() + '/simulacao/dividendos';
    }

    return (
      <a href={url} onClick={eventoPesquisaSite(suggestion.tipo)}>
        <div style={{ margin: '1px' }}>
          <span className={styles.spanTipo}>{suggestion.tipo}</span>
          <div style={{ display: 'grid', marginLeft: '10px' }}>
            <span className={styles.spanNome}>
              {suggestion.chave.toUpperCase()}
            </span>
            <span className={styles.spanDetalhe}>
              {suggestion.nome.toUpperCase()}
            </span>
          </div>
        </div>
      </a>
    );
  }

  function onFocusInput() {
    document.getElementById(props.id).value = '';
  }

  function produtoSelecionado() {
    let a = document.getElementById('topo' + props.id);

    const y = a.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 120,
      behavior: 'smooth',
    });
  }

  return (
    <div>
      <span id={'topo' + props.id}></span>
      <input
        // id="inputPortfolio"
        id={props.id}
        type="text"
        className={styles.input}
        placeholder={
          props.placeholder
            ? '🔎 ' + props.placeholder
            : 'INFORME O NOME DO ATIVO'
        }
        onChange={(e) => onChangeHandler(e.target.value)}
        onFocus={() => onFocusInput()}
        value={text}
      ></input>
      {suggestions &&
        suggestions.map((suggestion, i) => (
          <div
            key={i}
            className={styles.suggestion}
            onClick={() => onSuggestHandler(suggestion)}
          >
            {retornaListaBonita(suggestion)}
          </div>
        ))}
    </div>
  );
};

export default InputPortfolio2;
