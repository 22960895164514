import React from 'react';
import { Table } from 'react-bootstrap';
// import styles from '../../Pages/ControleFinanceiro/css/TabelaMovimento.module.css';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import {
  formataData,
  formataMoeda,
  formataMoedaCF,
} from '../Helper/DateUtilities';
import isMobile from '../Helper/Mobile';

import DividasAbatimentoList from './DividasAbatimentoList';
import ModalDividas from './ModalDividas';
import PatrimonioAtualizacaoList from './PatrimonioAtualizacaoList';
import ModalPatrimonio from './ModalPatrimonio';

const PatrimonioList = ({ data, dadosAtualizacao, hide }) => {
  const mobile = isMobile();
  const [show, setShow] = React.useState(false);
  const [idPatrimonio, setIdPatrimonio] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [dataInicio, setDataInicio] = React.useState(null);
  const [valorInicial, setValorInicial] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  const [tipoInvestimento, setTipoInvestimento] = React.useState(null);
  const [instituicao, setInstituicao] = React.useState(null);
  const [prazo, setPrazo] = React.useState(null);
  const [acao, setAcao] = React.useState(null);
  const [idMeta, setIdMeta] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const columns = React.useMemo(
    () => [
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Inicio', accessor: 'data_inicial' },
      { Header: 'Valor Inicial', accessor: 'valor_inicial' },
      { Header: 'Valor Atual', accessor: 'valor_atual' },
      { Header: 'Tipo', accessor: 'tipo' },
      { Header: 'Tipo Investimento', accessor: 'tipo_inv' },
      { Header: 'Instituição', accessor: 'instituicao' },
      { Header: 'Ult. Atualização', accessor: 'atualizacao' },
      { Header: 'Meta', accessor: 'meta' },
      { Header: 'id_meta', accessor: 'id_meta' },
      { Header: 'id', accessor: 'id' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: ['id'],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  function editDivida(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        setIdPatrimonio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Inicio') {
        setDataInicio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor Inicial') {
        setValorInicial(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo') {
        setTipo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo Investimento') {
        setTipoInvestimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Prazo') {
        setPrazo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Instituição') {
        setInstituicao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_meta') {
        setIdMeta(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removePatrimonio(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        removeItem(row.allCells[i].value);
      }
    }
  }

  function getDataAtualizacaoPatrimonio(idPatrimonio) {
    let atualizacaoData = dadosAtualizacao
      .sort(
        (a, b) =>
          parseFloat(a.atualizacao.toString().replaceAll('-', '')) -
          parseFloat(b.atualizacao.toString().replaceAll('-', '')),
      )
      .filter((dados) => dados.id_patrimonio === idPatrimonio);
    return atualizacaoData;
  }

  function removeItem(id_patrimonio) {
    let user_id = window.localStorage.getItem('gd_uid');

    const urlPostPortfolio = `https://jtjvccyz55.execute-api.sa-east-1.amazonaws.com/producao/patrimonio?user_id=${user_id}&id=${id_patrimonio}&metodo=DELETE-mae`;
    console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          setLoading(false);
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function getPatrimonioPorTipo(tipo) {
    return (
      <>
        <div className={stylesDiv.divSombra}>
          <div className="search-container">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder={'🔎 PESQUISA '}
            />
          </div>
          <Table
            responsive
            bordered
            hover
            size="sm"
            className={styles.tabela}
            {...getTableProps()}
          >
            <thead>
              <tr>
                <th>Ativo</th>
                {tipo === 'Ativos Financeiros' && <th>Instituição</th>}
                <th>(R$) Inicial</th>
                <th>(R$) Atual</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <>
                    {row.cells[4].value === tipo && (
                      <tr style={{ border: 'none' }}>
                        <td>
                          <div style={{ display: 'block' }}>
                            <div>
                              <span className={styles.spanDescricao}>
                                {row.cells[0].value}
                              </span>
                            </div>
                            <div>
                              <span className={styles.spanDetalhes}>
                                {row.cells[5].value}
                              </span>
                            </div>
                            {row.cells[9].value.length > 2 && (
                              <div>
                                <span className={styles.spanDetalhes}>
                                  {'Meta: ' + row.cells[8].value}
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        {tipo === 'Ativos Financeiros' && (
                          <td>{row.cells[6].value}</td>
                        )}

                        <td>{formataMoedaCF(row.cells[2].value, hide)}</td>
                        <td>{formataMoedaCF(row.cells[3].value, hide)}</td>
                        <td>
                          <div>
                            <button
                              className={styles.btnEdit}
                              onClick={(e) => {
                                setAcao('UPDATE-mae');
                                editDivida(e, row);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </button>

                            <button
                              className={styles.btnEdit}
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    'ATENÇÃO: TEM CERTEZA QUE DESEJA EXCLUIR O ATIVO (' +
                                      row.cells[0].value.toUpperCase() +
                                      ') NO VALOR DE ' +
                                      formataMoeda(row.cells[2].value) +
                                      '? ISSO EXCLUIRÁ TODAS AS ATUALIZAÇÕES DO PATRIMÔNIO!!!',
                                  )
                                ) {
                                  removePatrimonio(e, row);
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}

                    {row.cells[4].value === tipo && (
                      <tr
                        style={{
                          border: 'none',
                          borderBottom: '1px solid #435d7d',
                        }}
                      >
                        <td colSpan={5}>
                          <details>
                            <summary>
                              <span className={styles.spanDescricao}>
                                {'Atualizações ' + row.cells[0].value}
                              </span>
                            </summary>
                            <PatrimonioAtualizacaoList
                              data={getDataAtualizacaoPatrimonio(
                                row.allCells[10].value,
                              )}
                              hide={hide}
                            />
                          </details>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <>
      <details className={styles.details} open>
        <summary>Ativos Financeiros</summary>
        {getPatrimonioPorTipo('Ativos Financeiros')}
      </details>
      <hr />
      <details className={styles.details} open>
        <summary>Ativos Imobilizados</summary>
        {getPatrimonioPorTipo('Ativos Imobilizados')}
      </details>
      {show && (
        <ModalPatrimonio
          onClose={() => setShow(false)}
          show={show}
          acao={acao}
          idPatrimonio={idPatrimonio}
          descricao={descricao}
          dataInicio={dataInicio}
          valorInicial={valorInicial}
          instituicao={instituicao}
          tipoInvestimento={tipoInvestimento}
          prazo={prazo}
          idMeta={idMeta}
          tipo={tipo}
        />
      )}
    </>
  );
};

export default PatrimonioList;
