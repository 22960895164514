import React, { useState, useEffect } from 'react';

import { Container, Image } from 'react-bootstrap';
import styles from './LpPagBank.module.css';
import Head from '../../Components/Tags/Head';
import Button from '../../Components/Forms/Button';
import fgPagSeguro from '../../Assets/maquina/lp/logo-pagseguro.webp';
import fgVisa from '../../Assets/maquina/lp/band/visa.png';
import fgMaster from '../../Assets/maquina/lp/band/mastercard.png';
import fgElo from '../../Assets/maquina/lp/band/elo.png';
import fgHipercard from '../../Assets/maquina/lp/band/hipercard.png';
import fgHiper from '../../Assets/maquina/lp/band/hiper.png';
import fgCabal from '../../Assets/maquina/lp/band/cabal.png';
import fgAlelo from '../../Assets/maquina/lp/band/alelo.png';
import fgSodexo from '../../Assets/maquina/lp/band/sodexo.png';
import fgTicket from '../../Assets/maquina/lp/band/ticket.png';
import fgVr from '../../Assets/maquina/lp/band/vr.png';
import fgAmex from '../../Assets/maquina/lp/band/amex.png';
import fgDiners from '../../Assets/maquina/lp/band/dinersclub.png';
import fgBanri from '../../Assets/maquina/lp/band/banri.png';
import fgQrCode from '../../Assets/maquina/lp/band/qrcode.png';
import fgBen from '../../Assets/maquina/lp/band/ben.png';
import fgPix from '../../Assets/maquina/lp/band/pix.png';
import fgUpBrasil from '../../Assets/maquina/lp/band/upbrasil.png';
import fgPersonalCard from '../../Assets/maquina/lp/band/personalcard.png';
import fgMinizinhaNFC2 from '../../Assets/maquina/lp/band/minizinha-nfc2.webp';
import fgMinizinhaChip3 from '../../Assets/maquina/lp/band/minizinha-chip3.webp';
import fgModerninhaPro2 from '../../Assets/maquina/lp/band/moderninha-pro2.webp';
import fgModerninhaPlus2 from '../../Assets/maquina/lp/band/moderninha-plus-2.webp';
import fgModerninhaSmart2 from '../../Assets/maquina/lp/band/moderninha-smart2.webp';
import fgLinkSeguro from '../../Assets/maquina/lp/band/link-seguro.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faShoppingCart,
  faTruckMoving,
} from '@fortawesome/free-solid-svg-icons';
import Whatsapp from '../../Components/Helper/Whatsapp';
import PopUpMaquinasVendidas from '../../Components/PopUps/PopUpMaquinasVendidas';
import SSRProvider from 'react-bootstrap/SSRProvider';

const LpPagBank = () => {
  const sampleData = [];

  const [showComponent, setShowComponent] = useState(false);
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function retornaVantagens() {
    return (
      <div name="vantagens" className={styles.parent}>
        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>Taxa</p>
              </div>
              <div>
                <span>Zero 0%</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>Dinheiro</p>
              </div>
              <div>
                <span>Na hora</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>Aluguel</p>
              </div>
              <div>
                <span>Gratuito</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>Entrega</p>
              </div>
              <div>
                <span>Grátis</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>Acompanha Cartão</p>
              </div>
              <div>
                <span>Gratuito</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.child}>
          <div className={styles.divChild}>
            <div style={{ display: 'block' }}>
              <div>
                <p className={styles.p}>5 Anos de</p>
              </div>
              <div>
                <span>Garantia</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function retoenaBandeirasCredito() {
    return (
      <div style={{ textAlign: 'center' }}>
        <span className={styles.spanBandeira}>
          Aceite todas as bandeiras de Débito, Crédito e Refeição, Pix, QR Code
          e Carteiras Digitais
        </span>
        <br />
        <br />
        <div className={styles.divBandeira}>
          <div className={styles.parentBandeira}>
            <div className={styles.childBandeira}>
              <Image src={fgVisa} alt={'Visa'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgMaster} alt={'Mastercard'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgPix} alt={'Pix'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgElo} alt={'Hipercard'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgHipercard} alt={'Hipercard'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgHiper} alt={'Hiper'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgCabal} alt={'Cabal'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgAmex} alt={'American Express'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgDiners} alt={"Diner's Club"} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgBanri} alt={'Banri Compras'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgPersonalCard} alt={'Personal Card'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgUpBrasil} alt={'Up Brasil'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgAlelo} alt={'Alelo'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgSodexo} alt={'Sodexo'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgTicket} alt={'Ticket'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgVr} alt={'VR'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgBen} alt={'Ben Visa Vale'} fluid />
            </div>
            <div className={styles.childBandeira}>
              <Image src={fgQrCode} alt={'QR Code'} fluid />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function returnaMaquinas() {
    return (
      <div>
        <div className={styles.parentMaquina}>
          <div
            className={styles.childMaquina}
            onClick={() =>
              window.open(
                'https://click.afiliados.uol.com.br/Clique?idtUrl=380370&cpg=MzgwMzcw&idtLabel=134241&source=15&type=link&creative=TWluaXppbmhhIE5GQzIgLSBDaGVja291dA==&affRedir=https%3A%2F%2Fcarrinho.pagseguro.uol.com.br%2F%3F_env%3Dphoenix%26_flow%3DR%26er%3D5a59efd11053578d%26ori%3DPS%26aff_source%3Dd70ef8bdf38041e99ca2fe40a0a042aa',
                '_blank',
              )
            }
          >
            <div className={styles.divMaquina}>
              <div className={styles.divImageMaquina}>
                <Image
                  src={fgMinizinhaNFC2}
                  alt={'Minizinha NFC 2'}
                  fluid
                  className={styles.imgMaquina}
                />
                <div className={styles.divDesconto}>
                  <div>
                    <span className={styles.desconto}>81%</span>
                  </div>
                  <span className={styles.desconto}>OFF</span>
                </div>
              </div>
              <div>
                <span className={styles.nome}>
                  Minizinha NFC <strong>2</strong>
                </span>
              </div>
              <div>
                <span className={styles.spanPrecoDe}>De 12x R$9,90</span>
              </div>
              <div>
                <span className={styles.spanPrecoPor}>12x R$1,90</span>
              </div>
              <div>
                <Button className={styles.btn}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {'  Comprar Com Desconto'}
                </Button>
              </div>
              <div>
                <Image
                  src={fgLinkSeguro}
                  alt={'Link Seguro'}
                  fluid
                  className={styles.imgCadeado}
                />
                <span className={styles.spanCadeado}>Link Seguro</span>
              </div>
              <div>
                <ul className={styles.ul}>
                  <li>Agora com tela colorida</li>
                  <li>Agora com tecnlogia NFC</li>
                  <li>Precisa de celular</li>
                  <li>Conexão por bluetooth</li>
                  <li>Sem aluguel nem taxa de adesão</li>
                  <li>Recebe o dinheiro das vendas NA HORA!</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={styles.childMaquina}
            onClick={() =>
              window.open(
                'https://click.afiliados.uol.com.br/Clique?idtUrl=380370&cpg=MzgwMzcw&idtLabel=134241&source=15&type=link&creative=TWluaXppbmhhIENoaXAgMyAtIENoZWNrb3V0&affRedir=https%3A%2F%2Fcarrinho.pagseguro.uol.com.br%2F%3Fer%3Ddb5b0a0ae77905c3%26aff_source%3Dd70ef8bdf38041e99ca2fe40a0a042aa',
                '_blank',
              )
            }
          >
            <div className={styles.divMaquina}>
              <div className={styles.divEscassez}>
                <span className={styles.spanEscassez}>
                  POUCAS UNIDADES <br></br>(A mais vendida)
                </span>
              </div>
              <div className={styles.divImageMaquina}>
                <Image
                  src={fgMinizinhaChip3}
                  alt={'Minizinha Chip 3'}
                  fluid
                  className={styles.imgMaquina}
                />
                <div className={styles.divDesconto}>
                  <div>
                    <span className={styles.desconto}>72%</span>
                  </div>
                  <span className={styles.desconto}>OFF</span>
                </div>
              </div>
              <div>
                <span className={styles.nome}>
                  Minizinha Chip <strong>3</strong>
                </span>
              </div>
              <div>
                <span className={styles.spanPrecoDe}>De 12x R$24,90</span>
              </div>
              <div>
                <span className={styles.spanPrecoPor}>12x R$6,90</span>
              </div>
              <div>
                <Button className={styles.btn}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {'  Comprar Com Desconto'}
                </Button>
              </div>
              <div>
                <Image
                  src={fgLinkSeguro}
                  alt={'Link Seguro'}
                  fluid
                  className={styles.imgCadeado}
                />
                <span className={styles.spanCadeado}>Link Seguro</span>
              </div>
              <div>
                <ul className={styles.ul}>
                  <li>Agora com tela maior e NFC</li>
                  <li>Não precisa de celular</li>
                  <li>Chip e plano de dados grátis</li>
                  <li>Sem aluguel nem taxa de adesão</li>
                  <li>Recebe o dinheiro das vendas NA HORA!</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={styles.childMaquina}
            onClick={() =>
              window.open(
                'https://click.afiliados.uol.com.br/Clique?idtUrl=380370&cpg=MzgwMzcw&idtLabel=134241&source=15&type=link&creative=TW9kZXJuaW5oYSBQcm8gMiAtIENoZWNrb3V0&affRedir=https%3A%2F%2Fcarrinho.pagseguro.uol.com.br%2F%3F_env%3Dphoenix%26_flow%3DR%26er%3D5cd31781d153ba42%26ori%3DPS%26aff_source%3Dd70ef8bdf38041e99ca2fe40a0a042aa',
                '_blank',
              )
            }
          >
            <div className={styles.divMaquina}>
              <div className={styles.divEscassez}>
                <span className={styles.spanEscassez}>DESCONTO EXCLUSIVO</span>
              </div>
              <div className={styles.divImageMaquina}>
                <Image
                  src={fgModerninhaPro2}
                  alt={'Moderninha Pro 2'}
                  fluid
                  className={styles.imgMaquina}
                />
                <div className={styles.divDesconto}>
                  <div>
                    <span className={styles.desconto}>73%</span>
                  </div>
                  <span className={styles.desconto}>OFF</span>
                </div>
              </div>
              <div>
                <span className={styles.nome}>
                  Moderninha Pro <strong>2</strong>
                </span>
              </div>
              <div>
                <span className={styles.spanPrecoDe}>De 12x R$69,90</span>
              </div>
              <div>
                <span className={styles.spanPrecoPor}>12x R$18,90</span>
              </div>
              <div>
                <Button className={styles.btn}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {'  Comprar Com Desconto'}
                </Button>
              </div>
              <div>
                <Image
                  src={fgLinkSeguro}
                  alt={'Link Seguro'}
                  fluid
                  className={styles.imgCadeado}
                />
                <span className={styles.spanCadeado}>Link Seguro</span>
              </div>
              <div>
                <ul className={styles.ul}>
                  <li>Imprime comprovante</li>
                  <li>Você ganha 2% nas recargas de celular</li>
                  <li>Não precisa de celular</li>
                  <li>Chip e plano de dados grátis</li>
                  <li>Sem aluguel nem taxa de adesão</li>
                  <li>Recebe o dinheiro das vendas NA HORA!</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={styles.childMaquina}
            onClick={() =>
              window.open(
                'https://click.afiliados.uol.com.br/Clique?idtUrl=380370&cpg=MzgwMzcw&idtLabel=134241&source=15&type=link&creative=TW9kZXJuaW5oYSBQbHVzIDIgLSBDaGVja291dA==&affRedir=https%3A%2F%2Fcarrinho.pagseguro.uol.com.br%2F%3F_env%3Dphoenix%26_flow%3DR%26er%3D9918a2790911af90%26ori%3DPS%26aff_source%3Dd70ef8bdf38041e99ca2fe40a0a042aa',
                '_blank',
              )
            }
          >
            <div className={styles.divMaquina}>
              <div className={styles.divImageMaquina}>
                <Image
                  src={fgModerninhaPlus2}
                  alt={'Moderninha Plus 2'}
                  fluid
                  className={styles.imgMaquina}
                />
                <div className={styles.divDesconto}>
                  <div>
                    <span className={styles.desconto}>54%</span>
                  </div>
                  <span className={styles.desconto}>OFF</span>
                </div>
              </div>
              <div>
                <span className={styles.nome}>
                  Moderninha Plus <strong>2</strong>
                </span>
              </div>
              <div>
                <span className={styles.spanPrecoDe}>De 12x R$29,90</span>
              </div>
              <div>
                <span className={styles.spanPrecoPor}>12x R$9,90</span>
              </div>
              <div>
                <Button className={styles.btn}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {'  Comprar Com Desconto'}
                </Button>
              </div>
              <div>
                <Image
                  src={fgLinkSeguro}
                  alt={'Link Seguro'}
                  fluid
                  className={styles.imgCadeado}
                />
                <span className={styles.spanCadeado}>Link Seguro</span>
              </div>
              <div>
                <ul className={styles.ul}>
                  <li>Não precisa de celular</li>
                  <li>Você ganha 2% nas recargas de celular</li>
                  <li>Chip e plano de dados grátis</li>
                  <li>Pagamento por aproximação (NFC)</li>
                  <li>Sem aluguel nem taxa de adesão</li>
                  <li>Recebe o dinheiro das vendas NA HORA!</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={styles.childMaquina}
            onClick={() =>
              window.open(
                'https://click.afiliados.uol.com.br/Clique?idtUrl=380370&cpg=MzgwMzcw&idtLabel=134241&source=15&type=link&creative=Q2hlY2tvdXQgIE1vZGVybmluaGEgU21hcnQgMg==&affRedir=https%3A%2F%2Fcarrinho.pagseguro.uol.com.br%2F%3F_env%3Dphoenix%26_flow%3DR%26_gl%3D1%252A1pyedjg%252A_ga%252AMTU1NTIzMzgxMy4xNjMzMTE0ODk4%252A_ga_VZW8YVGM9B%252AMTY2MzA3NjY5Ny41NC4xLjE2NjMwNzc0OTcuMC4wLjA.%26er%3Dd0211debd8a96744%26ori%3DPS%26aff_source%3Dd70ef8bdf38041e99ca2fe40a0a042aa',
                '_blank',
              )
            }
          >
            <div className={styles.divMaquina}>
              <div className={styles.divImageMaquina}>
                <Image
                  src={fgModerninhaSmart2}
                  alt={'Moderninha Smart 2'}
                  fluid
                  className={styles.imgMaquina}
                />
                <div className={styles.divDesconto}>
                  <div>
                    <span className={styles.desconto}>64%</span>
                  </div>
                  <span className={styles.desconto}>OFF</span>
                </div>
              </div>
              <div>
                <span className={styles.nome}>
                  Moderninha Smart <strong>2</strong>
                </span>
              </div>
              <div>
                <span className={styles.spanPrecoDe}>De 12x R$69,90</span>
              </div>
              <div>
                <span className={styles.spanPrecoPor}>12x R$24,90</span>
              </div>
              <div>
                <Button className={styles.btn}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {'  Comprar Com Desconto'}
                </Button>
              </div>
              <div>
                <Image
                  src={fgLinkSeguro}
                  alt={'Link Seguro'}
                  fluid
                  className={styles.imgCadeado}
                />
                <span className={styles.spanCadeado}>Link Seguro</span>
              </div>
              <div>
                <ul className={styles.ul}>
                  <li>Não precisa de celular</li>
                  <li>Você ganha 2% nas recargas de celular</li>
                  <li>Chip e plano de dados grátis</li>
                  <li>Pagamento por aproximação (NFC)</li>
                  <li>Sem aluguel nem taxa de adesão</li>
                  <li>Faz gestão do seu negócio</li>
                  <li>Recebe o dinheiro das vendas NA HORA!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function retornaInformacoes() {
    return (
      <div className={styles.informacoes}>
        <ul>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}
            Todas as maquininhas vem com Conta Digital grátis para pagar conta,
            transferir dinheiro e fazer recarga de celular.
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Você recebe o
            dinheiro de suas vendas NA HORA{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Pagam contas
            Transferem dinheiro{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Recarregam celular
            Cartão Pré-Pago grátis{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Saque no Banco24Horas{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Fazem empréstimo 5
            anos de garantia{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Sem aluguel, nem taxa
            de adesão{' '}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Taxa 0% nos 30
            primeiros dias
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Frete Grátis para
            todo o Brasil
          </li>
          <li>
            {' '}
            <FontAwesomeIcon icon={faCheckSquare} /> {'  '}Aceite todas as
            bandeiras de Débito, Crédito e Vale Alimentação, Vale Refeição, Pix,
            QR Code e Carteiras Digitais
          </li>
        </ul>
      </div>
    );
  }

  return (
    <SSRProvider>
      <div className="App">
        <Head
          metaDescription="Compre agora sua maquininha com desconto."
          title="Promoção de Maquininhas"
          h1="Promoção de Maquininhas"
          canonical="https://www.guardardinheiro.com.br/lp/promocao-de-maquininhas"
          nivel="2"
          id_wp_post={1300}
        />
        <Container>
          {/* <header className="App-header">
            {seconds} seconds have elapsed since mounting.
          </header> */}
          <h1 className={styles.h1}>
            Compre agora sua <strong>maquininha com desconto</strong>
          </h1>
          <h2 className={styles.h2}>
            Super Vantagens ao comprar a sua maquininha
          </h2>
          <div className={styles.center}>
            <Image src={fgPagSeguro} alt={'PagSeguro'} fluid />
          </div>
          {retornaVantagens()}
          <br />
          {returnaMaquinas()}
          <br />
          <hr />
          {retornaInformacoes()}
          <br />
          {retoenaBandeirasCredito()}
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <span className={styles.spanFrete}>
              <FontAwesomeIcon icon={faTruckMoving} /> {'  '}
              <strong>FRETE GRÁTIS</strong> para todo o Brasil
            </span>
          </div>
          <br />
          <br />
          <br />
          {seconds % 7 === 0 && <PopUpMaquinasVendidas />}
        </Container>
        <hr />
        <Whatsapp pagina="promocao-maquininhas" />
      </div>
    </SSRProvider>
  );
};

export default LpPagBank;
