import React from 'react';
import { Container, Table } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesDiv from './css/Div.module.css';
import stylesButton from './css/Button.module.css';
import WalletItem from '../../Components/Carteira/WalletItem';
import H3 from '../../Components/Tags/H3';
import ModalCadastroAtivo from '../../Components/Carteira/ModalCadastroAtivo';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const CarteiraRV = () => {
  const [show, setShow] = React.useState(false);
  const [tipo, setTipo] = React.useState(null);

  const showModal = (event, tipo) => {
    event.preventDefault();
    setShow(true);
    setTipo(tipo);
  };

  return (
    <>
      <BreadcumbH1
        paginaAtual=":: MEUS ATIVOS"
        texto=":: MEUS ATIVOS"
        isPro={true}
      />

      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MEUS ATIVOS</h1>
        <H3 texto="Adicionar Produtos + " />
        <div className={stylesDiv.divFlexGap10}>
          <button
            className={stylesButton.button}
            onClick={(e) => showModal(e, 'acoes')}
          >
            Ação
          </button>
          <button
            className={stylesButton.button}
            onClick={(e) => showModal(e, 'fii')}
          >
            Fundo Imobiliário
          </button>
          <button
            className={stylesButton.button}
            onClick={(e) => showModal(e, 'etf')}
          >
            ETF
          </button>
          <button
            className={stylesButton.button}
            onClick={(e) => showModal(e, 'bdr')}
          >
            BDR
          </button>
        </div>

        <br />
        <WalletItem />

        {/* <AdsenseParagrafo5 /> */}
        {show && (
          <ModalCadastroAtivo
            onClose={() => setShow(false)}
            tipo={tipo}
            show={show}
          />
        )}
      </Container>
    </>
  );
};

export default CarteiraRV;
