import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoedaCF, formataPorcentagem } from '../Helper/DateUtilities';
import isMobile from '../Helper/Mobile';
import BoxBalanco from './BoxBalanco';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faHandHoldingUsd,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';

const BoxSaldo = ({ data, flagAnoOuMes, isInicio, hide }) => {
  const mobile = isMobile();

  function getSaldos(tipo) {
    let valor = 0;
    if (flagAnoOuMes === 'mes') {
      data
        .filter(
          (dados) =>
            dados.tipo === tipo &&
            // dados.tipo_registro === '1' &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 3,
            ) === window.sessionStorage.getItem('gd_cf_dt'),
        )
        .forEach((registro) => {
          valor = valor + parseFloat(registro.valor);
        });
    } else {
      data
        .filter(
          (dados) =>
            dados.tipo === tipo &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 6,
            ) === window.sessionStorage.getItem('gd_cf_ano'),
        )
        .forEach((registro) => {
          valor = valor + parseFloat(registro.valor);
        });
    }
    return formataMoedaCF(valor.toString(), hide);
  }

  function getPorcentagemOrcamento() {
    let dadosBalanco;
    let valorTotalDespesa = 0;
    let valorTotalReceita = 0;
    let valorTotalInvestimentos = 0;
    let valorTotalEssencial = 0;
    let valorTotalNaoEssencial = 0;
    let porcentagemEssencial = 0;
    let porcentagemNaoEssencial = 0;
    let porcentagemInv = 0;

    dadosBalanco = data.filter(
      (dados) =>
        // dados.tipo_registro === '1' &&
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 6) ===
          window.sessionStorage.getItem('gd_cf_ano'),
    );

    dadosBalanco.forEach((registro) => {
      if (registro.tipo === 'Despesa') {
        valorTotalDespesa = valorTotalDespesa + parseFloat(registro.valor);
        if (registro.essencial === 'S') {
          valorTotalEssencial =
            valorTotalEssencial + parseFloat(registro.valor);
        } else {
          valorTotalNaoEssencial =
            valorTotalNaoEssencial + parseFloat(registro.valor);
        }
      } else if (registro.tipo === 'Receita') {
        valorTotalReceita = valorTotalReceita + parseFloat(registro.valor);
      } else if (registro.tipo === 'Investimentos') {
        valorTotalInvestimentos =
          valorTotalInvestimentos + parseFloat(registro.valor);
      }
    });

    porcentagemEssencial = (valorTotalEssencial / valorTotalReceita) * 100;
    porcentagemNaoEssencial =
      (valorTotalNaoEssencial / valorTotalReceita) * 100;
    porcentagemInv = (valorTotalInvestimentos / valorTotalReceita) * 100;

    return (
      <Alert
        variant="info"
        style={{ textAlign: 'left', paddingTop: '1px', paddingBottom: '1px' }}
      >
        <p
          className={stylesTipografia.p}
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '12px',
          }}
        >
          {formataMoedaCF(valorTotalEssencial) +
            ' foram para gastos essenciais.' +
            formataPorcentagem(porcentagemEssencial) +
            ' da receita.'}
        </p>
        <p
          className={stylesTipografia.p}
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '12px',
            marginTop: '-15px',
          }}
        >
          {formataMoedaCF(valorTotalNaoEssencial) +
            ' foram para gastos não essenciais.' +
            formataPorcentagem(porcentagemNaoEssencial) +
            ' da receita.'}
        </p>
        <p
          className={stylesTipografia.p}
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '12px',
            marginTop: '-15px',
          }}
        >
          {formataMoedaCF(valorTotalInvestimentos) +
            ' foram para investir.' +
            formataPorcentagem(porcentagemInv) +
            ' da receita.'}
        </p>
      </Alert>
    );
  }

  return (
    <>
      <div className={stylesDiv.divCardName}>
        <h2 className={stylesTipografia.h2}>
          {flagAnoOuMes === 'mes' ? ':: balanço mensal' : ':: balanço anual'}
        </h2>
      </div>
      <div className={stylesDiv.divFlex100Infos}>
        <div
          className={stylesDiv.divSaldos}
          style={{ backgroundColor: '#28a745' }}
        >
          <div>
            <span className={stylesTipografia.spanSaldos}>
              {getSaldos('Receita')}
            </span>
          </div>
          <span className={stylesTipografia.span}>
            <FontAwesomeIcon icon={faHandHoldingUsd} /> {' Receita'}
          </span>
        </div>

        <div
          className={stylesDiv.divSaldos}
          style={{ backgroundColor: '#B22222' }}
        >
          <div>
            <span className={stylesTipografia.spanSaldos}>
              {getSaldos('Despesa')}
            </span>
          </div>
          <span className={stylesTipografia.spanSaldos}>
            <FontAwesomeIcon icon={faShoppingCart} /> {' Despesa'}
          </span>
        </div>

        <div
          className={stylesDiv.divSaldos}
          style={{ backgroundColor: '#008B8B' }}
        >
          <div>
            <span className={stylesTipografia.spanSaldos}>
              {getSaldos('Investimentos')}
            </span>
          </div>
          <span className={stylesTipografia.spanSaldos}>
            <FontAwesomeIcon icon={faChartLine} /> {' Investimentos'}
          </span>
        </div>
      </div>
      {!isInicio && (
        <BoxBalanco data={data} flagAnoOuMes={flagAnoOuMes} hide={hide} />
      )}
      {flagAnoOuMes !== 'mes' && getPorcentagemOrcamento()}
    </>
  );
};

export default BoxSaldo;
