import React from 'react';
// import { Helmet } from 'react-helmet';
import styles from './PopUpMaquinasVendidas.module.css';
import Toast from 'react-bootstrap/Toast';
import { Container, Image } from 'react-bootstrap';

const PopUpPlanilhasVendidas = () => {
  function retornaCompradoresCidade() {
    let mensagem = '';
    let compradores = ['2', '3', '4', '5', '6', '7'];
    // 17
    let nomes = [
      'Douglas',
      'Gracy',
      'Luan',
      'Rosângela',
      'Celso',
      'Ferreira',
      'Mariana',
      'Silvana',
      'Talita',
      'Rafael',
      'Rosa',
      'Clara',
      'Carlos',
      'Carol',
      'Mateus',
      'Nunes',
      'Léia',
      'Isaura',
      'Josias',
      'Nelson',
      'Jonas',
      'Maria',
      'José',
      'Bianca',
      'Evelyn',
      'Taís',
      'Ana Júlia',
      'Mércia',
      'Aline',
      'Bruno',
      'Cássio',
      'Débora',
      'Eduardo',
      'Fabiana',
      'Gustavo',
      'Helena',
      'Igor',
      'Janaína',
      'Karina',
      'Lucas',
      'Mário',
      'Nádia',
      'Otávio',
      'Patrícia',
      'Quirino',
      'Renata',
      'Sérgio',
      'Tatiana',
      'Ulisses',
      'Vera',
      'Wilson',
      'Xavier',
      'Yara',
      'Zélia',
      'Arthur',
      'Beatriz',
      'Camila',
      'Danilo',
      'Eliana',
      'Fernando',
      'Gabriela',
      'Henrique',
      'Isabela',
      'João',
      'Karen',
      'Leonardo',
      'Marta',
      'Nicolau',
      'Olívia',
      'Paulo',
      'Quésia',
      'Roberto',
      'Sandra',
      'Thiago',
      'Ubirajara',
      'Vanessa',
      'Wagner',
      'Ximena',
      'Yuri',
      'Zeca',
      'André',
      'Bruna',
      'Célia',
      'Diego',
      'Elisa',
      'Felipe',
      'Glória',
      'Hugo',
      'Iara',
      'Jaime',
      'Larissa',
      'Marcelo',
      'Natália',
      'Orlando',
      'Pedro',
      'Raquel',
      'Simone',
      'Tadeu',
      'Ursula',
      'Valdir',
      'Waldemar',
      'Ximena',
      'Yasmin',
      'Zenon',
      'Adriana',
      'Breno',
      'Carmen',
      'Diana',
      'Enzo',
      'Flávia',
      'Giovanni',
      'Horácio',
      'Ivana',
      'Juliano',
      'Luana',
      'Maurício',
      'Neusa',
      'Osvaldo',
      'Paloma',
      'Rafaela',
      'Samuel',
      'Tânia',
      'Ulisses',
      'Vitória',
      'Willian',
      'Xuxa',
      'Yara',
      'Zara',
      'Alice',
      'Benício',
      'Clara',
      'David',
      'Eduarda',
      'Fábio',
      'Giovana',
      'Hélio',
      'Inês',
      'José',
      'Lívia',
      'Marcos',
      'Nilza',
      'Otto',
      'Paula',
      'Ricardo',
      'Sara',
      'Tomás',
      'Ubiratan',
      'Vinícius',
      'Wanda',
      'Xuxa',
      'Yasmin',
      'Zeca',
      'Amélia',
      'Bruno',
      'Cauã',
      'Daniel',
      'Elena',
      'Frederico',
      'Gisele',
      'Heitor',
      'Irene',
      'Juliana',
      'Leonel',
      'Marlene',
      'Nélio',
      'Orion',
      'Pietra',
      'Renan',
      'Sofia',
      'Thiago',
      'Ubiraci',
      'Valentina',
      'Wesley',
      'Xuxa',
      'Yuri',
      'Zilda',
      'Ana',
      'Bernardo',
      'Cláudio',
      'Denise',
      'Eduardo',
      'Fernanda',
      'Guilherme',
      'Helena',
      'Ivan',
      'Júlio',
      'Lorena',
      'Miguel',
      'Nara',
      'Otávio',
      'Patrícia',
      'Renato',
      'Silvio',
      'Tatiana',
      'Uriel',
      'Vicente',
      'Wilma',
      'Xuxa',
      'Yara',
      'Zilda',
      'Arthur',
      'Brenda',
      'Carlos',
      'Daniela',
      'Ernesto',
      'Francisca',
      'Gustavo',
      'Helena',
      'Ivan',
      'José',
      'Larissa',
      'Matheus',
      'Nina',
      'Oscar',
      'Priscila',
      'Robson',
      'Sandra',
      'Thiago',
      'Uriel',
      'Vitória',
      'Wagner',
      'Xuxa',
      'Yasmin',
      'Zilda',
      'Amélia',
      'Breno',
      'Carlos',
      'Débora',
      'Elias',
      'Flávia',
      'Guilherme',
      'Henrique',
      'Isabel',
      'José',
      'Luan',
      'Marcelo',
      'Natália',
      'Orlando',
      'Paula',
      'Renato',
      'Simone',
      'Tiago',
      'Ulisses',
      'Vinícius',
      'Wesley',
      'Xuxa',
      'Yara',
      'Zeca',
      'Ana',
      'Bernardo',
      'Cecília',
      'Danilo',
      'Eliana',
      'Felipe',
      'Gabriela',
      'Henrique',
      'Iara',
      'João',
      'Lívia',
      'Marcos',
      'Natália',
      'Orion',
      'Paulo',
      'Renata',
      'Sofia',
      'Thiago',
      'Ulisses',
      'Vitória',
      'William',
      'Xuxa',
      'Yuri',
      'Zara',
      'Alice',
      'Breno',
      'Carlos',
      'Diego',
      'Elisa',
      'Fábio',
      'Gisele',
      'Heitor',
      'Isabela',
      'Jorge',
      'Lucas',
      'Maria',
      'Nara',
      'Otto',
      'Patrícia',
      'Renato',
      'Silvia',
      'Thiago',
      'Ursula',
      'Vicente',
      'Wagner',
      'Xuxa',
      'Yara',
      'Zilda',
      'Arthur',
      'Bruna',
      'Cecília',
      'David',
      'Elaine',
      'Felipe',
      'Giovana',
      'Hugo',
      'Ivana',
      'José',
      'Larissa',
      'Mariana',
      'Natália',
      'Oscar',
      'Paulo',
      'Renata',
      'Sérgio',
      'Tiago',
      'Ubirajara',
      'Vinícius',
      'Willian',
      'Xuxa',
      'Yara',
      'Zelda',
    ];

    // 28

    let tipo = ['1', '2', '3'];

    let tipoEscolhido = tipo[Math.floor(Math.random() * 2)];

    if (tipoEscolhido === '1') {
      return (
        <p>
          <strong>
            {compradores[
              Math.floor(Math.random() * compradores.length)
            ].toString() + ' pessoas compraram'}
          </strong>
          <i>{' nos últimos 15 minutos.'}</i>
        </p>
      );
    } else {
      return (
        <p>
          <strong>
            {nomes[Math.floor(Math.random() * nomes.length)].toString() +
              ' acabou de comprar. '}
          </strong>{' '}
          {/* <i>Restam poucas unidades promocionais!</i> */}
        </p>
      );
    }

    return mensagem;
  }

  function returnPopup() {
    return (
      <div>
        {/* <Toast show={show} onClose={toggleShow} className={styles.popup}> */}
        <Toast className={styles.popup}>
          <Toast.Header>
            <div style={{ display: 'block' }}>
              <div>
                <strong className="me-auto">
                  Pessoas estão aproveitando a oferta
                </strong>
              </div>
              <div>
                <p>{retornaCompradoresCidade()}</p>
              </div>
            </div>
          </Toast.Header>
        </Toast>
      </div>
    );
  }

  return <div>{returnPopup()}</div>;
};

export default PopUpPlanilhasVendidas;
