import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';

function ModalCartao(props) {
  const inputElement = React.useRef();
  const [idCartao, setIdCartao] = React.useState(null);
  const [nomeCartao, setNomeCartao] = React.useState(null);
  const [bandeira, setBandeira] = React.useState(null);
  const [limite, setLimite] = React.useState(null);
  const [diaVencimento, setDiaVencimento] = React.useState(null);
  const [diaFechamento, setDiaFechamento] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    if (props.idCartao) {
      setIdCartao(props.idCartao);
    }
    if (props.nomeCartao) {
      setNomeCartao(props.nomeCartao);
    }
    if (props.bandeira) {
      setBandeira(props.bandeira);
    }

    if (props.diaVencimento) {
      setDiaVencimento(props.diaVencimento);
    }
    if (props.diaFechamento) {
      setDiaFechamento(props.diaFechamento);
    }

    if (props.limite) {
      setLimite(props.limite);
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(
    http_method,
    id,
    nome_cartao,
    bandeira,
    limite,
    dia_vencimento,
    dia_fechamento,
  ) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id) {
      id = '1';
    }

    const urlPostPortfolio = `https://s6jxo2sk2g.execute-api.sa-east-1.amazonaws.com/producao/cartao?user_id=${user_id}&id=${id}&nome_cartao=${encodeURIComponent(
      nome_cartao,
    )}&limite=${limite}&bandeira=${bandeira}&dia_vencimento=${dia_vencimento}&dia_fechamento=${dia_fechamento}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          setLoading(false);
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addCartao(event) {
    event.preventDefault();
    setLoading(true);
    if (isValidForm()) {
      let acao = 'INSERT';
      if (props.acao === 'UPDATE') {
        acao = props.acao;
      }

      const selectBandeira = document.getElementById('select-bandeira');

      // console.log(diaFechamento, diaVencimento);
      postItem(
        acao,
        idCartao,
        nomeCartao,
        selectBandeira.value,
        limite,
        diaVencimento,
        diaFechamento,
      );
    }
  }

  function isValidForm() {
    let retorno = true;
    if (nomeCartao) {
      if (nomeCartao.length < 1) {
        document.getElementById('v-nome-cartao').innerHTML =
          'Nome do Cartão obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-nome-cartao').innerHTML =
        'Nome do Cartão obrigatório';
      retorno = false;
    }

    // if (bandeira) {
    //   if (bandeira.length < 1) {
    //     document.getElementById('v-bandeira-cartao').innerHTML =
    //       'Bandeira do Cartão obrigatório';
    //     retorno = false;
    //   }
    // } else {
    //   document.getElementById('v-bandeira-cartao').innerHTML =
    //     'Bandeira do Cartão obrigatório';
    //   retorno = false;
    // }

    const selectBandeira = document.getElementById('select-bandeira');
    if (selectBandeira) {
      if (selectBandeira.value.length < 1) {
        document.getElementById('v-bandeira-cartao').innerHTML =
          'Bandeira do Cartão obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-bandeira-cartao').innerHTML =
        'Bandeira do Cartão obrigatório';
      retorno = false;
    }
    // console.log(selectBandeira.value);

    if (diaFechamento) {
      if (diaFechamento.length < 1) {
        document.getElementById('v-fechamento-cartao').innerHTML =
          'Dia de Fechamento do Cartão obrigatório';
        retorno = false;
      } else {
        if (parseInt(diaFechamento) <= 0) {
          document.getElementById('v-fechamento-cartao').innerHTML =
            'Dia Fechamento do Cartão deve ser maior que Zero';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-fechamento-cartao').innerHTML =
        'Dia de Fechamento do Cartão obrigatório';
      retorno = false;
    }

    if (diaVencimento) {
      if (diaVencimento.length < 1) {
        document.getElementById('v-vencimento-cartao').innerHTML =
          'Dia de Vencimento do Cartão obrigatório';
        retorno = false;
      } else {
        if (parseInt(diaVencimento) <= 0) {
          document.getElementById('v-vencimento-cartao').innerHTML =
            'Dia Vencimento do Cartão deve ser maior que Zero';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-vencimento-cartao').innerHTML =
        'Dia de Vencimento do Cartão obrigatório';
      retorno = false;
    }

    if (limite) {
      if (limite.length < 1) {
        document.getElementById('v-limite-cartao').innerHTML =
          'Limite do Cartão obrigatório';
        retorno = false;
      } else {
        if (parseFloat(limite.replace(',', '.')) <= 0) {
          document.getElementById('v-limite-cartao').innerHTML =
            'Limite do Cartão deve ser maior que Zero';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-limite-cartao').innerHTML =
        'Limite do Cartão obrigatório';
      retorno = false;
    }

    if (retorno) {
      if (diaFechamento === diaVencimento) {
        document.getElementById('v-vencimento-cartao').innerHTML =
          'Dia Vencimento não pode ser igual ao Dia de Fechamento';
        retorno = false;
      }
    }

    if (!retorno) {
      setLoading(false);
    }

    // retorno = false;
    return retorno;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="xl"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {'Cartões'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-nome-categoria'}
              label={'Nome Cartão'}
              type="text"
              value={nomeCartao}
              ref={inputElement}
              onChange={({ target }) => {
                setNomeCartao(target.value);
                document.getElementById('v-nome-cartao').innerHTML = '';
              }}
            ></Input>
            <p id="v-nome-cartao" className={stylesTextos.textoErros}></p>
          </div>
          <div className={stylesModal.itemContainer}>
            <Select
              tipo="bandeira"
              label="Bandeira"
              name="select-bandeira"
              valor={props.bandeira}
            />
            <p id="v-bandeira-cartao" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-dia-fechamento'}
              label={'Dia Fechamento'}
              type="number"
              value={diaFechamento}
              ref={inputElement}
              onChange={({ target }) => {
                setDiaFechamento(target.value);
                document.getElementById('v-fechamento-cartao').innerHTML = '';
              }}
            ></Input>
            <p id="v-fechamento-cartao" className={stylesTextos.textoErros}></p>
          </div>
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-dia-vencimento'}
              label={'Dia Vencimento'}
              type="number"
              value={diaVencimento}
              ref={inputElement}
              onChange={({ target }) => {
                setDiaVencimento(target.value);
                document.getElementById('v-vencimento-cartao').innerHTML = '';
              }}
            ></Input>
            <p id="v-vencimento-cartao" className={stylesTextos.textoErros}></p>
          </div>
          <div className={stylesModal.itemContainer}>
            <InputMask
              name={'input-limite'}
              label="Limite"
              placeholder="R$"
              value={limite}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) => {
                setLimite(target.value.replace('R$', '').replace('.', ''));
                document.getElementById('v-limite-cartao').innerHTML = '';
              }}
            ></InputMask>
            <p id="v-limite-cartao" className={stylesTextos.textoErros}></p>
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button className={stylesButton.button} onClick={(e) => addCartao(e)}>
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCartao;
