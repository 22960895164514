// PreserveUTMs.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PreserveUTMs = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmTerm = searchParams.get('utm_term');

    if (utmSource || utmMedium || utmCampaign) {
      sessionStorage.setItem('utm_source', utmSource);
      sessionStorage.setItem('utm_medium', utmMedium);
      sessionStorage.setItem('utm_campaign', utmCampaign);
      sessionStorage.setItem('utm_term', utmTerm);
    }
  }, [location.search]);

  return <>{children}</>;
};

export default PreserveUTMs;
