import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import stylesDiv from '../../Pages/_css/Div.module.css';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';
import Switch from '../Forms/Switch';
import Label from '../Forms/Label';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ModalCadastroMovimento(props) {
  // console.log(props);

  const inputElement = React.useRef();
  const [idMovimento, setIdMovimento] = React.useState(null);
  const [idMovimentoDetalhe, setIdMovimentoDetalhe] = React.useState(null);
  const [dataComnpra, setDataCompra] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);
  const [conta, setConta] = React.useState(null);
  const [situacao, setSituacao] = React.useState(null);

  // const [dataVencimento, setDataVencimento] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [formaPagamento, setFormaPagamento] = React.useState(null);
  const [switchStateForma, setSwitchStateForma] = React.useState(false);
  const [switchStatePago, setSwitchStatePago] = React.useState(false);
  const [totalParcelas, setTotalParcelas] = React.useState(null);
  const [isValorParcela, setIsValorParcela] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    // console.log(props);
    const labelTotalParcelas = document.getElementById(
      'label-input-total-parcelas',
    );

    if (!props.idMovimento) {
      const inputTotalParcelas = document.getElementById(
        'input-total-parcelas',
      );
      labelTotalParcelas.style.visibility = 'hidden';
      inputTotalParcelas.style.visibility = 'hidden';
    }

    if (props.idMovimento) {
      setIdMovimento(props.idMovimento);
    }
    if (props.idMovimentoDetalhe) {
      setIdMovimentoDetalhe(props.idMovimentoDetalhe);
    }

    if (props.categoria) {
      setCategoria(props.categoria);
    } else {
      setCategoria('Selecione');
    }

    if (props.conta) {
      setConta(props.conta);
    } else {
      setConta('Selecione');
    }
    if (props.valor) {
      setValor(props.valor.toString().replace('.', ','));
    }
    if (props.formaPagamento) {
      setFormaPagamento(props.formaPagamento);
    }
    // else {
    //   // setFormaPagamento('Selecione');
    // }
    if (props.situacao) {
      setSituacao(props.situacao);

      if (props.situacao === '1') {
        document.getElementById('switch-pago').click();
      }
    }
    if (props.descricao) {
      setDescricao(props.descricao);
    }

    if (props.dataMovimento) {
      setDataCompra(props.dataMovimento);
    } else {
      var date = new Date();
      setDataCompra(
        date.getFullYear().toString() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0'),
      );
    }

    disableElementsToPay();
    disableElementsToEdit();
    disableElementsToInsert();
  }, []);

  if (!props.show) {
    return null;
  }

  function disableElementsToPay() {
    const divTipoValorParcela = document.getElementById('div-valor-parcela');
    divTipoValorParcela.style.display = 'none';
    if (props.acao === 'PAGAR') {
      // inputDescricao.setAttribute('disabled', 'disabled');
      // // selectCategoria.setAttribute('disabled', 'disabled');
      // inputValorTotal.setAttribute('disabled', 'disabled');
      // inputVencimento.setAttribute('disabled', 'disabled');

      if (props.situacao === '0') {
        document.getElementById('switch-pago').click();
      }

      var nodes = document
        .getElementById('main-movimento')
        .getElementsByTagName('*');
      for (var i = 0; i < nodes.length; i++) {
        if (
          (nodes[i].id === 'input-data-mov') |
          (nodes[i].id === 'input-descricao') |
          (nodes[i].id === 'select-categoria')
        )
          nodes[i].disabled = true;
      }

      if (props.cartao) {
        // console.log(props.cartao);
        if (props.cartao.length > 10) {
          var nodes = document
            .getElementById('div-categoria')
            .getElementsByTagName('*');
          for (var i = 0; i < nodes.length; i++) {
            nodes[i].style.visibility = 'hidden';
          }
        }
      }
    }
  }

  function disableElementsToEdit() {
    const divTipoValorParcela = document.getElementById('div-valor-parcela');
    divTipoValorParcela.style.display = 'none';
    if (props.acao) {
      if (props.acao.includes('UPDATE')) {
        //nao deixa alterar a conta...
        var nodes = document
          .getElementById('div-conta')
          .getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
          nodes[i].style.visibility = 'hidden';
        }
        const divPagamento = document.getElementById('div-switch-pago');
        divPagamento.style.visibility = 'hidden';
        var nodes = document
          .getElementById('main-movimento')
          .getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
          if (nodes[i].id === 'input-data-mov') nodes[i].disabled = true;
        }
        if (props.is_fatura) {
          document.getElementById('div-conta').style.display = 'none';
        }
      }
    }
  }

  function disableElementsToInsert() {
    const divTipoValorParcela = document.getElementById('div-valor-parcela');
    divTipoValorParcela.style.display = 'none';

    if (props.tipoLancamento === 'Investimentos') {
      if (props.acao !== 'UPDATE' && props.acao !== 'PAGAR') {
        document.getElementById('switch-avista').click();
      }

      var elemento = document.getElementById('div-repeat-mov');

      if (elemento) {
        var nodes = elemento.getElementsByTagName('*');

        for (var i = 0; i < nodes.length; i++) {
          nodes[i].disabled = true;
        }
      }
    }
  }

  function disableCategoria() {
    if (props.acao === 'PAGAR') {
      return true;
    } else {
      return false;
    }
  }

  function postMovimento(
    id,
    id_detalhe,
    descricao,
    id_categoria,
    id_conta,
    id_cartao,
    id_situacao,
    data_compra,
    valor,
    fg_forma,
    total_parcelas,
    http_method,
  ) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id) {
      id = '1';
    }

    if (!id_detalhe) {
      id_detalhe = '1';
    }

    if (isValorParcela === '1') {
      valor = valor * total_parcelas;
    }

    const url = `https://hul9706vv3.execute-api.sa-east-1.amazonaws.com/producao/user-movimentos?id=${id}&id_detalhe=${id_detalhe}&user_id=${user_id}&descricao=${encodeURIComponent(
      descricao,
    )}&id_categoria=${id_categoria}&id_conta=${id_conta}&id_cartao=${id_cartao}&id_situacao=${id_situacao}&data_compra=${data_compra}&valor=${valor}&fg_forma=${fg_forma}&total_parcelas=${total_parcelas}&metodo=${http_method}`;
    // console.log(url);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(url, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addProduto2(event) {
    event.preventDefault();
    const switchPago = document.getElementById('switch-pago');
    // console.log(props.acao);
  }

  const notify2 = () => {
    toast.info('Cadastrando ' + props.tipoLancamento, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const notify = () => {
    // event.preventDefault();

    // setLoading(true);

    if (isValidForm()) {
      toast.info('Cadastrando ' + props.tipoLancamento, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      const selectCategoria = document.getElementById('select-categoria');
      const selectConta = document.getElementById('select-conta');

      let comando = 'INSERT';
      if (props.idMovimento) {
        comando = 'UPDATE';
      }

      if (props.acao === 'PAGAR') {
        comando = 'PAGAR';
      }

      if (comando === 'UPDATE') {
        if (formaPagamento === '2' || formaPagamento === '3') {
          comando = document.querySelector(
            'input[name="group-update"]:checked',
          ).id;
        }
      }

      let tipoPagamento =
        selectConta.value === 'Selecione' ? '' : selectConta.value;
      let conta = '0';
      let cartao = '0';

      if (tipoPagamento.split('|')[0] === 'pgto-1') {
        cartao = tipoPagamento.split('|')[1];
      } else {
        conta = tipoPagamento.split('|')[1];
      }

      if (props.acao) {
        if (props.acao.includes('UPDATE') || props.acao.includes('PAGAR')) {
          cartao = props.cartao.split('|')[1];
          // conta = props.conta.split('|')[1];
          conta = tipoPagamento.split('|')[1];
        }
      }

      //pagamento do cartao de credito
      if (props.acao === 'PAGAR' && props.categoria === 'NA') {
        conta = tipoPagamento.split('|')[1];
      }

      const switchPago = document.querySelector(
        'input[id="switch-pago"]:checked',
      );

      let sitiacao_pagamento = '0';

      if (cartao !== '0' && comando === 'INSERT') {
        sitiacao_pagamento = '0';
      } else if (formaPagamento !== '1' && comando === 'INSERT') {
        sitiacao_pagamento = '0';
      } else {
        if (switchPago) {
          sitiacao_pagamento = '1';
        }
      }

      postMovimento(
        idMovimento,
        idMovimentoDetalhe,
        descricao,
        selectCategoria.value,
        conta,
        cartao,
        sitiacao_pagamento,
        dataComnpra,
        valor.replace(',', '.'),
        formaPagamento,
        totalParcelas ? totalParcelas : '1',
        comando,
      );
      // console.log('cadastrou');
    }

    // setLoading(false);
  };

  const handleChangeForma = (e) => {
    const switchForma = document.querySelector(
      'input[name="sw-tipo-pagamento"]:checked',
    ).id;
    const divPagamento = document.getElementById('div-switch-pago');
    const labelTotalParcelas = document.getElementById(
      'label-input-total-parcelas',
    );
    const inputTotalParcelas = document.getElementById('input-total-parcelas');
    const switchPago = document.getElementById('switch-pago');

    const divTipoValorParcela = document.getElementById('div-valor-parcela');

    setSituacao('0');

    if (switchForma === 'switch-avista') {
      setFormaPagamento('1');
      divTipoValorParcela.style.display = 'none';
      divPagamento.style.visibility = 'visible';
      labelTotalParcelas.style.visibility = 'hidden';
      inputTotalParcelas.style.visibility = 'hidden';

      // if (!switchPago.hasAttribute('checked')) {
      //   document.getElementById('switch-pago').click();
      //   setSituacao('1');
      // }

      if (props.tipoLancamento !== 'Investimentos') {
        removePagamento();
      }
    } else if (switchForma === 'switch-parcelado') {
      setFormaPagamento('2');
      // divTipoValorParcela.style.visibility = 'visible';
      divTipoValorParcela.style.display = 'block';
      divPagamento.style.visibility = 'hidden';
      labelTotalParcelas.style.visibility = 'visible';
      inputTotalParcelas.style.visibility = 'visible';

      divTipoValorParcela.scrollIntoView();
    } else if (switchForma === 'switch-recorrente') {
      setFormaPagamento('3');
      divTipoValorParcela.style.display = 'none';
      divPagamento.style.visibility = 'hidden';
      labelTotalParcelas.style.visibility = 'hidden';
      inputTotalParcelas.style.visibility = 'hidden';
    }

    document.getElementById('v-total-parcelas').innerHTML = '';

    if (props.acao) {
      if (props.acao.includes('UPDATE')) {
        const divPagamento = document.getElementById('div-switch-pago');
        divPagamento.style.visibility = 'hidden';
      }
    }
  };

  const handleChangeParcela = (e) => {
    const switchForma = document.querySelector(
      'input[name="sw-valor-parcela"]:checked',
    ).id;

    if (switchForma === 'switch-parcela-parcela') {
      setIsValorParcela('1');
    } else {
      setIsValorParcela('0');
    }
  };

  const handleChangePago = (e) => {
    setSwitchStatePago(!switchStatePago);
    // const switchPago = document.getElementById('switch-pago');

    // if (!switchPago.hasAttribute('checked')) {
    //   setSituacao('1');
    // } else {
    //   setSituacao('0');
    // }
    const switchPago = document.querySelector(
      'input[id="switch-pago"]:checked',
    );
    if (switchPago) {
      setSituacao('1');
    } else {
      setSituacao('0');
    }
  };

  function removePagamento() {
    const selectConta = document.getElementById('select-conta');
    let tipoPagamento =
      selectConta.value === 'Selecione' ? '' : selectConta.value;
    let conta = '0';
    let cartao = '0';

    if (tipoPagamento.split('|')[0] === 'pgto-1') {
      cartao = tipoPagamento.split('|')[1];
    } else {
      conta = tipoPagamento.split('|')[1];
    }

    const divPagamento = document.getElementById('div-switch-pago');
    if (divPagamento) {
      if (conta === '0') {
        divPagamento.style.visibility = 'hidden';
      } else {
        divPagamento.style.visibility = 'visible';
      }
    }
  }

  function isValidForm() {
    let retorno = true;
    if (descricao) {
      if (descricao.length < 1) {
        document.getElementById('v-descricao').innerHTML =
          'Descrição é obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-descricao').innerHTML =
        'Descrição é obrigatório';
      retorno = false;

      console.log('erro descricao');
    }

    if (dataComnpra) {
      if (dataComnpra.length < 1) {
        document.getElementById('v-data').innerHTML = 'Data é obrigatório';
        retorno = false;
        console.log('erro data');
      }
    } else {
      document.getElementById('v-data').innerHTML = 'Data é obrigatório';
      retorno = false;
      console.log('erro data');
    }

    if (valor) {
      if (valor.length < 1) {
        document.getElementById('v-valor').innerHTML = 'Valor é obrigatório';
        retorno = false;
        console.log('erro valor');
      } else {
        if (parseFloat(valor.replace(',', '.')) <= 0) {
          if (props.tipoLancamento !== 'Investimentos') {
            document.getElementById('v-valor').innerHTML =
              props.tipoLancamento + ' Valor deve ser maior que Zero';
            retorno = false;
          }
        }
      }
    } else {
      document.getElementById('v-valor').innerHTML = 'Valor é obrigatório';
      retorno = false;
      // console.log('erro valor');
    }

    const selectCategoria = document.getElementById('select-categoria');

    if (selectCategoria.value) {
      if (selectCategoria.value === 'Selecione') {
        document.getElementById('v-categoria').innerHTML =
          'Categoria é obrigatório';
        retorno = false;
        console.log('erro cat');
      }
    } else {
      document.getElementById('v-categoria').innerHTML =
        'Categoria é obrigatório';
      retorno = false;
      console.log('erro cat');
    }

    if (props.acao === 'PAGAR') {
      retorno = true;
    }

    if (props.acao !== 'UPDATE') {
      const selectConta = document.getElementById('select-conta');
      if (selectConta.value === 'Selecione') {
        document.getElementById('v-conta').innerHTML = 'Conta é obrigatório';
        retorno = false;
        // console.log('erro conta2');
      }
    }

    if (props.acao === 'UPDATE') {
      if (props.formaPagamento !== '2') {
        const selectConta = document.getElementById('select-conta');
        if (selectConta.value === 'Selecione') {
          if (!props.is_fatura) {
            document.getElementById('v-conta').innerHTML =
              'Conta é obrigatório';
            retorno = false;
            // console.log('erro conta3');
          }
          // console.log(props.formaPagamento);
        }
      }
    }

    if (formaPagamento) {
      if (formaPagamento === 'Selecione') {
        document.getElementById('v-tipo-pagamento').innerHTML =
          'Selecione um tipo de Pagamento';
        retorno = false;
      }
    } else {
      if (props.acao !== 'PAGAR') {
        document.getElementById('v-tipo-pagamento').innerHTML =
          'Selecione um tipo de Pagamento';
        retorno = false;
      }
    }

    if (formaPagamento === '2') {
      if (totalParcelas) {
        if (totalParcelas.length < 1) {
          document.getElementById('v-total-parcelas').innerHTML =
            'Qtde de Parcelas deve ser Maior que Zero';
          retorno = false;
        } else {
          if (parseInt(totalParcelas) <= 0) {
            document.getElementById('v-total-parcelas').innerHTML =
              'Qtde de Parcelas deve ser Maior que Zero';
            retorno = false;
          }
        }
      } else {
        if (!props.acao) {
          document.getElementById('v-total-parcelas').innerHTML =
            'Qtde de Parcelas deve ser Maior que Zero';
          retorno = false;
        }
      }
    }

    if (props.acao) {
      if (props.acao.includes('UPDATE')) {
        let http_method = '';
        if (props.formaPagamento === '1') {
          http_method = 'UPDATE';
        } else {
          const radio1 = document.querySelector(
            'input[id="UPDATE-UNICO"]:checked',
          );
          const radio2 = document.querySelector(
            'input[id="UPDATE-NAO-PAGAS"]:checked',
          );
          const radio3 = document.querySelector(
            'input[id="UPDATE-TODAS"]:checked',
          );

          if (radio1) {
            http_method = radio1.id;
          } else if (radio2) {
            http_method = radio2.id;
          } else if (radio3) {
            http_method = radio3.id;
          }

          if (http_method.length < 2) {
            document.getElementById('v-update').innerHTML =
              'Escolha o tipo de atualização';
            retorno = false;
          }
        }
      }
    }

    if (formaPagamento === '2') {
      if (props.acao !== 'PAGAR' && props.acao !== 'UPDATE') {
        if (!isValorParcela) {
          document.getElementById('v-valor-parcela-preenchido').innerHTML =
            'Selecione se o Valor Preenchido é o Valor Total da Compra ou é o Valor de Cada Parcela!';
          retorno = false;
          console.log('erro');
        }
      }
    }

    // console.log(situacao);
    // retorno = false;
    // console.log(retorno);
    if (!retorno) {
      setLoading(false);
    }

    // setLoading(false);
    // console.log(isValorParcela);

    // console.log(retorno, props.acao);
    return retorno;
  }

  function getTitle() {
    let titulo = '';
    if (props.acao === 'PAGAR') {
      if (props.tipoLancamento === 'Despesa') {
        titulo = 'Pagamento';
      } else {
        titulo = 'Recebimento';
      }
    } else if (props.acao === 'UPDATE') {
      if (props.tipoLancamento) {
        titulo = 'Editar ' + props.tipoLancamento;
      } else {
        if (props.is_fatura) {
          titulo = 'Editar Despesa de Cartão de Crédito';
        }
      }
    } else {
      if (props.tipoLancamento === 'Investimentos') {
        titulo = 'Novo ' + props.tipoLancamento;
      } else {
        titulo = 'Nova ' + props.tipoLancamento;
      }
    }
    return titulo;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      className={stylesModal.modal}
      scrollable={true}
      size="xl"
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
      // fullscreen={'xxl-down'}
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {getTitle()}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div id="main-movimento">
          <div id="div-switch-pago">
            <Switch
              id="switch-pago"
              label={'Pago'}
              onChange={handleChangePago}
              switchState={switchStatePago}
              type="switch"
            />
          </div>
          <hr />
          <div className={stylesModal.itemContainerDescricao}>
            <Input
              name={'input-descricao'}
              label={'Descrição'}
              type="text"
              placeholder="Descrição"
              value={descricao}
              ref={inputElement}
              onChange={({ target }) => {
                setDescricao(target.value);
                document.getElementById('v-descricao').innerHTML = '';
              }}
            ></Input>
            <p id="v-descricao" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.containerModal}>
            <div className={stylesModal.itemContainer}>
              <Input
                name={'input-data-mov'}
                label={'Dia'}
                type="date"
                placeholder="dd-mm-yyyy"
                value={dataComnpra}
                ref={inputElement}
                onChange={({ target }) => {
                  setDataCompra(target.value);
                  document.getElementById('v-data').innerHTML = '';
                }}
              ></Input>
              <p id="v-data" className={stylesTextos.textoErros}></p>
            </div>
            <div className={stylesModal.itemContainer}>
              <InputMask
                name={'input-valor-total'}
                label="Valor"
                placeholder="R$"
                value={valor}
                ref={inputElement}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'R$'}
                onChange={({ target }) => {
                  setValor(target.value.replace('R$', '').replace('.', ''));
                  document.getElementById('v-valor').innerHTML = '';
                }}
              ></InputMask>
              <p id="v-valor" className={stylesTextos.textoErros}></p>
            </div>

            <div className={stylesModal.itemContainer} id="div-categoria">
              <Select
                name={'select-categoria'}
                label="Categoria"
                tipo="categoria"
                valor={props.categoria}
                is_disabled={disableCategoria()}
                tipoLancamento={props.tipoLancamento}
              />
              <p id="v-categoria" className={stylesTextos.textoErros}></p>
            </div>

            <div className={stylesModal.itemContainer} id="div-conta">
              <Select
                name={'select-conta'}
                label="Conta"
                tipo="conta"
                valor={props.conta}
                acao={props.acao}
              />
              <p id="v-conta" className={stylesTextos.textoErros}></p>
            </div>
            {props.idMovimento ? null : (
              <div className={stylesModal.itemContainer} id="div-repeat-mov">
                <Label label="Repetir Lançamento" name={'repetir-mov'} />
                <Switch
                  id="switch-avista"
                  label={'Não'}
                  onChange={handleChangeForma}
                  switchState={switchStateForma}
                  name="sw-tipo-pagamento"
                  type="radio"
                />
                <Switch
                  id="switch-parcelado"
                  label={'Parcelado'}
                  onChange={handleChangeForma}
                  switchState={switchStateForma}
                  name="sw-tipo-pagamento"
                  type="radio"
                />

                <Switch
                  id="switch-recorrente"
                  label={'Fixo'}
                  onChange={handleChangeForma}
                  switchState={switchStateForma}
                  name="sw-tipo-pagamento"
                  type="radio"
                />
                <p
                  id="v-tipo-pagamento"
                  className={stylesTextos.textoErros}
                ></p>
              </div>
            )}
            {props.idMovimento && props.totalParcelas !== 'NA' ? (
              <div className={stylesModal.itemContainer}>
                <Input
                  name={'input-parcelas'}
                  label={'Parcela'}
                  type="text"
                  value={props.totalParcelas}
                  disable={true}
                ></Input>
              </div>
            ) : null}

            <div className={stylesModal.itemContainer}>
              {props.idMovimento ? null : (
                <Input
                  name={'input-total-parcelas'}
                  label={'Qtde.Parcelas'}
                  type="number"
                  placeholder="Exemplo 12 X"
                  value={totalParcelas}
                  ref={inputElement}
                  onChange={({ target }) => {
                    setTotalParcelas(target.value);
                    document.getElementById('v-total-parcelas').innerHTML = '';
                  }}
                ></Input>
              )}
              <p id="v-total-parcelas" className={stylesTextos.textoErros}></p>

              <div style={{ width: '100%' }} id="div-valor-parcela">
                <Label
                  label="O valor preenchido é o valor total ou o valor de cada parcela?"
                  name={'parcela-switch'}
                />
                <Switch
                  id="switch-parcela-total"
                  label={'Valor Total'}
                  onChange={handleChangeParcela}
                  // switchState={switchStateForma}
                  name="sw-valor-parcela"
                  type="radio"
                />
                <Switch
                  id="switch-parcela-parcela"
                  label={'Valor de Cada Parcela'}
                  onChange={handleChangeParcela}
                  // switchState={switchStateForma}
                  name="sw-valor-parcela"
                  type="radio"
                />

                <p
                  id="v-valor-parcela-preenchido"
                  className={stylesTextos.textoErros}
                ></p>
              </div>
            </div>

            {/* <div className={stylesModal.itemContainer} id="div-valor-parcela">
              <Label
                label="O valor preenchido é o valor total da compra ou o valor de cada parcela?"
                name={'parcela-switch'}
              />
              <Switch
                id="switch-parcela-total"
                label={'Valor Total'}
                onChange={handleChangeParcela}
                // switchState={switchStateForma}
                name="sw-valor-parcela"
                type="radio"
              />
              <Switch
                id="switch-parcela-parcela"
                label={'Valor de Cada Parcela'}
                onChange={handleChangeParcela}
                // switchState={switchStateForma}
                name="sw-valor-parcela"
                type="radio"
              />

              <p
                id="v-valor-parcela-preenchido"
                className={stylesTextos.textoErros}
              ></p>
            </div> */}
          </div>

          {props.acao &&
          props.acao.includes('UPDATE') &&
          (props.formaPagamento === '2' || props.formaPagamento === '3') ? (
            <Form>
              <div>
                <Form.Check
                  label="Atualizar apenas esta, e não realizar nenhuma alteração nas demais."
                  name="group-update"
                  type={'radio'}
                  id={'UPDATE-UNICO'}
                />
                <Form.Check
                  label="Atualizar esta e todas as próximas ainda não pagas."
                  name="group-update"
                  type={'radio'}
                  id={'UPDATE-NAO-PAGAS'}
                />
                <Form.Check
                  label="Atualizar todas pagas e não pagas."
                  name="group-update"
                  type={'radio'}
                  id={'UPDATE-TODAS'}
                />
                <p id="v-update" className={stylesTextos.textoErros}></p>
              </div>
            </Form>
          ) : null}

          {erro && <Error error={erro} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={stylesButton.buttonCancelar} onClick={props.onClose}>
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <>
            <button className={stylesButton.button} onClick={notify}>
              Salvar
            </button>
            <ToastContainer className={stylesDiv.toast} />
          </>
        )}
        {/* <div>
          <button className={stylesButton.button} onClick={notify2}>
            Notify
          </button>
          <ToastContainer className={stylesDiv.toast} />
        </div> */}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCadastroMovimento;
