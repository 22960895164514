import React from 'react';
import { Container, Image, Table, Accordion } from 'react-bootstrap';
import styles from './LpControleFinanceiro.module.css';
import Footer from '../../Footer/Footer';
// import isMobile from '../../Components/Helper/Mobile';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';

const LPControleFinanceiroObrigado = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.localStorage.setItem('gd_pro_popup', '1');

    if (typeof window.useShortlink === 'function') {
      window.useShortlink(
        'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro/?p=9012',
      );
    }
    // window.gtag('event', 'banner_pro');
  });

  return (
    <>
      <Helmet>
        <title>{'Guardar Dinheiro Pro - Obrigado'}</title>
        <meta itemProp="name" content="Guardar Dinheiro Pro - Obrigado"></meta>
        <meta
          name="description"
          content="Obrigado por escolher nossa assinatura do Guardar Dinheiro Pro."
        />

        <link
          rel="canonical"
          href={'https://www.guardardinheiro.com.br/lp/obrigado'}
        ></link>
        <link
          rel="shortlink"
          href={'https://www.guardardinheiro.com.br/lp/obrigado/?p=9012'}
        ></link>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2K23FV25Q6"
        ></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', 'G-2K23FV25Q6');              
    `}
        </script>
      </Helmet>
      <Container>
        <div
          style={{
            display: 'block',
            justifyContent: 'center',
            marginTop: '10%',
          }}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="green"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>{' '}
          <br />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p className={styles.pObrigado}>Obrigado!!!</p>
          </div>
          <br />
          <br />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p className={styles.pObrigadoMsg}>
              "Obrigado por escolher nossa assinatura do Gradar Dinheiro Pro!
              Estamos honrados em fazer parte da sua jornada financeira. Conte
              conosco para ajudá-lo(a) a alcançar suas metas e simplificar suas
              finanças. Seja bem-vindo(a) ao nosso sistema e aproveite ao máximo
              todas as funcionalidades!"
            </p>
          </div>
          <br />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p className={styles.pObrigadoMsg}>
              "VERIFIQUE SEU E-MAIL, POIS ENVIAMOS UM E-MAIL DE "COMPRA
              APROVADA", COM OS DADOS DE ACESSO AS VÍDEO AULAS." NA PRIMEIRA
              VÍDEO AULA, VOCÊ TERÁ TODAS AS INSTRUÇÕES PARA ACESSAR O GUARDAR
              DINHEIRO PRO.
            </p>
          </div>
          {/* <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <button
              className={styles.buttonAcessarGDPro}
              onClick={() => navigate('/autenticacao')}
            >
              Acessar Guardar Dinheiro Pro
            </button>
          </div> */}
          <hr />
        </div>
      </Container>
      <Footer isLP={true} />
    </>
  );
};

export default LPControleFinanceiroObrigado;
