import React from 'react';
// import AdsNobeta1 from '../AdsNobeta/AdsNobeta1';
import AdsHelper from './AdsHelper';
import stylesDiv from '../../Pages/_css/Div.module.css';
import thumbplanner from '../../Assets/cfi/planner/thumbnail-planner-360-300x250.webp';
import { Image } from 'react-bootstrap';

const AdsContent4 = () => {
  const [contentVisible, setContentVisible] = React.useState(true);
  React.useEffect(() => {
    var path = '/22523725657/guardardinheiro.com.br/Guardardinheiro_Content4';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
    ];
    var id = 'Content4';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250], [336, 280]])
        .build();

      googletag
        .defineSlot(path, size, id)
        .setTargeting('test', 'lazyload')
        .defineSizeMapping(mapping)
        .setCollapseEmptyDiv(true)
        .setTargeting('hora_do_dia', new Date().getHours().toString())
        .addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContentVisibility = () => {
      const contentElement = document.getElementById('Content4');
      if (contentElement) {
        setContentVisible(
          window.getComputedStyle(contentElement).display !== 'none',
        );
      }
    };

    checkContentVisibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContentVisibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className={stylesDiv.publicidade}>
        <span>Publicidade</span>
      </div>
      <div
        id="Content4"
        style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}
      ></div>
      {!contentVisible && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '300px',
                height: '250px',
                marginBottom: '10px',
                // border: '1px solid black',
              }}
            >
              <React.Suspense fallback={<div>...</div>}>
                <a href="/lp/guardar-dinheiro-planner-360">
                  <Image
                    src={thumbplanner}
                    style={{ cursor: 'pointer' }}
                    width={'100%'}
                    alt="guardar dinheiro planner"
                  />
                </a>
              </React.Suspense>
            </div>
          </div>
        </>
      )}
      {/* <AdsHelper segundos={500} name="ViewTaxContent4" /> */}
    </>
  );
};
export default AdsContent4;
