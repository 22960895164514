import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoeda } from '../Helper/DateUtilities';

import isMobile from '../Helper/Mobile';
import BoxBalanco from './BoxBalanco';
import BoxBalancoAnualChart from './BoxBalancoAnualChart';
import BoxEvolucaoSaldos from './BoxEvolucaoSaldos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAccessibleIcon } from '@fortawesome/fontawesome-free-brands';
import {
  faBuilding,
  faCoins,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

const BoxSaldoDividas = ({ data, hide }) => {
  const mobile = isMobile();

  function getSaldos() {
    let dadosDividas = data.filter((dados) => dados.registro === '0');

    var valorAtual = 0;

    dadosDividas.forEach((registro) => {
      valorAtual = valorAtual + parseFloat(registro.valor_atual);
    });

    return formataMoeda(valorAtual.toString());
  }

  return (
    <>
      <div className={stylesDiv.divSombra}>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}></h2>
        </div>
        <div className={stylesDiv.divFlex100Infos}>
          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#B22222' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {hide ? '-' : getSaldos()}
              </span>
            </div>
            <span className={stylesTipografia.spanSaldos}>
              <FontAwesomeIcon icon={faDollarSign} />
              <FontAwesomeIcon icon={faBuilding} /> {' Dívidas Total'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxSaldoDividas;
