import React, { useState } from 'react';
import styelesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesImagem from '../../Pages/ControleFinanceiro/css/Images.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Container, Offcanvas } from 'react-bootstrap';
import AcessoRapido from './AcessoRapido';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const CadastroRapido = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Novo Lançamento</Tooltip>}>
        <button
          className={styelesButton.btnShowAcessoRapido}
          onClick={handleShow}
        >
          +
        </button>
      </OverlayTrigger>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ zIndex: '9999999999999' }}
        placement="bottom"
      >
        {/* <Offcanvas.Header
          closeButton
          
        ></Offcanvas.Header> */}
        <Offcanvas.Body>
          <AcessoRapido />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CadastroRapido;
