import React from 'react';
import styles from './Categorias.module.css';
import AdsContent3 from '../AdsJoinAds/AdsContent3';
import isMobile from '../Helper/Mobile';
import Share from '../Share/Share';
// import AdsenseLeft from '../Adsense/AdsenseLeft';

const Categorias = (props) => {
  const mobile = isMobile();
  return (
    <>
      {/* <AdsenseLeft /> */}
      <Share />
      <div className={styles.container}>
        <div>
          <h2 style={{ fontSize: '12px', fontWeight: 'bold', margin: '5px' }}>
            MAIS LIDAS
          </h2>

          <ol>
            <li>
              <a
                href="/melhor-maquininha-de-cartao"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-maq');
                  }
                }}
              >
                Melhores Maquininhas de Cartão
              </a>
            </li>
            <li>
              <a
                href="/melhor-cartao-credito"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-cc');
                  }
                }}
              >
                Melhores Cartões de Crédito
              </a>
            </li>
            <li>
              <a
                href="/emprestimo/0/simulador"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-emp');
                  }
                }}
              >
                Simulador de Empréstimo
              </a>
            </li>
            <li>
              <a
                href="/melhores-fundos-de-investimento"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-fun');
                  }
                }}
              >
                Melhores Fundos de Investimento
              </a>
            </li>
            <li>
              <a
                href="/melhores-fundos-imobiliarios"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-fii');
                  }
                }}
              >
                Melhores Fundos Imobiliários
              </a>
            </li>
            <li>
              <a
                href="/melhores-acoes"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-acao');
                  }
                }}
              >
                Melhores Ações
              </a>
            </li>
            <li>
              <a
                href="/melhores-etf"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-etf');
                  }
                }}
              >
                Melhores ETFs
              </a>
            </li>
            <li>
              <a
                href="/melhores-bdr-etf"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-bdretf');
                  }
                }}
              >
                Melhores BDRs de ETFs
              </a>
            </li>
            <li>
              <a
                href="/melhores-bdr"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-bdr');
                  }
                }}
              >
                Melhores BDRs
              </a>
            </li>
            <li>
              <a
                href="/melhores-criptomoeda"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-cripto');
                  }
                }}
              >
                Melhores Criptomoedas
              </a>
            </li>
          </ol>
        </div>
        {!mobile && (
          <div className={styles.divAdsense}>
            <AdsContent3 />
          </div>
        )}
      </div>
    </>
  );
};

export default Categorias;
