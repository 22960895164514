import React from 'react';
import { Container, ListGroup, Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
// import styles from '../../Pages/ControleFinanceiro/css/TabelaMovimento.module.css';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import { formataData, formataMoedaCF } from '../Helper/DateUtilities';
import ModalCadastroMovimento from './ModalCadastroMovimento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillAlt,
  faMoneyBillWaveAlt,
  faMoneyCheck,
} from '@fortawesome/free-solid-svg-icons';
import ModalExcluiMov from './ModalExcluiMov';
import isMobile from '../Helper/Mobile';

const MovimentosListFatura = ({ dataFatura, hide }) => {
  const [show, setShow] = React.useState(false);
  const [showExclusao, setShowExclusao] = React.useState(false);
  const [acao, setAcao] = React.useState(null);

  const [idMovimento, setIdMovimento] = React.useState(null);
  const [idMovimentoDetalhe, setIdMovimentoDetalhe] = React.useState(null);
  const [dataMovimento, setDataMovimento] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);
  const [conta, setConta] = React.useState(null);
  const [cartao, setCartao] = React.useState(null);
  const [situacao, setSituacao] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [formaPagamento, setFormaPagamento] = React.useState(null);
  const [totalParcelas, setTotalParcelas] = React.useState(null);
  const [tipoPagamento, setTipoPagamento] = React.useState(null);

  const mobile = isMobile();

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  const showModalExclusao = (event) => {
    event.preventDefault();
    setShowExclusao(true);
  };

  function editOrDeleteMovimento(event, registro, acao) {
    event.preventDefault();

    setIdMovimento(registro.id_mov);
    setIdMovimentoDetalhe(registro.id);
    setDataMovimento(registro.data_compra);
    setCategoria(registro.id_categoria);
    setValor(registro.valor);
    setDescricao(registro.descricao);
    setFormaPagamento(registro.fg_forma);
    setSituacao(registro.id_situacao);
    setConta(registro.id_conta);
    setCartao(registro.id_cartao);
    setTotalParcelas(registro.parcela);
    setTipoPagamento(registro.tipo_pagamento);

    if (acao === 'edit') {
      showModal(event);
    } else {
      showModalExclusao(event);
    }
  }

  return (
    <>
      <div className={styles.fatura}>
        <Table responsive hover size="sm" className={styles.tabela}>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Valor</th>
              <th>Cartão</th>
              <th>Editar/Excluir</th>
            </tr>
          </thead>
          <tbody>
            {dataFatura.map((registro, index) => (
              <tr
                style={{ backgroundColor: 'white' }}
                key={registro.descricao + '-' + index.toString()}
              >
                <td>
                  <div style={{ display: 'block' }}>
                    <div>
                      <span className={styles.spanDescricao}>
                        {registro.descricao + ' ' + registro.parcela}
                      </span>
                    </div>
                    <div>
                      <span className={styles.spanDetalhes}>
                        {formataData(registro.data_compra) +
                          '-' +
                          registro.categoria}
                      </span>
                    </div>
                  </div>
                </td>
                <td>{formataMoedaCF(registro.valor, hide)}</td>
                <td>{registro.cartao}</td>
                <td>
                  <span className="d-inline-block">
                    <button
                      className={styles.btnEdit}
                      onClick={(e) => {
                        setAcao('UPDATE');
                        editOrDeleteMovimento(e, registro, 'edit');
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </button>
                  </span>

                  <span className="d-inline-block">
                    <button
                      className={styles.btnEdit}
                      onClick={(e) => {
                        editOrDeleteMovimento(e, registro, 'delete');
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {show && (
        <ModalCadastroMovimento
          onClose={() => setShow(false)}
          show={show}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          conta={'pgto-' + tipoPagamento + '|' + conta}
          cartao={'pgto-' + tipoPagamento + '|' + cartao}
          valor={parseFloat(valor).toFixed(2)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          acao={acao}
          is_fatura={true}
          tipoLancamento={'Despesa'}
        />
      )}
      {showExclusao && (
        <ModalExcluiMov
          onClose={() => setShowExclusao(false)}
          show={showExclusao}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          valor={parseFloat(valor)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          tipoLancamento={'Despesa'}
        />
      )}
    </>
  );
};

export default MovimentosListFatura;
