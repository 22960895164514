import React from 'react';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';

import {
  formataData,
  formataMoeda,
  formataMoedaCF,
} from '../Helper/DateUtilities';
import ModalPatrimonio from './ModalPatrimonio';

const PatrimonioAtualizacaoList = ({ data, hide }) => {
  const [show, setShow] = React.useState(false);
  const [idPatrimonio, setIdPatrimonio] = React.useState(null);
  const [idAtualizacao, setIdAtualizacao] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [dataInicio, setDataInicio] = React.useState(null);
  const [dataAtualizacao, setDataAtualizacao] = React.useState(null);
  const [valorInicial, setValorInicial] = React.useState(null);
  const [valorAtual, setValorAtual] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  const [tipoInvestimento, setTipoInvestimento] = React.useState(null);
  const [instituicao, setInstituicao] = React.useState(null);
  const [idMeta, setIdMeta] = React.useState(null);
  const [prazo, setPrazo] = React.useState(null);

  const [acao, setAcao] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'Atualização', accessor: 'atualizacao' },
      { Header: 'Valor Atual', accessor: 'valor_atual' },
      { Header: 'id', accessor: 'id' },
      { Header: 'id_patrimonio', accessor: 'id_patrimonio' },
      { Header: 'descricao', accessor: 'descricao' },
      { Header: 'valor_inicial', accessor: 'valor_inicial' },
      { Header: 'tipo', accessor: 'tipo' },
      { Header: 'data_inicial', accessor: 'data_inicial' },
      { Header: 'registro', accessor: 'registro' },
      { Header: 'Tipo Investimento', accessor: 'tipo_inv' },
      { Header: 'Prazo', accessor: 'prazo' },
      { Header: 'Instituição', accessor: 'instituicao' },
      { Header: 'id_meta', accessor: 'id_meta' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: [
      'id',
      'id_patrimonio',
      'descricao',
      'valor_inicial',
      'data_inicial',
      'registro',
      'tipo',
    ],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    console.log(data);
    event.preventDefault();
    setShow(true);
  };

  function editMovimento(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_patrimonio') {
        setIdPatrimonio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdAtualizacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'descricao') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'data_inicial') {
        setDataInicio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'valor_inicial') {
        setValorInicial(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor Atual') {
        setValorAtual(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Atualização') {
        setDataAtualizacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo') {
        setTipo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo Investimento') {
        setTipoInvestimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Prazo') {
        setPrazo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Instituição') {
        setInstituicao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_meta') {
        setIdMeta(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removeDivida(event, row) {
    event.preventDefault();
    let id_divida = '';
    let id_atualizacao = '';
    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_patrimonio') {
        id_divida = row.allCells[i].value;
      }
      if (row.allCells[i].column.Header === 'id') {
        id_atualizacao = row.allCells[i].value;
      }
    }
    // console.log(id_divida, id_abatimento);
    deleteItem(id_divida, id_atualizacao);
  }

  function novaAtualizacao(event, row) {
    event.preventDefault();
    // console.log(rows);
    var row = rows[0];

    setAcao('EVOLUCAO');

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_patrimonio') {
        setIdPatrimonio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'descricao') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'data_inicial') {
        setDataInicio(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'valor_inicial') {
        setValorInicial(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo') {
        setTipo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo Investimento') {
        setTipoInvestimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Prazo') {
        setPrazo(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Instituição') {
        setInstituicao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_meta') {
        setIdMeta(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function deleteItem(id_patrimonio, id_atualizacao) {
    const urlPostPortfolio = `https://jtjvccyz55.execute-api.sa-east-1.amazonaws.com/producao/patrimonio?id=${id_patrimonio}&id_atualizacao=${id_atualizacao}&metodo=DELETE-filho`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <div>
        <Table
          responsive
          // bordered
          hover={false}
          size="sm"
          className={stylesTabela.tabelaDetalhe}
          {...getTableProps()}
        >
          <thead>
            <tr>
              <th>Atualização</th>
              <th>Valor Atual</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr key={row.cells[0].value.toString().replaceAll('-', '')}>
                    <td>{formataData(row.cells[0].value)}</td>
                    <td>{formataMoedaCF(row.cells[1].value, hide)}</td>
                    <td>
                      <div className={stylesTabela.divBtnEdit}>
                        <OverlayTrigger overlay={<Tooltip>Alterar</Tooltip>}>
                          <button
                            className={stylesTabela.btnEdit}
                            onClick={(e) => {
                              setAcao('UPDATE-filho');
                              editMovimento(e, row);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip>Excluir</Tooltip>}>
                          <button
                            className={stylesTabela.btnEdit}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  'Tem certeza que deseja EXCLUIR a atualização do dia ' +
                                    row.cells[0].value +
                                    ' no valor de ' +
                                    formataMoeda(row.cells[1].value) +
                                    '?',
                                )
                              ) {
                                removeDivida(e, row);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
        <div>
          <button
            className={stylesButton.button}
            onClick={(e) => {
              novaAtualizacao(e, rows);
            }}
          >
            Nova Atualização
          </button>
        </div>
      </div>
      {show && (
        <ModalPatrimonio
          onClose={() => setShow(false)}
          show={show}
          acao={acao}
          idPatrimonio={idPatrimonio}
          idAtualizacao={idAtualizacao}
          descricao={descricao}
          dataInicio={dataInicio}
          valorInicial={valorInicial}
          tipo={tipo}
          instituicao={instituicao}
          tipoInvestimento={tipoInvestimento}
          prazo={prazo}
          idMeta={idMeta}
          dataAtualizacao={acao === 'UPDATE-filho' ? dataAtualizacao : null}
          valorAtual={acao === 'UPDATE-filho' ? valorAtual : null}
        />
      )}
    </>
  );
};

export default PatrimonioAtualizacaoList;
