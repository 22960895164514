import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesButton from './css/Button.module.css';
import { AWS_GET_USER_CATEGORIAS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import ModalCadastroCategoria from '../../Components/ControleFinanceiro/ModalCadastroCategoria';
import CategroriaList from '../../Components/ControleFinanceiro/CategroriaList';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Categoria = () => {
  const { data, loading, error, request } = useFetch();
  const [show, setShow] = React.useState(false);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_CATEGORIAS(
      window.localStorage.getItem('gd_uid'),
    );
    // console.log(window.localStorage.getItem('gd_uid'));
    request(url, options);
  }, [request]);

  return (
    <>
      <Head
        metaDescription="Categorias | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
        title="Categorias | Guardar Dinheiro PRO"
        h1="Categorias | Guardar Dinheiro PRO"
        canonical={window.location.href}
        nivel="1"
        id_wp_post={9000}
      />
      <BreadcumbH1
        paginaAtual=":: MINHAS CATEGORIAS"
        texto=":: MINHAS CATEGORIAS"
        isPro={true}
      />
      <Container>
        <AdsContent1 />
        <h1 className={stylesTipografia.h1}>:: MINHAS CATEGORIAS</h1>
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className={stylesButton.button} onClick={(e) => showModal(e)}>
            Registrar
          </button>
        </div>

        {data ? (
          <CategroriaList
            data={data.filter(
              (dados) =>
                dados.fg_status !== 'A' &&
                dados.nome_categoria !== 'Geral' &&
                dados.nome_categoria !== 'Gastos Essenciais' &&
                dados.nome_categoria !== 'Gastos Não Essenciais' &&
                dados.nome_categoria !== 'Guardar Dinheiro - Seu Futuro',
            )}
          />
        ) : (
          <Loading />
        )}

        {show && (
          <ModalCadastroCategoria onClose={() => setShow(false)} show={show} />
        )}
      </Container>
    </>
  );
};

export default Categoria;
