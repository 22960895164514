import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from './Switch.module.css';
const Switch = ({ id, label, name, onChange, switchState, type }) => {
  return (
    <Form.Check // prettier-ignore
      type={type}
      id={id}
      label={label}
      className={styles.switch}
      defaultChecked={switchState}
      onChange={onChange}
      name={name}
    />
  );
};

export default Switch;
