import React from 'react';
import Modal from 'react-bootstrap/Modal';
import stylesModal from '../../Pages/ControleFinanceiro/css/Modal.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';
import stylesSelect from '../../Pages/ControleFinanceiro/css/Select.module.css';
import Input from '../Forms/Input';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';
import Label from '../Forms/Label';
import { formataMoeda } from '../Helper/DateUtilities';
import Select from '../Forms/Select';

function ModalPatrimonio(props) {
  const inputElement = React.useRef();
  const [idPatrimonio, setIdPatrimonio] = React.useState(null);
  const [idAtualizacao, setIdAtualizacao] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [dataInicio, setDataInicio] = React.useState(null);
  const [dataAtualizacao, setDataAtualizacao] = React.useState(null);
  const [valorInicial, setValorInicial] = React.useState(null);
  const [valorAtual, setValorAtual] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  const [tipoInvestimento, setTipoInvestimento] = React.useState(null);
  const [instituicao, setInstituicao] = React.useState(null);
  const [idMeta, setIdMeta] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    // console.log(props);
    if (props.idPatrimonio) {
      setIdPatrimonio(props.idPatrimonio);
    }
    if (props.idAtualizacao) {
      setIdAtualizacao(props.idAtualizacao);
    }
    if (props.descricao) {
      setDescricao(props.descricao);
    }
    if (props.dataInicio) {
      setDataInicio(props.dataInicio);
    }
    if (props.dataAtualizacao) {
      setDataAtualizacao(props.dataAtualizacao);
    }
    if (props.valorInicial) {
      setValorInicial(formataMoeda(props.valorInicial));
    }
    if (props.valorAtual) {
      setValorAtual(formataMoeda(props.valorAtual));
    }
    if (props.tipo) {
      setTipo(props.tipo);
    }

    if (props.instituicao) {
      setInstituicao(props.instituicao);
    }

    if (props.tipoInvestimento) {
      setTipoInvestimento(props.tipoInvestimento);
    }

    if (props.idMeta) {
      setIdMeta(props.idMeta);
    }
    disableElementsToEdit();
  }, [props]);

  function disableElementsToEdit() {
    if (props.acao) {
      if (props.acao === 'INSERT' || props.acao === 'UPDATE-mae') {
        const divValorAtual = document.getElementById('div-valor-atual');
        divValorAtual.style.display = 'none';
        const divDataAtualizacao = document.getElementById(
          'div-data-atualizacao',
        );
        divDataAtualizacao.style.display = 'none';

        if (props.acao === 'UPDATE-mae') {
          // var nodes = document
          //   .getElementById('div-patrimonio-mae')
          //   .getElementsByTagName('*');
          // for (var x = 0; x < nodes.length; x++) {
          //   if (nodes[x].id !== 'input-descricao') {
          //     console.log(nodes[x]);
          //     // nodes[x].disabled = true;
          //   }
          // }
          document.getElementById('input-data-inicio').disabled = true;
          document.getElementById('input-valor-inicial').disabled = true;
        }
      } else if (props.acao === 'EVOLUCAO' || props.acao === 'UPDATE-filho') {
        var nodes = document
          .getElementById('div-patrimonio-mae')
          .getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
          nodes[i].disabled = true;
        }
      }
    }
  }

  if (!props.show) {
    return null;
  }

  function postItem(
    id_patrimonio,
    id_atualizacao,
    data_inicio,
    data_atualizacao,
    instituicao,
    descricao,
    valor_inicial,
    valor_atual,
    tipo,
    tipo_inv,
    id_meta,
    acao,
  ) {
    let user_id = window.localStorage.getItem('gd_uid');

    if (!id_patrimonio) {
      id_patrimonio = '1';
    }

    if (acao === 'INSERT') {
      data_atualizacao = data_inicio;
      valor_atual = valor_inicial;
    }

    if (tipo === 'Ativos Imobilizados') {
      tipo_inv = '';
      instituicao = '';
    }

    console.log(instituicao);

    const urlPostPortfolio = `https://jtjvccyz55.execute-api.sa-east-1.amazonaws.com/producao/patrimonio?user_id=${user_id}&id=${id_patrimonio}&id_atualizacao=${id_atualizacao}&data_inicio=${data_inicio}&data_atualizacao=${data_atualizacao}&descricao=${encodeURIComponent(
      descricao,
    )}&valor_inicial=${valor_inicial}&valor_atual=${valor_atual}&tipo=${tipo}&metodo=${acao}&tipo_inv=${tipo_inv}&id_meta=${id_meta}&instituicao=${instituicao}`;
    console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          setLoading(false);
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addPatrimonio(event) {
    event.preventDefault();
    setLoading(true);

    if (isValidForm()) {
      postItem(
        idPatrimonio,
        idAtualizacao,
        dataInicio,
        dataAtualizacao,
        document.getElementById('select-banco').value,
        descricao,
        valorInicial
          ? valorInicial.replace('R$', '').replaceAll('.', '').replace(',', '.')
          : valorInicial,
        valorAtual
          ? valorAtual.replace('R$', '').replaceAll('.', '').replace(',', '.')
          : valorAtual,
        tipo,
        tipoInvestimento,
        document.getElementById('select-meta').value,
        props.acao,
      );
    }
  }

  function isValidForm() {
    let retorno = true;
    if (descricao) {
      if (descricao.length < 1) {
        document.getElementById('v-descricao').innerHTML =
          'Descrição obrigatória';
        retorno = false;
      }
    } else {
      document.getElementById('v-descricao').innerHTML =
        'Descrição obrigatória';
      retorno = false;
    }

    if (dataInicio) {
      if (dataInicio.length < 1) {
        document.getElementById('v-data-inicio').innerHTML =
          'Data Início obrigatório';
        retorno = false;
      }
    } else {
      document.getElementById('v-data-inicio').innerHTML =
        'Data Início obrigatório';
      retorno = false;
    }

    if (props.acao !== 'INSERT') {
      if (dataAtualizacao) {
        if (dataAtualizacao.length < 1) {
          document.getElementById('v-data-atualizacao').innerHTML =
            'Data Atualização obrigatória';
          retorno = false;
        }
      } else {
        document.getElementById('v-data-atualizacao').innerHTML =
          'Data Atualização obrigatória';
        retorno = false;
      }

      if (valorAtual) {
        if (valorAtual.length < 1) {
          document.getElementById('v-valor-atual').innerHTML =
            'Valor Atual obrigatório';
          retorno = false;
        } else {
          if (parseFloat(valorAtual.replace(',', '.')) <= 0) {
            document.getElementById('v-valor-atual').innerHTML =
              'Valor Atual obrigatória';
            retorno = false;
          }
        }
      } else {
        document.getElementById('v-valor-atual').innerHTML =
          'Valor Atual obrigatória';
        retorno = false;
      }
    }

    if (valorInicial) {
      if (valorInicial.length < 1) {
        document.getElementById('v-valor-inicial').innerHTML =
          'Valor Inicial obrigatório';
        retorno = false;
      } else {
        if (parseFloat(valorInicial.replace(',', '.')) <= 0) {
          document.getElementById('v-valor-inicial').innerHTML =
            'Valor Inicial obrigatório';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-valor-inicial').innerHTML =
        'Valor Inicial obrigatório';
      retorno = false;
    }

    if (tipo) {
      if (tipo.length < 1) {
        document.getElementById('v-tipo').innerHTML = 'Renda obrigatória';
        retorno = false;
      } else {
        if (parseFloat(tipo.replace(',', '.')) <= 0) {
          document.getElementById('v-tipo').innerHTML = 'Renda obrigatória';
          retorno = false;
        }
      }
    } else {
      document.getElementById('v-tipo').innerHTML = 'Renda obrigatória';
      retorno = false;
    }

    if (props.acao === 'UPDATE-mae') {
      retorno = true;
      if (descricao) {
        if (descricao.length < 1) {
          document.getElementById('v-descricao').innerHTML =
            'Descrição obrigatória';
          retorno = false;
        }
      } else {
        document.getElementById('v-descricao').innerHTML =
          'Descrição obrigatória';
        retorno = false;
      }
    }

    if (retorno) {
      if (props.acao === 'RENDA') {
        if (
          parseFloat(props.dataInicio.replaceAll('-', '')) >=
          parseFloat(dataAtualizacao.replaceAll('-', ''))
        ) {
          document.getElementById('v-data-atualizacao').innerHTML =
            'Data Atualização deve ser maior que a Data de Início';
          retorno = false;
        }
      }
    }

    if (!retorno) {
      setLoading(false);
    }

    // retorno = false;
    return retorno;
  }

  function retornaTipoPatrimonio() {
    return (
      <select
        className={stylesSelect.select}
        onChange={(e) => {
          setTipo(e.target.value);
          if (e.target.value === 'Ativos Imobilizados') {
            document.getElementById('select-tipo-inv').disabled = true;
            document.getElementById('select-meta').disabled = true;
            document.getElementById('select-banco').disabled = true;
          } else {
            document.getElementById('select-tipo-inv').disabled = false;
            document.getElementById('select-meta').disabled = false;
            document.getElementById('select-banco').disabled = false;
          }
        }}
        value={tipo}
        id={'select-tipo'}
      >
        <option key="Selecione:" value="Selecione">
          Selecione
        </option>
        <option key="Ativos Financeiros" value="Ativos Financeiros">
          Ativos Financeiros
        </option>
        <option key="Ativos Imobilizados" value="Ativos Imobilizados">
          Ativos Imobilizados
        </option>
      </select>
    );
  }

  function retornaTipoInvestimento() {
    return (
      <select
        className={stylesSelect.select}
        onChange={(e) => {
          setTipoInvestimento(e.target.value);
        }}
        value={tipoInvestimento}
        id={'select-tipo-inv'}
      >
        <option key="Selecione:" value="Selecione">
          Selecione
        </option>
        <option key="Renda Fixa" value="Renda Fixa">
          Renda Fixa
        </option>
        <option key="Renda Variavel" value="Renda Variavel">
          Renda Variavel
        </option>
      </select>
    );
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ top: '7%' }}
      size="xl"
    >
      <Modal.Header className={stylesModal.modalHeader}>
        <Modal.Title className={stylesModal.modalTitle}>
          {'Patrimônio'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={stylesModal.containerModal} id="div-patrimonio-mae">
          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-descricao'}
              label={'Descrição'}
              type="text"
              value={descricao}
              ref={inputElement}
              onChange={({ target }) => {
                setDescricao(target.value);
                document.getElementById('v-descricao').innerHTML = '';
              }}
            ></Input>
            <p id="v-descricao" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Input
              name={'input-data-inicio'}
              label={'Inicio'}
              type="date"
              placeholder="dd-mm-yyyy"
              value={dataInicio}
              ref={inputElement}
              onChange={({ target }) => {
                setDataInicio(target.value);
                document.getElementById('v-data-inicio').innerHTML = '';
              }}
            ></Input>
            <p id="v-data-inicio" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <InputMask
              name={'input-valor-inicial'}
              label="Valor"
              placeholder="R$"
              value={valorInicial}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) => {
                setValorInicial(
                  target.value.replace('R$', '').replace('.', ''),
                );

                document.getElementById('v-valor-inicial').innerHTML = '';
              }}
            ></InputMask>
            <p id="v-valor-inicial" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Label name={'Tipo'} label={'Tipo'} />
            {retornaTipoPatrimonio()}
            <p id="v-tipo" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Label name={'Tipo Investimento'} label={'Tipo Investimento'} />
            {retornaTipoInvestimento()}
            <p id="v-tipo_inv" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Select
              tipo="banco"
              label="Instituição"
              name="select-banco"
              valor={props.instituicao}
            />
            <p id="v-banco" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer}>
            <Select
              tipo="meta"
              label="Projeto/Meta"
              name="select-meta"
              valor={props.idMeta}
              is_disabled={
                (props.acao === 'EVOLUCAO') | (props.acao === 'UPDATE-filho')
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className={stylesModal.containerModal}>
          <div className={stylesModal.itemContainer} id="div-data-atualizacao">
            <Input
              name={'input-data-atualizacao'}
              label={'Data Atualização'}
              type="date"
              placeholder="dd-mm-yyyy"
              value={dataAtualizacao}
              ref={inputElement}
              onChange={({ target }) => {
                setDataAtualizacao(target.value);
                document.getElementById('v-data-atualizacao').innerHTML = '';
              }}
            ></Input>
            <p id="v-data-atualizacao" className={stylesTextos.textoErros}></p>
          </div>

          <div className={stylesModal.itemContainer} id="div-valor-atual">
            <InputMask
              name={'input-valor-atual'}
              label="Valor Atual"
              placeholder="R$"
              value={valorAtual}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) => {
                setValorAtual(target.value.replace('R$', '').replace('.', ''));
                document.getElementById('v-valor-atual').innerHTML = '';
              }}
            ></InputMask>
            <p id="v-valor-atual" className={stylesTextos.textoErros}></p>
          </div>
        </div>

        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className={stylesButton.buttonCancelar}
          variant="primary"
          onClick={props.onClose}
        >
          Fechar
        </button>

        {loading ? (
          <button className={stylesButton.button} disabled>
            Salvando...
          </button>
        ) : (
          <button
            className={stylesButton.button}
            onClick={(e) => addPatrimonio(e)}
          >
            Salvar
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPatrimonio;
