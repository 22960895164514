import React from 'react';
import styles from './BreadcumbH1.module.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import isMobile from '../Helper/Mobile';
// import AdsFixedMobile from '../AdsJoinAds/AdsFixedMobile';
// import AdsFixedDesktop from '../AdsJoinAds/AdsFixedDesktop';

const BreadcumbH1 = (props) => {
  const mobile = isMobile();
  return (
    <div
      className={
        window.location.href.includes('plano-pro')
          ? styles.divMasterPro
          : styles.divMaster
      }
    >
      {/* {mobile ? <AdsFixedMobile /> : <AdsFixedDesktop />} */}
      <div className={styles.div}>
        <Breadcrumb className={styles.breadcumb}>
          <Breadcrumb.Item href={props.isPro ? '/plano-pro/home' : '/'}>
            <FontAwesomeIcon icon={faHome} className={styles.spanBreadcumb} />
            <span className={styles.spanBreadcumb}>{' Home'}</span>
          </Breadcrumb.Item>
          {props.paginaAnterior && (
            <Breadcrumb.Item href={props.rotaPaginaAnterior}>
              <FontAwesomeIcon className={styles.spanBreadcumb} />
              <span className={styles.spanBreadcumb}>
                {props.paginaAnterior}
              </span>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active style={{ color: '#3F3F44' }}>
            {props.paginaAtual}
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />
        {/* <h1 className={props.isPro ? stylesTipografia.h1 : styles.h1}>
          {props.texto}
        </h1> */}
      </div>
    </div>
  );
};

export default BreadcumbH1;
