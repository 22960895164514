import { useParams } from 'react-router-dom';
import React from 'react';
import { Container } from 'react-bootstrap';
import Head from '../../Components/Tags/Head';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import AdsAmpContent2 from '../../Components/AdsJoinAds/AdsAmpContent2';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import stylesbutton from '../../Pages/_css/Button.module.css';
import { formataMoeda } from '../../Components/Helper/DateUtilities';
import ItemDuvida from '../../Components/Tags/ItemDuvida';
import stylePost from '../../Components/Tags/Post.module.css';
import isMobile from '../../Components/Helper/Mobile';
import H2 from '../../Components/Tags/H2';
import AdsContent3 from '../../Components/AdsJoinAds/AdsContent3';
import AdsContent4 from '../../Components/AdsJoinAds/AdsContent4';
import SideBar from '../../Components/Categorias/SideBar';
import CleverAdsDesktop from '../../Components/AdsAposta/CleverAdsDesktop';
import CookieManager from '../../Components/Helper/CookieManager';
import AdsSticky from '../../Components/AdsUtil/AdsSticky';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import AdsContent2 from '../../Components/AdsJoinAds/AdsContent2';

const OndeInvestir = () => {
  const mobile = isMobile();
  const params = useParams();
  const [inputValor, setInputValor] = React.useState(params.valor);

  React.useEffect(() => {
    // retornaResultados(params.valor, 12);
    // console.log(params.valor);

    if (params.valor > 0) {
      setInputValor(formataMoeda(parseFloat(params.valor)));
    } else {
      setInputValor('seu dinheiro');
    }
  }, [params.valor]);

  function getTitle() {
    let titulo = '';

    if (params.valor > 0) {
      titulo = 'Onde Investir ' + params.valor + ' Reais ';
    } else {
      titulo = 'Onde Investir Seu Dinheiro ';
    }

    return titulo;
  }

  return (
    <>
      <Head
        nivel="2"
        title={getTitle()}
        metaDescription={getTitle()}
        h1={getTitle()}
        canonical={
          'https://www.guardardinheiro.com.br/onde-investir/' + params.valor
        }
        id_wp_post={1600}
      />

      <BreadcumbH1 paginaAtual={getTitle()} texto={getTitle()} />
      <CookieManager category={'Investimentos'} />
      <Container>
        <AdsSticky ads={'content1'} pixels={mobile ? 600 : 100} />
        <div className={stylePost.container}>
          <div className={stylePost.itemPost}>
            <h1 className={stylesTipografia.h1}>{getTitle()}</h1>
            <p>
              {'Se você está se perguntando onde investir ' +
                inputValor +
                ' de forma inteligente, saiba que existem investimentos que podem trazer bons retornos no futuro.'}
            </p>
            <p>
              Neste artigo, exploraremos algumas opções viáveis, abordando desde
              investimentos conservadores até alternativas um pouco mais
              ousadas, sempre pensando em maximizar seus ganhos e, quem sabe,
              até ganhar dinheiro com esses rendimentos.
            </p>

            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' no Tesouro Direto'}
            </h2>
            <p>
              {'O Tesouro Direto é, sem dúvida, uma das opções mais seguras para quem deseja começar a investir. Nesse programa do governo federal, você pode aplicar ' +
                inputValor +
                ' em títulos públicos que oferecem diferentes tipos de rentabilidade, como pré-fixada, pós-fixada e atrelada à inflação.'}
            </p>

            <p>
              Além disso, o Tesouro Direto é uma excelente escolha para quem
              busca uma reserva de emergência, pois oferece liquidez diária.
              Assim, você pode retirar seu dinheiro a qualquer momento, caso
              precise. Outra vantagem é que, mesmo com um pequeno valor
              investido, é possível ver seu dinheiro crescer de forma
              consistente e segura.
            </p>

            <p>
              Faça uma simulação aqui de quanto seu dinheiro pode render no
              Tesouro direto.
            </p>
            <a
              href={'/quanto-rende/' + params.valor + '/tesouro'}
              className={stylesbutton.a}
            >
              {'Simulação de ' + inputValor + ' no Tesouro Direto'}
            </a>

            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' em CDB'}
            </h2>
            <p>
              {'Uma outra opção segura de onde investir ' +
                inputValor +
                ' que pode ser até melhor que o Tesouro Direto é o CDB. Esses títulos de renda fixa oferecem rendimento bem atrativos, justamente para atrair mais investidores.'}
            </p>

            <AdsContent1 />
            <p>
              Ademais, uma das grandes vantagens dos CDBs é que eles são
              garantidos pelo Fundo Garantidor de Créditos (FGC) até o limite de
              R$ 250.000 por CPF, por instituição financeira. Isso significa
              que, mesmo que o banco quebre, você não perde seu dinheiro,
              garantindo que seu investimento seja seguro e lucrativo.
            </p>

            <p>
              {'Confira alguns exemplos abaixo de quanto seu dinheiro pode render ao investir ' +
                inputValor +
                '. em alguns dos melhores CDBs atualmente.'}
            </p>
            <a
              href={'/quanto-rende/' + params.valor + '/pagbank'}
              className={stylesbutton.a}
            >
              {'Quanto rende ' + inputValor + ' no PagBank'}
            </a>
            <a
              href={'/quanto-rende/' + params.valor + '/picpay'}
              className={stylesbutton.a}
            >
              {'Quanto rende ' + inputValor + ' no PicPay'}
            </a>
            <a
              href={'/quanto-rende/' + params.valor + '/nubank'}
              className={stylesbutton.a}
            >
              {'Quanto rende ' + inputValor + ' no Nubank'}
            </a>
            <a
              href={'/quanto-rende/' + params.valor + '/c6'}
              className={stylesbutton.a}
            >
              {'Quanto rende ' + inputValor + ' no C6'}
            </a>
            <a
              href={'/quanto-rende/' + params.valor + '/mercadopago'}
              className={stylesbutton.a}
            >
              {'Quanto rende ' + inputValor + ' no Mercado Pago'}
            </a>

            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' em Fundos Imobiliários'}
            </h2>
            <p>
              {'Outra opção interessante de onde investir ' +
                inputValor +
                ' são em Fundos Imobiliários (FIIs). Esses fundos permitem que você participe do mercado imobiliário de maneira indireta, comprando cotas que representam parte de um imóvel ou de um conjunto de imóveis, como shoppings, edifícios comerciais e galpões logísticos.'}
            </p>

            <AdsContent2 />
            <p>
              Você sabia que é possível investir em imóveis de forma bem mais
              acessível? Os Fundos Imobiliários, ou FIIs, oferecem cotas que
              podem custar menos de R$10 cada uma. Isso significa que você pode
              começar a receber uma renda extra, semelhante ao aluguel de um
              imóvel, com um investimento bem pequeno.
            </p>
            <p>
              É como se você estivesse se tornando sócio de um grande prédio ou
              de um shopping center, dividindo os lucros com outros
              investidores.
            </p>
            <p>
              Um bom exemplo de Fundo Imobiliário é o{' '}
              <a href="/fii/mxrf11">MXRF11</a>. Ele é o mais popular entre os
              investidores, com o maior número de pessoas aplicando seu dinheiro
              nele. Para quem está começando, este fundo imobiliário é bem
              barato e uma ótima opção.
            </p>
            <p>
              {'Confira abaixo os melhores fundos imobiliários até o momento e simule quanto você pode ganhar investindo ' +
                inputValor +
                ' em fundos imobiliários.'}
            </p>
            <a href="/melhores-fundos-imobiliarios" className={stylesbutton.a}>
              {'Melhores Fundos imobiliários do momento'}
            </a>

            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' em Ações'}
            </h2>

            <p>
              {'Para quem tem um perfil um pouco mais arrojado e está disposto a encarar os riscos do mercado de ações, investir ' +
                inputValor +
                ' em ações pode ser uma excelente alternativa para fazer seus dinheiro renderem mais. '}
            </p>

            <p>
              Atualmente, várias corretoras permitem que você compre ações
              fracionadas, ou seja, você pode comprar menos de uma ação
              completa, investindo exatamente o valor que tem disponível.
              Contudo, investir em ações exige estudo e acompanhamento do
              mercado, pois é um investimento mais volátil e com risco de perda.
            </p>

            <AdsContent3 />
            <p>
              Entretanto, as ações também oferecem um potencial de ganho muito
              superior aos investimentos de renda fixa, especialmente a longo
              prazo. Além disso, empresas que pagam bons dividendos podem
              proporcionar uma renda passiva interessante para o investidor.
            </p>

            <p>
              {'Confira abaixo as melhores ações até o momento e simule quanto você pode ganhar de dividendos investindo ' +
                inputValor +
                '.'}
            </p>
            <a href="/melhores-acoes" className={stylesbutton.a}>
              {'Melhores Ações do momento'}
            </a>

            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' em ETF'}
            </h2>
            <p>
              {'Os ETFs (Exchange Traded Funds) são uma ótima opção para quem deseja investir ' +
                inputValor +
                ' e diversificar seus investimentos sem precisar escolher ações individuais.'}
            </p>
            <p>
              Os ETFs são como cestas que reúnem várias ações de uma só vez.
              Imagine que você queira investir em diversas empresas, mas não
              sabe quais escolher. Com os ETFs, você compra um pedacinho de
              todas essas empresas de uma só vez! É como se você estivesse
              investindo em um índice, como o Ibovespa. Assim, você diversifica
              seus investimentos e diminui os riscos, sem precisar ser um
              especialista em bolsa.
            </p>

            <AdsContent4 />
            <p>
              {'Confira abaixo os melhores ETFs até o momento e simule quanto você pode ganhar investindo ' +
                inputValor +
                '.'}
            </p>
            <a href="/melhores-etf" className={stylesbutton.a}>
              {'Melhores ETFs do momento'}
            </a>
            <h2 className={stylesTipografia.h2}>
              {'Investir ' + inputValor + ' em BDR'}
            </h2>
            <p>
              {'Se você deseja investir em grandes empresas globais sem sair do Brasil, os BDRs (Brazilian Depositary Receipts) são a escolha certa para investir ' +
                inputValor +
                '.'}
            </p>
            <p>
              {'Com eles, você pode comprar recibos que representam ações de empresas estrangeiras, como Apple, Amazon e Google, utilizando ' +
                inputValor +
                '. Isso permite que você participe do desempenho dessas gigantes internacionais, diversificando ainda mais sua carteira e, eventualmente, obtendo lucros significativos com a valorização dessas ações no exterior'}
            </p>
            <p>
              {'Confira abaixo os melhores BDRs até o momento e simule quanto você pode ganhar investindo ' +
                inputValor +
                '.'}
            </p>
            <a href="/melhores-bdr" className={stylesbutton.a}>
              {'Melhores BDRs do momento'}
            </a>

            <h2 className={stylesTipografia.h2}>
              {'Conclusão - Onde Investir ' + inputValor}
            </h2>
            <p>
              {'Investir ' +
                inputValor +
                ' com as estratégias certas, é possível fazer esse montante crescer e até mesmo lucrar com os rendimentos. Seja optando por investimentos seguros como o Tesouro Direto e CDB, ou explorando alternativas mais arrojadas como Ações, Fundos Imobiliários e ETF. O importante é começar a investir e criar o hábito de poupar e reinvestir os ganhos.'}
            </p>
            <p>
              {' Portanto, agora que você já conhece algumas opções de onde investir ' +
                inputValor +
                ', que tal dar o primeiro passo e fazer seu dinheiro trabalhar para você? Se você tiver dúvidas ou outras sugestões de investimento, deixe seu comentário abaixo! Vamos adorar saber sua opinião.'}
            </p>

            <H2 texto="Dúvidas frequentes" />
            <ItemDuvida duvida="cdb" />
            <ItemDuvida duvida="acao" />
            <ItemDuvida duvida="fii" />
            <ItemDuvida duvida="etf" />
            <ItemDuvida duvida="bdr" />
            <br />
          </div>
          <SideBar quantidade={1} />
        </div>
      </Container>

      {/* <div className={styles.divFerramentas}>
        <div className={styles.containerFerramentas}>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>Renda Fixa</p>
              <p className={styles.paragrafo}>
                {'Confira nossa calculadora de renda fixa e veja onde é melhor investir ' +
                  inputValor +
                  ' (Nubank, Picpay, Pagbank e muito mais)'}
              </p>
              <a
                href={'/calculadora-renda-fixa'}
                className={styles.buttonFerramentas}
              >
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>Fundos de Investimentos</p>
              <p className={styles.paragrafo}>
                {'Melhores Fundos de Investimentos do momento para investir ' +
                  inputValor}
              </p>
              <a
                className={styles.buttonFerramentas}
                href="/melhores-fundos-de-investimento"
              >
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>Fundos Imobiliários</p>
              <p className={styles.paragrafo}>
                {'Melhores Fundos Imobiliários do momento disponíveis na Bolsa para investir ' +
                  inputValor}
              </p>
              <a
                className={styles.buttonFerramentas}
                href="/melhores-fundos-imobiliarios"
              >
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>Ações</p>
              <p className={styles.paragrafo}>
                {'Melhores Ações da Bolsa Brasileira do momento para investir ' +
                  inputValor}
              </p>
              <a className={styles.buttonFerramentas} href="/melhores-acoes">
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>ETFs</p>
              <p className={styles.paragrafo}>
                {'Melhores ETFs do momento disponíveis na Bolsa para investir ' +
                  inputValor +
                  '. '}
              </p>
              <a className={styles.buttonFerramentas} href="/melhores-etf">
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>BDRs</p>
              <p className={styles.paragrafo}>
                {'Melhores BDRs para investir ' +
                  inputValor +
                  '. Agora você pode investir no Facebook por exemplo'}
              </p>
              <a className={styles.buttonFerramentas} href="/melhores-bdr">
                CONFIRA{'  '}
              </a>
            </div>
          </div>
          <div className={styles.itemFerramentas}>
            <div>
              <p className={styles.tituloFerramenta}>Criptomoedas</p>
              <p className={styles.paragrafo}>
                {'Confira as Melhores Criptomoedas do momento para investir ' +
                  inputValor}
              </p>
              <a
                className={styles.buttonFerramentas}
                href="/melhores-criptomoeda"
              >
                CONFIRA{'  '}
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default OndeInvestir;
