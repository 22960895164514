import React from 'react';
import { formataMoedaCF, formataPorcentagem } from '../Helper/DateUtilities';
import ProgressBar from 'react-bootstrap/ProgressBar';
import isMobile from '../Helper/Mobile';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';

const BoxBalanco = ({ data, flagAnoOuMes, hide }) => {
  const mobile = isMobile();

  function getSaldoPorBalanco() {
    let dadosBalanco;
    if (flagAnoOuMes === 'mes') {
      dadosBalanco = data.filter(
        (dados) =>
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dadosBalanco = data.filter(
        (dados) =>
          // dados.tipo_registro === '1' &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    // console.log(dadosBalanco);

    let valorDespesa = 0;
    let valorReceita = 0;
    let valorInvestimento = 0;
    dadosBalanco.forEach((registro) => {
      if (registro.tipo === 'Despesa') {
        valorDespesa = valorDespesa + parseFloat(registro.valor);
      } else if (registro.tipo === 'Receita') {
        valorReceita = valorReceita + parseFloat(registro.valor);
      } else if (registro.tipo === 'Investimentos') {
        valorInvestimento = valorInvestimento + parseFloat(registro.valor);
      }
    });

    // console.log(valorDespesa);
    let nowDespesa = 0;
    let nowReceita = 0;
    let nowInvestimentos = 0;
    let resultado = valorReceita - valorDespesa;

    if (valorDespesa > valorReceita) {
      nowDespesa = 100;
      nowReceita = (valorReceita / valorDespesa) * 100;
    } else {
      nowReceita = 100;
      nowDespesa = (valorDespesa / valorReceita) * 100;
    }

    if (valorReceita >= valorDespesa) {
      nowInvestimentos = (valorInvestimento / valorReceita) * 100;
    } else {
      nowInvestimentos = (valorInvestimento / valorDespesa) * 100;
    }

    // console.log(valorInvestimento);
    // console.log(valorReceita);

    let mensagem = '';
    if (valorReceita > valorDespesa) {
      mensagem =
        'Até o momento você utilizou' +
        formataMoedaCF(nowDespesa, hide).replace('R$', '') +
        '% da sua Receita';

      // if (valorInvestimento > 0 && valorInvestimento < valorReceita) {
      //   mensagem =
      //     mensagem +
      //     ' e Investiu' +
      //     formataMoeda(nowInvestimentos).replace('R$', '') +
      //     '% da sua Receita.';
      // }
      if (valorInvestimento === 0) {
        if (valorReceita > valorDespesa) {
          mensagem =
            mensagem +
            '. Está sobrando um valor para você investir. Vamos nessa!';
        }
      } else {
        mensagem = mensagem + '. Parabéns você está conseguindo investir!!';
      }
    } else if (valorDespesa > valorReceita) {
      mensagem =
        'Até o momento você gastou ' +
        formataMoedaCF(valorDespesa - valorReceita, hide).toString() +
        ' a mais que a sua receita. ';

      if (valorReceita > 0) {
        let porcentagemAMais = 100 - (valorReceita / valorDespesa) * 100;
        mensagem =
          mensagem +
          'Você gastou' +
          formataMoedaCF(porcentagemAMais, hide).replace('R$', '') +
          '% a mais do que ganhou.';
      }
    }

    return (
      <>
        <div>
          {nowReceita ? (
            <div style={{ textAlign: 'left' }}>
              <span className={stylesTipografia.span}>Receita</span>
              <div className={stylesDiv.divProgressBar}>
                <div
                  className={stylesDiv.divPercProgressBar}
                  style={{
                    width: nowReceita + '%',
                    backgroundColor: '#28a745',
                  }}
                ></div>
              </div>
            </div>
          ) : null}

          {nowDespesa ? (
            <div style={{ textAlign: 'left' }}>
              <span className={stylesTipografia.span}>Despesa</span>
              <div className={stylesDiv.divProgressBar}>
                <div
                  className={stylesDiv.divPercProgressBar}
                  style={{
                    width: nowDespesa + '%',
                    backgroundColor: '#B22222',
                  }}
                ></div>
              </div>
            </div>
          ) : null}

          {nowInvestimentos ? (
            <div style={{ textAlign: 'left' }}>
              <span className={stylesTipografia.span}>Investimentos</span>
              <div className={stylesDiv.divProgressBar}>
                <div
                  className={stylesDiv.divPercProgressBar}
                  style={{
                    width: nowInvestimentos + '%',
                    backgroundColor: '#008B8B',
                  }}
                ></div>
              </div>
            </div>
          ) : null}

          {/* <span>Resultado</span> */}

          <p className={stylesTipografia.p}>{hide ? '-' : mensagem}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        {getSaldoPorBalanco()}
      </div>
    </>
  );
};

export default BoxBalanco;
