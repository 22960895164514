import React from 'react';

const AdsenseFixedMobileHeader = (props) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ins
        className="adsbygoogle"
        style={{
          // marginTop: '10px',
          display: 'inline-block',
          width: '320px',
          height: '100px',
          // border: '1px solid #00bfff',
        }}
        data-ad-client="ca-pub-4615548473778420"
        data-ad-slot="6051091196"
        data-ad-keywords="investimentos, finanças pessoais"
      ></ins>
    </div>
  );
};
export default AdsenseFixedMobileHeader;
