import React from 'react';
import styles from './SideBar.module.css';
import isMobile from '../Helper/Mobile';
import Share from '../Share/Share';
import AdsContent1 from '../AdsJoinAds/AdsContent1';
import AdsContent2 from '../AdsJoinAds/AdsContent2';
import AdsContent3 from '../AdsJoinAds/AdsContent3';
import AdsContent4 from '../AdsJoinAds/AdsContent4';
import AdsContent5 from '../AdsJoinAds/AdsContent5';
import AdsContent6 from '../AdsJoinAds/AdsContent6';
import AdsMGUIDSidebar from '../AdsMGID/AdsMGUIDSidebar';
import AdsMoneytizerSplitScreen from '../AdsMoneytizer/AdsMoneytizerSplitScreen';
import AdsVertical1 from '../AdsJoinAds/AdsVertical1';

const SideBar = (props) => {
  const mobile = isMobile();

  const renderDivsAnuncioMguid = () => {
    let divs = [];
    for (let i = 1; i < props.quantidade; i++) {
      // divs.push(<div key={i}>Div número {i + 1}</div>);
      divs.push(
        <div className={styles.divAdsense}>
          <AdsMGUIDSidebar />
        </div>,
      );
    }
    return divs;
  };
  return (
    <>
      {/* <AdsenseLeft /> */}

      <div className={styles.container}>
        <Share />
        <div>
          <h2 style={{ fontSize: '12px', fontWeight: 'bold', margin: '5px' }}>
            MAIS LIDAS
          </h2>

          <ol>
            <li>
              <a
                href="/melhor-maquininha-de-cartao"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-maq');
                  }
                }}
              >
                Melhores Maquininhas de Cartão
              </a>
            </li>
            <li>
              <a
                href="/melhor-cartao-credito"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-cc');
                  }
                }}
              >
                Melhores Cartões de Crédito
              </a>
            </li>
            <li>
              <a
                href="/emprestimo/0/simulador"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-emp');
                  }
                }}
              >
                Simulador de Empréstimo
              </a>
            </li>
            <li>
              <a
                href="/melhores-fundos-de-investimento"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-fun');
                  }
                }}
              >
                Melhores Fundos de Investimento
              </a>
            </li>
            <li>
              <a
                href="/melhores-fundos-imobiliarios"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-fii');
                  }
                }}
              >
                Melhores Fundos Imobiliários
              </a>
            </li>
            <li>
              <a
                href="/melhores-acoes"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-acao');
                  }
                }}
              >
                Melhores Ações
              </a>
            </li>
            <li>
              <a
                href="/melhores-etf"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-etf');
                  }
                }}
              >
                Melhores ETFs
              </a>
            </li>
            <li>
              <a
                href="/melhores-bdr-etf"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-bdretf');
                  }
                }}
              >
                Melhores BDRs de ETFs
              </a>
            </li>
            <li>
              <a
                href="/melhores-bdr"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-bdr');
                  }
                }}
              >
                Melhores BDRs
              </a>
            </li>
            <li>
              <a
                href="/melhores-criptomoeda"
                className={styles.a}
                onClick={() => {
                  if (!window.location.href.includes('localhost')) {
                    window.gtag('event', 'categoria-cripto');
                  }
                }}
              >
                Melhores Criptomoedas
              </a>
            </li>
          </ol>
        </div>

        {!mobile && (
          <div
            className={styles.container2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {/* <div className={styles.divAdsense}>
              {props.interests1 && props.interests1.length > 0 ? (
                <AdsContent1
                  keywords={props.keywords1}
                  interests={props.interests1}
                />
              ) : (
                <AdsContent1 />
              )}
            </div>

            <div className={styles.divAdsense}>
              {props.interests2 && props.interests2.length > 0 ? (
                <AdsContent2
                  keywords={props.keywords2}
                  interests={props.interests2}
                />
              ) : (
                <AdsContent2 />
              )}
            </div> */}

            <div className={styles.divAdsense}>
              <AdsVertical1 />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SideBar;
