import React from 'react';
import { Container, Image, Table, Accordion } from 'react-bootstrap';
import styles from './LpControleFinanceiro.module.css';
import stylesButton from '../../Pages/_css/Button.module.css';
import Whatsapp from '../../Components/Helper/Whatsapp';
import sonhos from '../../Assets/cfi/sonhos.webp';
import garantia from '../../Assets/cfi/7-dias-de-garantia.webp';
import orcamento from '../../Assets/cfi/planner/orcamento.webp';
import aulas from '../../Assets/cfi/planner/aulas.webp';
import cartoes from '../../Assets/cfi/formas-pagamento.webp';
import categoria from '../../Assets/cfi/planner/categoria.webp';
import movimentos from '../../Assets/cfi/planner/movimentos.webp';
import dashAnual1 from '../../Assets/cfi/planner/dash-anual-1.webp';
import dashAnual2 from '../../Assets/cfi/planner/dash-anual-2.webp';
import dashAnual3 from '../../Assets/cfi/planner/dash-anual-3.webp';
import dashAnual4 from '../../Assets/cfi/planner/dash-anual-4.webp';
import dashMensal1 from '../../Assets/cfi/planner/dash-mensal-1.webp';
import dashMensal2 from '../../Assets/cfi/planner/dash-mensal-2.webp';
// import extrato from '../../Assets/cfi/planner/extrato.webp';
import dividas from '../../Assets/cfi/planner/dividas.webp';
import meta1 from '../../Assets/cfi/planner/meta-1.webp';
import meta2 from '../../Assets/cfi/planner/meta-2.webp';
import mockup3d from '../../Assets/cfi/planner/mockup-3d-planner-360.webp';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../../Footer/Footer';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import ImageMagnifier from '../../Components/Helper/ImageMagnifier';
import isMobile from '../../Components/Helper/Mobile';
import styleLista from '../../Components/Tags/Lista.module.css';
import PopUpPlanilhasVendidas from '../../Components/PopUps/PopUpPlanilhasVendidas';

const LpPlanner360 = () => {
  const mobile = isMobile();
  const [lupaStyle, setLupaStyle] = React.useState({});
  const [seconds, setSeconds] = React.useState(1);

  React.useEffect(() => {
    // window.gtag('event', 'categoria-etf');

    if (typeof window.useShortlink === 'function') {
      window.useShortlink(
        'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-planner-360/?p=9026',
      );
    }

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  });

  const handleMouseMove = (e) => {
    const { left, top } = e.target.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    setLupaStyle({
      left: `${x - 50}px`,
      top: `${y - 50}px`,
      backgroundPosition: `-${x * 2}px -${y * 2}px`,
    });
  };

  const getSVG = (tipo) => {
    if (tipo === 'check') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#28a745"
          className="bi bi-check-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
        </svg>
      );
    } else if (tipo === 'alert') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#28a745"
          className="bi bi-exclamation-triangle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
      );
    } else if (tipo === 'seta-direita') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="#28a745"
          className="bi bi-arrow-right-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
        </svg>
      );
    }
  };

  const returnFiveStars = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
          marginTop: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
      </div>
    );
  };

  const getSectionQtdeFerramentas = () => {
    return (
      <section id="secao-qtde-ferramenta">
        <div style={{ backgroundColor: '#f7f7f7' }}>
          <Container>
            <div className={styles.parent}>
              <div className={styles.childSessao1}>
                <h3 className={styles.h1}>GUARDAR DINHEIRO PLANNER 360º</h3>

                <h2 className={styles.h2}>
                  Organize suas finanças e alcance seus objetivos com o PLANNER
                  360º.
                </h2>
                {/* <br /> */}
                <p style={{ fontSize: '1.5rem' }}>
                  Chegou a hora de tomar o controle das suas finanças!
                </p>
              </div>
              <div className={styles.childSessao2}>
                <React.Suspense fallback={<div>...</div>}>
                  <Image
                    src={mockup3d}
                    className={styles.box}
                    alt="telas guardar dinheiro planner 360"
                  />
                </React.Suspense>
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  };

  const getSectionSonho = () => {
    return (
      <section id="sonho">
        <div className={styles.parent}>
          <div className={styles.child}>
            <div style={{ display: 'block' }}>
              <p className={styles.pFuncionalidades}>
                Para toda e qualquer pessoa que está em busca do seu controle
                financeiro para deixar de viver atolado em dívidas, e assim
                conseguir fazer com que sobre dinheiro para aproveitar as coisas
                boas da vida.
              </p>

              <p
                className={styles.pFuncionalidades}
                style={{
                  color: '#1a7058',
                  fontWeight: 'bold',
                  fontSize: '25px',
                  textTransform: 'uppercase',
                }}
              >
                Já Imaginou Seu Sonho fora do Papel?
              </p>
            </div>
          </div>
          <div className={styles.child}>
            <React.Suspense fallback={<div>...</div>}>
              <Image
                src={sonhos}
                alt="sonhos-no-papel"
                className={styles.sonhos}
                roundedCircle
              />
            </React.Suspense>
          </div>
        </div>
      </section>
    );
  };

  const getSecionSafeBuy = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <div>
          <React.Suspense fallback={<div>...</div>}>
            <Image
              src={cartoes}
              className={styles.imgCartoes}
              alt="formas-pagamento"
            />
          </React.Suspense>
        </div>
      </div>
    );
  };

  const getSectionDepoimentos = () => {
    return (
      <section id="depoimentos">
        <h3 className={styles.h3}>
          <span className={styles.span}>
            O que dizem as pessoas que estão usando o Planner 360º
          </span>
        </h3>
        <Carousel>
          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Eu não conseguia acompanhar minhas finanças pessoais
                      antes de usar o GUARDAR DINHEIRO - PLANNER 360º. Agora,
                      com apenas alguns cliques, tenho uma visão completa do meu
                      patrimônio líquido, receitas, despesas e investimentos. É
                      uma ferramenta poderosa que todos deveriam experimentar!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Carlos"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Nunca pensei que um sistema de controle financeiro
                      pudesse fazer tanta diferença na minha vida. Agora consigo
                      visualizar meus gastos de forma clara e identificar onde
                      posso economizar. Estou muito mais consciente dos meus
                      hábitos financeiros e isso fez toda a diferença. Obrigado
                      GUARDAR DINHEIRO - PLANNER 360º!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Isabela"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Esse sistema é incrível! Desde que comecei a usar Guardar
                      Dinheiro - PLANNER 360º, minha vida mudou completamente.
                      Agora consigo gerenciar minhas finanças de forma eficiente
                      e planejar melhor meu futuro financeiro. Recomendo a
                      todos"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Tiago"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "GUARDAR DINHEIRO - PLANNER 360º simplificou minha vida
                      financeira. Agora tenho uma visão clara dos meus gastos,
                      consigo acompanhar meu orçamento mensal e até mesmo
                      economizar para investimentos futuros. Estou muito
                      satisfeito!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Rita"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Nunca fui bom em lidar com finanças, mas o Guardar
                      Dinheiro - PLANNER 360º me ajudou a mudar isso. Com suas
                      ferramentas intuitivas e relatórios detalhados, consigo
                      tomar decisões mais inteligentes e evitar dívidas
                      desnecessárias. É incrível!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Rose"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Eu costumava perder horas tentando organizar minhas
                      despesas e receitas, mas o Guaradr Dinheiro - PLANNER 360º
                      fez tudo isso por mim. Agora posso focar em outras coisas
                      importantes da minha vida, sabendo que meu dinheiro está
                      sendo cuidado de forma eficiente."
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Celso"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  };

  const getSectionDuvidasFrequentes = () => {
    return (
      <section id="duvidas">
        <h3 className={styles.h3}>Perguntas Frequentes:</h3>
        <Accordion defaultActiveKey="-1">
          <Accordion.Item eventKey="-1">
            <Accordion.Header className={styles.duvidas}>
              O GUARDAR DINHEIRO - PLANNER 360º pode me ajudar a melhorar minha
              saúde financeira?
            </Accordion.Header>
            <Accordion.Body>
              Sim, o GUARDAR DINHEIRO - PLANNER 360º foi criado para ajudá-lo a
              identificar onde reduzir seus gastos, e ter total controle das
              suas despesas, receitas e investimentos, melhorando sua saúde
              financeira em geral.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              O GUARDAR DINHEIRO - PLANNER 360º é adequada para iniciantes em
              finanças e investimentos?
            </Accordion.Header>
            <Accordion.Body>
              Com certeza! Ele foi desenvolvido para que até mesmo quem não tem
              experiência com finanças consiga ter controle total sobre o
              dinheiro.
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>
              O GUARDAR DINHEIRO - PLANNER 360º é compatível com
              dispositivos móveis e tablets?
            </Accordion.Header>
            <Accordion.Body>
              Você consegue acessar o site do GUARDAR DINHEIRO - PLANNER 360º de
              qualquer dispositivo que possui internet.
            </Accordion.Body>
          </Accordion.Item> */}

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              O GUARDAR DINHEIRO - PLANNER 360º oferece algum tipo de suporte?
            </Accordion.Header>
            <Accordion.Body>
              Além das vídeo-aulas, oferecemos suporte por WhatsApp para ajudar
              você a utilizar o Planner da melhor maneira possível.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              É seguro inserir minhas informações financeiras no GUARDAR
              DINHEIRO - PLANNER 360º?
            </Accordion.Header>
            <Accordion.Body>
              Sim! A partir do momento que sua planilha fica no seu computador,
              não temos acesso as suas informações.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Há algum prazo de garantia caso eu não goste do GUARDAR DINHEIRO -
              PLANNER 360º?
            </Accordion.Header>
            <Accordion.Body>
              Sim, oferecemos uma garantia de satisfação de 7 dias.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Onde receberei os dados para acessar a planilha?
            </Accordion.Header>
            <Accordion.Body>
              Após o pagamento, você receberá automaticamente no e-mail
              cadastrado no momento da compra, os dados de acesso.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Quando terei acesso ao GUARDAR DINHEIRO - PLANNER 360º?
            </Accordion.Header>
            <Accordion.Body>
              Para pagamentos feitos via cartão ou pix, o envio da planilha é
              IMEDIATO! Para pagamentos via boleto bancário,o evio da planilha é
              até 2 dias úteis devido acompensação bancária.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    );
  };

  const getSectionRecapitulando = () => {
    return (
      <section id="recapitulando">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h4}>Recapitulando tudo que você recebe</h3>

          <p className={styles.p}>
            Você terá acesso a nossa planilha em Excel Guardar Dinheiro PLANNER
            360º, com todas as funcionalidades abaixo:
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Table className={styles.tableRecap}>
            <tbody>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Vídeo Aulas com o treinamento completo do
                  GUARDAR DINHEIRO PLANNER 360º
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receitas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Investimentos
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Dívidas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Contas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Cartão de Crédito
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesa por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receita por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Investimentos por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesa por Conta
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receita por Conta
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Planejamento Financeiro de Gastos
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Gastos Essenciais
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Gastos Não Essenciais
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Reserva de Emergência
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Limite de Gastos por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Projetos e Metas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Patrimônio da Riqueza
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Passo a Passo em Vídeo Aulas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Suporte individual via WhatsApp
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Acesso VITALÍCIO
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />
      </section>
    );
  };

  const getSessionPrecos = () => {
    return (
      <section id="preco">
        <br />
        <div style={{ textAlign: 'center' }}>
          <section id="precos2">
            <div className={styles.parentPreco}>
              <div className={[styles.package, styles.brilliant].join(' ')}>
                <h3 className={styles.h4}>OFERTA LIMITADA:</h3>
                <span className={styles.priceCustaria}>de R$138,00</span>
                <hr />
                <div style={{ display: 'block' }}>
                  <div style={{ marginTop: '-10px' }}>
                    <span>por apenas 4X de</span>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <span style={{ fontSize: '6rem', fontWeight: 'bold' }}>
                      R$9,68
                    </span>
                  </div>
                  <div style={{ margin: '15px' }}>
                    <span>ou R$ 36,00 à vista</span>
                  </div>
                </div>

                <div className={styles.divBtnComprar}>
                  <button
                    id="btn-plano-anual"
                    className={styles.btnComprarPlano}
                    onClick={() => {
                      window.gtag('event', 'gdpro_checkout_anual');
                      window.open(
                        'https://app.monetizze.com.br/checkout/DQL309530',
                        '_self',
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-cart-check-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>{' '}
                    QUERO O ACESSO IMEDIATO
                  </button>
                </div>
              </div>
            </div>
            <br />

            <p>{getSVG('check')} Acesso VITALÍCIO</p>
            <p>
              {getSVG('check')}
              Suporte individual via WhatsApp
            </p>
            <p>{getSVG('check')} Passo a Passo em Vídeo Aulas</p>
          </section>

          <br />

          {getSecionSafeBuy()}
          <br />
          <span className={styles.pergunta}>E se eu não gostar?</span>
          <br />
          <br />
          <span className={styles.pergunta}>O Risco é todo NOSSO!</span>
          <br />
          <br />
          <span className={styles.pergunta}>
            Se não gostar, você têm a Garantia incondicional de 7 DIAS!
          </span>

          <br />
          <React.Suspense fallback={<div>...</div>}>
            <Image src={garantia} alt="garantia" sizes="60vw" />
          </React.Suspense>
        </div>
      </section>
    );
  };

  const getAindaTemDuvidas = () => {
    return (
      <div>
        <h3 className={styles.h3}>VOCÊ AINDA TEM ALGUMA DÚVIDA?</h3>

        <a
          href="https://api.whatsapp.com/send?phone=5511913569157&text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20dúvidas%20sobre%20o%20Guardar%20Dinheiro-Planner-360%3F"
          className={stylesButton.a}
          target="_blank"
        >
          FALE COM NOSSO SUPORTE
        </a>
      </div>
    );
  };

  const getTimeLine = () => {
    return (
      <section id="timeline">
        <div className={styles.timeline}>
          <div className={styles.timelineWrapper}>
            <h3 className={styles.h4}>
              TUDO O QUE VOCÊ PRECISA PARA ORGANIZAR SEU DINHEIRO EM UM SÓ
              LUGAR.{' '}
            </h3>
            <p style={{ fontSize: '1.5rem' }}>
              Chega de perder tempo com planilhas complicadas ou de gastar além
              do que deve. O Guardar Dinheiro Planner 360º é a ferramenta que
              vai revolucionar suas finanças.
            </p>
            <br />
            <h4 className={styles.h4}>
              COMO O PLANNER 360º VAI MUDAR A SUA VIDA?{' '}
              <FontAwesomeIcon
                style={{ fontSize: '30px' }}
                icon={faHandHoldingUsd}
              />
            </h4>
            <ul className={styles.timelineSession}>
              <li>
                <div className={styles.timelineDiv}>
                  {' '}
                  SUPORTE EXCLUSIVO E VÍDEO AULAS{' '}
                </div>
                <p className={styles.timelineP}>
                  {' '}
                  Com o Guardar Dinheiro Planner 360º, você terá acesso a vide
                  oaulas que explicam cada detalhe da planilha. Além disso, terá
                  um planejador financeiro à disposição no WhatsApp para
                  responder todas as suas dúvidas.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  NÃO PRECISA SABER DE EXCEL
                </div>
                <p className={styles.timelineP}>
                  O GUARDAR DINHEIRO - PLANNER 360º está todo pronto e
                  automatizado para você usar. É exigido apenas a instalação do
                  pacote Microsoft Office Excel em seu Desktop ou Notebook.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>PRÁTICO</div>
                <p className={styles.timelineP}>
                  Registre suas contas de forma prática GUARDAR DINHEIRO -
                  PLANNER 360º, conseguindo assim ter uma visão completa da suas
                  finanças em um só lugar.
                </p>
              </li>

              <li>
                <div className={styles.timelineDiv}>
                  Controle gastos, receitas e investimentos
                </div>
                <p className={styles.timelineP}>
                  Saiba de uma vez por todas pra onde vai cada centavo do seu
                  dinheiro. Controle de todas as suas contas e cartões de
                  crédito. Nunca mais estoure o limite dos seus cartões de
                  crédito.
                </p>
              </li>

              <li>
                <div className={styles.timelineDiv}>Elimine suas dívidas</div>
                <p className={styles.timelineP}>
                  Com o Guardar Dinheiro - PLANNER 360º, você vai conseguir
                  identificar gastos desnecessários e criar estratégias para
                  reduzir dívidas e evitar endividamentos futuros.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Acompanhe seu planejamento financeiro
                </div>
                <p className={styles.timelineP}>
                  Visão detalhada do planejamento alertas alertas dos seus
                  gastos exagerados.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Controle a Evolução dos seus Projetos e Metas de Riqueza
                </div>
                <p className={styles.timelineP}>
                  Coloque suas metas e sonhos fora da caixa e automatize um
                  plano para alcançar seus objetivos.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  };

  const getCards = () => {
    return (
      <>
        <div className={styles.atencao}> Atenção!</div>

        <p style={{ fontSize: '1.2rem', textTransform: 'uppercase' }}>
          Você está prestes a descobrir um <strong>SPOILER</strong> das
          ferramentas do <strong>Guardar Dinheiro - PLANNER 360º</strong>, que
          podem fazer seu dinheiro render muito mais No final do mês.
        </p>
        {!mobile && (
          <p
            style={{
              fontSize: '1.2rem',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Passe o Mouse para dar aumentar as telas
          </p>
        )}

        <div className={styles.divTela}>
          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Orçamento</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>Organize seu orçamento de acordo com seus ganhos.</li>
                    <li>
                      Configure alertas para monitorar suas despesas essenciais,
                      não essenciais e investimentos.
                    </li>
                    <li>
                      Defina um limite geral de gastos e receba notificações
                      sobre o progresso do seu orçamento, ajudando você a manter
                      tudo sob controle.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={orcamento}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Categorias</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Cadastre todas as categorias que desejar. Muitas planilhas
                      vendidas por aí já vem com as categorias cadastradas sem
                      deixar você cadastrar uma nova.{' '}
                    </li>
                    <li>
                      Configure um orçamento de gastos por categoria e receba
                      alertas quando ultrapassar o limite ou estiver se
                      aproximando do valor final.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={categoria}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Movimentações</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Registre suas receitas, despesas e investimentos de forma
                      rápida e prática.
                    </li>
                    <li>
                      Depois de registrar essas informações, a ferramenta começa
                      automaticamente a analisar seus dados e te alerta sobre
                      seu planejamento financeiro.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={movimentos}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Mensal - Parte 1</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Veja o total de todas as suas receitas, despesas,
                      investimentos e saldo disponível.
                    </li>
                    <li>
                      Utilização da receita: acompanhe quanto da sua receita foi
                      destinado para despesas, investimentos e o saldo restante.
                    </li>
                    <li>
                      Alerta de orçamento: monitore quanto foi utilizado em
                      despesas essenciais, não essenciais e investimentos no
                      mês.
                    </li>
                    <li>
                      Acompanhe as categorias com limite de orçamento definido e
                      veja se você está dentro ou já ultrapassou o valor
                      planejado.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashMensal1}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Mensal - Parte 2</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Confira como suas receitas estão distribuídas e quais
                      categorias de receita você teve.
                    </li>
                    <li>
                      Acompanhe a distribuição dos gastos por conta e cartão de
                      crédito.
                    </li>
                    <li>
                      Monitore o limite das suas contas e cartões de crédito
                      para garantir que está dentro do planejado.
                    </li>
                    <li>
                      Veja a distribuição das suas despesas. Alerta com seus
                      maiores gastos e planeje-se para reduzi-los e começar a
                      investir.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashMensal2}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Anual - Parte 1</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Confira o total acumulado de suas receitas, despesas,
                      investimentos e saldo ao longo do ano.
                    </li>
                    <li>
                      Veja como sua receita foi utilizada: qual porcentagem foi
                      destinada a despesas, investimentos e quanto permaneceu
                      como saldo.
                    </li>
                    <li>
                      Acompanhe a média de suas receitas, despesas e
                      investimentos ao longo do ano.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashAnual1}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Anual - Parte 2</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Veja, mês a mês, o total das suas receitas, despesas e
                      investimentos.
                    </li>
                    <li>
                      Acompanhe o acumulado de tudo o que você recebeu, gastou e
                      investiu, além do saldo total que foi juntando em cada um.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashAnual2}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Anual - Parte 3</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Confira como suas despesas foram distribuídas ao longo do
                      ano inteiro. A soma do total de cada categoria de despesa
                      ao longo do ano.
                    </li>

                    <li>
                      Escolha qualquer categoria de despesa e veja
                      detalhadamente como você gastou mês a mês durante o ano.
                      Isso vai te ajudar a entender melhor onde está o seu
                      dinheiro e como você pode se organizar melhor.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashAnual3}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Relatório Anual - Parte 4</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Acompanhe, mês a mês, como foram seus gastos com o cartão
                      de crédito e com cada conta que você cadastrou ao longo
                      dos 12 meses. Isso te ajudará a ver exatamente onde você
                      está gastando e a se organizar melhor.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dashAnual4}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Metas e Investimentos - Parte 1</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Anote suas metas e projetos futuros e acompanhe o
                      progresso de cada um deles.
                    </li>
                    <li>
                      Registre seus investimentos e associe-os às suas metas
                      para ver o quanto você já avançou em cada uma.
                    </li>
                    <li>
                      Informe o valor dos seus imóveis para ter uma visão
                      completa do seu patrimônio. Isso vai te ajudar a entender
                      melhor o estado geral das suas finanças.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={meta1}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Metas e Investimentos - Parte 2</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Veja o valor total das suas metas e quando você já
                      atingiu.
                    </li>
                    <li>
                      Patrimônio total: Confira a soma de todo o seu dinheiro e
                      bens, incluindo investimentos e imóveis.
                    </li>
                    <li>
                      Distribuição do patrimônio: Veja a porcentagem do seu
                      dinheiro em imóveis versus investimentos (ex.: 60% em
                      imóveis, 40% em investimentos).
                    </li>
                    <li>
                      Classifique seus investimentos em renda variável (ações) e
                      renda fixa (poupança). Veja a distribuição dos seus
                      investimentos tanto em porcentagem quanto em valores.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={meta2}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Controle de Dívidas</Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>Resgistre e acompanhe suas dívidas.</li>
                    <li>
                      Anote cada pagamento e veja automaticamente o saldo da
                      dívida diminuir.
                    </li>
                    <li>
                      Mantenha um histórico completo de todas as suas quitações.
                      Isso te ajudará a ter total controle das suas dívidas e a
                      trabalhar para quitá-las completamente, evitando novas
                      dívidas no futuro
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={dividas}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>

          <div className={styles.divItemTela}>
            <Card>
              <Card.Body>
                <Card.Title>Treinamento Completo e Suporte </Card.Title>
                <Card.Text>
                  <ul className={styleLista.checkList}>
                    <li>
                      Tenha acesso a todas as aulas gravadas para assistir
                      quando e onde quiser, com acesso vitalício.
                    </li>
                    <li>
                      Suporte a duvidas via whatsapp. Nossos especialistas estão
                      prontos para te auxiliar da forma mais rápida e eficiente
                      possível.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className={styles.divItemTela}>
            <React.Suspense fallback={<div>...</div>}>
              <ImageMagnifier
                src={aulas}
                width={mobile && '100%'}
                // height="400px"
                magnifierHeight={200}
                magnifierWidth={300}
                zoomLevel={mobile ? 2.1 : 2}
              />
            </React.Suspense>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{'GUARDAR DINHEIRO PLANNER 360º'}</title>
        <meta itemProp="name" content="GUARDAR DINHEIRO PLANNER 360º"></meta>
        <meta
          name="description"
          content="Controle Financeiro GUARDAR DINHEIRO 360º - Organize suas finanças e faça o seu dinheiro render todo mês."
        />

        <link
          rel="canonical"
          href={
            'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-planner-360/'
          }
        ></link>
        <link
          rel="shortlink"
          href={
            'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-planner-360/?p=9026'
          }
        ></link>

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@type":"Organization",
          "url":"https://www.guardardinheiro.com.br",
          "name":"Guardar Dinheiro",
          "logo": "https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/logo3.png",
          "description": "Controle Financeiro GUARDAR DINHEIRO 360º - Organize suas finanças e faça o seu dinheiro render todo mês.",
          "sameAs" : [
            "https://www.facebook.com/siteguardardinhero",
            "https://twitter.com/guardardinheir1",
            "https://www.linkedin.com/in/guardar-dinheiro-267073230/",
            "https://br.pinterest.com/guardardinheiro/",
            "https://www.instagram.com/guardardinheiro/",
            "https://www.youtube.com/channel/CYhHakmdv-lGolh3zahffCQ",    
            "https://medium.com/@guardardinheiro"
          ]            
        }`}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2K23FV25Q6"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-2K23FV25Q6');        
            gtag('event','page_view_pro')
        `}
        </script>
      </Helmet>

      <style type="text/css">
        {`
          .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left-square' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/%3E%3C/svg%3E") !important;
           }

           .carousel-control-next-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right-square' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") !important;
          }

          .carousel-indicators [data-bs-target] {
            background-color: #28a745;
          }

          .accordion-button{
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 18px;
          }
           `}
      </style>

      {getSectionQtdeFerramentas()}
      <Container>
        <br />
        <p style={{ fontSize: '1.5rem' }}>
          Com o Guardar Dinheiro Planner 360º, você organiza seus gastos e faz o
          seu dinheiro render todos os meses, de maneira prática e automatizada.
        </p>
        <p style={{ fontSize: '1.5rem' }}>
          A cada mês, você verá seu saldo crescer!
        </p>
        <hr />
        <h2 className={styles.h2}>Assista Agora!</h2>
        <p style={{ fontSize: '1.5rem' }}>
          Descubra como o Guardar Dinheiro Planner 360º vai transformar sua vida
          financeira. Assista agora e veja como é simples colocar seus sonhos em
          prática.
        </p>

        <div
          className="video"
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0,
            margin: '25px',
          }}
        >
          <iframe
            title="youTubeVideoPro"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            // src="https://www.youtube.com/embed/FvuGunJ-sj0"
            src="https://www.youtube.com/embed/RAgXCxPcJbY"
          />
        </div>

        <a href="#preco" className={stylesButton.a}>
          SIM! EU QUERO O GUARDAR DINHEIRO PLANNER 360
        </a>

        {getTimeLine()}
        <a href="#preco" className={stylesButton.a}>
          SIM! EU QUERO O GUARDAR DINHEIRO PLANNER 360
        </a>

        {getCards()}

        <hr />
        {getSectionSonho()}
        <hr />

        {getSectionDepoimentos()}
        <br />

        <a href="#preco" className={stylesButton.a}>
          SIM! EU QUERO O GUARDAR DINHEIRO - PLANNER 360º
        </a>
        <br />
        <hr />
        {getSectionRecapitulando()}
        <br />
        <div style={{ textAlign: 'center', width: '100%' }}>
          <React.Suspense fallback={<div>...</div>}>
            <Image
              src={mockup3d}
              className={styles.box}
              alt="telas guardar dinheiro pro"
            />
          </React.Suspense>
        </div>
        <br />
        {getSessionPrecos()}
        {/* <hr /> */}
        {/* <br /> */}
        {/* {getSectionAtualizacoes()} */}
        {/* <hr /> */}
        <br />
        <a href="#preco" className={stylesButton.a}>
          SIM! EU QUERO O GUARDAR DINHEIRO - PLANNER 360º
        </a>
        <br />
        {getSectionDuvidasFrequentes()}
        <br />
        <br />
        {getAindaTemDuvidas()}
        <br />
        <br />
        {seconds % 5 === 0 && <PopUpPlanilhasVendidas />}
      </Container>
      <Footer isLP={true} />
      <Whatsapp pagina="guardar-dinheiro-360" />
    </>
  );
};

export default LpPlanner360;
