import React, { useEffect } from 'react';

const AdsMGIDArtigo = () => {
  useEffect(() => {
    (function (w, q) {
      w[q] = w[q] || [];
      w[q].push(['_mgc.load']);
    })(window, '_mgq');
  }, []);

  return (
    <div
      style={{ marginBottom: '10px' }}
      data-type="_mgwidget"
      data-widget-id="1647202"
    ></div>
  );
};

export default AdsMGIDArtigo;
