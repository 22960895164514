import React from 'react';
import { Container, Table } from 'react-bootstrap';
import styles from './ControleFinanceiro.module.css';
import { AWS_GET_USER_MOVIMENTOS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import InputMonth from '../../Components/Forms/InputMonth';
import Label from '../../Components/Forms/Label';
import FaturasList from '../../Components/ControleFinanceiro/FaturasList';
import stylesTipografia from './css/Tipografia.module.css';
import stylesDiv from './css/Div.module.css';
import stylesImagem from './css/Images.module.css';
import stylesSelect from './css/Select.module.css';
import stylesDetails from './css/Details.module.css';
import BoxLimiteCartao from '../../Components/ControleFinanceiro/BoxLimiteCartao';
import LookNumbers from '../../Components/Helper/LookNumbers';
import { NumberContext } from '../../Contexts/NumberContext';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import isMobile from '../../Components/Helper/Mobile';
import Head from '../../Components/Tags/Head';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const Faturas = () => {
  const mobile = isMobile();
  const { data, loading, error, request } = useFetch();
  const { hide } = React.useContext(NumberContext);
  const [show, setShow] = React.useState(false);
  const [ano, setAno] = React.useState(new Date().getFullYear().toString());
  const [mes, setMes] = React.useState(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );
  const [dataMovimento, setDataMovimento] = React.useState(ano + '-' + mes);
  const [filtroCartao, setFiltroCartao] = React.useState(null);
  const [filtroCategoria, setFiltroCategoria] = React.useState(null);

  const [ordem, setOrdem] = React.useState('');

  React.useEffect(() => {
    teste();
  }, []);

  function teste() {
    if (window.sessionStorage.getItem('gd_cf_dt') === null) {
      window.sessionStorage.setItem('gd_cf_dt', dataMovimento);
    } else {
      setMesAno(window.sessionStorage.getItem('gd_cf_dt'));
    }

    const { url, options } = AWS_GET_USER_MOVIMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_dt'),
      'data_vencimento',
    );

    request(url, options);
  }

  function setMesAno(valor) {
    // console.log(valor);
    setDataMovimento(valor);
    window.sessionStorage.setItem('gd_cf_dt', valor);
  }

  function aumentaDiminuiMesAno(event) {
    event.preventDefault();
    let mes = 0;
    let ano = 0;
    // console.log(event.target.id);
    if (event.target.id.includes('diminui')) {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) - 1;
      // console.log(mes);
      if (mes === 0) {
        mes = 12;
        ano = parseInt(ano) - 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    } else {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) + 1;
      // console.log(mes);
      if (mes === 13) {
        mes = 1;
        ano = parseInt(ano) + 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    }
    window.sessionStorage.setItem(
      'gd_cf_dt',
      ano.toString() + '-' + mes.toString().padStart(2, '0'),
    );
    teste();
  }

  function retornaFaturas() {
    if (data) {
      let dados = data.filter(
        (dados) =>
          dados.tipo_registro !== '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === dataMovimento,
      );

      if (filtroCartao) {
        dados = dados.filter((dados) => dados.cartao === filtroCartao);
      }

      if (filtroCategoria) {
        dados = dados.filter(
          (dados) => dados.categoria === filtroCategoria.split('-')[1],
        );
      }

      if (ordem === 'Maior Valor') {
        dados = dados.sort((a, b) => b.valor - a.valor);
      }

      if (ordem === 'Menor Valor') {
        dados = dados.sort((a, b) => a.valor - b.valor);
      }

      if (ordem === 'Maior Data de Compra') {
        console.log(ordem);
        dados = dados.sort(
          (a, b) =>
            b.data_compra.toString().replaceAll('-', '') -
            a.data_compra.toString().replaceAll('-', ''),
        );
      }

      if (ordem === 'Menor Data de Compra') {
        console.log(ordem);
        dados = dados.sort(
          (a, b) =>
            a.data_compra.toString().replaceAll('-', '') -
            b.data_compra.toString().replaceAll('-', ''),
        );
      }

      return <FaturasList data={dados} hide={hide} />;
    } else {
      return <Loading />;
    }
  }

  function getCategorias() {
    let categorias = [];

    if (data) {
      let dados = data.filter(
        (dados) =>
          dados.tipo_registro !== '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === dataMovimento,
      );
      dados.forEach((registro) => {
        if (
          categorias.indexOf(registro.tipo + '-' + registro.categoria) === -1
        ) {
          categorias.push(registro.tipo + '-' + registro.categoria);
        }
      });
    }

    categorias = categorias.sort();
    return categorias;
  }

  function getCartoes() {
    let cartao = [];

    if (data) {
      let dados = data.filter(
        (dados) =>
          dados.tipo_registro !== '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === dataMovimento,
      );
      dados.forEach((registro) => {
        if (cartao.indexOf(registro.cartao) === -1) {
          if (registro.cartao.length > 3) {
            cartao.push(registro.cartao);
          }
        }
      });
    }
    cartao = cartao.sort();
    return cartao;
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        <Head
          metaDescription="Faturas de Cartão de Crédito | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
          title="Faturas de Cartão de Crédito | Guardar Dinheiro PRO"
          h1="Faturas de Cartão de Crédito | Guardar Dinheiro PRO"
          canonical={window.location.href}
          nivel="1"
          id_wp_post={9000}
        />
        <BreadcumbH1
          paginaAtual=":: FATURAS DE CARTÃO DE CRÉDITO"
          texto=":: FATURAS DE CARTÃO DE CRÉDITO"
          isPro={true}
        />
        <Container>
          <AdsContent1 />
          <h1 className={stylesTipografia.h1}>
            :: FATURAS DE CARTÃO DE CRÉDITO
          </h1>
          <LookNumbers />

          <hr />
          <div style={{ textAlign: 'left' }}>
            <div className={stylesDiv.divFlex50}>
              <div className={stylesDiv.child}>
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiMesAno(e)}
                >
                  <svg
                    id="svg-diminui-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImagem.svgAcessoRapido}
                    fill="currentColor"
                    // className="bi bi-caret-left-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-diminui-mes"
                      d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
              <div className={stylesDiv.child}>
                <InputMonth
                  type="month"
                  // label={'Lançamento'}
                  value={dataMovimento}
                  onChange={({ target }) => {
                    setMesAno(target.value);
                  }}
                />
              </div>
              <div className={stylesDiv.child} id="div-aumenta-mes">
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiMesAno(e)}
                >
                  <svg
                    id="svg-aumenta-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImagem.svgAcessoRapido}
                    fill="currentColor"
                    // class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-aumenta-mes"
                      d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <hr />

          <br />
          <BoxLimiteCartao hide={hide} isInicio={false} />
          <br />

          <div className={stylesDiv.divSombra}>
            <div style={{ textAlign: 'left' }}>
              <h2 className={stylesTipografia.h2}>:: lançamentos</h2>
            </div>
            <details
              className={stylesDetails.details}
              open={!mobile ? true : false}
            >
              <summary>filtros e ordenação</summary>
              <div className={stylesDiv.divFlex100}>
                <div className={stylesDiv.child}>
                  <Label label={'Ordenar Por'} />
                  <select
                    // className={style.dropdownList}
                    id="seletorOrdenacao"
                    onChange={(e) => setOrdem(e.target.value)}
                    value={ordem}
                    className={stylesSelect.select}
                  >
                    <option
                      // className={style.dropdownList}
                      key=""
                      value=""
                    >
                      Selecione
                    </option>
                    <option
                      key={'Maior Data de Compra'}
                      value={'Maior Data de Compra'}
                    >
                      {'Maior Data de Compra'}
                    </option>
                    <option
                      key={'Menor Data de Compra'}
                      value={'Menor Data de Compra'}
                    >
                      {'Menor Data de Compra'}
                    </option>

                    <option key={'Maior Valor'} value={'Maior Valor'}>
                      {'Maior Valor'}
                    </option>
                    <option key={'Menor Valor'} value={'Menor Valor'}>
                      {'Menor Valor'}
                    </option>
                  </select>
                </div>

                <div className={stylesDiv.child}>
                  <Label label={'Cartão'} />
                  <select
                    // className={style.dropdownList}
                    id="seletorConta"
                    onChange={(e) => setFiltroCartao(e.target.value)}
                    value={filtroCartao}
                    className={stylesSelect.select}
                  >
                    <option
                      // className={style.dropdownList}
                      key=""
                      value=""
                    >
                      Selecione
                    </option>
                    {data &&
                      getCartoes().map((dado) => (
                        <option
                          // className={style.dropdownList}
                          key={dado}
                          value={dado}
                        >
                          {dado}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={stylesDiv.child}>
                  <Label label={'Categoria'} />
                  <select
                    // className={style.dropdownList}
                    id="seletorConta"
                    onChange={(e) => setFiltroCategoria(e.target.value)}
                    value={filtroCategoria}
                    className={stylesSelect.select}
                  >
                    <option
                      // className={style.dropdownList}
                      key=""
                      value=""
                    >
                      Selecione
                    </option>
                    {data &&
                      getCategorias().map((dado) => (
                        <option
                          // className={style.dropdownList}
                          key={dado}
                          value={dado}
                        >
                          {dado}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </details>

            <div>{retornaFaturas()}</div>
          </div>
        </Container>
      </>
    );
  } else return null;
};

export default Faturas;
