import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import stylesImagem from '../../Pages/ControleFinanceiro/css/Images.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import ModalCadastroMovimento from './ModalCadastroMovimento';
import ModalTransf from './ModalTransf';

const AcessoRapido = () => {
  const [show, setShow] = React.useState(false);
  const [showTransf, setShowTransf] = React.useState(false);
  const [tipoLancamento, setTipoLancamento] = React.useState(null);

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  const showModalTransf = (event) => {
    event.preventDefault();
    setShowTransf(true);
  };

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <span className={stylesTipografia.span}>O que vamos adicionar?</span>
        <hr style={{ margin: '10px' }} />
      </div>
      <div className={stylesDiv.divFlexAcessoRapido}>
        <div style={{ display: 'block', textAlign: 'center' }}>
          <div>
            <button
              className={stylesButton.btnAcessoRapido}
              onClick={(e) => {
                setTipoLancamento('Despesa');
                showModal(e);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={stylesImagem.svgAcessoRapido}
                fill="#B22222"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>
          </div>
          <span className={stylesTipografia.span}>Despesa</span>
        </div>

        <div style={{ display: 'block', textAlign: 'center' }}>
          <div>
            <button
              className={stylesButton.btnAcessoRapido}
              onClick={(e) => {
                setTipoLancamento('Receita');
                showModal(e);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={stylesImagem.svgAcessoRapido}
                fill="#28a745"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
          <span className={stylesTipografia.span}>Receita</span>
        </div>

        <div style={{ display: 'block', textAlign: 'center' }}>
          <div>
            <button
              className={stylesButton.btnAcessoRapido}
              onClick={(e) => {
                setTipoLancamento('Investimentos');
                showModal(e);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={stylesImagem.svgAcessoRapido}
                fill="#008B8B"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                />
              </svg>
            </button>
          </div>
          <span className={stylesTipografia.span}>Investimentos</span>
        </div>

        <div style={{ display: 'block', textAlign: 'center' }}>
          <div>
            <button
              className={stylesButton.btnAcessoRapido}
              onClick={(e) => {
                setTipoLancamento('Transferências');
                showModalTransf(e);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={stylesImagem.svgAcessoRapido}
                fill="black"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
            </button>
          </div>
          <span className={stylesTipografia.span}>Transferências</span>
        </div>
      </div>

      {showTransf && (
        <ModalTransf onClose={() => setShowTransf(false)} show={showTransf} />
      )}

      {show && (
        <ModalCadastroMovimento
          onClose={() => setShow(false)}
          show={show}
          tipoLancamento={tipoLancamento}
        />
      )}
    </>
  );
};

export default AcessoRapido;
