import React from 'react';
import { Container } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesDiv from './css/Div.module.css';
import stylesImage from './css/Images.module.css';
import ModalCadastroMovimento from '../../Components/ControleFinanceiro/ModalCadastroMovimento';
import { AWS_GET_USER_MOVIMENTOS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import InputMonth from '../../Components/Forms/InputMonth';
import BoxSaldo from '../../Components/ControleFinanceiro/BoxSaldo';
import BoxCategoria from '../../Components/ControleFinanceiro/BoxCategoria';
import BoxOrcamento from '../../Components/ControleFinanceiro/BoxOrcamento';
import BoxPorDia from '../../Components/ControleFinanceiro/BoxPorDia';
import BoxContas from '../../Components/ControleFinanceiro/BoxContas';
import BoxPendentes from '../../Components/ControleFinanceiro/BoxPendentes';
import { NumberContext } from '../../Contexts/NumberContext';
import LookNumbers from '../../Components/Helper/LookNumbers';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import isMobile from '../../Components/Helper/Mobile';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const ControleFinanceiro = () => {
  const mobile = isMobile();
  const { data, loading, error, request } = useFetch();
  const { hide } = React.useContext(NumberContext);

  const [show, setShow] = React.useState(false);
  const [ano, setAno] = React.useState(new Date().getFullYear().toString());
  const [mes, setMes] = React.useState(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );
  const [dataMovimento, setDataMovimento] = React.useState(ano + '-' + mes);

  React.useEffect(() => {
    teste();
  }, []);

  function teste() {
    if (window.sessionStorage.getItem('gd_cf_dt') === null) {
      window.sessionStorage.setItem('gd_cf_dt', dataMovimento);
    } else {
      setMesAno(window.sessionStorage.getItem('gd_cf_dt'));
    }

    const { url, options } = AWS_GET_USER_MOVIMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_dt'),
      'data_vencimento',
    );

    request(url, options);
  }

  function setMesAno(valor) {
    // console.log(valor);
    setDataMovimento(valor);
    window.sessionStorage.setItem('gd_cf_dt', valor);
  }

  function aumentaDiminuiMesAno(event) {
    event.preventDefault();
    let mes = 0;
    let ano = 0;
    // console.log(event.target.id);
    if (event.target.id.includes('diminui')) {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) - 1;
      // console.log(mes);
      if (mes === 0) {
        mes = 12;
        ano = parseInt(ano) - 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    } else {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) + 1;
      // console.log(mes);
      if (mes === 13) {
        mes = 1;
        ano = parseInt(ano) + 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    }
    window.sessionStorage.setItem(
      'gd_cf_dt',
      ano.toString() + '-' + mes.toString().padStart(2, '0'),
    );
    teste();
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        <Head
          metaDescription="Resumo Mensal | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
          title="Resumo Mensal | Guardar Dinheiro PRO"
          h1="Resumo Mensal | Guardar Dinheiro PRO"
          canonical={window.location.href}
          nivel="1"
          id_wp_post={9000}
        />

        {!mobile && (
          <>
            <br />
            <br />
            <hr />
          </>
        )}

        <BreadcumbH1
          paginaAtual=":: RESUMO MENSAL"
          texto=":: RESUMO MENSAL"
          isPro={true}
        />
        <Container>
          <AdsContent1 />
          <h1 className={stylesTipografia.h1}>:: RESUMO MENSAL</h1>
          <LookNumbers />

          <hr />
          <div>
            <div className={stylesDiv.divFlex50}>
              <div className={stylesDiv.child}>
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiMesAno(e)}
                >
                  <svg
                    id="svg-diminui-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImage.svgAcessoRapido}
                    fill="currentColor"
                    // className="bi bi-caret-left-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-diminui-mes"
                      d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
              <div className={stylesDiv.child}>
                <InputMonth
                  type="month"
                  // label={'Lançamento'}
                  value={dataMovimento}
                  onChange={({ target }) => {
                    setMesAno(target.value);
                  }}
                />
              </div>
              <div className={stylesDiv.child} id="div-aumenta-mes">
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiMesAno(e)}
                >
                  <svg
                    id="svg-aumenta-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImage.svgAcessoRapido}
                    fill="currentColor"
                    // class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-aumenta-mes"
                      d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <hr />

          <div className={stylesDiv.divDash}>
            <div className={stylesDiv.childDash}>
              {data && (
                <BoxSaldo
                  data={data}
                  flagAnoOuMes={'mes'}
                  isInicio={false}
                  hide={hide}
                />
              )}
            </div>

            <div className={stylesDiv.childDash}>
              {data && (
                <BoxOrcamento dataMov={data} box="orcamento" hide={hide} />
              )}
            </div>

            <div className={stylesDiv.childDash}>
              {data && (
                <BoxPendentes
                  data={data}
                  tipo={'despesa'}
                  flagAnoOuMes={'mes'}
                  hide={hide}
                />
              )}
            </div>

            <div className={stylesDiv.childDash}>
              {data && (
                <BoxPendentes
                  data={data}
                  tipo={'receita'}
                  flagAnoOuMes={'mes'}
                  hide={hide}
                />
              )}
            </div>
          </div>

          <br />
          <div className={stylesDiv.divSombra}>
            {data && <BoxOrcamento dataMov={data} box="limite" />}
          </div>

          <br />
          {data && (
            <BoxCategoria data={data} flagAnoOuMes={'mes'} hide={hide} />
          )}

          <br />
          {data && (
            <BoxContas
              data={data}
              flagAnoOuMes={'mes'}
              fgContaOuCartao={'Conta'}
              hide={hide}
            />
          )}

          <br />
          {data && (
            <BoxContas
              data={data}
              flagAnoOuMes={'mes'}
              fgContaOuCartao={'Cartão'}
              hide={hide}
            />
          )}

          <br />
          <div className={stylesDiv.divSombra}>
            {data && <BoxPorDia data={data} flagAnoOuMes={'mes'} />}
          </div>

          <br />
        </Container>
      </>
    );
  } else return null;
};

export default ControleFinanceiro;
