import React from 'react';
import { Container } from 'react-bootstrap';
import { AWS_GET_USER_MOVIMENTOS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import MovimentosList from '../../Components/ControleFinanceiro/MovimentoList';
import InputMonth from '../../Components/Forms/InputMonth';
import Label from '../../Components/Forms/Label';
import stylesTipografia from './css/Tipografia.module.css';
import stylesDiv from './css/Div.module.css';
import stylesDetails from './css/Details.module.css';
import stylesImagem from './css/Images.module.css';
import stylesSelect from './css/Select.module.css';

const MovimentosCompra = () => {
  const { data, loading, error, request } = useFetch();

  const [show, setShow] = React.useState(false);
  const [ano, setAno] = React.useState(new Date().getFullYear().toString());
  const [mes, setMes] = React.useState(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );
  const [dataMovimento, setDataMovimento] = React.useState(ano + '-' + mes);
  const [filtroPago, setFiltroPago] = React.useState(null);
  const [filtroTipo, setFiltroTipo] = React.useState(null);
  const [filtroConta, setFiltroConta] = React.useState(null);
  const [filtroCategoria, setFiltroCategoria] = React.useState(null);
  const [filtroCartao, setFiltroCartao] = React.useState(null);

  const [ordem, setOrdem] = React.useState('');

  const dadosOrdenacao = [
    'Maior Valor',
    'Menor Valor',
    'Maior Data',
    'Menor Data',
  ];

  React.useEffect(() => {
    teste();
  }, []);

  function teste() {
    if (window.sessionStorage.getItem('gd_cf_dt') === null) {
      window.sessionStorage.setItem('gd_cf_dt', dataMovimento);
    } else {
      setMesAno(window.sessionStorage.getItem('gd_cf_dt'));
    }

    const { url, options } = AWS_GET_USER_MOVIMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_dt'),
      'data_compra',
    );

    request(url, options);
  }

  function setMesAno(valor) {
    // console.log(valor);
    setDataMovimento(valor);
    window.sessionStorage.setItem('gd_cf_dt', valor);
  }

  function aumentaDiminuiMesAno(event) {
    event.preventDefault();
    let mes = 0;
    let ano = 0;
    // console.log(event.target.id);
    if (event.target.id.includes('diminui')) {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) - 1;
      // console.log(mes);
      if (mes === 0) {
        mes = 12;
        ano = parseInt(ano) - 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    } else {
      // console.log(dataMovimento);
      ano = dataMovimento.substring(0, 4);
      mes = dataMovimento.substring(5, 7);

      mes = parseInt(mes) + 1;
      // console.log(mes);
      if (mes === 13) {
        mes = 1;
        ano = parseInt(ano) + 1;
      }
      setDataMovimento(ano.toString() + '-' + mes.toString().padStart(2, '0'));
    }
    window.sessionStorage.setItem(
      'gd_cf_dt',
      ano.toString() + '-' + mes.toString().padStart(2, '0'),
    );
    teste();
  }

  function getCartoes() {
    let cartao = [];

    if (data) {
      data.forEach((registro) => {
        if (cartao.indexOf(registro.cartao) === -1) {
          if (registro.cartao.length > 3) {
            cartao.push(registro.cartao);
          }
        }
      });
    }
    cartao = cartao.sort();
    return cartao;
  }

  function getContas() {
    let contas = [];

    if (data) {
      data.forEach((registro) => {
        if (contas.indexOf(registro.conta) === -1) {
          if (registro.conta.length > 3) {
            contas.push(registro.conta);
          }
        }
      });
    }
    contas = contas.sort();
    return contas;
  }

  function getCategorias() {
    let categorias = [];

    if (data) {
      data.forEach((registro) => {
        if (
          categorias.indexOf(registro.tipo + '-' + registro.categoria) === -1
        ) {
          categorias.push(registro.tipo + '-' + registro.categoria);
        }
      });
    }
    categorias = categorias.sort();
    return categorias;
  }

  function retornaMovimentoLista() {
    if (data) {
      let dados = data
        .sort((a, b) => a.descricao - b.descricao)
        .filter(
          (dados) =>
            dados.categoria !== 'Cartão de Crédito' &&
            dados.data_compra.substring(0, dados.data_compra.length - 3) ===
              dataMovimento,
        );

      let dadosFatura = data.filter(
        (dados) =>
          dados.tipo_registro !== '1' &&
          dados.data_compra.substring(0, dados.data_compra.length - 3) ===
            dataMovimento,
      );

      if (filtroPago === 'Pago') {
        dados = dados.filter((dados) => dados.id_situacao === '1');
      }

      if (filtroPago === 'Não Pago') {
        dados = dados.filter((dados) => dados.id_situacao === '0');
      }

      if (filtroConta) {
        dados = dados.filter((dados) => dados.conta === filtroConta);
      }

      if (filtroCartao) {
        dados = dados.filter((dados) => dados.cartao === filtroCartao);
      }

      if (filtroCategoria) {
        dados = dados.filter(
          (dados) => dados.categoria === filtroCategoria.split('-')[1],
        );
      }

      if (filtroTipo === 'Despesa') {
        dados = dados.filter((dados) => dados.tipo === 'Despesa');
      }

      if (filtroTipo === 'Receita') {
        dados = dados.filter((dados) => dados.tipo === 'Receita');
      }

      if (filtroTipo === 'Investimentos') {
        dados = dados.filter((dados) => dados.tipo === 'Investimentos');
      }

      if (ordem === 'Maior Valor') {
        dados = dados.sort((a, b) => b.valor - a.valor);
      }

      if (ordem === 'Menor Valor') {
        dados = dados.sort((a, b) => a.valor - b.valor);
      }

      if (ordem === 'Menor Data') {
        dados = dados.sort(
          (a, b) =>
            parseFloat(a.data_compra.toString().replaceAll('-', '')) -
            parseFloat(b.data_compra.toString().replaceAll('-', '')),
        );
      }

      if (ordem === 'Maior Data') {
        dados = dados.sort(
          (a, b) =>
            parseFloat(b.data_compra.toString().replaceAll('-', '')) -
            parseFloat(a.data_compra.toString().replaceAll('-', '')),
        );
      }

      return (
        <MovimentosList
          data={dados}
          dataFatura={dadosFatura}
          filtro={'data_compra'}
        />
      );
    } else {
      return <Loading />;
    }
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <Container>
        <div className={stylesTipografia.divH1}>
          <div>
            <h1>:: RESUMO DIARIO</h1>
          </div>
        </div>
        <hr />
        <div style={{ textAlign: 'left' }}>
          <div className={stylesDiv.divFlex50}>
            <div className={stylesDiv.child}>
              <span
                id="span-diminui-mes"
                onClick={(e) => aumentaDiminuiMesAno(e)}
              >
                <svg
                  id="svg-diminui-mes"
                  xmlns="http://www.w3.org/2000/svg"
                  className={stylesImagem.svgAcessoRapido}
                  fill="currentColor"
                  // className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="path-diminui-mes"
                    d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                  />
                </svg>
              </span>
            </div>
            <div className={stylesDiv.child}>
              <InputMonth
                type="month"
                // label={'Lançamento'}
                value={dataMovimento}
                onChange={({ target }) => {
                  setMesAno(target.value);
                }}
              />
            </div>
            <div className={stylesDiv.child} id="div-aumenta-mes">
              <span
                id="span-diminui-mes"
                onClick={(e) => aumentaDiminuiMesAno(e)}
              >
                <svg
                  id="svg-aumenta-mes"
                  xmlns="http://www.w3.org/2000/svg"
                  className={stylesImagem.svgAcessoRapido}
                  fill="currentColor"
                  // class="bi bi-caret-right-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="path-aumenta-mes"
                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <hr />

        <div className={stylesDiv.divSombra}>
          <details className={stylesDetails.details} open>
            <summary>filtros e ordenação</summary>
            <div className={stylesDiv.divFlex100}>
              <div className={stylesDiv.child}>
                <Label label={'Ordenar Por'} />
                <select
                  // className={style.dropdownList}
                  id="seletorOrdenacao"
                  onChange={(e) => setOrdem(e.target.value)}
                  value={ordem}
                  className={stylesSelect.select}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                  >
                    Selecione
                  </option>
                  {dadosOrdenacao.map((dado) => (
                    <option
                      // className={style.dropdownList}
                      key={dado}
                      value={dado}
                    >
                      {dado}
                    </option>
                  ))}
                </select>
              </div>
              <div className={stylesDiv.child}>
                <Label label={'Pagamento'} />
                <select
                  className={stylesSelect.select}
                  id="seletorPago"
                  onChange={(e) => setFiltroPago(e.target.value)}
                  value={filtroPago}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                    // disabled={true}
                  >
                    Selecione
                  </option>

                  <option key={'Pago'} value={'Pago'}>
                    {'Pago'}
                  </option>
                  <option key={'Não Pago'} value={'Não Pago'}>
                    {'Não Pago'}
                  </option>
                </select>
              </div>

              <div className={stylesDiv.child}>
                <Label label={'Tipo'} />
                <select
                  className={stylesSelect.select}
                  id="seletorTipo"
                  onChange={(e) => setFiltroTipo(e.target.value)}
                  value={filtroTipo}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                    // disabled={true}
                  >
                    Selecione
                  </option>

                  <option key={'Despesa'} value={'Despesa'}>
                    {'Despesa'}
                  </option>
                  <option key={'Receita'} value={'Receita'}>
                    {'Receita'}
                  </option>
                  <option key={'Investimentos'} value={'Investimentos'}>
                    {'Investimentos'}
                  </option>
                </select>
              </div>
              <div className={stylesDiv.child}>
                <Label label={'Conta'} />
                <select
                  // className={style.dropdownList}
                  id="seletorConta"
                  onChange={(e) => setFiltroConta(e.target.value)}
                  value={filtroConta}
                  className={stylesSelect.select}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                  >
                    Selecione
                  </option>
                  {data &&
                    getContas().map((dado) => (
                      <option
                        // className={style.dropdownList}
                        key={dado}
                        value={dado}
                      >
                        {dado}
                      </option>
                    ))}
                </select>
              </div>
              <div className={stylesDiv.child}>
                <Label label={'Cartão'} />
                <select
                  // className={style.dropdownList}
                  id="seletorCartao"
                  onChange={(e) => setFiltroCartao(e.target.value)}
                  value={filtroCartao}
                  className={stylesSelect.select}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                  >
                    Selecione
                  </option>
                  {data &&
                    getCartoes().map((dado) => (
                      <option
                        // className={style.dropdownList}
                        key={dado}
                        value={dado}
                      >
                        {dado}
                      </option>
                    ))}
                </select>
              </div>
              <div className={stylesDiv.child}>
                <Label label={'Categoria'} />
                <select
                  // className={style.dropdownList}
                  id="seletorConta"
                  onChange={(e) => setFiltroCategoria(e.target.value)}
                  value={filtroCategoria}
                  className={stylesSelect.select}
                >
                  <option
                    // className={style.dropdownList}
                    key=""
                    value=""
                  >
                    Selecione
                  </option>
                  {data &&
                    getCategorias().map((dado) => (
                      <option
                        // className={style.dropdownList}
                        key={dado}
                        value={dado}
                      >
                        {dado}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </details>

          {data && retornaMovimentoLista()}
        </div>

        <br />
        <br />
        <br />
      </Container>
    );
  } else return null;
};

export default MovimentosCompra;
