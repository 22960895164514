import React from 'react';
import isMobile from '../Helper/Mobile';
import { Pie } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';

const BoxPatrimonio = ({ data }) => {
  const mobile = isMobile();

  function getDistribuicaoPorTipoInv() {
    let dadosPatrimonio = [];

    dadosPatrimonio = data.filter((dados) => dados.registro === '0');

    dadosPatrimonio = dadosPatrimonio.sort((a, b) => a.tipo_inv - b.tipo_inv);

    var total = 0;
    dadosPatrimonio.forEach((registro) => {
      total = total + parseFloat(registro.valor_atual);
    });

    var labels = ['Renda Fixa', 'Renda Variavel'];
    var valores = [];

    var totalRF = 0;
    var totalRV = 0;
    dadosPatrimonio.forEach((registro) => {
      if (registro.tipo_inv === 'Renda Fixa') {
        totalRF = totalRF + parseFloat(registro.valor_atual);
      } else if (registro.tipo_inv === 'Renda Variavel') {
        totalRV = totalRV + parseFloat(registro.valor_atual);
      }
    });

    valores.push(totalRF.toFixed(2));
    valores.push(totalRV.toFixed(2));

    var cores = ['#28a745', '#FA8072'];

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Patrimônio',
          data: valores,
          options: {},
          backgroundColor: cores,
        },
      ],
    };

    return (
      <>
        {labels.length > 0 && (
          <div className={stylesDiv.divCardName}>
            <span className={stylesTipografia.span}>
              {'Distribuição por Tipo Investimento'}
            </span>
            <div>
              <Pie
                // width={100}
                height={150}
                data={dadosGrafico}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    // text: titulo,
                    fontSize: 20,
                    fontFamily: 'Roboto, sans-serif',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      fontFamily: 'Roboto, sans-serif',
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  function getDistribuicaoPorBanco() {
    let dadosPatrimonio = [];

    dadosPatrimonio = data.filter(
      (dados) => dados.registro === '0' && dados.tipo === 'Ativos Financeiros',
    );

    dadosPatrimonio = dadosPatrimonio.sort(
      (a, b) => a.instituicao - b.instituicao,
    );

    var total = 0;
    dadosPatrimonio.forEach((registro) => {
      total = total + parseFloat(registro.valor_atual);
    });

    var labels = [];
    dadosPatrimonio.forEach((registro) => {
      if (labels.indexOf(registro.instituicao) === -1) {
        labels.push(registro.instituicao);
      }
    });

    var valores = [];

    for (var i = 0; i < labels.length; i++) {
      var valor = 0;
      dadosPatrimonio.forEach((registro) => {
        if (registro.instituicao === labels[i]) {
          valor = valor + parseFloat(registro.valor_atual);
        }
      });
      valores.push(valor.toFixed(2));
    }

    var cores = [
      '#28a745',
      '#6A5ACD',
      '#A9A9A9',
      '#008B8B',
      '#FA8072',
      'rgba(255, 206, 86, 1)',
      '#00FF00',
      '#873600',
      'rgba(75, 192, 192, 1)',
      '#F0E68C',
      'black',
      '#DE3163',
      '#FFFF00',
    ];

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Patrimônio',
          data: valores,
          options: {},
          backgroundColor: cores,
        },
      ],
    };

    return (
      <>
        {labels.length > 0 && (
          <div className={stylesDiv.divCardName}>
            <span className={stylesTipografia.span}>
              {'Distribuição por Instituição'}
            </span>
            <div>
              <Pie
                // width={100}
                height={150}
                data={dadosGrafico}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    // text: titulo,
                    fontSize: 20,
                    fontFamily: 'Roboto, sans-serif',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      fontFamily: 'Roboto, sans-serif',
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  function getDistribuicaoPatrimonioTotal() {
    let dadosPatrimonio = data.filter((dados) => dados.registro === '0');

    dadosPatrimonio = dadosPatrimonio.sort((a, b) => a.tipo - b.tipo);

    var labels = ['Ativos Financeiros', 'Ativos Imobilizados'];

    var total = 0;
    dadosPatrimonio.forEach((registro) => {
      total = total + parseFloat(registro.valor_atual);
    });

    var passivoTotal = 0;
    var ativoTotal = 0;
    dadosPatrimonio.forEach((registro) => {
      if (registro.tipo === 'Ativos Financeiros') {
        passivoTotal = passivoTotal + parseFloat(registro.valor_atual);
      } else {
        ativoTotal = ativoTotal + parseFloat(registro.valor_atual);
      }
    });

    var valores = [];
    // valores.push(((passivoTotal / total) * 100).toFixed(2));
    // valores.push(((ativoTotal / total) * 100).toFixed(2));

    valores.push(passivoTotal.toFixed(2));
    valores.push(ativoTotal.toFixed(2));

    var cores = ['#28a745', '#A9A9A9'];

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Patrimônio',
          data: valores,
          options: {},
          backgroundColor: cores,
        },
      ],
    };

    return (
      <>
        {labels.length > 0 && (
          <div className={stylesDiv.divCardName}>
            <span className={stylesTipografia.span}>
              {'Distribuição Por Tipo'}
            </span>
            <div>
              <Pie
                // width={50}
                // height={80}
                data={dadosGrafico}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    // text: titulo,
                    fontSize: 20,
                    fontFamily: 'Roboto, sans-serif',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      fontFamily: 'Roboto, sans-serif',
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  if (data.length > 0) {
    return (
      <div className={stylesDiv.divSombra}>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            :: distribuição dos patrimônios
          </h2>
          <div className={stylesDiv.divFlex100}>
            <div className={stylesDiv.child50}>
              {getDistribuicaoPatrimonioTotal()}
            </div>

            <div className={stylesDiv.child50}>
              {getDistribuicaoPorTipoInv()}
            </div>
            <div className={stylesDiv.child50}>{getDistribuicaoPorBanco()}</div>
          </div>
        </div>
      </div>
    );
  } else return null;
};
export default BoxPatrimonio;
