import React from 'react';
import { AWS_GET_USER_MOVIMENTOS_RESUMO } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import stylesTable from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import { formataMoedaCF } from '../Helper/DateUtilities';
import { Alert, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faCreditCard,
  faHandHoldingUsd,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import BoxLimiteCartao from './BoxLimiteCartao';

const BoxResumoInicio = ({ hide }) => {
  const { data, loading, error, request } = useFetch();

  const [show, setShow] = React.useState(false);
  const [ano, setAno] = React.useState(new Date().getFullYear().toString());
  const [mes, setMes] = React.useState(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );
  const [dataMovimento, setDataMovimento] = React.useState(ano + '-' + mes);

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_MOVIMENTOS_RESUMO(
      window.localStorage.getItem('gd_uid'),
      dataMovimento,
      'data_vencimento',
    );

    request(url, options);
  }, [request]);

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        <hr />
        <h2 className={stylesTipografia.h2}>:: visão geral do mês</h2>

        <div className={stylesDiv.divFlex100Infos}>
          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#28a745' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {formataMoedaCF(data.receitas_mes, hide)}
              </span>
            </div>
            <span className={stylesTipografia.span}>
              <FontAwesomeIcon icon={faHandHoldingUsd} /> {' Receita'}
            </span>
          </div>

          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#B22222' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {formataMoedaCF(data.despesas_mes, hide)}
              </span>
            </div>
            <span className={stylesTipografia.span}>
              <FontAwesomeIcon icon={faShoppingCart} /> {' Despesa'}
            </span>
          </div>

          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: '#008B8B' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {formataMoedaCF(data.investimentos_mes, hide)}
              </span>
            </div>
            <span className={stylesTipografia.span}>
              <FontAwesomeIcon icon={faChartLine} /> {' Investimentos'}
            </span>
          </div>
        </div>

        <div className={stylesDiv.divFlex100Infos}>
          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: 'black' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {formataMoedaCF(data.fatura_cartao_mes, hide)}
              </span>
            </div>
            <span className={stylesTipografia.span}>
              <FontAwesomeIcon icon={faCreditCard} /> {' Fatura Mês Atual'}
            </span>
          </div>

          <div
            className={stylesDiv.divSaldos}
            style={{ backgroundColor: 'black' }}
          >
            <div>
              <span className={stylesTipografia.spanSaldos}>
                {formataMoedaCF(data.fatura_cartao_proximo_mes, hide)}
              </span>
            </div>
            <span className={stylesTipografia.span}>
              <FontAwesomeIcon icon={faCreditCard} /> {' Fatura Próximo Mês'}
            </span>
          </div>
        </div>
        {data.possui_contas_vencendo_hoje === '1' ||
        data.possui_contas_vencidas === '1' ? (
          <>
            <hr />
            <h2 className={stylesTipografia.h2}>:: alertas</h2>

            {data.possui_contas_vencendo_hoje === '1' && (
              <Alert
                variant="danger"
                style={{ paddingTop: '1px', paddingBottom: '1px' }}
              >
                <p
                  className={stylesTipografia.pAlertasAnimate}
                  style={{ fontWeight: 'bold' }}
                >
                  Você possui lançamentos vencendo hoje. Acesse seu Extrato
                  Mensal para efetuar o pagamento!!!
                </p>
              </Alert>
            )}
            {data.possui_contas_vencidas === '1' && (
              <Alert
                variant="danger"
                style={{ paddingTop: '1px', paddingBottom: '1px' }}
              >
                <p
                  className={stylesTipografia.pAlertasAnimate}
                  style={{ fontWeight: 'bold' }}
                >
                  Você possui lançamentos vencidos neste mês. Acesse seu Extrato
                  para efetuar o pagamento!!!
                </p>
              </Alert>
            )}
          </>
        ) : null}
        <BoxLimiteCartao
          isInicio={true}
          hide={hide}
          dataMovimento={dataMovimento}
        />
      </>
    );
  } else return null;
};

export default BoxResumoInicio;
