import React from 'react';
import isMobile from '../Helper/Mobile';
import { Bar } from 'react-chartjs-2';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import styleSelect from '../../Pages/ControleFinanceiro/css/Select.module.css';
import Label from '../Forms/Label';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';

const BoxCategoriaAnual = ({ data, categorias }) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const [categoria, setCategoria] = React.useState(null);
  const [tipoCategoria, setTipoCategoria] = React.useState(' ');

  React.useEffect(() => {
    if (data.length > 1) {
      const categoriaSelecionada = document.getElementById('opt-categoria-0');
      // console.log(categoriaSelecionada.value);
      setCategoria(categoriaSelecionada.value.split('-')[1]);
      setTipoCategoria(categoriaSelecionada.value.split('-')[0]);
    }
  }, []);

  function getDadosMesPorCategoria(dadosAnual) {
    let retorno = [];
    let mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    for (let i = 0; i < mes.length; i++) {
      let dataset = dadosAnual.filter(
        (dados) =>
          dados.categoria === categoria &&
          dados.data_vencimento.substring(
            5,
            dados.data_vencimento.length - 3,
          ) === mes[i],
      );

      let total = 0;
      if (dataset.length > 0) {
        dataset.forEach((registro) => {
          total = total + parseFloat(registro.valor);
        });
        retorno.push(total.toFixed(2));
      } else {
        retorno.push(0);
      }
    }

    // console.log(retorno);
    return retorno;
  }

  function getSaldoMensal() {
    let dadosAnual = data.filter(
      (dados) =>
        dados.forma !== 'Cartão de Crédito' &&
        dados.data_vencimento.substring(0, dados.data_vencimento.length - 6) ===
          window.sessionStorage.getItem('gd_cf_ano'),
    );

    let dataSetCategoria = getDadosMesPorCategoria(dadosAnual, categoria);

    let labels = [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez',
    ];

    return (
      <>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            {':: balanço mês a mês por categoria'}
          </h2>
        </div>
        <br />
        <div>
          <div style={{ textAlign: 'left', margin: '5px' }}>
            <Label label={'Selecione uma Categoria'} />

            <select
              // className={style.dropdownList}
              id="seletoCategoria"
              onChange={(e) => {
                setCategoria(e.target.value.split('-')[1]);
                setTipoCategoria(e.target.value.split('-')[0]);
              }}
              // value={categoria}
              className={styleSelect.select}
            >
              <option id="opt-categoria-selecione" key="" value="">
                Selecione
              </option>
              {categorias.map((dado, idx) => (
                <option
                  id={'opt-categoria-' + idx}
                  defaultValue={idx === 0 ? true : false}
                  key={dado}
                  value={dado}
                >
                  {dado}
                </option>
              ))}
            </select>
          </div>
        </div>
        {categoria && (
          <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  label: categoria ? categoria : '',
                  data: dataSetCategoria,
                  backgroundColor:
                    tipoCategoria === 'Despesa'
                      ? '#B22222'
                      : tipoCategoria === 'Receita'
                      ? '#28a745'
                      : '#008B8B',
                  barPercentage: 1,
                },
              ],
            }}
            height={!mobile ? 100 : 200}
          ></Bar>
        )}
        <div style={{ textAlign: 'left' }}>
          <button
            className={stylesButton.button}
            onClick={() => navigate('/plano-pro/movimentos')}
          >
            Ver Lançamentos
          </button>
        </div>
      </>
    );
  }

  return <>{getSaldoMensal()}</>;
};
export default BoxCategoriaAnual;
