import React from 'react';
import { Container } from 'react-bootstrap';
import isMobile from '../../Components/Helper/Mobile';
import Head from '../../Components/Tags/Head';
import stylePost from '../../Components/Tags/Post.module.css';
import Categorias from '../../Components/Categorias/Categorias';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import InputPortfolio2 from '../../Components/Forms/InputPortfolio2';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';

const Analise = () => {
  const mobile = isMobile();

  return (
    <>
      <Head
        title={'Análise Inteligente | Guardar Dinheiro'}
        metaDescription={
          'Análise Inteligente - Digite o ticker da sua Ação, Fundo Imobiliário ou ETF e receba uma análise inteligente em segundos. Invista com conhecimento!'
        }
        h1={'Análise Inteligente'}
        canonical={window.location.href}
        id_wp_post={9001}
      />

      <BreadcumbH1
        paginaAtual={'Cotação em Tempo Real'}
        texto={'Cotação em Tempo Real'}
      />

      <Container>
        <div className={stylePost.container}>
          <div className={stylePost.itemPost}>
            <AdsContent1 />
            <h1 className={stylesTipografia.h1}>Cotação em Tempo Real</h1>
            <div>
              <p>
                Digite o código da Ação, Fundo Imobiliário ou ETF e confira a{' '}
                <strong>cotação em tempo real</strong>, retorno mês a mês
                comparado com os principais índices do mercado e simule seu
                investimento. Invista com conhecimento!
              </p>
              <InputPortfolio2
                funcionalidade="acoes"
                id="inputPortfolioHeaderAcoes"
                isSearchHeader={true}
                placeholder="Pesquise por Ações"
              />
              <br />
              <InputPortfolio2
                funcionalidade="fii"
                id="inputPortfolioHeaderFii"
                isSearchHeader={true}
                placeholder="Pesquise por Fundos Imobiliários"
              />
              <br />
              <InputPortfolio2
                funcionalidade="etf"
                id="inputPortfolioHeaderEtf"
                isSearchHeader={true}
                placeholder="Pesquise por ETFs"
              />
            </div>
            <br />
            <AdsContent1 />
          </div>
          {!mobile ? (
            <div className={stylePost.itemCategorias}>
              <Categorias />
            </div>
          ) : null}
        </div>

        {mobile && (
          <>
            <hr />
            <div className={stylePost.itemCategorias}>
              <Categorias />
            </div>
          </>
        )}
        <br />
      </Container>
    </>
  );
};

export default Analise;
