import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import BoxFatura from './BoxFatura';
import BoxBalancoContas from './BoxBalancoContas';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';

const BoxContas = ({ data, flagAnoOuMes, fgContaOuCartao, hide }) => {
  const navigate = useNavigate();

  function getBancoPorConta(tipo) {
    let dadosConta;

    if (fgContaOuCartao === 'Conta') {
      if (flagAnoOuMes === 'mes') {
        dadosConta = data.filter(
          (dados) =>
            dados.tipo === tipo &&
            dados.conta.length > 2 &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 3,
            ) === window.sessionStorage.getItem('gd_cf_dt'),
        );
      } else {
        dadosConta = data.filter(
          (dados) =>
            dados.tipo === tipo &&
            dados.conta.length > 2 &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 6,
            ) === window.sessionStorage.getItem('gd_cf_ano'),
        );
      }
    } else {
      if (flagAnoOuMes === 'mes') {
        dadosConta = data.filter(
          (dados) =>
            dados.tipo === tipo &&
            dados.cartao.length > 2 &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 3,
            ) === window.sessionStorage.getItem('gd_cf_dt'),
        );
      } else {
        dadosConta = data.filter(
          (dados) =>
            dados.tipo === tipo &&
            dados.cartao.length > 2 &&
            dados.forma !== 'Cartão de Crédito' &&
            dados.data_vencimento.substring(
              0,
              dados.data_vencimento.length - 6,
            ) === window.sessionStorage.getItem('gd_cf_ano'),
        );
      }
    }

    var labels = [];
    var valores = [];
    let total = 0;

    dadosConta.forEach((registro) => {
      total = total + parseFloat(registro.valor);
    });

    // dadosConta.sort((a, b) => b.conta - a.conta);
    // console.log(dadosConta);

    if (fgContaOuCartao === 'Conta') {
      dadosConta.forEach((registro) => {
        if (labels.indexOf(registro.conta) === -1) {
          labels.push(registro.conta);

          let dadosContaEspecifica = dadosConta.filter((contaEspecifica) =>
            contaEspecifica.conta.includes(registro.conta),
          );

          var saldoConta = 0;

          dadosContaEspecifica.forEach((registro) => {
            saldoConta = saldoConta + parseFloat(registro.valor);
          });

          let porcentagemConta = (total / saldoConta) * 100;

          valores.push(`${saldoConta.toFixed(2)} `);
          // valores.push(`${saldoCategoria.toFixed(2)} `);
        }
      });
    }

    if (fgContaOuCartao === 'Cartão') {
      dadosConta.forEach((registro) => {
        if (labels.indexOf(registro.cartao) === -1) {
          labels.push(registro.cartao);

          let dadosContaEspecifica = dadosConta.filter((contaEspecifica) =>
            contaEspecifica.cartao.includes(registro.cartao),
          );

          var saldoConta = 0;

          dadosContaEspecifica.forEach((registro) => {
            saldoConta = saldoConta + parseFloat(registro.valor);
          });

          let porcentagemConta = (total / saldoConta) * 100;

          valores.push(`${saldoConta.toFixed(2)} `);
          // valores.push(`${saldoCategoria.toFixed(2)} `);
        }
      });
    }

    let height = 100;

    // console.log(labels);
    // console.log(valores);

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Setores',
          data: valores,
          options: {},
          backgroundColor: [
            '#28a745',
            '#6A5ACD',
            '#A9A9A9',
            '#008B8B',
            '#FA8072',
            'rgba(255, 206, 86, 1)',
            '#00FF00',
            '#873600',
            'rgba(75, 192, 192, 1)',
            '#F0E68C',
            'black',
            '#DE3163',
            '#FFFF00',
          ],
        },
      ],
    };

    return (
      <div className={stylesDiv.divFlex100}>
        <div className={stylesDiv.child100}>
          <Doughnut
            // width={100}
            height={150}
            data={dadosGrafico}
            options={{
              cutoutPercentage: 70,
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                // text: titulo,
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
              },
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  fontFamily: 'Roboto, sans-serif',
                },
              },
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={stylesDiv.divSombra}>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>
            {fgContaOuCartao === 'Conta'
              ? ':: balanço por conta'
              : ':: total de despesas por cartões de crédito'}
          </h2>
        </div>

        <div className={stylesDiv.divFlex100}>
          <div className={stylesDiv.child50}>
            {fgContaOuCartao === 'Conta' ? (
              <div className={stylesDiv.divCardName}>
                <span className={stylesTipografia.span}>
                  {'Despesa Por ' + fgContaOuCartao}
                </span>
                {getBancoPorConta('Despesa')}
              </div>
            ) : (
              getBancoPorConta('Despesa')
            )}
          </div>
          <hr />
          {fgContaOuCartao === 'Cartão' && (
            <div className={stylesDiv.child50}>
              <div className={stylesDiv.divCardName}>
                <span className={stylesTipografia.span}>{'Faturas'}</span>

                <BoxFatura
                  data={data}
                  flagAnoOuMes={flagAnoOuMes}
                  hide={hide}
                />
              </div>
            </div>
          )}

          {fgContaOuCartao === 'Conta' && <div className="vr" />}
          {fgContaOuCartao === 'Conta' && (
            <>
              <div className={stylesDiv.child50}>
                <div className={stylesDiv.divCardName}>
                  <span className={stylesTipografia.span}>
                    {'Receita Por ' + fgContaOuCartao}
                  </span>
                  {getBancoPorConta('Receita')}
                </div>
              </div>
            </>
          )}
          <hr />
          {fgContaOuCartao === 'Conta' && (
            <div className={stylesDiv.divFlex100}>
              <div className={stylesDiv.divCardName}>
                <span className={stylesTipografia.span}>
                  {'Saldo por ' + fgContaOuCartao}
                </span>
              </div>
              <BoxBalancoContas
                data={data}
                flagAnoOuMes={flagAnoOuMes}
                hide={hide}
              />
            </div>
          )}
        </div>
        {fgContaOuCartao === 'Conta' && (
          <div style={{ textAlign: 'left' }}>
            {flagAnoOuMes === 'mes' ? (
              <button
                className={stylesButton.button}
                onClick={() => navigate('/plano-pro/movimentos')}
              >
                Ver Extrato
              </button>
            ) : (
              <button
                className={stylesButton.button}
                onClick={() => navigate('/plano-pro/movimentos-ano')}
              >
                Ver Extrato Anual
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BoxContas;
