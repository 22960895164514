import React from 'react';
import { Container, Table } from 'react-bootstrap';
import stylesTipografia from './css/Tipografia.module.css';
import stylesDiv from './css/Div.module.css';
import stylesImagem from './css/Images.module.css';
import { AWS_GET_USER_MOVIMENTOS } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import InputMonth from '../../Components/Forms/InputMonth';
import BoxSaldo from '../../Components/ControleFinanceiro/BoxSaldo';
import BoxCategoria from '../../Components/ControleFinanceiro/BoxCategoria';
import BoxBalancoAnualChart from '../../Components/ControleFinanceiro/BoxBalancoAnualChart';
import BoxCategoriaAnual from '../../Components/ControleFinanceiro/BoxCategoriaAnual';
import BoxCartaoCreditoAnual from '../../Components/ControleFinanceiro/BoxCartaoCreditoAnual';
import BoxPorDia from '../../Components/ControleFinanceiro/BoxPorDia';
import BoxContas from '../../Components/ControleFinanceiro/BoxContas';
import BoxPendentes from '../../Components/ControleFinanceiro/BoxPendentes';
import BoxBalancoMedio from '../../Components/ControleFinanceiro/BoxBalancoMedio';
import BoxEvolucaoSaldos from '../../Components/ControleFinanceiro/BoxEvolucaoSaldos';
import LookNumbers from '../../Components/Helper/LookNumbers';
import { NumberContext } from '../../Contexts/NumberContext';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Head from '../../Components/Tags/Head';
import isMobile from '../../Components/Helper/Mobile';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';

const DashAnual = () => {
  const mobile = isMobile();
  const { data, loading, error, request } = useFetch();
  const { hide } = React.useContext(NumberContext);
  const [show, setShow] = React.useState(false);
  const [ano, setAno] = React.useState(new Date().getFullYear().toString());

  React.useEffect(() => {
    teste();
  }, []);

  function teste() {
    if (window.sessionStorage.getItem('gd_cf_ano') === null) {
      window.sessionStorage.setItem('gd_cf_ano', ano);
    } else {
      setAno(window.sessionStorage.getItem('gd_cf_ano'));
    }

    const { url, options } = AWS_GET_USER_MOVIMENTOS(
      window.localStorage.getItem('gd_uid'),
      window.sessionStorage.getItem('gd_cf_ano'),
      'data_vencimento',
    );

    request(url, options);
  }

  function aumentaDiminuiAno(event) {
    event.preventDefault();

    if (event.target.id.includes('diminui')) {
      setAno(parseInt(ano) - 1);
      window.sessionStorage.setItem('gd_cf_ano', parseInt(ano) - 1);
    } else {
      setAno(parseInt(ano) + 1);
      window.sessionStorage.setItem('gd_cf_ano', parseInt(ano) + 1);
    }
    teste();
  }

  function getCategorias() {
    let categorias = [];

    if (data) {
      data.forEach((registro) => {
        if (
          categorias.indexOf(registro.tipo + '-' + registro.categoria) === -1
        ) {
          if (registro.categoria !== 'Cartão de Crédito') {
            categorias.push(registro.tipo + '-' + registro.categoria);
          }
        }
      });
    }
    categorias = categorias.sort();
    return categorias;
  }

  function getContas() {
    let contas = [];

    if (data) {
      data.forEach((registro) => {
        if (contas.indexOf(registro.conta) === -1) {
          if (registro.conta) {
            contas.push(registro.conta);
          }
        }
      });
    }
    contas = contas.sort();
    return contas;
  }

  function getCartoes() {
    let cartoes = [];

    if (data) {
      data.forEach((registro) => {
        if (cartoes.indexOf(registro.cartao) === -1) {
          if (registro.forma === 'Cartão de Crédito') {
            cartoes.push(registro.cartao);
          }
        }
      });
    }
    cartoes = cartoes.sort();
    return cartoes;
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        <Head
          metaDescription="Resumo Anual | Guardar Dinheiro PRO - Você no Controle Total da sua Vida Financeira."
          title="Resumo Anual | Guardar Dinheiro PRO"
          h1="Resumo Anual | Guardar Dinheiro PRO"
          canonical={window.location.href}
          nivel="1"
          id_wp_post={9000}
        />

        <BreadcumbH1
          paginaAtual=":: RESUMO ANUAL"
          texto=":: RESUMO ANUAL"
          isPro={true}
        />
        <Container>
          <AdsContent1 />
          <h1 className={stylesTipografia.h1}>:: RESUMO ANUAL</h1>
          <LookNumbers />

          <hr />
          <div style={{ textAlign: 'left' }}>
            <div className={stylesDiv.divFlex50}>
              <div className={stylesDiv.child}>
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiAno(e)}
                >
                  <svg
                    id="svg-diminui-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImagem.svgAcessoRapido}
                    fill="currentColor"
                    // className="bi bi-caret-left-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-diminui-mes"
                      d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
              <div className={stylesDiv.child}>
                <InputMonth
                  type="year"
                  // label={'Lançamento'}
                  value={ano}
                  onChange={({ target }) => {
                    setAno(target.value);
                    window.sessionStorage.setItem('gd_cf_ano', target.value);
                  }}
                />
              </div>
              <div className={stylesDiv.child} id="div-aumenta-mes">
                <span
                  id="span-diminui-mes"
                  onClick={(e) => aumentaDiminuiAno(e)}
                >
                  <svg
                    id="svg-aumenta-mes"
                    xmlns="http://www.w3.org/2000/svg"
                    className={stylesImagem.svgAcessoRapido}
                    fill="currentColor"
                    // class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="path-aumenta-mes"
                      d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <hr />

          <div className={stylesDiv.divDash}>
            <div className={stylesDiv.childDash}>
              {data && (
                <BoxSaldo
                  data={data}
                  flagAnoOuMes={'ano'}
                  isInicio={false}
                  hide={hide}
                />
              )}
            </div>
            <div className={stylesDiv.childDash}>
              {data && <BoxBalancoMedio data={data} hide={hide} />}
            </div>

            <div className={stylesDiv.childDash}>
              {data && (
                <BoxPendentes
                  data={data}
                  tipo={'despesa'}
                  flagAnoOuMes={'ano'}
                  hide={hide}
                />
              )}
            </div>

            <div className={stylesDiv.childDash}>
              {data && (
                <BoxPendentes
                  data={data}
                  tipo={'receita'}
                  flagAnoOuMes={'ano'}
                  hide={hide}
                />
              )}
            </div>
          </div>
          <br />

          <div className={stylesDiv.divSombra}>
            {data && <BoxBalancoAnualChart data={data} hide={hide} />}
          </div>
          <br />
          <div className={stylesDiv.divSombra}>
            {data && <BoxEvolucaoSaldos data={data} hide={hide} />}
          </div>

          <br />
          {data && (
            <BoxCategoria data={data} flagAnoOuMes={'ano'} hide={hide} />
          )}
          <br />

          <div className={stylesDiv.divSombra}>
            {data && (
              <BoxCategoriaAnual
                data={data}
                categorias={getCategorias()}
                hide={hide}
              />
            )}
          </div>

          <br />
          {data && (
            <BoxContas
              data={data}
              flagAnoOuMes={'ano'}
              fgContaOuCartao={'Conta'}
              hide={hide}
            />
          )}

          <br />
          <div className={stylesDiv.divSombra}>
            {data && (
              <BoxCartaoCreditoAnual
                data={data}
                contasOuCartoes={getContas()}
                fgContaOuCartao={'Conta'}
              />
            )}
          </div>

          <br />
          {data && (
            <BoxContas
              data={data}
              flagAnoOuMes={'ano'}
              fgContaOuCartao={'Cartão'}
              hide={hide}
            />
          )}
          <br />

          <div className={stylesDiv.divSombra}>
            {data && (
              <BoxCartaoCreditoAnual
                data={data}
                contasOuCartoes={getCartoes()}
                fgContaOuCartao={'Cartao'}
              />
            )}
          </div>

          <br />
          <div className={stylesDiv.divSombra}>
            {data && <BoxPorDia data={data} flagAnoOuMes={'ano'} />}
          </div>

          {/* null} */}

          <br />
          <br />
          <br />
        </Container>
      </>
    );
  } else return null;
};

export default DashAnual;
