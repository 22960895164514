import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoedaCF } from '../Helper/DateUtilities';
import { Table } from 'react-bootstrap';

const BoxBalancoContas = ({ data, flagAnoOuMes, hide }) => {
  function getSaldoPorConta() {
    let dadosConta;
    let dadosTransf;
    if (flagAnoOuMes === 'mes') {
      dadosConta = data.filter(
        (dados) =>
          dados.id_conta.length > 10 &&
          dados.tipo !== 'Transferência' &&
          dados.tipo_registro === '1' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );

      dadosTransf = data.filter(
        (dados) =>
          dados.tipo === 'Transferência' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dadosConta = data.filter(
        (dados) =>
          dados.id_conta.length > 10 &&
          dados.tipo_registro === '1' &&
          dados.tipo !== 'Transferência' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );

      dadosTransf = data.filter(
        (dados) =>
          dados.tipo === 'Transferência' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    dadosConta.sort((a, b) => b.id_conta - a.id_conta);
    // console.log(dadosConta);

    let labels = [];
    let valores = [];

    dadosConta.forEach((registro) => {
      if (labels.indexOf(registro.conta) === -1) {
        labels.push(registro.conta);

        let dadosContaEspecifica = dadosConta.filter((contaEspecifica) =>
          contaEspecifica.conta.includes(registro.conta),
        );

        var receita = 0;
        var despesa = 0;
        var investimentos = 0;
        var transferenciaEntrada = 0;
        var transferenciaSaida = 0;
        var transferencia = 0;
        var saldo = 0;

        dadosContaEspecifica.forEach((registro) => {
          if (registro.tipo === 'Despesa') {
            despesa = despesa + parseFloat(registro.valor);
          } else if (registro.tipo === 'Receita') {
            receita = receita + parseFloat(registro.valor);
          } else if (registro.tipo === 'Investimentos') {
            investimentos = investimentos + parseFloat(registro.valor);
          }
        });

        let dadosTransfEntrada = dadosTransf.filter(
          (contaEspecifica) =>
            contaEspecifica.descricao.split('->')[1] === registro.conta,
        );

        let dadosTransfSaida = dadosTransf.filter(
          (contaEspecifica) =>
            contaEspecifica.descricao.split('->')[0].replace('Transf ', '') ===
            registro.conta,
        );

        dadosTransfEntrada.forEach((registro) => {
          transferenciaEntrada =
            transferenciaEntrada + parseFloat(registro.valor);
        });

        dadosTransfSaida.forEach((registro) => {
          transferenciaSaida = transferenciaSaida + parseFloat(registro.valor);
        });

        transferencia = transferenciaEntrada - transferenciaSaida;
        saldo = saldo + parseFloat(receita);
        saldo = saldo - parseFloat(despesa);
        saldo = saldo - parseFloat(investimentos);
        saldo = saldo + parseFloat(transferencia);

        // console.log(registro.conta);
        // console.log(saldo);

        valores.push(
          receita.toString() +
            '|' +
            despesa.toString() +
            '|' +
            investimentos.toString() +
            '|' +
            transferencia.toString() +
            '|' +
            saldo.toString(),
        );
      }
    });
    // console.log(labels);
    // console.log(valores);

    // for (let i = 0; i < valores.length; i++) {
    //   console.log(valores[i]['receita']);
    // }

    let jsonString = '[';
    for (let i = 0; i < labels.length; i++) {
      jsonString +=
        '{' +
        '"conta" : "' +
        labels[i] +
        '",' +
        '"receita" : "' +
        valores[i].split('|')[0] +
        '",' +
        '"despesa" : "' +
        valores[i].split('|')[1] +
        '",' +
        '"investimentos" : "' +
        valores[i].split('|')[2] +
        '",' +
        '"transferencias" : "' +
        valores[i].split('|')[3] +
        '",' +
        '"saldo" : "' +
        valores[i].split('|')[4] +
        '"},';
    }

    jsonString = jsonString.substring(0, jsonString.length - 1);
    jsonString = jsonString + ']';
    // console.log(JSON.parse(jsonString));

    // labels = [];

    if (labels.length >= 1) {
      var jsonFinal = JSON.parse(jsonString);

      let total = 0;
      jsonFinal.forEach((registro) => {
        // console.log(registro);
        total = total + parseFloat(registro.saldo);
      });

      // let corColuma = '#B22222';

      return (
        <Table
          responsive
          // striped
          // bordered
          hover
          size="sm"
          className={stylesTabela.tabela}
        >
          <thead>
            <tr>
              <th>Conta</th>
              <th>Receita</th>
              <th>Despesa</th>
              <th>Investimentos</th>
              <th>Transferências</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {jsonFinal.map((registro) => (
              <tr key={registro.conta}>
                <td>
                  <span className={stylesTabela.spanDescricao}>
                    {registro.conta}
                  </span>
                </td>
                <td>
                  <span className={stylesTipografia.valorPositivo}>
                    {formataMoedaCF(parseFloat(registro.receita), hide)}
                  </span>
                </td>
                <td>
                  <span className={stylesTipografia.valorNegativo}>
                    {formataMoedaCF(parseFloat(registro.despesa), hide)}
                  </span>
                </td>
                <td>
                  <span className={stylesTipografia.spanInvestimento}>
                    {formataMoedaCF(parseFloat(registro.investimentos), hide)}
                  </span>
                </td>
                <td>
                  {parseFloat(registro.transferencias) >= 0 ? (
                    <span className={stylesTipografia.valorPositivo}>
                      {formataMoedaCF(
                        parseFloat(registro.transferencias),
                        hide,
                      )}
                    </span>
                  ) : (
                    <span className={stylesTipografia.valorNegativo}>
                      {formataMoedaCF(
                        parseFloat(registro.transferencias),
                        hide,
                      )}
                    </span>
                  )}
                </td>

                {registro.saldo >= 0 ? (
                  <td>
                    <span className={stylesTipografia.valorPositivo}>
                      {formataMoedaCF(registro.saldo, hide)}
                    </span>
                  </td>
                ) : (
                  <td>
                    <span className={stylesTipografia.valorNegativo}>
                      {formataMoedaCF(registro.saldo, hide)}
                    </span>
                  </td>
                )}
              </tr>
            ))}
            <tr key={'saldos'}>
              <td colSpan={5}>
                <span className={stylesTipografia.span}>Saldo</span>
              </td>
              <td colSpan={4}>
                {total >= 0 ? (
                  <span className={stylesTipografia.valorPositivo}>
                    {formataMoedaCF(total, hide)}
                  </span>
                ) : (
                  <span className={stylesTipografia.valorNegativo}>
                    {formataMoedaCF(total, hide)}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      );
    } else return null;
  }

  return (
    <>
      <div className={stylesDiv.divFlex100}>
        <div className={stylesDiv.child100}>{getSaldoPorConta()}</div>
      </div>
    </>
  );
};

export default BoxBalancoContas;
