export const API_URL = 'https://afternoon-inlet-88907.herokuapp.com/api/v1';
export const API_URL_AWS =
  'https://d5lah6zn5k.execute-api.sa-east-1.amazonaws.com/producao';

export function GET_TOKEN_FINANCIAL_DATA() {
  return {
    url: 'https://api.financialdata.io/token',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usuario: 'douglas.eduardof@bol.com.br',
        senha: 'rabito',
      }),
    },
  };
}

export function GET_ESTATTISTICS_FINANCIAL_DATA(token, cnpj) {
  return {
    url: `$https://api.financialdata.io//fundos/${cnpj}/estatisticas`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function AWS_API_RENDA_FIXA(body) {
  // console.log(JSON.stringify(body));
  return {
    url: 'https://jl4ld9psa1.execute-api.sa-east-1.amazonaws.com/producao/renda-fixa',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function AWS_GET_PRATELEIRA_FUNDOS() {
  return {
    url:
      // dataframe
      'https://3fy140uv41.execute-api.sa-east-1.amazonaws.com/producao/fundos',
    options: {
      method: 'GET',
    },
  };
}

export function AWS_GET_ACOES() {
  return {
    url: 'https://48r1pdiu25.execute-api.sa-east-1.amazonaws.com/producao/acoes',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_STOCKS() {
  return {
    url: 'https://dh6pptmd90.execute-api.sa-east-1.amazonaws.com/producao/get-investimentos?produto=STOCKS_EUA&chave=TODOS',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_POST_COMPARADOR_FUNDOS(body) {
  // console.log(JSON.stringify(body));
  return {
    url: ' https://o0snqzxqed.execute-api.sa-east-1.amazonaws.com/producao/comparador-bolsa',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function AWS_POST_COMPARADOR_FUNDOS_DF(body) {
  // console.log(JSON.stringify(body));
  return {
    url: 'https://v2j12xgo6a.execute-api.sa-east-1.amazonaws.com/producao/rendimentorendafixa',

    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function AWS_GET_LANCAMENTO_FUNDOS() {
  return {
    url: 'https://sl1nqc9j1f.execute-api.sa-east-1.amazonaws.com/producao/lancamento-fundos',
    options: {
      method: 'GET',
    },
  };
}

export function AWS_GET_INVESTIMENTOS(produto, chave) {
  if (produto === 'MERCADO' && chave !== 'TODOS') {
    chave = chave;
  } else {
    chave = chave.toUpperCase();
  }

  return {
    url: `https://dh6pptmd90.execute-api.sa-east-1.amazonaws.com/producao/get-investimentos?produto=${produto.toUpperCase()}&chave=${chave}`,

    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_INVESTIMENTOS_FILTRO(body) {
  console.log(JSON.stringify(body));
  return {
    url: 'https://5qzblrna21.execute-api.sa-east-1.amazonaws.com/producao/post-investimentos-filtro',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function AWS_GET_NETFLIX() {
  return {
    url: 'https://tdtqg755u6.execute-api.sa-east-1.amazonaws.com/producao/netflix',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_FECHAMENTO_MERCADO() {
  return {
    url: 'https://do6htk0856.execute-api.sa-east-1.amazonaws.com/producao/fechamento',
    options: {
      method: 'GET',
    },
  };
}

export function AWS_GET_RATING_STARS(pagina) {
  var url = `https://2rvgwie2kj.execute-api.sa-east-1.amazonaws.com/producao/portfolio?pagina=${pagina}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_POST_RATING_STARS(pagina, voto) {
  var url = `https://2rvgwie2kj.execute-api.sa-east-1.amazonaws.com/producao/portfolio?pagina=${pagina}¨&voto=${voto}`;
  return {
    url: url,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
}

export function AWS_GET_ROTINAS() {
  return {
    url: 'https://frzlxrsp2m.execute-api.sa-east-1.amazonaws.com/producao/rotinas',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_FUNDAMENTUS(ticker) {
  var url = `https://6o8ac60x21.execute-api.sa-east-1.amazonaws.com/producao/fundamentus?tickers=${ticker}`;
  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
}

export function AWS_GET_TBFUNDAMENTUS(tickers) {
  // https://t8nx4uao96.execute-api.sa-east-1.amazonaws.com/producao/fundamentus?tickers=MGLU3|PETR4
  var url = `https://t8nx4uao96.execute-api.sa-east-1.amazonaws.com/producao/fundamentus?tickers=${tickers}`;
  // console.log(url);
  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
}

export function AWS_GET_USER_PRODUCTS(user_id) {
  var url = `https://vfjza7hau1.execute-api.sa-east-1.amazonaws.com/producao/user-product?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_WALLET(user_id) {
  var url = `https://oe8p7mxbw5.execute-api.sa-east-1.amazonaws.com/producao/user-wallet?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_MOVIMENTOS(user_id, anomes, filtro) {
  var url = `https://hul9706vv3.execute-api.sa-east-1.amazonaws.com/producao/user-movimentos?user_id=${user_id}&anomes=${anomes}&filtro=${filtro}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_CATEGORIAS(user_id) {
  var url = `https://rgmmkoiye2.execute-api.sa-east-1.amazonaws.com/producao/categorias?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_CONTAS(user_id) {
  var url = `https://m2fbwt18e0.execute-api.sa-east-1.amazonaws.com/producao/contas?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_CARTAO(user_id) {
  var url = `https://s6jxo2sk2g.execute-api.sa-east-1.amazonaws.com/producao/cartao?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_PAGAMENTOS(user_id) {
  var url = `https://eg5d2fjmx9.execute-api.sa-east-1.amazonaws.com/producao/pagamentos?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_ORCAMENTOS(user_id, anomes) {
  var url = `https://gnz8sbpu9e.execute-api.sa-east-1.amazonaws.com/producao/orcamento/?user_id=${user_id}&anomes=${anomes}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_DIVIDAS(user_id) {
  var url = `https://6rfu0efxfg.execute-api.sa-east-1.amazonaws.com/producao/dividas?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_PATRIMONIO(user_id) {
  var url = `https://jtjvccyz55.execute-api.sa-east-1.amazonaws.com/producao/patrimonio?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_LIMITE_CARTAO(user_id) {
  var url = `https://3xkrf0vra1.execute-api.sa-east-1.amazonaws.com/producao/user-limite?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_DICA_FINANCEIRA(dataDica) {
  var url = `https://cj9qck57k7.execute-api.sa-east-1.amazonaws.com/producao/dica?dica=${dataDica}`;
  console.log(url);

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_MOVIMENTOS_RESUMO(user_id, anomes, filtro) {
  var url = `https://dgoc7ncxij.execute-api.sa-east-1.amazonaws.com/producao/resumo-inicio?user_id=${user_id}&anomes=${anomes}&filtro=${filtro}`;
  // console.log(url);
  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_USER_META(user_id) {
  var url = `https://4zwba0gyk8.execute-api.sa-east-1.amazonaws.com/producao/meta?user_id=${user_id}`;

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}

export function AWS_GET_SIMULADOR_DIVIDENDOS(ticker, tipoInvestimento) {
  if (tipoInvestimento === 'acoes') {
    tipoInvestimento = 'STOCKS';
  } else if (tipoInvestimento === 'fii') {
    tipoInvestimento = 'REITS';
  }

  var url = `https://9wr7icqwb7.execute-api.sa-east-1.amazonaws.com/producao/dividendos?produto=${tipoInvestimento}&chave=${ticker}&pais=brazil`;

  console.log(url);

  return {
    url: url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
      },
    },
  };
}
