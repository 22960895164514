import React from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import styleTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import {
  formataData,
  formataMoedaCF,
  formataPorcentagem,
} from '../Helper/DateUtilities';
import ModalMeta from './ModalMeta';

const MetasList = ({ data, hide }) => {
  const [show, setShow] = React.useState(false);
  const [idMeta, setIdMeta] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [dataFim, setDataFim] = React.useState(null);
  const [acao, setAcao] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const columns = React.useMemo(
    () => [
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Meta', accessor: 'valor' },
      { Header: 'Prazo', accessor: 'data_fim' },
      { Header: '(R$) Atual', accessor: 'valor_atual_meta' },
      { Header: 'Conclusão', accessor: 'porcentagem' },
      { Header: 'Aporte', accessor: 'aporte_recomendado' },
      { Header: 'id', accessor: 'id' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: ['id'],
  };

  const { rows, state, setGlobalFilter, prepareRow } = useTable(
    { columns, data, initialState },
    useGlobalFilter,
    useSortBy,
  );

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  function editMeta(event, row) {
    event.preventDefault();

    setAcao('UPDATE');

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        setIdMeta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Meta') {
        setValor(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Prazo') {
        setDataFim(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removeMeta(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        removeItem(row.allCells[i].value);
      }
    }
  }

  function removeItem(id_meta) {
    let user_id = window.localStorage.getItem('gd_uid');

    const urlPostPortfolio = `https://4zwba0gyk8.execute-api.sa-east-1.amazonaws.com/producao/meta?user_id=${user_id}&id=${id_meta}&metodo=DELETE`;
    console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);
          setTimeout(() => setLoading(false), 1000);
          // return response.json();
        } else {
          setLoading(false);
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <div className={stylesDiv.divSombra}>
        <div className="search-container">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder={'🔎 PESQUISA '}
          />
        </div>
        <Table responsive bordered hover size="sm" className={styles.tabela}>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>(R$) Meta</th>
              <th>(R$) Atual</th>
              <th>Prazo</th>
              <th>Aporte Recomendado Mensal</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr key={row.allCells[6].value}>
                  <td>
                    <div style={{ display: 'block' }}>
                      <div>
                        <span className={styles.spanDescricao}>
                          {row.cells[0].value}
                        </span>
                      </div>
                      <div>
                        <span className={styles.spanDetalhes}>
                          {'Conclusão: ' +
                            formataPorcentagem(row.cells[4].value)}
                        </span>
                      </div>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {'Conclusão: ' +
                              formataPorcentagem(row.cells[4].value)}
                          </Tooltip>
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            border: '1px solid black',
                            borderRadius: '5px',
                            width: '100%',
                            gap: '-1px',
                          }}
                        >
                          <div
                            className={stylesDiv.divPercProgressBar}
                            style={{
                              width: row.cells[4].value + '%',
                              backgroundColor: '#28a745',
                            }}
                          ></div>
                          <div
                            className={stylesDiv.divPercProgressBar}
                            style={{
                              width:
                                (100 - row.cells[4].value).toString() + '%',
                              backgroundColor: '#B22222',
                            }}
                          ></div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>{formataMoedaCF(row.cells[1].value, hide)}</td>
                  <td>{formataMoedaCF(row.cells[3].value, hide)}</td>
                  <td>{formataData(row.cells[2].value)}</td>
                  <td>{formataMoedaCF(row.cells[5].value, hide)}</td>
                  <td>
                    <div className={styleTabela.divBtnEdit}>
                      <button
                        className={styleTabela.btnEdit}
                        onClick={(e) => {
                          editMeta(e, row);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </button>

                      <button
                        className={styleTabela.btnEdit}
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'TEM CERTEZA QUE DESEJA EXCLUIR A META ' +
                                row.cells[0].value.toString().toUpperCase() +
                                '?',
                            )
                          ) {
                            removeMeta(e, row);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {show && (
        <ModalMeta
          onClose={() => setShow(false)}
          show={show}
          idMeta={idMeta}
          acao={acao}
          descricao={descricao}
          dataFim={dataFim}
          valor={valor}
        />
      )}
    </>
  );
};

export default MetasList;
