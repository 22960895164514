import React from 'react';
// import AdsMGIDArtigo from '../AdsMGID/AdsMGIDArtigo';
import { Container } from 'react-bootstrap';
import isMobile from '../Helper/Mobile';
// import AdsMoneytizer1Billboard from '../AdsMoneytizer/AdsMoneytizer1Billboard';
// import AdsNobeta1 from '../AdsNobeta/AdsNobeta1';
// import AdsenseResponsivo1 from '../Adsense/AdsenseResponsivo1';
import AdsenseVertical from '../Adsense/AdsenseVertical';
// import AdsContent5 from './AdsContent5';

const AdsVertical1 = (props) => {
  const mobile = isMobile();
  const [content1Visible, setContent1Visible] = React.useState(true);
  React.useEffect(() => {
    var path = '/22523725657/guardardinheiro.com.br/Guardardinheiro_Vertical';
    var size = [
      [160, 600],
      [300, 600],
      [120, 600],
      [240, 400],
    ];
    var id = 'Vertical1';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize(
          [0, 0],
          ['fluid', [160, 600], [300, 600], [120, 600], [240, 400]],
        )
        .build();

      if (props.interests && props.interests.length > 0) {
        googletag
          .defineSlot(path, size, id)
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      } else {
        googletag
          .defineSlot(path, size, id)
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      }

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContent1Visibility = () => {
      const content1Element = document.getElementById('Vertical1');
      if (content1Element) {
        setContent1Visible(
          window.getComputedStyle(content1Element).display !== 'none',
        );
      }
    };

    checkContent1Visibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContent1Visibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <div id="Vertical1" style={{ textAlign: 'center' }}></div>
      {!content1Visible && (
        <div
        // style={{
        //   border: '1px solid #00bfff',
        // }}
        >
          {/* {mobile ? <AdsMoneytizer1Billboard /> : <AdsMGIDArtigo />} */}
          {/* <AdsenseResponsivo1 /> */}
          <AdsenseVertical />
        </div>
      )}
    </Container>
  );
};
export default AdsVertical1;
