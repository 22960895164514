import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './ColorPicker.module.css';

const ColorPicker = (props) => {
  const [color, setColor] = React.useState(props.valor);

  return (
    <input
      id={props.id}
      className={styles.picker}
      type="color"
      value={color}
      onChange={(e) => setColor(e.target.value)}
    />
  );
};
export default ColorPicker;
