import React from 'react';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';
import { formataMoeda, formataPorcentagem } from '../Helper/DateUtilities';
import ModalOrcamento from './ModalOrcamento';
import stylesTextos from '../../Pages/ControleFinanceiro/css/Textos.module.css';

const OrcamentoList = ({ data, isGeral, isEssencial, valorDivisao }) => {
  const [show, setShow] = React.useState(false);
  const [idCategoria, setIdCategoria] = React.useState(null);
  const [nomeCategoria, setNomeCategoria] = React.useState(null);
  const [valor, setValor] = React.useState(0);
  const [anomes, setAnomes] = React.useState(null);
  const [total, setTotal] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'id', accessor: 'id' },
      { Header: 'Categoria', accessor: 'nome_categoria' },
      { Header: 'Valor', accessor: 'valor' },
      { Header: 'anomes', accessor: 'anomes' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: ['id', 'anomes'],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  function editOrcamento(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        setIdCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Categoria') {
        setNomeCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'anomes') {
        setAnomes(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor') {
        setValor(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function removeOrcamento(event, row) {
    event.preventDefault();
    var idCategoria = '';
    var anoMes = '';
    for (var i = 0; i < row.cells.length; i++) {
      if (row.allCells[i].column.Header === 'id') {
        idCategoria = row.allCells[i].value;
      }
    }

    deleteItem(idCategoria, window.sessionStorage.getItem('gd_cf_dt'), '0');
  }

  function deleteItem(id_categoria, ano_mes, valor) {
    let user_id = window.localStorage.getItem('gd_uid');

    const urlPostPortfolio = `https://gnz8sbpu9e.execute-api.sa-east-1.amazonaws.com/producao/orcamento/?user_id=${user_id}&id_categoria=${id_categoria}&anomes=${ano_mes}&valor=${valor}&metodo=DELETE`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(false);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function retornaColunasBody(item) {
    var key = item.column.Header.toString() + item.row.index.toString();
    let valor = item.value;
    if (item.column.Header === 'Valor') {
      if (item.value !== 'null') {
        if (isEssencial) {
          valor = formataPorcentagem(item.value);
        } else {
          valor = formataMoeda(item.value);
        }
      } else {
        valor = '-';
      }
    }

    return <td key={key}>{valor}</td>;
  }

  function getTotalDivisaoReceita(row) {
    console.log(row);
    // var linhaGastosEssenciais = document.getElementById('Gastos Essenciais');
    // var colunaGastosEssenciais =
    //   linhaGastosEssenciais.getElementsByTagName('td')[1];
    // var valorGastosEssenciais = colunaGastosEssenciais.innerHTML
    //   .replace('&nbsp;', '')
    //   .replace('%', '')
    //   .replace(',', '.');

    // var linhaFuturo = document.getElementById('Guardar Dinheiro - Seu Futuro');
    // var colunaFuturo = linhaFuturo.getElementsByTagName('td')[1];
    // var valorFuturo = colunaFuturo.innerHTML
    //   .replace('&nbsp;', '')
    //   .replace('%', '')
    //   .replace(',', '.');

    // var linhaGastosNaoEssenciais = document.getElementById(
    //   'Gastos Não Essenciais',
    // );
    // var colunaGastosNaoEssenciais =
    //   linhaGastosNaoEssenciais.getElementsByTagName('td')[1];
    // var valorGastosNaoEssenciais = colunaGastosNaoEssenciais.innerHTML
    //   .replace('&nbsp;', '')
    //   .replace('%', '')
    //   .replace(',', '.');

    // var total = valorGastosEssenciais + valorGastosNaoEssenciais + valorFuturo;
    var total = 0;
    return total;
  }

  return (
    <>
      <div>
        <div className="search-container">
          {!isGeral && !isEssencial ? (
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder={'🔎 Pesquisar...'}
            />
          ) : null}
        </div>
        <Table
          responsive
          bordered
          hover
          size="sm"
          className={stylesTabela.tabela}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? '▾'
                          : '▴'
                        : '▴▾'}
                    </span>
                  </th>
                ))}
                <th>{'Editar / Excluir'}</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} id={row.cells[0].value}>
                  {row.cells.map((cell, index) => {
                    return retornaColunasBody(row.cells[index]);
                  })}
                  <td>
                    <div className={stylesTabela.divBtnEdit}>
                      <OverlayTrigger overlay={<Tooltip>Alterar</Tooltip>}>
                        <button
                          className={stylesTabela.btnEdit}
                          onClick={(e) => {
                            editOrcamento(e, row);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger overlay={<Tooltip>Excluir</Tooltip>}>
                        <button
                          className={stylesTabela.btnEdit}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                'Tem certeza que deseja EXCLUIR o orçamento da categoria ' +
                                  row.cells[0].value +
                                  '?',
                              )
                            ) {
                              removeOrcamento(e, row);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}

            {isEssencial && (
              <tr>
                <td>Total</td>
                {valorDivisao > 100 ? (
                  <td colSpan={2}>
                    {formataPorcentagem(valorDivisao)}
                    <div>
                      <p className={stylesTextos.textoErros}>
                        O Valor Total deve ser no máximo 100%
                      </p>
                    </div>
                  </td>
                ) : (
                  <td colSpan={2}>{formataPorcentagem(valorDivisao)}</td>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <hr />
      {show && (
        <ModalOrcamento
          onClose={() => setShow(false)}
          show={show}
          idCategoria={idCategoria}
          nomeCategoria={nomeCategoria}
          anomes={anomes}
          valor={valor}
          isEssencial={isEssencial}
        />
      )}
    </>
  );
};

export default OrcamentoList;
