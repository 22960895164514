import React from 'react';
import { Container } from 'react-bootstrap';
import Head from '../../Components/Tags/Head';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import { AWS_GET_DICA_FINANCEIRA } from '../../Components/Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Components/Helper/Loading';
import stylesTipografia from '../_css/Tipografia.module.css';
import stylesButton from '../_css/Button.module.css';
import { formataData } from '../../Components/Helper/DateUtilities';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import { useParams } from 'react-router-dom';
import PrecisaVer from '../../Components/Categorias/PrecisaVer';
import AdsContent4 from '../../Components/AdsJoinAds/AdsContent4';
import AdsSticky from '../../Components/AdsUtil/AdsSticky';
import isMobile from '../../Components/Helper/Mobile';

const DicaFinanceiraParam = () => {
  const mobile = isMobile();
  const { data, loading, error, request } = useFetch();

  const params = useParams();
  const dicasManual = [
    {
      data_dica: '2024-05-01',
      dicaTitulo: 'Identifique e organize as receitas e despesas',
      dica: 'Para controlar suas finanças, é importante listar todo o dinheiro que entra e sai todo mês. Muitos se perdem nisso, o que pode causar problemas. Uma ferramenta como o Guardar Dinheiro Pro pode te ajudar a organizar sua vida financeira.',
      descricaoLink: 'Preço promocional por tempo limitado. Compre já!',
      link: 'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro?utm_source=dica-manual&utm_medium=site&utm_campaign=dica',
    },
    {
      data_dica: '2024-05-02',
      dicaTitulo: 'Crie um orçamento financeiro eficaz',
      dica: 'Anote o que ganha e o que gasta. Faça um orçamento. Use ferramentas para ver onde vai seu dinheiro. Decida quanto gastar e economize todo mês! Não esqueça de incluir despesas como café, Uber e iFood, que podem atrapalhar seu orçamento.',
      descricaoLink: 'Preço promocional por tempo limitado. Compre já!',
      link: 'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro?utm_source=dica-manual&utm_medium=site&utm_campaign=dica',
    },
    {
      data_dica: '2024-05-03',
      dicaTitulo: 'Faça um planejamento financeiro',
      dica: 'Organize suas finanças: pense nos gastos de agora, no médio e longo prazo. Lembre-se das despesas inesperadas e reserve dinheiro para futuros projetos.',
      descricaoLink: 'Preço promocional por tempo limitado. Compre já!',
      link: 'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro?utm_source=dica-manual&utm_medium=site&utm_campaign=dica',
    },
    {
      data_dica: '2024-05-04',
      dicaTitulo: 'Economize e reduza despesas',
      dica: 'Ao fazer seu orçamento, veja tudo direitinho. Mudanças pequenas, como trocar o plano de celular ou comprar no supermercado com mais cuidado, podem te ajudar a economizar. Não deixe de se divertir, mas tente gastar menos!',
      descricaoLink: 'Preço promocional por tempo limitado. Compre já!',
      link: 'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro?utm_source=dica-manual&utm_medium=site&utm_campaign=dica',
    },
    {
      data_dica: '2024-05-05',
      dicaTitulo: 'Acompanhe seus gastos e receitas',
      dica: 'Mantenha controle de suas finanças! Além de criar um orçamento eficiente, atualize seus registros regularmente para evitar desorganização.',
      descricaoLink: 'Preço promocional por tempo limitado. Compre já!',
      link: 'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro?utm_source=dica-manual&utm_medium=site&utm_campaign=dica',
    },
  ];

  React.useEffect(() => {
    let usaManual = false;
    for (var i = 0; i < dicasManual.length; ++i) {
      if (dicasManual[i].data_dica === params.data_dica) {
        usaManual = true;
      }
    }
    if (!usaManual) {
      const { url, options } = AWS_GET_DICA_FINANCEIRA(params.data_dica);
      request(url, options);
    }
  }, []);

  function getDicasManual() {
    let usaManual = false;
    for (var i = 0; i < dicasManual.length; ++i) {
      console.log(dicasManual[i]);
      if (dicasManual[i].data_dica === params.data_dica) {
        // setDica(dicasManual[i].dica);
        // setLink(dicasManual[i].link);
        usaManual = true;
        return (
          <>
            <div style={{ textAlign: 'center' }}>
              <h2 className={stylesTipografia.tituloDicaFinanceiraManual}>
                {dicasManual[i].dicaTitulo}
              </h2>

              <p className={stylesTipografia.dicaFinanceiraManual}>
                {dicasManual[i].dica}
              </p>

              <div
                style={{
                  marginLeft: '20%',
                  marginRight: '20%',
                  marginBottom: '50px',
                }}
              >
                <a className={stylesButton.a} href={dicasManual[i].link}>
                  <span style={{ fontWeight: 'bold' }}>
                    {dicasManual[i].descricaoLink}{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </>
        );
      }
    }

    if (!usaManual) {
      if (loading) return <Loading />;
      if (data) {
        return (
          <>
            <div style={{ textAlign: 'center' }}>
              {data.length > 0 && (
                <p className={stylesTipografia.dicaFinanceira}>
                  {data[0]['dica']}
                </p>
              )}
            </div>
          </>
        );
      } else return null;
    }
  }

  return (
    <>
      <Head
        metaDescription={'Dica Financeira do dia ' + params.data_dica}
        title={'Dica Financeira do dia ' + params.data_dica}
        h1={'Dica Financeira do dia ' + params.data_dica}
        canonical={
          'https://www.guardardinheiro.com.br/dica-financeira-do-dia/dia/' +
          params.data_dica
        }
        nivel="2"
        id_wp_post={1700}
      />
      <BreadcumbH1
        paginaAtual={'Dica Financeira do Dia ' + params.data_dica}
        texto={'Dica Financeira do Dia ' + params.data_dica}
        paginaAnterior={'Dica Financeira do Dia'}
        rotaPaginaAnterior={'/dica-financeira-do-dia'}
      />
      <Container>
        <AdsSticky ads={'content1'} pixels={mobile ? 70 : 50} />
        <h1 className={stylesTipografia.h1}>
          {'Dica Financeira do Dia ' + params.data_dica}
        </h1>
        {getDicasManual()}
        <AdsContent1 />
        <PrecisaVer pagina={'dica'} />
      </Container>
    </>
  );
};

export default DicaFinanceiraParam;
