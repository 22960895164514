import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './Wallet.module.css';
import Input from '../Forms/Input';
import InputMask from '../Forms/InputMask';
import Error from '../Helper/Error';

function ModalWalletForm(props) {
  const inputElement = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [precoMedio, setPrecoMedio] = React.useState(null);
  const [qtde, setQtde] = React.useState(null);
  const [erro, setErro] = React.useState(null);

  React.useEffect(() => {
    // console.log(props);

    setPrecoMedio(props.preco_medio);
    setQtde(props.qtde);

    // request(url, options);
  }, []);

  if (!props.show) {
    return null;
  }

  function postItem(http_method, tipo, chave, qtde, preco_medio, user_id) {
    const urlPostPortfolio = `https://oe8p7mxbw5.execute-api.sa-east-1.amazonaws.com/producao/user-wallet?user_id=${user_id}&tipo=${tipo}&chave=${chave}&qtde=${qtde}&preco_medio=${preco_medio}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);
          if (http_method === 'POST') {
            window.location.reload(false);
          }
          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function addFavoritos(event) {
    event.preventDefault();
    setLoading(true);

    let cadastroCompleto = true;
    setErro(null);

    if (!qtde) {
      setErro('Informe a quantidade para continuar o cadastro');
      cadastroCompleto = false;
    }

    if (!precoMedio) {
      setErro('Informe o preço médio para continuar o cadastro');
      cadastroCompleto = false;
    }

    if (cadastroCompleto) {
      postItem(
        'POST',
        props.tipo,
        props.ticker,
        qtde,
        // precoMedio.replace('.', '').replace(',', '.'),
        precoMedio
          .replace('.', '')
          .replace(',', '.')
          .replace('R$', '')
          .replace(' ', ''),
        window.localStorage.getItem('gd_uid'),
      );
    }
    // setTimeout(() => setLoading(false), 1000);
  }

  return (
    <Modal show={props.show} onHide={props.onClose} style={{ top: '20%' }}>
      <Modal.Header>
        <Modal.Title>{/* {'Inclusão de'} */}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.containerModal}>
          <div className={styles.itemContainer}>
            <h2 id={'title-' + props.ticker}>{props.ticker}</h2>
          </div>
          <div className={styles.itemContainer}>
            <InputMask
              name={'input-preco-medio'}
              label="PREÇO MÉDIO"
              placeholder="R$"
              value={precoMedio}
              ref={inputElement}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'R$'}
              onChange={({ target }) =>
                setPrecoMedio(target.value.replace('R$', ''))
              }
            ></InputMask>
          </div>
          <div className={styles.itemContainer}>
            <Input
              name={'input-qtde'}
              label={'QTDE'}
              type="number"
              placeholder="0"
              value={qtde}
              ref={inputElement}
              onChange={({ target }) => setQtde(target.value)}
            ></Input>
          </div>
        </div>
        {erro && <Error error={erro} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onClose}>
          Fechar
        </Button>

        {loading ? (
          <Button variant="primary" disabled>
            Salvando...
          </Button>
        ) : (
          <Button variant="primary" onClick={(e) => addFavoritos(e)}>
            Salvar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWalletForm;
