import React from 'react';
import { Container, ListGroup, Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import styleTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import { formataData, formataMoedaCF } from '../Helper/DateUtilities';
import ModalCadastroMovimento from './ModalCadastroMovimento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillAlt,
  faMoneyBillWaveAlt,
  faMoneyCheck,
} from '@fortawesome/free-solid-svg-icons';
import ModalExcluiMov from './ModalExcluiMov';
import isMobile from '../Helper/Mobile';
import MovimentosListFatura from './MovimentoListFatura';

const FaturasList = ({ data, hide }) => {
  const mobile = isMobile();
  const [show, setShow] = React.useState(false);
  const [showExclusao, setShowExclusao] = React.useState(false);
  const [idMovimento, setIdMovimento] = React.useState(null);
  const [idMovimentoDetalhe, setIdMovimentoDetalhe] = React.useState(null);
  const [dataMovimento, setDataMovimento] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);
  const [conta, setConta] = React.useState(null);
  const [cartao, setCartao] = React.useState(null);
  const [situacao, setSituacao] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [formaPagamento, setFormaPagamento] = React.useState(null);
  const [totalParcelas, setTotalParcelas] = React.useState(null);
  const [acao, setAcao] = React.useState(null);
  const [tipoPagamento, setTipoPagamento] = React.useState(null);
  const [tipoLancamento, setTipoLancamento] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'Tipo', accessor: 'tipo' },
      { Header: 'Dia', accessor: 'data_compra' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Categoria', accessor: 'categoria' },
      { Header: 'Valor', accessor: 'valor' },
      { Header: 'Vencimento', accessor: 'data_vencimento' },
      { Header: 'fg_forma', accessor: 'fg_forma' },
      { Header: 'Forma', accessor: 'forma' },
      { Header: 'Pagamento', accessor: 'id_situacao' },
      { Header: 'id', accessor: 'id' },
      { Header: 'id_mov', accessor: 'id_mov' },
      { Header: 'id_conta', accessor: 'id_conta' },
      { Header: 'id_cartao', accessor: 'id_cartao' },
      { Header: 'conta', accessor: 'conta' },
      { Header: 'cartao', accessor: 'cartao' },
      { Header: 'Parcela', accessor: 'parcela' },
      { Header: 'data_pagamento', accessor: 'data_pagamento' },

      { Header: 'id_categoria', accessor: 'id_categoria' },
      { Header: 'tipo_pagamento', accessor: 'tipo_pagamento' },
      { Header: 'cor', accessor: 'cor' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: [
      // 'id',
      // 'id_mov',
      // 'id_conta',
      // 'id_cartao',
      // 'fg_forma',
      // 'id_categoria',
      // 'tipo_pagamento',
      // 'data_pagamento',
      // 'parcela',
      // 'conta',
      // 'cartao',
    ],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  const showModalExclusao = (event) => {
    event.preventDefault();
    setShowExclusao(true);
  };

  function editMovimento(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_mov') {
        setIdMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdMovimentoDetalhe(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo') {
        setTipoLancamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Dia') {
        setDataMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_categoria') {
        setCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor') {
        setValor(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'fg_forma') {
        setFormaPagamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Pagamento') {
        setSituacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_conta') {
        setConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_cartao') {
        setCartao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Parcela') {
        setTotalParcelas(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo_pagamento') {
        setTipoPagamento(row.allCells[i].value);
      }
    }

    showModal(event);
  }

  function deleteMovimento(event, row) {
    event.preventDefault();
    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_mov') {
        setIdMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdMovimentoDetalhe(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo') {
        setTipoLancamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Dia') {
        setDataMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_categoria') {
        setCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor') {
        setValor(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'fg_forma') {
        setFormaPagamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Pagamento') {
        setSituacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_conta') {
        setConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_cartao') {
        setCartao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Parcela') {
        setTotalParcelas(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo_pagamento') {
        setTipoPagamento(row.allCells[i].value);
      }
    }

    showModalExclusao(event);
  }

  return (
    <>
      <div className="search-container">
        {/* <input
            type="text"
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
          /> */}

        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          placeholder={'🔎 PESQUISA '}
        />
      </div>
      <Table
        responsive
        // bordered
        hover
        size="sm"
        className={styleTabela.tabela}
        {...getTableProps()}
      >
        <thead>
          <tr>
            <th>Descrição</th>
            <th>R$</th>
            <th>Vencimento</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <tr
                  {...row.getRowProps()}
                  id={row.cells[0].value}
                  key={row.cells[0].value}
                >
                  <td>
                    <div style={{ display: 'block' }}>
                      <div>
                        <span className={styles.spanDescricao}>
                          {row.cells[2].value + ' ' + row.cells[15].value}
                        </span>
                      </div>
                      <div>
                        <span className={styles.spanDetalhes}>
                          {formataData(row.cells[1].value) +
                            '-' +
                            row.cells[3].value}
                        </span>
                      </div>
                      <div>
                        <span className={styles.spanDetalhes}>
                          {row.cells[14].value}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>{formataMoedaCF(row.cells[4].value, hide)}</td>
                  <td>{formataData(row.cells[5].value)}</td>

                  <td>
                    <span className={styles.span}>
                      <button
                        disabled={row.allCells[18].value === '1' ? true : false}
                        className={styles.btnEdit}
                        onClick={(e) => {
                          setAcao('UPDATE');
                          editMovimento(e, row);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </button>
                      <button
                        disabled={row.allCells[18].value === '1' ? true : false}
                        className={styles.btnEdit}
                        onClick={(e) => {
                          deleteMovimento(e, row);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </span>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>

      {show && (
        <ModalCadastroMovimento
          onClose={() => setShow(false)}
          show={show}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          conta={'pgto-' + tipoPagamento + '|' + conta}
          cartao={'pgto-' + tipoPagamento + '|' + cartao}
          valor={parseFloat(valor).toFixed(2)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          acao={acao}
          is_fatura={true}
          tipoLancamento={tipoLancamento}
        />
      )}
      {showExclusao && (
        <ModalExcluiMov
          onClose={() => setShowExclusao(false)}
          show={showExclusao}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          valor={parseFloat(valor).toFixed(2)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          tipoLancamento={tipoLancamento}
        />
      )}
    </>
  );
};

export default FaturasList;
