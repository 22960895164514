import React from 'react';
import stylesDiv from '../../Pages/ControleFinanceiro/css/Div.module.css';
import stylesTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import { formataMoedaCF, formataPorcentagem } from '../Helper/DateUtilities';
import { Pie } from 'react-chartjs-2';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const BoxCategoria = ({ data, flagAnoOuMes, hide }) => {
  const navigate = useNavigate();

  function getSaldoPorCategoria(tipo) {
    let dadosCategoria;

    if (flagAnoOuMes === 'mes') {
      dadosCategoria = data.filter(
        (dados) =>
          dados.tipo === tipo &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 3,
          ) === window.sessionStorage.getItem('gd_cf_dt'),
      );
    } else {
      dadosCategoria = data.filter(
        (dados) =>
          dados.tipo === tipo &&
          dados.forma !== 'Cartão de Crédito' &&
          dados.data_vencimento.substring(
            0,
            dados.data_vencimento.length - 6,
          ) === window.sessionStorage.getItem('gd_cf_ano'),
      );
    }

    var labels = [];
    var valores = [];
    let total = 0;

    dadosCategoria.forEach((registro) => {
      total = total + parseFloat(registro.valor);
    });

    dadosCategoria.sort((a, b) => b.categoria - a.categoria);
    // console.log(dadosCategoria);

    dadosCategoria.forEach((registro) => {
      if (
        labels.indexOf(
          registro.categoria.toLowerCase() + '|' + registro.cor,
        ) === -1
      ) {
        labels.push(registro.categoria.toLowerCase() + '|' + registro.cor);

        let dadosCategoriaEspecifica = dadosCategoria.filter(
          (categoriaEspecifica) =>
            categoriaEspecifica.categoria.includes(registro.categoria),
        );

        var saldoCategoria = 0;

        dadosCategoriaEspecifica.forEach((registro) => {
          saldoCategoria = saldoCategoria + parseFloat(registro.valor);
        });

        let porcentagemCategoria = (total / saldoCategoria) * 100;

        valores.push(`${saldoCategoria.toFixed(2)} `);
        // valores.push(`${saldoCategoria.toFixed(2)} `);
      }
    });

    var cores = [];
    var categorias = [];
    for (let i = 0; i < labels.length; i++) {
      cores.push('#' + labels[i].split('|')[1]);
      categorias.push(labels[i].split('|')[0]);
    }

    // console.log(cores);
    // console.log(categorias);
    // console.log(valores);

    let jsonString = '[';
    for (let i = 0; i < categorias.length; i++) {
      jsonString +=
        '{' +
        '"cor" : "' +
        cores[i] +
        '",' +
        '"categoria" : "' +
        categorias[i] +
        '",' +
        '"valor" : "' +
        valores[i].replace(' ', '') +
        '",' +
        '"porcentagem" : "' +
        ((parseFloat(valores[i].replace(' ', '')) / total) * 100).toString() +
        '"},';
    }

    jsonString = jsonString.substring(0, jsonString.length - 1);
    jsonString = jsonString + ']';
    // console.log(jsonString);

    var jsonFinal = '';
    if (categorias.length >= 1) {
      jsonFinal = JSON.parse(jsonString);

      jsonFinal.sort((a, b) => b.porcentagem - a.porcentagem);
      // console.log(jsonFinal);
    }

    let height = 100;

    var dadosGrafico = {
      labels: categorias,
      datasets: [
        {
          label: 'Setores',
          data: valores,
          options: {},
          backgroundColor: cores,
        },
      ],
    };

    return (
      <div className={stylesDiv.divFlex100}>
        {/* <div className={stylesDiv.child}>
          <Pie
            // width={100}
            // height={height}
            data={dadosGrafico}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              title: {
                display: true,
                // text: titulo,
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
              },
              legend: {
                display: false,
                position: 'right',
                labels: {
                  fontFamily: 'Roboto, sans-serif',
                },
              },
            }}
          />
        </div> */}

        <div className={stylesDiv.child100}>
          <Table
            responsive
            hover
            size="sm"
            className={stylesTabela.tabelaSemBorda}
          >
            <tbody>
              {jsonString.length > 5 &&
                jsonFinal.map((dado) => (
                  <tr key={dado.categoria}>
                    <td style={{ width: '3%' }}>
                      <div
                        className={stylesDiv.divQuadradoCategoria}
                        style={{
                          backgroundColor: dado.cor,
                        }}
                      ></div>
                    </td>
                    <td
                      style={{ width: '40%', fontFamily: 'Roboto, sans-serif' }}
                    >
                      {dado.categoria}
                    </td>
                    <td style={{ width: '50%' }}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {formataPorcentagem(dado.porcentagem)}
                          </Tooltip>
                        }
                      >
                        <div className={stylesDiv.divProgressBar}>
                          <div
                            className={stylesDiv.divPercProgressBar}
                            style={{
                              width: dado.porcentagem + '%',
                              backgroundColor:
                                tipo === 'Despesa' ? '#B22222' : '#28a745',
                            }}
                          ></div>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td style={{ fontFamily: 'Roboto, sans-serif' }}>
                      {formataMoedaCF(dado.valor, hide)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={stylesDiv.divSombra}>
        <div className={stylesDiv.divCardName}>
          <h2 className={stylesTipografia.h2}>{':: balanço por categoria'}</h2>
        </div>
        <div className={stylesDiv.divFlex100}>
          <div className={stylesDiv.child50}>
            <div className={stylesDiv.divCardName}>
              <span className={stylesTipografia.span}>
                {'Despesa Por Categoria'}
              </span>
              {getSaldoPorCategoria('Despesa')}
            </div>
          </div>
          <div className="vr" />
          <div className={stylesDiv.child50}>
            <div className={stylesDiv.divCardName}>
              <span className={stylesTipografia.span}>
                {'Receita Por Categoria'}
              </span>
              {getSaldoPorCategoria('Receita')}
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          {flagAnoOuMes === 'mes' ? (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos')}
            >
              Ver Extrato
            </button>
          ) : (
            <button
              className={stylesButton.button}
              onClick={() => navigate('/plano-pro/movimentos-ano')}
            >
              Ver Extrato Anual
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BoxCategoria;
