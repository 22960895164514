import React from 'react';
import styles from './Label.module.css';

const Label = ({ label, name }) => {
  return (
    <label htmlFor={name} className={styles.label}>
      {label}
    </label>
  );
};

export default Label;
