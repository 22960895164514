import React from 'react';

const AdsenseContent2Amp = (props) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // marginTop: '20px',
          // marginBottom: '20px',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'inline-block',
            width: '320px',
            height: '100px',
            // border: '1px solid #00bfff',
          }}
          data-ad-client="ca-pub-4615548473778420"
          data-ad-slot="4142837361"
          data-ad-keywords="investimentos, finanças pessoais, economia"
        ></ins>
      </div>
    </>
  );
};
export default AdsenseContent2Amp;
